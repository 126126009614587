import React from 'react'
import { Link } from 'react-router-dom'
import ButtonMui from '@mui/material/Button'

const Button = ({ href, ...props }) =>
  href ? (
    <ButtonMui to={href} component={Link} {...props} />
  ) : (
    <ButtonMui {...props} />
  )

export default Button
