import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useInsurances = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Insurances', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/insurances.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient insurances")
      }
      const result = await res.json()
      return result
    },
    enabled: !!patientId,
  })

  return { insurances: data, isLoading, isError }
}

export const useAddInsurance = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addInsurance, ...others } = requestMiddleware({
    key: ['AddInsurance', `${patientId}`],
    request: async insurance =>
      await fetch(`${baseURL}/v2/rest/patients/${patientId}/insurances.json`, {
        method: 'POST',
        headers,
        body: JSON.stringify(insurance),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientId}`],
      })
    },
  })

  return { addInsurance, ...others }
}

export const useRemoveInsurance = (patientId, insuranceId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: removeInsurance, ...others } = requestMiddleware({
    key: ['RemoveInsurance', `${patientId}`, `${insuranceId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/insurances/${insuranceId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientId}`],
      })
    },
  })

  return { removeInsurance, ...others }
}

export const useInsuranceSubscribers = insuranceId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['InsuranceSubscribers', `${insuranceId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/insurances/${insuranceId}/eligibilities/subscribers.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the subscribers")
      }
      const result = await res.json()
      return result
    },
    enabled: !!insuranceId,
  })

  return { subscribers: data, isLoading, isError }
}
