import React, { useRef, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import InfiniteScroll from 'react-infinite-scroller'
import CircularProgress from '@mui/material/CircularProgress'
import DayDivider from '@/v2/components/common/DayDivider'
import Typography from '@/v2/components/common/Typography'
import { useMessages } from '@/v2/hooks/messages/useMessages'
import RTMessages from '@/v2/hooks/subscriptions/messages/RTMessages'
import { getGroupedMessages, sortByCreation, getDate } from './utils'
import SendReply from './SendReply'
import Message from './Message'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  padding: 23px 23px 0 33px;
`

// PS: The Conversation endpoint returns the first batch of messages (first page),
//     the next pages we request from messages endpoint (messageHistory)
const MessagesHistory = ({
  historyMessages = [],
  currentPage: currentPageProp,
  totalPages,
}) => {
  const queryClient = useQueryClient()
  const [messages, setMessages] = useState([]) // second page or more
  const [currentPage, setCurrentPage] = useState(currentPageProp || 1)
  const { conversationId } = useParams()
  const { messageHistory } = useMessages(
    currentPage === 1 ? null : conversationId,
    currentPage
  )
  let debounce

  useEffect(() => {
    scrollToBottom()
  }, [historyMessages])

  useEffect(() => {
    if (messageHistory) {
      const filteredMessages = messageHistory?.records.filter(
        record => !historyMessages.find(({ id }) => id === record.id)
      )
      setMessages(messages => [...messages, ...filteredMessages])
    }
  }, [messageHistory])

  const groupedMessages = useMemo(
    () => getGroupedMessages([...historyMessages, ...messages]),
    [historyMessages, messages]
  )

  const days = Object.keys(groupedMessages).sort((a, b) =>
    new Date(a) > new Date(b) ? 1 : -1
  )

  const hasMore = conversationId
    ? currentPage < (messageHistory?.totalPages || totalPages)
    : false

  const scrollRef = useRef()
  const scrollToBottom = () => {
    const element = scrollRef.current
    if (element) {
      element.scrollTo(0, element.scrollHeight)
    }
  }

  const handleLoadMore = () => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      if (hasMore) {
        if (historyMessages.length < 10) {
          queryClient.invalidateQueries({
            queryKey: ['Conversation', conversationId],
          })
        }
        setCurrentPage(currentPage + 1)
      }
    }, 1000)
  }

  return (
    <>
      <RTMessages />
      <Wrapper ref={scrollRef}>
        <InfiniteScroll
          isReverse
          element="div"
          useWindow={false}
          initialLoad={false}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          loader={
            <CircularProgress
              key="loader"
              sx={{ margin: '10px auto', display: 'block' }}
            />
          }
          style={{ minHeight: 'calc(100% - 90px)' }}
        >
          {!hasMore && (
            <Typography textAlign="center" fontSize="14px">
              No more messages.
            </Typography>
          )}
          {days.map(day => (
            <div key={day}>
              <DayDivider date={getDate(day)} />
              {sortByCreation(groupedMessages[day]).map(message => (
                <Message key={message.id} message={message} />
              ))}
            </div>
          ))}
        </InfiniteScroll>
        <SendReply onSend={scrollToBottom} />
      </Wrapper>
    </>
  )
}

export default MessagesHistory
