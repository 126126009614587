import React from 'react'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import Form from './Form'
import { useProfileNotes } from '@/v2/hooks/useProfileNotes'
import { useUpdateTreatmentNote } from '@/v2/hooks/treatments/useTreatmentNotes'

const EditPatientNote = ({ patientId, note, onSave }) => {
  const { updateTreatmentNote } = useUpdateTreatmentNote(patientId, note.id)
  const { onOpenSnackbar } = useSnackbarContext()
  const { updateProfileNote } = useProfileNotes()

  const handleEdit = async value => {
    try {
      if (value.length === 0) {
        return onOpenSnackbar('You need to fill the note field.', 'error')
      }

      if (note.classification === 'yellow') {
        const { error, ...data } = await updateTreatmentNote(value)

        if (error) {
          throw new Error(error)
        }
        onSave && onSave(data)
      } else {
        const updatedNote = await updateProfileNote({
          note: {
            id: note.id,
            annotation: value,
            patientId,
          },
        })

        onSave(updatedNote)
      }

      onOpenSnackbar(`Edited ${note.classification} note.`)
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  return <Form note={note} onSave={value => handleEdit(value)} />
}

export default EditPatientNote
