import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import Typography from '@/v2/components/common/Typography'
import { useFindPatient } from '@/v2/hooks/patient/useFindPatient'
import { formatSearch } from '@/v2/utils/format'
import { getHighlightPhone } from '@/v2/utils/highlight'
import { Wrapper, SearchBar, Patient, Info } from './styles'

const SearchMobile = () => {
  const theme = useTheme()
  const textInput = useRef(null)
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const { patients = [], isLoading, isError } = useFindPatient(searchTerm)

  let debounce
  const handleChangeSearch = term => {
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      setSearchTerm(formatSearch(term))
    }, 300)
  }
  const handleRedirect = patient => {
    if (!patient?.id) return
    history.push(`/v2/patient/${patient.id}`)
  }

  if (isError)
    return <Typography textAlign="center">Something went wrong.</Typography>

  return (
    <Wrapper>
      <SearchBar theme={theme.palette}>
        <OutlinedInput
          fullWidth
          autoFocus
          placeholder="Search..."
          inputRef={textInput}
          inputProps={{
            style: { fontSize: '20px', fontWeight: '500' },
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="black" />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear field value"
                onClick={() => {
                  textInput.current.value = ''
                  handleChangeSearch('')
                }}
                edge="end"
              >
                <CloseIcon color="black" />
              </IconButton>
            </InputAdornment>
          }
          onChange={({ target }) => handleChangeSearch(target.value)}
        />
      </SearchBar>
      {searchTerm && isLoading && (
        <Typography textAlign="center" sx={{ mt: '12px' }}>
          Loading...
        </Typography>
      )}
      {patients.map(patient => (
        <Patient key={patient.id} onClick={() => handleRedirect(patient)}>
          <PatientAvatar patient={patient} size="60px" />
          <Info>
            <Typography
              variant="h2"
              fontSize="20px"
              style={{ textTransform: 'capitalize' }}
            >
              {patient.fullName}
            </Typography>
            <Typography variant="h6">
              {getHighlightPhone(patient.phoneNumber, searchTerm)}
            </Typography>
          </Info>
        </Patient>
      ))}
    </Wrapper>
  )
}

export default SearchMobile
