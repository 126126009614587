import React, { useState } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useUpdatePatient } from '@/v2/hooks/patient/usePatient'
import { toSnakeCase } from '@/v2/utils/format'
import Form from './Form'
import validator from './validator'

const EditPatient = ({ patient, isOpen, onClose, onSuccess }) => {
  const [values, setValues] = useState({
    firstName: patient.firstName || '',
    lastName: patient.lastName || '',
    dob: patient.dob || null,
    gender: patient.gender || 0,
    ssn: patient.ssn || '',
    referredBy: patient.referredBy || '',
  })
  const [error, setError] = useState({})
  const { updatePatient } = useUpdatePatient(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()

  const handleEditPatient = async () => {
    try {
      // TODO: ask to migrate from snake case to camel case
      const { error, ...data } = await updatePatient({
        patient: toSnakeCase({
          id: patient.id,
          fullName: `${values.firstName} ${values.lastName}`,
          ...values,
        }),
      })

      if (error) {
        setError(toCamelCase(error))
      } else {
        onSuccess && onSuccess(data)
        onOpenSnackbar('Patient has been updated.', 'success')
        onClose()
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  const handleSubmit = () => {
    const validErrors = validator(values)
    setError(validErrors || {})

    if (!validErrors) {
      handleEditPatient()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Patient"
      confirmLabel="Save"
      cancelLabel="Cancel"
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Form
        values={values}
        error={error}
        onChange={value => setValues({ ...values, ...value })}
      />
    </Modal>
  )
}

export default EditPatient
