import parse from 'html-react-parser'
import { toUSNumberFormat } from '@/v2/utils/format'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import { DAY_IN_US_FORMAT, CONTRACT_STATUS } from '@/v2/constants'
import { format } from 'date-fns'

export const capitalize = val => val.charAt(0).toUpperCase() + val.slice(1)

const toFixed = (num, fixed) => {
  if (!num) return
  if (typeof num === 'number') num = Math.ceil(num * 100) / 100
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
  return parseFloat(num.toString().match(re)[0])
}

export const geThisDayOnNextMonth = () => {
  const today = nowOnClinicTimezone()

  return format(
    new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()),
    DAY_IN_US_FORMAT
  )
}

export const getContractStatus = contract => {
  switch (true) {
    case !contract.active:
      return CONTRACT_STATUS.archived
    case contract.signed && contract.active:
      return CONTRACT_STATUS.signed
    default:
      return CONTRACT_STATUS.unsigned
  }
}

export const hideUnregister = invoice => {
  return (
    invoice.status === 'payment_confirmed' ||
    invoice.status === 'refunded' ||
    invoice.status === 'canceled' ||
    invoice.status === 'partially_refunded'
  )
}

export const range = (init, stop, step = 1) => {
  let range = []
  let counter = init

  do {
    range.push(counter)
    counter += step
  } while (counter <= stop)

  return range
}

export const parseHtmlToString = html => {
  if (!html) return ''

  let formatHtml = html

  formatHtml = formatHtml.replace(/\n/g, '<br />')

  return parse(formatHtml)
}

/* HELPERS */
export const toCurrency = (val, prefix = true) => {
  const number = typeof val === 'number' ? val.toFixed(2) : val
  const formattedNumber = toUSNumberFormat(number)

  return prefix ? `${formattedNumber}` : formattedNumber
}

export const toCamelCase = str => {
  if (str.includes('_')) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return str
    .replace(/\s(.)/g, function($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function($1) {
      return $1.toLowerCase()
    })
}

export const calculateAdjustment = (adjustment, amount) => {
  return adjustment > 0 ? amount * (adjustment / 100) : 0
}

export const fromCurrencyToNumber = val => {
  return parseFloat(val.replace(',', ''))
}

export const revertCreatePaymentFormFormat = params => {
  if (isNaN(params.downPayment)) {
    params.downPayment = ''
  }

  return {
    ...params,
    amount: String(params.amount),
    downPayment: String(params.downPayment),
    discountRate: params.discountRate * 100,
    interestRate: params.interestRate * 100,
  }
}

const downPaymentCalculation = (params, installmentAmount) => {
  if (
    params.downPayment &&
    installmentAmount &&
    parseFloat(params.downPayment).toFixed(2) >=
      parseFloat(installmentAmount).toFixed(2)
  ) {
    const installments = params.installments - 1

    const restOfDownPayment = params.downPayment - installmentAmount

    const newTotal = installmentAmount * installments

    const newAmountWithDiscount = newTotal - restOfDownPayment

    const newTotalDue =
      newAmountWithDiscount +
      calculateAdjustment(params.interestRate * 100, newAmountWithDiscount)

    const newInstallmentAmount = newTotalDue / installments

    return {
      updatedInstallments: installments,
      totalDue: newTotalDue,
      installmentAmount: toFixed(newInstallmentAmount, 2),
    }
  }

  return {
    updatedInstallments: params.installments,
  }
}

export const updateAmounts = params => {
  let amount = params.amount

  if (typeof amount === 'string') {
    amount = fromCurrencyToNumber(amount)
  }

  const amountWithDiscount =
    amount - calculateAdjustment(params.discountRate, amount)

  const totalDue =
    amountWithDiscount +
    calculateAdjustment(params.interestRate, amountWithDiscount)

  const installmentAmount = totalDue / params.installments

  return {
    ...params,
    amountWithDiscount: amountWithDiscount,
    installmentAmount: toFixed(installmentAmount, 2),
    totalDue: totalDue,
    downPayment: String(toFixed(installmentAmount, 2)),
  }
}

export const calcInstallmentAmountWithDiscount = (
  amount,
  installments,
  discountRate
) => {
  let amountWithDiscount = amount / installments
  if (discountRate) {
    amountWithDiscount =
      (amount - calculateAdjustment(discountRate, amount)) / installments
  }

  return toFixed(amountWithDiscount, 2).toString()
}

export const updateContractAmounts = params => {
  let amount = params.amount

  if (typeof amount === 'string') {
    amount = fromCurrencyToNumber(amount)
  }

  let amountWithDiscount =
    amount - calculateAdjustment(params.discountRate * 100, amount)

  const totalDue =
    amountWithDiscount +
    calculateAdjustment(params.interestRate * 100, amountWithDiscount)

  const installmentAmount = amount / params.installments

  const installmentAmountWithDiscount = totalDue / params.installments

  return {
    ...params,
    amountWithDiscount: amountWithDiscount,
    installmentAmount: toFixed(installmentAmountWithDiscount, 2),
    installmentAmountWithoutDiscount: toFixed(installmentAmount, 2),
    totalDue: totalDue,
    ...downPaymentCalculation(params, installmentAmountWithDiscount),
  }
}
