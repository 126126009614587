import React, { createContext, useContext, useState, useMemo } from 'react'
import { useCallback } from 'react'

export const MessagesContext = createContext([])

export const MessagesProvider = ({ children, patient: patientValue = {} }) => {
  const [message, setMessage] = useState('')
  const [patient, setPatient] = useState(patientValue)

  const handleSetPatient = useCallback(value => {
    if (patient.id !== value.id) {
      setPatient(value)
      setMessage('')
    }
  }, [])

  const handleSetMessage = useCallback(text => {
    setMessage(text)
  }, [])

  const handleAppendToMessage = useCallback(text => {
    setMessage(message =>
      message[message.length - 1] === ' '
        ? `${message}${text}`
        : `${message} ${text}`
    )
  }, [])

  const messagesInfo = useMemo(
    () => ({
      message,
      patient,
      onSetMessage: handleSetMessage,
      onAppendToMessage: handleAppendToMessage,
      onSetPatient: handleSetPatient,
    }),
    [
      message,
      patient,
      handleSetMessage,
      handleAppendToMessage,
      handleSetPatient,
    ]
  )
  return (
    <MessagesContext.Provider value={messagesInfo}>
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessagesContext = () => {
  const {
    message,
    patient,
    onSetMessage,
    onAppendToMessage,
    onSetPatient,
  } = useContext(MessagesContext)

  return { message, patient, onSetMessage, onAppendToMessage, onSetPatient }
}
