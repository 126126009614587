import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import { format, parseISO } from 'date-fns'
import Grid from '@mui/material/Grid'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconMenu from '@/v2/components/common/IconMenu'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { currency } from '@/v2/utils/format'
import PaymentStatus from '../PaymentStatus'
import useInvoiceOptions from '@/v2/hooks/useInvoiceOptions'
import PaidWith from './PaidWith'
import DefaultMethod from './DefaultMethod'
import InvoiceLabel from '../shared/InvoiceLabel'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main};
  ${({ theme, $showBorder }) =>
    $showBorder ? `border-bottom: 1px solid ${theme.palette.silver.dark};` : ''}
  padding: 28px 34px;
`

const Invoice = ({ invoice, showBorder, paymentMethods, isSingle, plan }) => {
  const theme = useTheme()
  const invoiceOptions = useInvoiceOptions({
    invoice,
    isInstallPayment: true,
  })

  const buildInvoice = () => {
    const invoiceCode = (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="18px"
          color={theme.palette.gray.dark}
          sx={{ height: '23px' }}
        >
          INVOICE
        </Typography>
        <Button
          variant="text"
          href={{ pathname: invoice.publicUrl }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Typography
            fontSize="15px"
            color={theme.palette.link.main}
            sx={{ textDecoration: 'underline' }}
          >
            {`#${invoice.identifierToken}`}
          </Typography>
        </Button>
      </Grid>
    )

    const invoiceDueDate = (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="18px"
          color={theme.palette.gray.dark}
          sx={{ height: '23px' }}
        >
          DUE DATE
        </Typography>
        <Typography fontSize="15px">
          {format(parseISO(invoice.dueDate), DAY_IN_US_FORMAT)}
        </Typography>
      </Grid>
    )

    const invoiceTotal = (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="18px"
          color={theme.palette.gray.dark}
          sx={{ height: '23px' }}
        >
          TOTAL
        </Typography>
        <Typography fontSize="15px">
          {currency(invoice.finalAmount.toFixed(2))}
        </Typography>
      </Grid>
    )

    const invoiceTotalPaid = (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="18px"
          color={theme.palette.gray.dark}
          sx={{ height: '23px' }}
        >
          TOTAL PAID
        </Typography>
        <Typography fontSize="15px">
          {currency(invoice.paid.toFixed(2))}
        </Typography>
      </Grid>
    )

    const invoiceStatus = (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="18px"
          color={theme.palette.gray.dark}
          sx={{ height: '23px' }}
        >
          STATUS
        </Typography>
        <PaymentStatus
          status={invoice.status}
          dueDate={invoice.dueDate}
          paidAt={invoice.paidAt}
          overdue={invoice.overdue}
          payments={invoice.payments}
        />
      </Grid>
    )

    const invoiceDefaultMethod = (
      <DefaultMethod invoice={invoice} paymentMethods={paymentMethods} />
    )

    const invoicePaidWith = <PaidWith invoice={invoice} />

    const invoiceName = (
      <Grid item xs={1}>
        <Typography wordBreak bold lineHeight="23px">
          {plan.description}
        </Typography>
        <InvoiceLabel status={plan.status} />
      </Grid>
    )

    if (isSingle) {
      return (
        <Wrapper theme={theme} $isSingle={isSingle} $showBorder={showBorder}>
          <Grid container spacing={1} columns={8}>
            {invoiceName}
            {invoiceDueDate}
            {invoiceTotal}
            {invoiceTotalPaid}
            {invoiceStatus}
            {invoiceCode}
            {invoiceDefaultMethod}
            {invoicePaidWith}
          </Grid>

          <IconMenu
            icon={<MoreVertIcon fontSize="small" />}
            aria-label="More"
            title="More"
            style={{ marginLeft: 'auto', marginRight: '0' }}
            options={invoiceOptions}
          />
        </Wrapper>
      )
    }

    return (
      <Wrapper theme={theme} showBorder={showBorder}>
        <Grid container spacing={1} columns={7}>
          {invoiceCode}
          {invoiceDueDate}
          {invoiceTotal}
          {invoiceTotalPaid}
          {invoiceStatus}
          {invoiceDefaultMethod}
          {invoicePaidWith}
        </Grid>

        <IconMenu
          icon={<MoreVertIcon fontSize="small" />}
          aria-label="More"
          title="More"
          style={{ marginLeft: 'auto', marginRight: '0' }}
          options={invoiceOptions}
        />
      </Wrapper>
    )
  }

  return buildInvoice()
}

export default Invoice
