import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import InfiniteScroll from 'react-infinite-scroller'
import Typography from '@/v2/components/common/Typography'
import { useClosedConversations } from '@/v2/hooks/messages/useConversations'
import Conversation from '../Conversation'
import { filterConversations } from './utils'

const NoConversations = () => (
  <Typography sx={{ textAlign: 'center', my: '10px' }}>
    No conversations
  </Typography>
)

const ClosedConversations = ({ filter }) => {
  const history = useHistory()
  const {
    conversations,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useClosedConversations()

  if (isError) history.push('/500')

  const filteredConversations = useMemo(
    () => filterConversations([...conversations], filter),
    [conversations, filter]
  )

  return (
    <InfiniteScroll
      element="div"
      useWindow={false}
      initialLoad={filter !== 'all'}
      loadMore={() => !isLoading && hasNextPage && fetchNextPage()}
      hasMore={hasNextPage}
      loader={
        <CircularProgress
          key="loader"
          sx={{ margin: '10px auto', display: 'block' }}
        />
      }
    >
      {filteredConversations.map(conversation => (
        <Conversation key={conversation.id} {...conversation} />
      ))}
      {filteredConversations.length === 0 && !isLoading && <NoConversations />}
      {isLoading && (
        <CircularProgress
          key="loader"
          sx={{ margin: '10px auto', display: 'block' }}
        />
      )}
    </InfiniteScroll>
  )
}

export default ClosedConversations
