import React, { useState } from 'react'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'
import Modal from '@/v2/components/common/Modal'
import validator from '@/v2/components/forms/TimeOff/validator'
import TimeOffForm from '@/v2/components/forms/TimeOff/Form'
import formatTimeOff from '@/v2/components/forms/TimeOff/toForm'
import fromForm from '@/v2/components/forms/TimeOff/fromForm'
import UpdateTimeOff from '@/v2/graphql/mutations/UpdateTimeOff.gql'
import DeleteTimeOff from '@/v2/graphql/mutations/DeleteTimeOff.gql'
import getDayInfo from '@/v2/graphql/queries/DaySchedule.gql'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
// TODO: remove lodash
import isEqual from 'lodash/isEqual'
import { errorsFromJson } from '@/v2/utils/forms'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import { useLoadData } from './hooks/useLoadData'

const EditDeleteTimeOff = ({ id, timeoff, isOpen, onClose }) => {
  const [updateTimeOff] = useMutation(UpdateTimeOff)
  const [deleteTimeOff] = useMutation(DeleteTimeOff)
  const { loading, rooms, doctors, clinic } = useLoadData(id)
  const [values, setValues] = useState(formatTimeOff(timeoff))
  const [formError, setFormError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { onOpenSnackbar } = useSnackbarContext()

  const handleChange = value => {
    setValues({ ...values, ...value })
  }

  const handleEditTimeOff = async () => {
    const response = await updateTimeOff({
      variables: { timeOff: fromForm(values) },
    })

    const asyncErrors = errorsFromJson(response.data.updateTimeOff.errors)

    if (asyncErrors) {
      return setFormError(asyncErrors)
    } else {
      onOpenSnackbar('Updated blocked time.')
    }

    onClose()
  }

  const handleSubmit = () => {
    const validErrors = validator(values)
    setFormError(validErrors || {})

    if (!validErrors) {
      setIsLoading(true)
      handleEditTimeOff()
    }
  }

  const handleDelete = () => {
    setIsLoading(true)
    try {
      deleteTimeOff({
        variables: { timeOff: { id } },
        refetchQueries: [
          {
            query: getDayInfo,
            variables: { date: format(values.startsAtDate, DATE_PARAM_FORMAT) },
          },
          'DaySchedule',
        ],
      })
    } finally {
      onClose()
      onOpenSnackbar('Blocked time canceled.')
    }
  }

  const handleCancel = () =>
    confirm('Are you sure you want to cancel this blocked time?') &&
    handleDelete()

  return (
    <Modal
      isOpen={isOpen}
      loading={isLoading || loading}
      title="Edit Blocked Time"
      cancelLabel="Cancel Blocked Time"
      onConfirm={handleSubmit}
      onClose={({ header, footer }) => {
        if (header) {
          if (!isEqual(formatTimeOff(timeoff), values)) {
            return (
              confirm(
                'Are you sure you want to leave without saving changes?'
              ) && onClose()
            )
          } else {
            onClose()
          }
        }
        if (footer) {
          return handleCancel()
        }
      }}
    >
      <TimeOffForm
        values={values}
        clinic={clinic}
        rooms={rooms}
        doctors={doctors}
        error={formError}
        onChange={handleChange}
      />
    </Modal>
  )
}

export default EditDeleteTimeOff
