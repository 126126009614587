import styled from 'styled-components'

export const ConversationWrapper = styled.div`
  width: calc(100% - 290px);
`

export const ConversationContent = styled.div`
  display: inline-flex;
  max-height: calc(100vh - 185px);
  width: 100%;
  height: 100%;
`

export const RightBar = styled.div`
  width: 290px;
  border-left: 1px solid ${({ theme }) => theme.primary.main};
  overflow: auto;
`

export const SelectConversation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
