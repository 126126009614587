import styled from 'styled-components'

export const Wrapper = styled.div`
  ${({ $bg }) => `background: ${$bg || 'transparent'};`}
  background-size: 54px;
  height: ${({ height }) => height || 24}px;
  position: absolute;
  flex: 1;
  overflow: hidden;
  padding: 3px 10px 2px 10px;
  margin-right: 10px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
  z-index: 90;
  transition: 0.3s;

  &:hover {
    ${({ type }) =>
      type === 'appointment'
        ? `
          -webkit-box-shadow: inset 0px 0px 44px 4px rgba(0,0,0,0.07);
          box-shadow: inset 0px 0px 44px 4px rgba(0,0,0,0.07);
        `
        : 'filter: contrast(96%);'}
  }
`

export const Description = styled.div`
  display: flex;
  align-items: center;

  && > * {
    margin-right: 6px;
    max-height: 16px;
    overflow: hidden;
  }
`

export const Labels = styled.div`
  display: flex;
  align-items: center;
`
