import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Select from '@/v2/components/common/Select'
import RTConversations from '@/v2/hooks/subscriptions/messages/RTConversations'
import { ListHeader, ListBody } from './styles'
import OpenConversations from './OpenConversations'
import ClosedConversations from './ClosedConversations'

const closedOptions = [
  { value: 'all', label: 'All' },
  { value: 'unanswered', label: 'Unanswered' },
  { value: 'flagged', label: 'Flagged' },
]

const allOptions = [
  { value: 'all', label: 'All' },
  { value: 'unread', label: 'Unread' },
  { value: 'unanswered', label: 'Unanswered' },
  { value: 'flagged', label: 'Flagged' },
]

const OpenClosedConversations = () => {
  const theme = useTheme()
  const [tab, setTab] = useState(0)
  const [filter, setFilter] = useState('all')

  return (
    <>
      <RTConversations />
      <ListHeader theme={theme.palette}>
        <Select
          key="filter"
          variant="standard"
          defaultValue="all"
          options={tab === 0 ? allOptions : closedOptions}
          onChange={value => setFilter(value)}
          sx={{ float: 'right', top: '9px' }}
        />
        <Tabs value={tab}>
          <Tab key="Open" label="Open" onClick={() => setTab(0)} />
          <Tab key="Closed" label="Closed" onClick={() => setTab(1)} />
        </Tabs>
      </ListHeader>
      <ListBody key={tab}>
        {tab === 0 ? (
          <OpenConversations key="Open" filter={filter} />
        ) : (
          <ClosedConversations key="Closed" filter={filter} />
        )}
      </ListBody>
    </>
  )
}

export default OpenClosedConversations
