import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import MoreIcon from '@mui/icons-material/MoreVert'
import IconMenu from '@/v2/components/common/IconMenu'
import { useDeleteImage } from '@/v2/hooks/useSeriesImages'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import ImageItem from './ImageItem'
import ConfirmDelete from './ConfirmDelete'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

const size = {
  1: { width: '300px', height: '300px', aspect: 1 },
  2: { width: '300px', height: '300px', aspect: 1 },
  3: { width: '300px', height: '300px', aspect: 1 },
  4: { width: '300px', height: '200px', aspect: 1.515 },
  5: { width: '300px', height: '200px', aspect: 1.515 },
  6: { width: '300px', height: '200px', aspect: 1.515 },
  7: { width: '300px', height: '200px', aspect: 1.515 },
  8: { width: '300px', height: '200px', aspect: 1.515 },
  9: { width: '300px', height: '300px', aspect: 1 },
  10: { width: 'calc(600px + 1vw)', height: '300px', aspect: 2.54 }, //height should be 250px, but for alignment I'm making the same height as position 9
}

const mobileSize = {
  1: { width: '32vw', height: '32vw' },
  2: { width: '32vw', height: '32vw' },
  3: { width: '32vw', height: '32vw' },
  4: { width: '32vw', height: '21vw' },
  5: { width: '32vw', height: '21vw' },
  6: { width: '32vw', height: '21vw' },
  7: { width: '32vw', height: '21vw' },
  8: { width: '32vw', height: '21vw' },
  9: { width: '32vw', height: '32vw' },
  10: { width: '65vw', height: '32vw' },
}

export const getDimensions = (isMobile, position = 1) =>
  isMobile
    ? `
    height: ${mobileSize[position].height};
    width: ${mobileSize[position].width};
  `
    : `
    width: ${size[position].width};
    height: ${size[position].height};
    border-radius: 3px;
    cursor: pointer;
  `

const Photo = styled.div`
  ${({ $isMobile, $position }) => getDimensions($isMobile, $position)}
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  transition: 0.2s;
  position: relative;

  &:hover {
    opacity: 0.8;
  }
`

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
`

export const ImageContent = ({
  id,
  src,
  twoColumns,
  position,
  handleUploadClick,
}) => {
  const { isMobile } = useDetectDevice()
  const { id: patientId, progression, series } = useParams()
  const { deleteImage } = useDeleteImage()
  const theme = useTheme()
  const [show, setShow] = useState(false)
  const { onDeleteImage } = usePatientContext()
  const { onOpenSnackbar } = useSnackbarContext()
  const [open, setOpen] = React.useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  const handleClickOpen = e => {
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemove = () => {
    setOpenDeleteConfirmation(true)
    setShow(false)
  }

  const handleDelete = async () => {
    try {
      await deleteImage(patientId, series, { id }, () => {
        onDeleteImage(progression, series, id)
        setOpenDeleteConfirmation(false)
      })
    } catch (error) {
      let message = 'We were not able to delete the image'
      if (error?.message) message = error.message
      onOpenSnackbar(message, 'error')
    }
  }

  const imageId = src.split('/').pop()

  return (
    <>
      <Photo
        className="photo"
        $isMobile={isMobile ? 1 : 0}
        twoColumns={twoColumns}
        $position={position}
        src={src}
        onClick={handleClickOpen}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <IconMenu
          options={[
            {
              label: 'Edit',
              onClick: handleUploadClick,
            },
            { label: 'Remove', onClick: handleRemove },
          ]}
          icon={<MoreIcon />}
          aria-label="More"
          sx={{
            height: '30px',
            width: '30px',
            background: theme.palette.white.main,
            borderRadius: '50% !important',
            display: show ? 'flex' : 'none',
            position: 'absolute',
            zIndex: 99,
            right: '0px',
            margin: '10px',
            justifyContent: 'center',
          }}
        />
      </Photo>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="view-dialog-title"
        aria-describedby="view-dialog-description"
        fullScreen
        maxWidth="xl"
      >
        <DialogContent
          sx={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Picture src={src} id={imageId} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDelete
        isOpen={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDelete}
        singular
      />
    </>
  )
}

const Image = ({
  id,
  src,
  twoColumns,
  children,
  position,
  originalImageUrl,
}) => {
  return (
    <ImageItem
      twoColumns={twoColumns}
      position={position}
      aspect={size[position || 1].aspect}
      id={id}
      src={src}
      originalImageUrl={originalImageUrl}
    >
      {children}
    </ImageItem>
  )
}

export default Image
