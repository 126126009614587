import React from 'react'
import { useTheme } from '@mui/material/styles'
import { format, parseISO } from 'date-fns'
import Typography from '@/v2/components/common/Typography'
import Day from './Day'
import { Column, Headline, BlankHeadline, HeadlineWrapper } from './styles'

const WeekDay = props => {
  const theme = useTheme()
  const {
    date,
    hasHourRule,
    hasBorder,
    subjectView,
    typeFilter,
    loading,
    error,
    appointments,
    timeOffs,
    clinicHours,
    defaultStartHour,
    defaultEndHour,
  } = props

  return (
    <Column
      $hasHourRule={hasHourRule}
      $hasBorder={hasBorder}
      theme={theme.palette}
    >
      <HeadlineWrapper>
        {hasHourRule && <BlankHeadline />}
        <Headline>
          <Typography variant="h3">
            {format(parseISO(date), 'd EEEE')}
          </Typography>
        </Headline>
      </HeadlineWrapper>
      <Day
        date={date}
        subjectView={subjectView}
        typeFilter={typeFilter}
        loading={loading}
        error={error}
        appointments={appointments}
        timeOffs={timeOffs}
        clinicHours={clinicHours}
        defaultStartHour={defaultStartHour}
        defaultEndHour={defaultEndHour}
      />
    </Column>
  )
}

export default WeekDay
