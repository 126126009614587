import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'
import useInvoiceActions from '@/v2/hooks/useInvoiceActions'
import { useCallback } from 'react'
import { isAfter } from 'date-fns'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import { useUser } from '@/v2/hooks/useUser'

const useInvoiceOptions = ({ invoice, isInstallPayment = false }) => {
  const { onOpenSnackbar } = useSnackbarContext()
  const { patient } = usePatientContext()
  const { goTo } = useModals()
  const {
    sendPaymentLink: { mutate: onSendLink },
    cancelInvoice: { mutate: onCancelInvoice },
    scheduleInvoiceForToday,
  } = useInvoiceActions({ patientId: patient.id })
  const { user } = useUser()

  const { creditCards, bankAccounts } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const hasPaymentMethods =
    (creditCards && creditCards.length > 0) ||
    (bankAccounts && bankAccounts.length > 0)

  const hasCheckPayments = invoice.payments.some(
    payment =>
      payment.paymentMethod === 'check' && payment.status !== 'declined'
  )

  const isInvoiceSchedulable =
    !invoice.overdue &&
    isAfter(new Date(invoice.dueDate), new Date()) &&
    invoice.paymentMethodId

  const awaitingPaymentItems = user?.admin
    ? [
        {
          label: 'Send Payment Link',
          onClick: () => {
            onSendLink(invoice)
            onOpenSnackbar('Payment Link Sent.')
          },
        },
        {
          label: 'Cancel Invoice',
          onClick: () => {
            onCancelInvoice(invoice)
            onOpenSnackbar('Invoice Canceled.')
          },
        },
      ]
    : [
        {
          label: 'Send Payment Link',
          onClick: () => {
            onSendLink(invoice)
            onOpenSnackbar('Payment Link Sent.')
          },
        },
      ]

  const deniedItems = user?.admin
    ? [
        {
          label: 'Cancel Invoice',
          onClick: () => {
            onCancelInvoice(invoice)
            onOpenSnackbar('Invoice Canceled.')
          },
        },
      ]
    : []

  const declineCheckOption = hasCheckPayments
    ? [
        {
          label: 'Decline Check',
          onClick: () =>
            goTo(`/v2/patient/${patient.id}/finance/decline-payment`, {
              invoice,
            }),
        },
      ]
    : []

  const paidItems = user?.admin
    ? [
        {
          label: 'Refund',
          onClick: () =>
            goTo(`/v2/patient/${patient.id}/finance/refund-payment`, {
              invoice,
            }),
        },
        ...declineCheckOption,
      ]
    : []

  const chargeNowOption = [
    {
      label: 'Charge now',
      onClick: () =>
        goTo(`/v2/patient/${patient.id}/finance/change-payment-method`, {
          invoice,
          type: 'charge',
        }),
    },
  ]

  const sheduleInvoiceOption = isInvoiceSchedulable
    ? [
        {
          label: 'Schedule to due date',
          onClick: async () => {
            try {
              if (confirm('Are you sure you want to schedule this invoice?')) {
                const res = await scheduleInvoiceForToday({
                  invoiceId: invoice.id,
                })

                if (res.error) {
                  throw new Error(res.error)
                }

                return onOpenSnackbar('Invoice scheduled.')
              }
            } catch (e) {
              return onOpenSnackbar(e.message, 'error')
            }
          },
        },
      ]
    : []

  const viewInvoiceOption = [
    {
      label: 'View invoice',
      target: '_blank',
      href: { pathname: invoice.publicUrl },
    },
  ]

  const changePaymentMethodOption = hasPaymentMethods
    ? [
        {
          label: 'Change Payment Method',
          onClick: () =>
            goTo(`/v2/patient/${patient.id}/finance/change-payment-method`, {
              invoice,
              type: 'change',
              isInstallPayment,
            }),
        },
      ]
    : []

  const getOptionsBasedOnInvoiceStatus = useCallback(() => {
    const isInvoicePaid = invoice.payments.some(
      payment => payment.status === 'processed'
    )

    switch (invoice.status) {
      case 'awaiting_process':
        return [
          ...viewInvoiceOption,
          ...chargeNowOption,
          ...changePaymentMethodOption,
          ...awaitingPaymentItems,
        ]
      case 'awaiting_payment':
        return isInvoicePaid > 0
          ? [
              ...viewInvoiceOption,
              ...chargeNowOption,
              ...changePaymentMethodOption,
              ...sheduleInvoiceOption,
              ...paidItems,
              ...awaitingPaymentItems,
            ]
          : [
              ...viewInvoiceOption,
              ...chargeNowOption,
              ...changePaymentMethodOption,
              ...sheduleInvoiceOption,
              ...awaitingPaymentItems,
            ]
      case 'payment_confirmed':
        return [...viewInvoiceOption, ...paidItems]
      case 'denied':
        return [...deniedItems]
      case 'refunded':
        return [...viewInvoiceOption]
      case 'partially_refunded':
        return [...viewInvoiceOption, ...paidItems]
      case 'canceled':
        return [...viewInvoiceOption]
      default:
        return [
          ...viewInvoiceOption,
          ...changePaymentMethodOption,
          ...awaitingPaymentItems,
        ]
    }
  }, [
    awaitingPaymentItems,
    changePaymentMethodOption,
    chargeNowOption,
    deniedItems,
    invoice.status,
    invoice.payments,
    paidItems,
    sheduleInvoiceOption,
    viewInvoiceOption,
  ])

  return getOptionsBasedOnInvoiceStatus()
}

export default useInvoiceOptions
