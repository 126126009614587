import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import Typography from '@/v2/components/common/Typography'
import ConversationActions from './ConversationActions'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import { formatPhone } from '@/v2/utils/format'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  height: 61px;
`

const PatientHeader = ({ patient, conversation }) => {
  const theme = useTheme()
  return (
    <Wrapper theme={theme.palette}>
      {patient && (
        <PatientAvatar
          patient={patient}
          size={40}
          sx={{ width: 40, height: 40 }}
        />
      )}
      <Typography fontSize="20px" variant="h2" sx={{ margin: '10px' }}>
        {patient.fullName || formatPhone(patient.phoneNumber)}
      </Typography>
      <Chip label={conversation.open ? 'Open' : 'Closed'} />
      {!!conversation && <ConversationActions conversation={conversation} />}
    </Wrapper>
  )
}

export default PatientHeader
