import styled from 'styled-components'

export const Wrapper = styled.div`
  ${({ height }) => (height ? `max-height: ${height}` : '')}
`

export const Title = styled.div`
  padding: 13px 20px 10px 20px;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: calc(100% - 47px);
  overflow-y: auto;

  /* hides scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

export const WrapperItem = styled.div`
  position: relative;

  &:hover > .hover-buttons {
    opacity: 1;
  }
`

export const HoverWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: 0.3s;
  align-items: start;
`

export const Button = styled.div`
  background: ${({ theme }) => theme?.link.main || theme.primary.main};
  color: ${({ theme }) => theme.background.default};
  height: 30px;
  width: 80px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
`
