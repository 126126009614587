import { DATE_PARAM_FORMAT } from '@/v2/constants'
import { format, isWeekend } from 'date-fns'

export const getTileClass = (date, daysRate = [], isGrayWhenFull = false) => {
  if (isWeekend(date)) return 'gray-date'

  const formattedDate = format(date, DATE_PARAM_FORMAT)
  const day = daysRate.find(({ date }) => date === formattedDate)

  if (!day || !day.isOpen) return 'gray-date'

  const rate = Number.parseInt(day.occupancyRate)

  if (isGrayWhenFull && rate === 100) return 'gray-date'

  if (rate < 30) {
    return 'green-line'
  } else if (rate >= 30 && rate < 75) {
    return 'yellow-line'
  } else if (rate >= 75 && rate <= 100) {
    return 'red-line'
  } else {
    return 'gray-date'
  }
}
