import { format, addMinutes } from 'date-fns'

const appointmentState = {
  patientType: 'registered',
  newPatient: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    heardFrom: '',
  },
  registeredPatient: {},
  doctorId: '',
  typeId: '',
  roomId: '',
  virtual: false,
  startsAtDate: new Date(),
  duration: '',
}

const timeOffState = {
  type: 'clinic',
  clinicId: '',
  doctorId: '',
  roomId: '',
  virtual: false,
  startsAtDate: new Date(),
  startsAtTime: '10:00 AM',
  endsAtTime: '10:30 AM',
  notes: '',
}

const formatHour = date => {
  const hour = format(date, 'hh:mm:aa')
  const parts = hour.split(':')
  const newMinutes = `${parts[1] - (parts[1] % 15)}`
  const minutes = newMinutes.length === 1 ? `0${newMinutes}` : newMinutes
  return `${parts[0]}:${minutes} ${parts[2]}`
}

export const getAppointmentState = ({
  registeredPatient,
  doctorId,
  roomId,
  virtual,
  startDate,
  contactMethod,
}) => {
  const startsAtDate = startDate || appointmentState.startsAtDate
  const startsAtTime = formatHour(startsAtDate)

  return {
    ...appointmentState,
    registeredPatient: registeredPatient || {},
    virtual: virtual || appointmentState.virtual,
    doctorId: doctorId || appointmentState.doctorId,
    roomId: roomId || appointmentState.roomId,
    startsAtDate: startsAtDate || appointmentState.startsAtDate,
    startsAtTime: startsAtTime,
    contactMethod: contactMethod || null,
  }
}

export const getTimeOffState = (
  clinicId,
  { doctorId, roomId, virtual, startDate }
) => {
  const startsAtDate = startDate || timeOffState.startsAtDate
  const startsAtTime = formatHour(startsAtDate)
  return {
    ...timeOffState,
    clinicId: clinicId,
    doctorId: doctorId || timeOffState.doctorId,
    roomId: roomId || timeOffState.roomId,
    virtual: virtual,
    startsAtDate: startsAtDate,
    startsAtTime: startsAtTime,
    endsAtTime: format(addMinutes(startsAtDate, 30), 'hh:mm aa'),
  }
}
