import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper } from '../styles'
import { formatLabel } from './utils'

const Subscriber = ({ subscriber }) => {
  if (!subscriber)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Subscriber data found
      </Typography>
    )

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber.firstName} {subscriber.lastName}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Gender:{' '}
          <Typography variant="span" fontWeight={200}>
            {formatLabel(subscriber?.gender)}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Member ID:{' '}
          <Typography variant="span" fontWeight={200}>
            {subscriber?.memberId}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Relationship:{' '}
          <Typography variant="span" fontWeight={200}>
            {formatLabel(subscriber?.relationship)}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Sequence Code:{' '}
          <Typography variant="span" fontWeight={200}>
            {formatLabel(subscriber?.sequenceCode)}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
    </Wrapper>
  )
}

export default Subscriber
