import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  baseURL,
  headers,
  STALE_TIME,
  requestMiddleware,
} from '@/v2/constants/requests'

export const useConsentForm = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ConsentForm', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/consent_form_signatures`
      )
      if (!res.ok) {
        throw new Error('We could not find consent forms.')
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
    staleTime: STALE_TIME,
  })

  return { consentForms: data, isLoading, isError }
}

export const useCreateConsentForm = patientId => {
  const queryClient = useQueryClient()

  const { mutateAsync: createConsentForm, ...others } = requestMiddleware({
    key: ['CreateConsentForm', `${patientId}`],
    request: async formId =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/consent_form_signatures`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            consent_form_signature: {
              consent_form_id: formId,
            },
          }),
        }
      ),
    onSuccess: result => {
      queryClient.setQueryData(['ConsentForm', `${patientId}`], old => [
        ...old,
        result,
      ])
    },
  })

  return { createConsentForm, ...others }
}

export const useResendConsentForm = patientId => {
  const { mutateAsync: resendConsentForm, ...others } = requestMiddleware({
    key: ['ResendConsentForm', `${patientId}`],
    request: async formId =>
      await fetch(
        `${baseURL}/v2/rest/consent_form_signatures/${formId}/emails`,
        {
          method: 'POST',
          headers,
          body: '{}',
        }
      ),
  })

  return { resendConsentForm, ...others }
}

export const useConsentForms = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ConsentForms'],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/consent_forms`)
      if (!res.ok) {
        throw new Error('We could not find consent forms.')
      }
      const result = await res.json()

      return result
    },
    staleTime: STALE_TIME,
  })

  return { consentForms: data, isLoading, isError }
}

export const useConsentFormsDetails = formId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ConsentFormsDetails', `${formId}`],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/consent_forms/${formId}`)
      if (!res.ok) {
        throw new Error('We could not find consent forms.')
      }
      const result = await res.json()

      return result
    },
    staleTime: STALE_TIME,
  })

  return { consentForm: data, isLoading, isError }
}
