import { useMutation, useQueryClient } from '@tanstack/react-query'
import PatientFinanceInfo from '@/v2/hooks/usePatientFinanceInfo/graphql/queries/PatientFinanceInfo.gql'
import { baseURLGraphql, headers, baseURL } from '@/v2/constants/requests'

const usePaymentMethods = () => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: updateInvoicesPaymentMethod,
    isLoading: isUpdatingInvoicesPaymentMethod,
    isError: isUpdateInvoicesPaymentMethodError,
  } = useMutation({
    mutationKey: ['updateInvoicesPaymentMethod', baseURL],
    mutationFn: async ({ patientId }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/finance/payment_method.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({
            patient_id: patientId,
          }),
        }
      )

      return res.json()
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          variables.patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: removeInvoicesPaymentMethod,
    isLoading: isRemovingInvoicesPaymentMethod,
    isError: isRemoveInvoicesPaymentMethodError,
  } = useMutation({
    mutationKey: ['updateInvoicesPaymentMethod', baseURL],
    mutationFn: async ({ patientId, paymentMethodId }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/finance/payment_method.json`,
        {
          method: 'DELETE',
          headers,
          body: JSON.stringify({
            patient_id: patientId,
            payment_method_id: paymentMethodId,
          }),
        }
      )

      return res.json()
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          variables.patientId,
        ],
      })
    },
  })

  return {
    updateInvoicesPaymentMethod,
    isUpdatingInvoicesPaymentMethod,
    isUpdateInvoicesPaymentMethodError,
    removeInvoicesPaymentMethod,
    isRemovingInvoicesPaymentMethod,
    isRemoveInvoicesPaymentMethodError,
  }
}

export default usePaymentMethods
