import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Button from '@/v2/components/common/Button'
import Modal from '@/v2/components/common/Modal'
import { MEDICAL_ALERTS } from '@/v2/constants'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useUpdatePatient } from '@/v2/hooks/patient/usePatient'
import { toSnakeCase } from '@/v2/utils/format'

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const getValue = value => [...value.map(({ name }) => name)]

const MedicalAlerts = ({ isOpen, value, patientId, onClose, onSuccess }) => {
  const [selected, setSelected] = useState(getValue(value) || [])
  const { updatePatient } = useUpdatePatient(patientId)
  const theme = useTheme()
  const { onOpenSnackbar } = useSnackbarContext()

  const isSelected = name => !!selected.find(item => name === item)

  const handleToggle = name => {
    if (isSelected(name)) {
      setSelected([...selected].filter(item => name !== item))
    } else {
      setSelected([...selected, name])
    }
  }

  const handleSave = async () => {
    const items = MEDICAL_ALERTS.map(({ name }) => ({
      [name]: isSelected(name),
    }))
    const bools = items.reduce((acc, curr) => ({ ...acc, ...curr }), {})

    try {
      // TODO: ask to migrate from snake case to camel case
      const { error, ...data } = await updatePatient({
        patient: toSnakeCase({
          id: patientId,
          pregnant: bools.pregnant,
          latex_allergy: bools.latexAllergy,
          premedication: bools.premedication,
        }),
      })

      if (!error) {
        onOpenSnackbar('Patient has been updated.', 'success')
        onSuccess && onSuccess(data)
        onClose()
      }
    } catch (error) {
      console.log(error)
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Medical Alerts"
      onConfirm={handleSave}
      onClose={onClose}
    >
      <Wrapper>
        {MEDICAL_ALERTS.map(({ name, label, color }) => (
          <Button
            key={name}
            variant="contained"
            sx={{
              background: isSelected(name)
                ? theme.palette[color].medicalAlert
                : theme.palette.gray.main,
              color: theme.palette.background.default,
              '&:hover': {
                backgroundColor: theme.palette[color].medicalAlert,
              },
            }}
            onClick={() => handleToggle(name)}
          >
            {label}
          </Button>
        ))}
      </Wrapper>
    </Modal>
  )
}

export default MedicalAlerts
