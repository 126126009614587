import React, { useState, useEffect, useRef } from 'react'
import { TextArea } from './styles'
import { useGetDoctors } from '@/v2/hooks/useDoctors.js'
import Typography from '@/v2/components/common/Typography'

const NoteForm = ({ note, onChange }) => {
  const ref = useRef(null)
  const { data: doctors, loading } = useGetDoctors()
  const [value, setValue] = useState('')
  const [doctor, setDoctor] = useState()

  useEffect(() => {
    if (!loading && doctors.length) {
      setDoctor(doctors[0])
      onChange({ value, doctor: doctors[0], edit: !!note, id: note?.id })
    }
    if (note) {
      setValue(note.annotation)
      ref.current.value = note.annotation
      note.doctor && setDoctor(note.doctor)
      onChange({
        value: note.annotation,
        doctor: note.doctor,
        edit: true,
        id: note.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, doctor, doctors, note])

  let debounce
  const handleChange = value => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      setValue(value)
      onChange({ value, doctor, edit: !!note, id: note?.id })
    }, 300)
  }

  if (!loading && doctors.length === 0)
    return (
      <Typography>Please, create a doctor to start creating notes.</Typography>
    )

  return (
    <>
      <TextArea
        multiline
        className="note-input"
        inputRef={ref}
        rows={4}
        placeholder="Write a new note..."
        defaultValue=""
        onChange={({ target }) => handleChange(target.value)}
      />
    </>
  )
}

export default NoteForm
