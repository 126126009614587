import styled from 'styled-components'
import TextField from '@mui/material/TextField'

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const NoteWrapper = styled.div`
  background: ${({ theme }) => theme.silver.main};
  padding: 26px 30px;
  border-radius: 3px;
  width: 100%;
  max-width: 800px;
`

export const InfoWrapper = styled.div`
  display: flex;
`

export const TextArea = styled(TextField)`
  && {
    width: 100%;
    max-width: 800px;
    margin-top: 54px;
    margin-bottom: 20px;
  }
`
