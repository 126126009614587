import { useQuery } from '@tanstack/react-query'
import { baseURL, STALE_TIME } from '@/v2/constants/requests'

export const useUser = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['User'],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/user.json`)
      if (!res.ok) {
        throw new Error('We could not find the logged user')
      }
      return res.json()
    },
    staleTime: STALE_TIME,
  })

  return { user: data, isLoading, isError }
}
