import { useState } from 'react'
import { useNewPassword } from '@/v2/hooks/auth/useAuthentication'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useHistory } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

const resetPasswordSchema = z.object({
  email: z.string().email('Invalid email address'),
})

export const useNewPasswordForm = () => {
  const { push } = useHistory()
  const { newPassword, isLoading } = useNewPassword()
  const { onOpenSnackbar } = useSnackbarContext()

  const [values, setValues] = useState({
    email: '',
  })
  const [errors, setErrors] = useState({})

  const {
    register,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(resetPasswordSchema),
  })

  const handleNewPassword = async () => {
    const newPwdBody = {
      session: {
        email: values.email,
      },
    }
    await newPassword(newPwdBody, {
      onSuccess: data => {
        push('/v2/login')
        onOpenSnackbar(data.message, 'success')
      },
      onError: error => {
        onOpenSnackbar(`${error}`, 'error')
      },
    })
  }

  const handleSubmitForm = () => {
    const validErrors =
      resetPasswordSchema.safeParse(values).error?.formErrors.fieldErrors || {}
    setErrors(validErrors)
    if (Object.keys(validErrors).length === 0) {
      handleNewPassword()
    }
  }

  return {
    values,
    errors,
    isLoading,
    isSubmitting,
    register,
    setValues,
    handleSubmitForm,
  }
}
