import React, { useEffect } from 'react'
import { toUSNumberFormat } from '@/v2/utils/format'
import Typography from '@/v2/components/common/Typography'
import ActionButton from '@/v2/components/common/ActionButton'
import Button from '@/v2/components/common/Button'
import { useForm } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { revertCreatePaymentFormFormat } from '@/v2/utils/helpers'
import { initCreateContractValues } from './../CreateContractStateContext/CreateContractStateContext'
import { useCreateContract } from '@/v2/hooks/documents/useContracts'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { geThisDayOnNextMonth } from '@/v2/utils/helpers'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import Wrapper from '@/v2/components/common/Wrapper'

const ContractConfirm = ({ patientId, state, setState, onClose, email }) => {
  const { createContract } = useCreateContract(patientId)
  const { onOpenSnackbar } = useSnackbarContext()

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: state,
  })

  const onSubmit = async data => {
    try {
      const installments =
        data.classification === 'one_time_payment' ? 1 : data.installments
      const downPayment = data.downPayment || 0

      // TODO: ask to migrate from snake case to camel case
      const { error } = await createContract({
        patientId,
        downPayment,
        description: data.description,
        totalCost: data.amount,
        interestRate: data.interestRate,
        discountRate: data.discountRate,
        sendEmail: true,
        monthlyPaymentCount: parseInt(installments, 10),
        monthlyPaymentAmount: data.installmentAmountWithoutDiscount,
      })

      if (!error) {
        onOpenSnackbar('Contract created successfully', 'success')
      } else {
        console.log(error)
      }
    } catch (error) {
      onOpenSnackbar(error.message, 'error')
      const revertForm = revertCreatePaymentFormFormat(data)
      setState({ ...revertForm, step: 1, error: error.message })
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setState(initCreateContractValues)
      onClose()
    }
  }, [isSubmitSuccessful, onClose, reset, setState])

  const handleBack = () => {
    const revertForm = revertCreatePaymentFormFormat(state)
    setState({
      ...revertForm,
      step: 1,
      updatedInstallments:
        state.classification !== 'one_time_payment'
          ? state.updatedInstallments
          : null,
    })
  }

  const initialPayment = state.downPayment ? state.downPayment : 0

  const installments = state.updatedInstallments
    ? state.updatedInstallments
    : state.installments

  return (
    <FormControl
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      variant="filled"
    >
      <Box>
        <Button
          variant="text"
          color="primary"
          disabled={isSubmitting}
          onClick={handleBack}
        >
          <Typography
            variant="body"
            sx={{
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <ArrowBackIos fontSize="13px" />
            Edit Contract
          </Typography>
        </Button>
      </Box>
      <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
        <Wrapper>
          <Box>
            <Typography variant="body">Description:</Typography>
            <Typography variant="body" bold>
              {state.description}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body">Amount:</Typography>
            <Typography variant="body" bold>
              {toUSNumberFormat(state.amount, true, true)}
            </Typography>
          </Box>

          {state.amountWithDiscount > 0 && state.discountRate > 0 ? (
            <>
              <Box>
                <Typography variant="body">Discount:</Typography>
                <Typography variant="body" bold>
                  {state.discountRate * 100}%
                </Typography>
              </Box>

              <Box>
                <Typography variant="body">{`Total with ${state.discountRate *
                  100}% discount:`}</Typography>
                <Typography variant="body" bold>
                  {toUSNumberFormat(state.amountWithDiscount, true, true)}
                </Typography>
              </Box>
            </>
          ) : null}
        </Wrapper>

        {state.classification === 'installments' &&
        state.installments > 1 &&
        initialPayment > 0 ? (
          <>
            <Wrapper>
              {initialPayment > 0 ? (
                <Box>
                  <Typography variant="body">Initial Payment:</Typography>
                  <Typography variant="body" bold>
                    {toUSNumberFormat(initialPayment, true, true)}
                  </Typography>
                </Box>
              ) : null}
            </Wrapper>
          </>
        ) : null}

        <Wrapper>
          {state.classification === 'installments' ? (
            <Box>
              <Typography variant="body">Remaining payments:</Typography>
              <Typography variant="body" bold>
                {`${toUSNumberFormat(
                  state.installmentAmount,
                  true,
                  true
                )} x ${installments} ${
                  installments === 1 ? 'month' : 'months'
                }`}
              </Typography>
            </Box>
          ) : null}

          {state.interestRate > 0 ? (
            <>
              <Box>
                <Typography variant="body">Interest rate:</Typography>
                <Typography variant="body" bold>
                  {state.interestRate * 100}%
                </Typography>
              </Box>

              <Box>
                <Typography variant="body">{`Total with ${state.interestRate *
                  100}% interest rate:`}</Typography>
                <Typography variant="body" bold>
                  ${toUSNumberFormat(state.totalDue, true, true)}
                </Typography>
              </Box>
            </>
          ) : null}

          {state.classification !== 'one_time_payment' && (
            <Grid display={'flex'} gap={1} mt={2}>
              <Typography
                fontWeight="light"
                variant="body3"
                color={'fieldLabel.main'}
              >
                Next payment {geThisDayOnNextMonth()}
              </Typography>
            </Grid>
          )}
        </Wrapper>
      </Box>

      <Box
        bgcolor={'gray.light'}
        borderRadius={2}
        px={2}
        py={2}
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography variant="body" fontWeight="light">
          Contract will be emailed to:
        </Typography>
        <Typography variant="body" bold>
          {email}
        </Typography>
      </Box>

      <Grid container mt={3} gap={1} justifyContent="flex-end">
        <ActionButton
          variant="contained"
          color="secondary"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Email for Signature
        </ActionButton>
      </Grid>
    </FormControl>
  )
}

export default ContractConfirm
