import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from 'apollo-utilities'
import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      daySchedule: (_, args, { getCacheKey }) => {
        return getCacheKey({ __typename: 'DaySchedule', id: args.date })
      },
      appointment: (_, args, { getCacheKey }) => {
        return getCacheKey({ __typename: 'Appointment', id: args.id })
      },
    },
  },
  // TODO: Workaround to prevent graphql warning regarding me requesting
  //   time offs, appointments and clinic hours separatelly instead of once.
  //   To fix this we must create a separate query for each one of them on v2.
  typePolicies: {
    Query: {
      fields: {
        clinic: {
          merge(existing = [], incoming) {
            return { ...existing, ...incoming }
          },
        },
        daySchedule: {
          merge(existing = [], incoming) {
            return { ...existing, ...incoming }
          },
        },
        hours: {
          merge(existing = [], incoming) {
            return { ...existing, ...incoming }
          },
        },
      },
    },
  },
})

const request = operation => {
  const meta = document.querySelector('meta[name=csrf-token]')
  const csrfToken = meta && meta.getAttribute('content')

  operation.setContext({
    headers: { 'X-CSRF-Token': csrfToken },
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const getCableUrl = () => {
  // We can define a custom url by using this method.
  // For now, we're using the same url as the web page
}

const createActionCableLink = () => {
  const cable = createConsumer(getCableUrl())
  return new ActionCableLink({ cable })
}

const hasSubscriptionOperation = ({ query }) => {
  const definition = getMainDefinition(query)

  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  )
}

const _onError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.warn('[GraphQL error]: ', graphQLErrors)
  }
  if (networkError) {
    console.warn('[Network error]: ', networkError)
  }
}

const link = ApolloLink.from([
  onError(_onError),
  requestLink,
  ApolloLink.split(
    hasSubscriptionOperation,
    createActionCableLink(),
    new HttpLink({
      uri: '/v2/graphql',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document
          .querySelector('meta[name=csrf-token]')
          .getAttribute('content'),
      },
    })
  ),
])

const client = new ApolloClient({
  link: link,
  cache,
  connectToDevTools: true,
})

export default client
