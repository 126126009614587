import React, { useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import SVGInline from 'react-svg-inline'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '#/assets/images/v2/plus.svg?inline'
import { useUploadImage } from '@/v2/hooks/useSeriesImages'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'
import { EmptyImage, Icon, Loading } from './styles'
import { ImageContent } from '../Image'

const ImageItem = ({
  twoColumns,
  children,
  position,
  aspect,
  src,
  id,
  originalImageUrl,
}) => {
  const {
    id: patientId,
    progression: progressionId,
    series: seriesId,
  } = useParams()
  const { loading, uploadImage } = useUploadImage()
  const { progressions, onUploadImage } = usePatientContext()
  const { goTo } = useModals()
  const theme = useTheme()
  const { isMobile } = useDetectDevice()
  const inputRef = useRef(null)

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async e => {
    if (!e.target.files) return
    await handleUpload(e.target.files[0])
  }

  const handleEditImage = () => {
    goTo(`/v2/patient/${patientId}/crop-image/`, {
      image: src,
      seriesId,
      progressionId,
      position,
      aspect: aspect,
      originalImage: originalImageUrl,
      onUploadImage,
      imageId: id,
    })
  }

  const handleUpload = async file => {
    const series = progressions.find(
      ({ id }) => id === parseInt(progressionId, 10)
    ).series

    const folderName = position < 9 ? 'Images' : 'X-rays'
    const originalSeriesId = series.find(({ name }) => name === folderName).id

    const formData = new FormData()
    formData.append('image[file]', file)
    formData.append('image[position]', position || 0)

    if (originalSeriesId !== parseInt(seriesId, 10)) {
      // Here we save the original image for backup, in the future, we may not need this anymore
      await uploadImage(patientId, originalSeriesId, formData, image => {
        onUploadImage(progressionId, originalSeriesId, image)
        goTo(`/v2/patient/${patientId}/crop-image/`, {
          image: file,
          seriesId,
          progressionId,
          position,
          aspect: aspect,
          originalImage: image?.url,
          onUploadImage,
          imageId: id,
        })
      })
    } else {
      goTo(`/v2/patient/${patientId}/crop-image/`, {
        image: file,
        seriesId,
        progressionId,
        position,
        aspect: aspect,
        onUploadImage,
        imageId: id,
        originalImage: originalImageUrl,
      })
    }
  }

  return (
    <>
      {loading && (
        <Loading theme={theme.palette} $isMobile={isMobile ? 1 : 0}>
          <CircularProgress color="secondary" />
        </Loading>
      )}

      {src ? (
        <ImageContent
          className="photo"
          twoColumns={twoColumns}
          position={position}
          src={src}
          id={id}
          handleUploadClick={handleEditImage}
        />
      ) : (
        <EmptyImage
          className="no-image"
          $isMobile={isMobile ? 1 : 0}
          $twoColumns={twoColumns}
          $position={position}
          theme={theme.palette}
          onClick={handleUploadClick}
        >
          {isMobile ? (
            <Icon $isMobile={isMobile ? 1 : 0} theme={theme.palette}>
              <SVGInline svg={AddIcon} width="24px" height="24px" />
            </Icon>
          ) : (
            !children && (
              <Icon $isMobile={isMobile ? 1 : 0} theme={theme.palette}>
                <SVGInline svg={AddIcon} width="48px" height="48px" />
              </Icon>
            )
          )}
          {children}
        </EmptyImage>
      )}

      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default ImageItem
