import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import {
  parseISO,
  startOfMonth,
  endOfMonth,
  addDays,
  startOfWeek,
} from 'date-fns'
import useCalendarOccupancy from '@/v2/hooks/calendar/useCalendarOccupancy'
import Filterbar from '../Filterbar'
import DayCard from './DayCard'
import WeekBar from './WeekBar'
import { Canvas, Loading } from '../styles'

const MonthlyView = styled.div`
  grid-row: 2;
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 50px repeat(6, 1fr);
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: auto;
`

const Monthly = ({ date }) => {
  const monthStart = startOfMonth(parseISO(date))
  const monthEnd = endOfMonth(parseISO(date))
  const firstDate = startOfWeek(monthStart)
  const lastDate = addDays(firstDate, 6 * 7 - 1)
  const theme = useTheme()
  const { occupancy, isLoading, isError } = useCalendarOccupancy({
    from: firstDate,
    to: lastDate,
  })

  if (isLoading)
    return (
      <Loading>
        <p>Loading...</p>
      </Loading>
    )

  if (isError) return 'Error!'

  return (
    <Canvas>
      <Filterbar date={date} />
      <MonthlyView>
        <WeekBar theme={theme.palette} />
        {occupancy.map(
          ({
            date,
            appointmentsCount,
            confirmedAppointmentsCount,
            occupancyRate,
            isOpen,
          }) => (
            <DayCard
              key={date}
              outOfBound={
                new Date(parseISO(date)) < monthStart ||
                new Date(parseISO(date)) > monthEnd
              }
              dateAsString={date}
              fulfillmentRate={occupancyRate}
              appointmentsCount={appointmentsCount}
              confirmedAppointmentsCount={confirmedAppointmentsCount}
              closed={!isOpen}
            />
          )
        )}
      </MonthlyView>
    </Canvas>
  )
}

export default Monthly
