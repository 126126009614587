import React from 'react'
import { ContentWrapper, SmallValue, Value, Item } from '../styles'
import { format, parseISO } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import Button from '@/v2/components/common/Button'
import { useAddCheckEligibility } from '@/v2/hooks/insurance/useEligibilities'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import useModals from '@/v2/hooks/useModals'

const EligibilityInfo = ({ eligibility, patient }) => {
  const theme = useTheme()
  const { createdAt, provider, insurance, status } = eligibility
  const { addCheckEligibility } = useAddCheckEligibility(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()
  const { goTo } = useModals()

  const handleCheckEligibility = async () => {
    const body = {
      insuranceId: insurance.id,
      eligibility: {
        providerAttributes: {
          firstName: provider?.firstName || provider.name.split(' ')[0],
          lastName: provider?.lastName || provider.name.split(' ')[1],
          npi: provider?.npi,
          taxId: provider?.taxId,
        },
      },
    }
    try {
      const { error } = await addCheckEligibility(body)
      if (!error) {
        onOpenSnackbar('Eligibility added', 'success')
        goTo(`/v2/patient/${patient.id}/eligibility/show`, {
          modalType: 'show',
          eligibility,
          patient,
        })
      }
    } catch (error) {
      console.log('error', error)
      onOpenSnackbar(`${error}`, 'error')
    }
  }

  const options = [
    {
      label: 'Show Eligibility',
      onClick: () =>
        goTo(`/v2/patient/${patient.id}/eligibility/show`, {
          modalType: 'show',
          eligibility,
          patient,
        }),
    },
    {
      label: 'Check Again',
      onClick: () => handleCheckEligibility(),
    },
  ]

  const statusColors = {
    failed: theme.palette.error.main,
    created: theme.palette.success.main,
    requested: theme.palette.warning.main,
  }

  return (
    <Item theme={theme.palette}>
      <ContentWrapper>
        <SmallValue>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Created
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {format(parseISO(createdAt), 'MMMM dd, yyyy HH:mm')}
          </Typography>
        </SmallValue>
        <SmallValue>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Doctor
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {provider.firstName} {provider.lastName}
          </Typography>
        </SmallValue>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Insurance
          </Typography>
          <Typography bold fontSize="14px">
            {insurance.payer.name}
          </Typography>
        </Value>
        <Value>
          <Button
            key={name}
            variant="contained"
            disableElevation
            sx={{
              background: statusColors[status],
              color: theme.palette.background.default,
              width: 'auto',
              minWidth: '40px',
              height: '20px',
              mt: '15px',
              cursor: 'not-allowed',
              pointerEvents: 'none',
            }}
          >
            {status}
          </Button>
        </Value>
      </ContentWrapper>
      <IconMenu
        options={options}
        icon={<MoreIcon fontSize="small" />}
        aria-label="More"
        title="More"
      />
    </Item>
  )
}

export default EligibilityInfo
