import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import useModals from '@/v2/hooks/useModals'
import { usePatientContext } from '@/v2/contexts/PatientContext'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`

const MedicalAlerts = () => {
  const theme = useTheme()
  const { patient, medicalAlerts, onSetPatient } = usePatientContext()
  const { goTo } = useModals()

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Medical Alerts ({medicalAlerts.length})
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/medical-alerts`, {
              patientId: patient.id,
              value: medicalAlerts,
              onSuccess: data => {
                onSetPatient(data)
              },
            })
          }
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Manage</Typography>
        </Button>
      </Header>
      <Wrapper>
        {medicalAlerts.map(({ name, label, color }) => (
          <Button
            key={name}
            variant="contained"
            sx={{
              background: theme.palette[color].medicalAlert,
              color: theme.palette.background.default,
            }}
          >
            {label}
          </Button>
        ))}
      </Wrapper>
      {medicalAlerts.length === 0 && (
        <Typography variant="h5" textAlign="center">
          No alerts
        </Typography>
      )}
    </Paper>
  )
}

export default MedicalAlerts
