import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { nowOnClinicTimezone } from '../utils/convert'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

const baseUrl = window.location.origin
const headers = { 'Content-Type': 'application/json' }

const currentDate = nowOnClinicTimezone()

const formattedDate = format(currentDate, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX")

const useNotes = (props = {}) => {
  const { appointmentId } = props
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['notes', appointmentId, baseUrl],
    queryFn: () =>
      fetch(
        `${baseUrl}/v2/rest/appointments/${appointmentId}/notes`
      ).then(res => res.json()),
    enabled: !!appointmentId,
  })

  const mutationCreate = useMutation({
    mutationKey: ['createNote', baseUrl],
    mutationFn: async note => {
      await fetch(
        `${baseUrl}/v2/rest/appointments/${note.appointmentId}/notes`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            doctor_id: note.doctor,
            annotation: note.note,
          }),
        }
      )
    },
    onMutate: async newNote => {
      newNote = {
        ...newNote,
        id: uuidv4(),
        createdAt: formattedDate,
        updatedAt: formattedDate,
      }
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['notes', newNote.appointmentId, baseUrl],
      })

      // Snapshot the previous value
      const previousNotes = queryClient.getQueryData([
        'notes',
        newNote.appointmentId,
        baseUrl,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['notes', newNote.appointmentId, baseUrl],
        old => [...old, newNote]
      )

      // Return a context object with the snapshotted value
      return { previousNotes }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newNote, context) => {
      queryClient.setQueryData(
        ['notes', newNote.appointmentId, baseUrl],
        context.previousNotes
      )
    },
    // Always refetch after error or success:
    onSettled: (_newNote, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['notes', variables.appointmentId, baseUrl],
      })
    },
  })

  const mutationUpdate = useMutation({
    mutationKey: ['editNote', baseUrl],
    mutationFn: async note => {
      await fetch(
        `${baseUrl}/v2/rest/appointments/${note.appointmentId}/notes/${note.id}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({
            doctor_id: note.doctor,
            annotation: note.note,
          }),
        }
      )
    },
    onMutate: async newNote => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      newNote = {
        ...newNote,
        createdAt: formattedDate,
        updatedAt: formattedDate,
      }

      await queryClient.cancelQueries({
        queryKey: ['notes', newNote.appointmentId, baseUrl],
      })

      // Snapshot the previous value
      const previousNotes = queryClient.getQueryData([
        'notes',
        newNote.appointmentId,
        baseUrl,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(['notes', newNote.appointmentId, baseUrl], old =>
        old.map(note => {
          if (note.id === newNote.id) {
            return newNote
          }
          return note
        })
      )

      // Return a context object with the snapshotted value
      return { previousNotes }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newNote, context) => {
      queryClient.setQueryData(
        ['notes', newNote.appointmentId, baseUrl],
        context.previousNotes
      )
    },
    // Always refetch after error or success:
    onSettled: (_newNote, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['notes', variables.appointmentId, baseUrl],
      })
    },
  })

  const mutationDelete = useMutation({
    mutationKey: ['deleteNote', baseUrl],
    mutationFn: async note => {
      await fetch(
        `${baseUrl}/v2/rest/appointments/${note.appointmentId}/notes/${note.id}`,
        {
          method: 'DELETE',
          headers,
        }
      )
    },
    onMutate: async newNote => {
      newNote = {
        ...newNote,
        createdAt: formattedDate,
        updatedAt: formattedDate,
      }
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['notes', newNote.appointmentId, baseUrl],
      })

      // Snapshot the previous value
      const previousNotes = queryClient.getQueryData([
        'notes',
        newNote.appointmentId,
        baseUrl,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(['notes', newNote.appointmentId, baseUrl], old =>
        old.filter(note => note.id !== newNote.id)
      )

      // Return a context object with the snapshotted value
      return { previousNotes }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newNote, context) => {
      queryClient.setQueryData(
        ['notes', newNote.appointmentId, baseUrl],
        context.previousNotes
      )
    },
    // Always refetch after error or success:
    onSettled: (_newNote, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['notes', variables.appointmentId, baseUrl],
      })
    },
  })

  return { query, mutationCreate, mutationUpdate, mutationDelete }
}

export default useNotes
