import React, { useState } from 'react'
import IconButton from '@/v2/components/common/IconButton'
import Menu from '@mui/material/Menu'
import Option from './Option'

const ITEM_HEIGHT = 48

const IconMenu = ({ icon, options, sx = {}, ...others }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleClick = event => onClick => {
    event.stopPropagation()
    setAnchorEl(null)
    onClick && onClick()
  }

  return (
    <>
      <IconButton
        id="options"
        aria-controls={open ? 'menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        bordercolor="transparent"
        sx={{ width: '24px', height: '24px', ...sx }}
        {...others}
        onClick={handleOpen}
      >
        {icon}
      </IconButton>

      {options && options.length > 0 ? (
        <Menu
          id="menu"
          MenuListProps={{ 'aria-labelledby': 'options' }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map(({ label, href, target, onClick }) => (
            <Option
              key={label}
              label={label}
              href={href}
              target={target}
              onClick={event => handleClick(event)(onClick)}
            />
          ))}
        </Menu>
      ) : null}
    </>
  )
}

export default IconMenu
