import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MiniCalendar from '@/v2/components/common/MiniCalendar'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import getRandomId from '@/v2/utils/random-id'
import {
  Wrapper,
  FakeInput,
  ArrowDown,
  CalendarWrapper,
  Backdrop,
} from './styles'

const SelectCalendar = ({
  label,
  value,
  error,
  disabled = false,
  hasOccupancy = true,
  labelFormat = 'MM/dd/yy',
  absoluteHeight = false,
  onChange,
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState()
  const [fakeInputId] = useState(getRandomId())
  const [calendarId] = useState(getRandomId())
  const [calendarTop, setCalendarTop] = useState(null)

  useEffect(() => {
    if (value && typeof value === 'string') {
      setDate(getDateTimeWithoutTimezone(value))
    } else {
      setDate(value)
    }
  }, [value])

  /* START - This fixes the positioning of the calendar div on new appointment modal */
  useEffect(() => {
    if (absoluteHeight && open) {
      const fakeInput = document.getElementById(fakeInputId)
      const calendar = document.getElementById(calendarId)

      if (fakeInput && calendar) {
        const inputPosition = fakeInput.getBoundingClientRect()
        const calendarHeight = calendar.offsetHeight
        const isCalendarFullVisible =
          window.innerHeight - inputPosition.top > calendarHeight

        const calendarPosition = isCalendarFullVisible
          ? inputPosition.bottom
          : inputPosition.top - calendarHeight + 2

        setCalendarTop(calendarPosition)
      }
    }
  }, [open, absoluteHeight, fakeInputId, calendarId])

  useEffect(() => {
    if (absoluteHeight && !calendarTop) {
      const fakeInput = document
        .getElementById(fakeInputId)
        ?.getBoundingClientRect()
      if (fakeInput.bottom) {
        setCalendarTop(fakeInput.bottom)
      }
    }
  }, [calendarTop, absoluteHeight, fakeInputId])
  /* END - This fixes the positioning of the calendar div on new appointment modal */

  return (
    <Wrapper theme={theme.palette}>
      <Label>{label}</Label>
      <FakeInput
        id={fakeInputId}
        theme={theme.palette}
        open={open ? 1 : 0}
        disabled={disabled ? 1 : 0}
        onClick={() => {
          if (!disabled) {
            setOpen(!open)
          }
        }}
      >
        <Typography
          fontSize="14px"
          lineHeight="14px"
          color={disabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
        >
          {date ? format(date, labelFormat) : labelFormat.toLocaleLowerCase()}
        </Typography>
        <ArrowDown theme={theme.palette} disabled={disabled ? 1 : 0} />
      </FakeInput>
      {open && (
        <>
          <Backdrop onClick={() => setOpen(false)} />
          <CalendarWrapper
            id={calendarId}
            theme={theme.palette}
            $top={calendarTop}
          >
            <MiniCalendar
              isGrayWhenFull
              date={date}
              hasOccupancy={hasOccupancy}
              onSelect={date => {
                setDate(date)
                setOpen(false)
                onChange && onChange(date)
              }}
            />
          </CalendarWrapper>
        </>
      )}
      {error && <Typography variant="error">{error}</Typography>}
    </Wrapper>
  )
}

export default SelectCalendar
