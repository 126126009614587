import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import { formatPhone } from '@/v2/utils/format'
import { useSearchConversations } from '@/v2/hooks/messages/useConversations'
import { useFindPatient } from '@/v2/hooks/patient/useFindPatient'
import Conversation from '../Conversation'
import Patient from '../Patient'
import { Wrapper, NoResult, SectionTitle } from './styles'

const orderPatients = patients =>
  [...patients].sort((a, b) =>
    a?.lastName > b?.lastName ? 1 : b?.lastName > a?.lastName ? -1 : 0
  )

const SearchResults = ({ searchTerm }) => {
  const theme = useTheme()
  const history = useHistory()
  const {
    patients = [],
    isLoading: isLoadingPatient,
    isError: errorPatients,
  } = useFindPatient(searchTerm)
  const {
    conversations,
    isLoading: isLoadingConversations,
    isError: errorConversations,
  } = useSearchConversations(searchTerm)

  if (errorPatients || errorConversations) return history.push('/500')

  const isValidPhone = searchTerm.replace(/\D/g, '').length === 10

  if (isLoadingPatient || isLoadingConversations) {
    return <CircularProgress sx={{ margin: '10px auto', display: 'block' }} />
  }

  if (conversations.length === 0 && patients.length === 0) {
    return (
      <NoResult>
        {!isValidPhone && (
          <Typography variant="h5">
            Please, enter a valid mobile phone number to start a new
            conversation.
          </Typography>
        )}
        {isValidPhone && (
          <>
            <Typography variant="h4" textAlign="center">
              Click on the "New Conversation" button to start a new conversation
              with <u>{formatPhone(searchTerm)}</u>
            </Typography>
            <Button
              variant="contained"
              href={`/v2/messages/new/${searchTerm.replace(/\D/g, '')}`}
              sx={{
                margin: '0 auto',
                display: 'block',
                marginTop: '10px',
                width: '160px',
              }}
            >
              New Conversation
            </Button>
          </>
        )}
      </NoResult>
    )
  }

  return (
    <Wrapper>
      {conversations.length > 0 && (
        <>
          <SectionTitle theme={theme.palette}>Conversations</SectionTitle>
          {conversations.map(conversation => (
            <Conversation key={conversation.id} {...conversation} />
          ))}
        </>
      )}
      {patients.length > 0 && (
        <>
          <SectionTitle theme={theme.palette}>Patients</SectionTitle>
          {orderPatients(patients).map(patient => (
            <Patient key={patient.id} patient={patient} />
          ))}
        </>
      )}
    </Wrapper>
  )
}

export default SearchResults
