import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import Typography from '@/v2/components/common/Typography'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'
import { useTreatments } from '@/v2/hooks/treatments/useTreatments'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import AppointmentInfo from './AppointmentInfo'
import TreatmentNote from './TreatmentNote'
import MenuButton from '@/v2/components/common/MenuButton'
import Box from '@mui/material/Box'

const AppointmentsTab = () => {
  const queryClient = useQueryClient()
  const { goTo } = useModals()
  const { id, seatAppointmentId } = useParams()
  const { patient, onAddNote } = usePatientContext()
  const { treatments, isLoading, isError } = useTreatments(id)
  const { onOpenSnackbar } = useSnackbarContext()
  const options = [
    {
      label: 'Add Appointment',
      onClick: () =>
        goTo('/v2/event/new', {
          initialValues: {
            start: nowOnClinicTimezone(),
            patient,
            onCreate: () => {
              queryClient.refetchQueries({
                queryKey: ['PatientAppointments', id],
              })
              queryClient.refetchQueries({
                queryKey: ['Patient', id],
              })
              queryClient.refetchQueries({
                queryKey: ['Treatments', `${id}`],
              })
            },
          },
        }),
    },
    {
      label: 'Add Yellow Note',
      onClick: () =>
        goTo(`/v2/patient/${id}/notes/new/yellow`, {
          onSave: note => onAddNote(note),
        }),
    },
  ]

  useEffect(() => {
    if (!isLoading && !!isError)
      onOpenSnackbar("It wasn't possible to load the patient notes.", 'error')
  }, [isLoading, isError, onOpenSnackbar])

  if (isLoading)
    return (
      <Typography
        variant="h5"
        fontWeight="300"
        sx={{ textAlign: 'center', marginTop: '40px' }}
      >
        Loading...
      </Typography>
    )

  return (
    <>
      <Box display={'flex'} flexDirection={'row-reverse'}>
        <MenuButton options={options} aria-label="More" title="More">
          Add New
        </MenuButton>
      </Box>
      {treatments.map(item =>
        item.classification ? (
          <TreatmentNote key={item.id} note={item} />
        ) : (
          <AppointmentInfo
            key={item.id}
            appointment={item}
            openAddNote={parseInt(seatAppointmentId, 10) === item.id}
          />
        )
      )}
      {treatments.length === 0 && (
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          No previous data
        </Typography>
      )}
    </>
  )
}

export default AppointmentsTab
