import React from 'react'
import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import { Grid } from '@mui/material'

const Wrapper = styled.div`
  padding: 38px 30px 23px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 10px;
`

const ConfirmDelete = ({ isOpen, onClose, onConfirm, singular = false }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Wrapper>
        <Typography variant="h3" sx={{ marginBottom: '14px' }}>
          {!singular
            ? 'Deletion can not be undone, and will remove all the images in these series.'
            : 'Deletion can not be undone, and will remove this image from the series'}
        </Typography>
        <Grid display="flex" width={'100%'} justifyContent={'flex-end'} gap={1}>
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onConfirm}
            sx={{
              color: '#fff',
            }}
          >
            Delete
          </Button>
        </Grid>
      </Wrapper>
    </Dialog>
  )
}

export default ConfirmDelete
