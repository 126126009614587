import styled from 'styled-components'
import PersonIcon from '@mui/icons-material/Person'
import Typography from '@/v2/components/common/Typography'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 690px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`
export const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 295px;

  @media (max-width: 768px) {
    width: 350px;
  }
`

export const AppointmentInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const AppointmentInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.yellow.note};
  gap: 15px;
  padding: 18px 24px;
`

export const AppointmentInfoActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const Item = styled(Typography)`
  display: flex;
  column-gap: 13px;

  && {
    font-size: 14px;
    line-height: 22px;
  }
}
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`

export const PatientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 295px;
  gap: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const PatientProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const PatientProfileHeader = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`

export const PatientFooter = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  padding: 20px 24px;
  border-top: 1px solid ${({ theme }) => theme.primary.main};
`
export const PatientPage = styled(PersonIcon)`
  margin-bottom: -6px;
  margin-left: 4px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`

export const PatientProfile = styled.div`
  display: flex;
  flex-direction: column;
`

export const PatientActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const LabelsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 10px;
`
export const Label = styled.div`
  display: flex;
  padding: 6px 10px;
  transition: 0.3s;
  column-gap: 4px;
  background: ${({ theme, color }) => theme[color].label};
  border-radius: 2px;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const FooterWrapper = styled.div``

export const PreviousAppointments = styled.div`
  display: flex;
  flex-direction: column;
`

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 16px 16px 24px;
  gap: 5px;
  background: ${({ theme }) => theme.gray.light};
  width: 100%;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
`

export const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${({ reverse = false }) =>
    reverse ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
`

export const NotesForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
`

export const NoteContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  word-wrap: break-word;
`

export const NoteActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  height: 100%;
`

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
export const PreviousAppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

export const NoteFormActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
