import { isThisWeek, differenceInCalendarDays, format } from 'date-fns'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import { DAY_IN_US_FORMAT } from '@/v2/constants'

export const getGroupedMessages = (array = []) => {
  if (!array.length) return []
  return array.reduce((acc, obj) => {
    const key = format(getDateTimeWithoutTimezone(obj.createdAt), 'yyyy-M-dd')
    if (!acc[key]) {
      acc[key] = []
    }
    // Add object to list for given key's value
    acc[key].push(obj)
    return acc
  }, {})
}

export const getDate = datetime => {
  const date = new Date(datetime.replace(/-/g, '/'))
  const days = differenceInCalendarDays(date, nowOnClinicTimezone())

  if (days === 0) return 'TODAY'
  if (days === -1) return 'YESTERDAY'

  return isThisWeek(date)
    ? format(date, 'iiii').toUpperCase()
    : format(date, DAY_IN_US_FORMAT)
}

export const sortByCreation = messages =>
  [...messages].sort((a, b) =>
    new Date(a.createdAt) > new Date(b.createdAt)
      ? 1
      : new Date(b.createdAt) > new Date(a.createdAt)
      ? -1
      : 0
  )
