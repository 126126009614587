import React from 'react'
import Typography from '@/v2/components/common/Typography'
import Box from '@mui/material/Box'
import { INFOBAR_HEIGHT, TOPBAR_HEIGHT } from '@/v2/constants'
import Card from './Card'
import ContactInformation from './ContactInformation'
import EmergencyContact from './EmergencyContact'
import FamilyMembers from './FamilyMembers'
import Referrals from './Referrals'
import Tasks from './Tasks'
import MedicalAlerts from './MedicalAlerts'
import HealthHistory from './HealthHistory'
import Notes from './Notes'
import Appointments from './Appointments'
import Contract from './Contract'

const Profile = () => {
  return (
    <Box>
      <Box
        bgcolor="silver.main"
        padding={'38px 24px 60px 24px'}
        height={`calc(100vh - ${INFOBAR_HEIGHT} - ${TOPBAR_HEIGHT})`}
        overflow={'auto'}
      >
        <Typography variant="h1" fontSize="26px" sx={{ mb: '36px' }}>
          Patient Profile
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          minHeight="calc(100% - 66px)"
          sx={{
            '& > div:first-of-type': {
              flex: '56%',
              maxWidth: '56%',
              paddingRight: '23px',
            },
            '& > div:nth-of-type(2)': { flex: '44%', maxWidth: '44%' },
          }}
        >
          <Box>
            <Card />
            <ContactInformation />
            <EmergencyContact />
            <FamilyMembers />
            <Referrals />
          </Box>
          <Box>
            <Tasks />
            <Notes />
            <MedicalAlerts />
            <HealthHistory />
            <Contract />
            <Appointments />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile
