import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { TOPBAR_HEIGHT } from '@/v2/constants'
import MobilePatientRoutes from '@/v2/routes/MobilePatientRoutes'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import PatientBarMobile from '../PatientBar/mobile'
import Typography from '@/v2/components/common/Typography'
import usePatient from '@/v2/hooks/usePatient/usePatient'

const PatientWrapper = styled.div`
  display: block;
  max-height: calc(100vh - ${TOPBAR_HEIGHT});
  height: 100%;
  overflow: hidden;
`

const MobilePatient = () => {
  const { id } = useParams()
  const { patientData, isPatientDataLoading, isPatientDataError } = usePatient({
    patientId: id,
    rest: false,
  })
  const { onSetPatient } = usePatientContext()

  useEffect(() => {
    if (!isPatientDataLoading && !isPatientDataError) {
      onSetPatient(patientData)
    }
  }, [isPatientDataLoading, isPatientDataError, patientData, onSetPatient])

  if (isPatientDataLoading)
    return (
      <Typography textAlign="center" sx={{ mt: '12px' }}>
        Loading...
      </Typography>
    )
  if (isPatientDataError)
    return <Typography textAlign="center">Something went wrong.</Typography>
  return (
    <PatientWrapper>
      <MobilePatientRoutes patient={patientData} />
      <PatientBarMobile />
    </PatientWrapper>
  )
}

export default MobilePatient
