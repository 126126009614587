import React, { useEffect } from 'react'
import SelectInputField from '@/v2/components/common/SelectInputField'
import { range } from '@/v2/utils/helpers'
import { useWatch } from 'react-hook-form'
import { updateAmounts } from '@/v2/utils/helpers'

const DiscountField = ({
  register,
  errors,
  control,
  setValue,
  onBlurChange,
  getFieldState,
  setState,
}) => {
  const watch = useWatch({
    control,
    name: [
      'discountRate',
      'amount',
      'interestRate',
      'installments',
      'classification',
    ],
  })
  const watchDiscountRate = watch[0]
  const watchAmount = watch[1]
  const watchInterestRate = watch[2]
  const watchInstallments = watch[3]
  const watchClassification = watch[4]

  const isDownPaymentFieldModified = getFieldState('downPayment').isDirty

  useEffect(() => {
    if (
      !isDownPaymentFieldModified &&
      watchClassification === 'installments' &&
      (watchDiscountRate > 0 || watchInstallments > 0 || watchInterestRate > 0)
    ) {
      const res = updateAmounts({
        amount: watchAmount,
        discountRate: watchDiscountRate,
        interestRate: watchInterestRate,
        installments: watchInstallments,
      })
      setValue('amountWithDiscount', res.amountWithDiscount)
      setValue('totalDue', res.totalDue)
      setValue('installmentAmount', res.installmentAmount)
      setValue(
        'downPayment',
        res.downPayment && !isNaN(res.downPayment) ? res.downPayment : '0'
      )
      setState(s => ({ ...s, updatedInstallments: watchInstallments - 1 }))
    }
  }, [
    watchAmount,
    watchDiscountRate,
    watchInterestRate,
    watchInstallments,
    watchClassification,
    isDownPaymentFieldModified,
    setState,
    setValue,
  ])

  return (
    <SelectInputField
      noMargin
      label="Apply discount"
      name="discount-rate"
      id="payment-plan-discount"
      options={range(0, 50, 5).map(discount => ({
        label: `${discount}%`,
        value: discount,
      }))}
      color="secondary"
      error={errors?.discountRate?.message}
      {...register('discountRate', {
        valueAsNumber: true,
      })}
      onBlur={onBlurChange}
    />
  )
}

export default DiscountField
