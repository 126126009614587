import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { InfoBox } from './styles'
import { useTheme } from '@mui/material/styles'

const TooltipInfo = ({ info }) => {
  const theme = useTheme()
  return info ? (
    <Tooltip title={info}>
      <IconButton sx={{ px: 0, py: 0 }}>
        <InfoBox theme={theme.palette}>?</InfoBox>
      </IconButton>
    </Tooltip>
  ) : null
}

export default TooltipInfo
