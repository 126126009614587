import React, { useEffect, useMemo } from 'react'
import { toUSNumberFormat } from '@/v2/utils/format'
import Typography from '@/v2/components/common/Typography'
import Divider from '@/v2/components/common/Divider'
import ActionButton from '@/v2/components/common/ActionButton'
import usePatientFinancePaymentMethods from '@/v2/hooks/usePatientFinancePaymentMethods/usePatientFinancePaymentMethods'
import { useForm } from 'react-hook-form'
import { usePaymentPlan } from '@/v2/hooks/usePaymentPlan'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Wrapper from '@/v2/components/common/Wrapper'
import { revertCreatePaymentFormFormat } from '@/v2/utils/helpers'
import { initCreatePaymentValues } from '../CreatePaymentStateContext/CreatePaymentStateContext'

const PlanConfirm = ({ patient, state, setState, onClose }) => {
  const { createPaymentPlan } = usePaymentPlan()

  const toCurrency = val => toUSNumberFormat(val)

  const { creditCards } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const card = useMemo(() => {
    const findCardById = creditCards?.find(p => p.id === state.paymentMethodId)
    return findCardById
  }, [creditCards, state.paymentMethodId])

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: state,
  })

  const onSubmit = async data => {
    try {
      const installments =
        data.classification === 'one_time_payment' ? 1 : data.installments

      const noPaymentMethod = data.paymentMethodType === 'none'
      const isPaymentLink = data.paymentMethodType === 'payment_link'

      const res = await createPaymentPlan({
        patientId: patient.id,
        contractId: data.contractId,
        description: data.description,
        amount: data.amount,
        interestRate: data.interestRate,
        discountRate: data.discountRate,
        paymentMethodId: noPaymentMethod ? null : data.paymentMethodId,
        sendPaymentLink: isPaymentLink,
        installments,
      })

      if (res.createPaymentPlan.lastErrorMessage) {
        throw new Error(res.createPaymentPlan.lastErrorMessage)
      }

      if (res.createPaymentPlan.errors) {
        const errors = JSON.parse(res.createPaymentPlan.errors)
        if (errors?.contract_id) {
          throw new Error(`Contract ${errors?.contract_id[0]}`)
        }
      }
    } catch (error) {
      const revertForm = revertCreatePaymentFormFormat(data)
      setState({ ...revertForm, step: 1, error: error.message })
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setState(initCreatePaymentValues)
      onClose()
    }
  }, [isSubmitSuccessful, onClose, reset, setState])

  const handleBack = () => {
    const revertForm = revertCreatePaymentFormFormat(state)
    setState({ ...revertForm, step: 1 })
  }

  const initialPayment = state?.contract?.downPayment || 0

  const installments = state.updatedInstallments || state.installments || 1

  return (
    <FormControl
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      variant="filled"
    >
      <Box
        bgcolor={'gray.light'}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        borderRadius={2}
        px={2}
        py={2}
      >
        <Wrapper>
          <Box>
            <Typography variant="body">Description:</Typography>
            <Typography variant="body">{state.description}</Typography>
          </Box>

          <Box>
            <Typography variant="body">Amount:</Typography>
            <Typography variant="body">${toCurrency(state.amount)}</Typography>
          </Box>

          {state.amountWithDiscount > 0 && state.discountRate > 0 ? (
            <>
              <Box>
                <Typography variant="body">Discount:</Typography>
                <Typography variant="body">
                  {state.discountRate * 100}%
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="body"
                  bold
                >{`Total with ${state.discountRate *
                  100}% discount:`}</Typography>

                <Typography variant="body" bold>
                  ${toCurrency(state.amountWithDiscount)}
                </Typography>
              </Box>
            </>
          ) : null}
        </Wrapper>

        {state?.contract?.downPayment && state.installments > 1 ? (
          <>
            <Divider margin="0 4px" />
            <Wrapper>
              <Box>
                <Typography variant="body">Monthly payments:</Typography>
                <Typography variant="body">
                  ${toCurrency(state.installmentAmount)}
                </Typography>
              </Box>

              {initialPayment > 0 ? (
                <Box>
                  <Typography variant="body" bold>
                    Total due today:
                  </Typography>
                  <Typography variant="body" bold>
                    ${toCurrency(initialPayment)}
                  </Typography>
                </Box>
              ) : null}
            </Wrapper>
          </>
        ) : null}

        <Divider margin="0 4px" />

        <Wrapper>
          {state.classification === 'installments' &&
          (!state.interestRate || state.interestRate === 0) ? (
            <Box>
              <Typography variant="body">Remaining payments:</Typography>
              <Typography variant="body">
                {`$${toCurrency(
                  state.installmentAmount
                )} x ${installments} months`}
              </Typography>
            </Box>
          ) : null}

          {state.interestRate > 0 ? (
            <>
              <Box>
                <Typography variant="body">Remaining payments:</Typography>
                <Typography variant="body">
                  {`$${toCurrency(
                    state.installmentAmount
                  )} x ${installments} months`}
                </Typography>
              </Box>

              <Box>
                <Typography variant="body">Interest rate:</Typography>
                <Typography variant="body">
                  {state.interestRate * 100}%
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="body"
                  bold
                >{`Total with ${state.interestRate *
                  100}% interest rate:`}</Typography>
                <Typography variant="body" bold>
                  ${toCurrency(state.totalDue)}
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="body" bold>
                Remaining balance:
              </Typography>
              <Typography variant="body" bold>
                ${toCurrency(state.totalDue)}
              </Typography>
            </Box>
          )}
        </Wrapper>

        <Divider margin="0 4px" />

        <Wrapper>
          {state.paymentMethodType === 'card' ? (
            <Box>
              <Typography variant="body">Card:</Typography>
              <Typography variant="body">
                **** **** **** {card.last4}
              </Typography>
            </Box>
          ) : state.paymentMethodType === 'none' ? (
            <Box>
              <Typography variant="body">Payment method:</Typography>
              <Typography variant="body" bold>
                Choose Later
              </Typography>
            </Box>
          ) : state.paymentMethodType === 'ach' ? (
            <Box>
              <Typography variant="body">Payment method:</Typography>
              <Typography variant="body" bold>
                Bank Account
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body">Payment method:</Typography>
              <Typography variant="body" bold>
                Send Payment Link
              </Typography>
            </Box>
          )}

          {state.contract && state.contract.patientLegalName !== '' ? (
            <Box>
              <Typography variant="body">Contract signer:</Typography>
              <Typography variant="body">
                {state.contract?.patientLegalName}
              </Typography>
            </Box>
          ) : null}
        </Wrapper>
      </Box>

      <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
        {state.paymentMethodType === 'card' ? (
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="body">Receipt will be emailed to:</Typography>
            <Typography variant="body" bold>
              {patient.email}
            </Typography>
          </Box>
        ) : state.paymentMethodType === 'none' ? (
          <Typography variant="body">
            The payment method has not been defined.
            <br />
            <br />
            {state.classification !== 'installments'
              ? 'Keep in mind that for a single installment plan, the payment will need to be manually charged. Make sure to add a payment method later and charge it accordingly'
              : 'Please note that the first payment is required to be manually charged, so ensure you add a payment method and set up the remaining invoices to use this added method.'}
          </Typography>
        ) : (
          <Typography variant="body">
            The first invoice will be emailed to: <span> {patient.email}</span>.
            <br />
            <br />
            The patient pays this invoice, and we save the payment method on the
            gateway for the subsequent invoices if had.
          </Typography>
        )}
      </Box>

      <Grid container gap={2} justifyContent="flex-end">
        <ActionButton
          variant="text"
          color="primary"
          disabled={isSubmitting}
          onClick={handleBack}
        >
          Back
        </ActionButton>
        <ActionButton
          variant="contained"
          color="secondary"
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Confirm
        </ActionButton>
      </Grid>
    </FormControl>
  )
}

export default PlanConfirm
