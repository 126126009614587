import React, { useEffect } from 'react'
import SVGInline from 'react-svg-inline'
import Smilebar from '#/assets/images/v2/smilebar.svg?inline'
import {
  Wrapper,
  AuthenticationCard,
  AuthenticationLink,
} from '@/v2/components/layouts/Authentication'
import Typography from '@/v2/components/common/Typography'
import { useTheme } from '@mui/material/styles'
import { useNewPasswordForm } from './NewPasswordForm/useNewPasswordForm'
import NewPasswordForm from './NewPasswordForm/Form'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '@/v2/contexts/AuthContext'

const NewPassword = () => {
  const { setUser, setIsLoggedIn } = useAuthContext()
  const { push } = useHistory()
  const theme = useTheme()
  const {
    values,
    errors,
    isLoading,
    isSubmitting,
    register,
    setValues,
    handleSubmitForm,
  } = useNewPasswordForm()

  useEffect(() => {
    setIsLoggedIn(false)
    setUser(null)
  }, [])

  return (
    <Wrapper theme={theme}>
      <AuthenticationCard theme={theme}>
        <SVGInline
          svg={Smilebar}
          className="filled icon"
          height="50px"
          width="400px"
        />
        <Typography
          fontSize="18px"
          fontWeight="600"
          mt="20px"
          color={theme.palette.common.black}
          align="center"
        >
          Enter your email to reset your password
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight="400"
          mt="10px"
          mb="24px"
          color={theme.palette.common.black}
          align="center"
        >
          Inform your credentials to start the recovery process
        </Typography>
        <NewPasswordForm
          values={values}
          errors={errors}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          register={register}
          setValues={setValues}
          onSubmitForm={handleSubmitForm}
        />
        <Typography
          fontSize="14px"
          fontWeight="300"
          mt="20px"
          color={theme.palette.grey[400]}
          align="center"
          onClick={() => push('/v2/login')}
        >
          <AuthenticationLink theme={theme}>Cancel</AuthenticationLink>
        </Typography>
      </AuthenticationCard>
    </Wrapper>
  )
}

export default NewPassword
