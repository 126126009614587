import React from 'react'
import { useTheme } from '@mui/material/styles'
import TopIcon from '@mui/icons-material/ExpandLess'
import AppointmentCard from '@/v2/components/common/AppointmentCard'
import IconButton from '@/v2/components/common/IconButton'
import useModals from '@/v2/hooks/useModals'
import LoadingItem from './LoadingItem'
import {
  Wrapper,
  Title,
  List,
  WrapperItem,
  Button,
  HoverWrapper,
} from './styles'

const AppointmentsList = ({
  appointments = [],
  title,
  loading = false,
  height,
  hoverButttons = [],
}) => {
  const theme = useTheme()
  const { goTo } = useModals()
  return (
    <Wrapper height={height}>
      <Title>{title}</Title>
      <List>
        {loading ? (
          <LoadingItem />
        ) : (
          appointments.map(appointment => (
            <WrapperItem
              key={appointment.id}
              className={
                appointment.fromBookingPage
                  ? 'from-booking-page'
                  : 'from-clearworks'
              }
              onClick={() =>
                goTo(`/v2/appointment/${appointment.id}`, {
                  initialValues: { appointment: { ...appointment } },
                })
              }
            >
              <AppointmentCard appointment={appointment} />
              <HoverWrapper className="hover-buttons">
                {hoverButttons.map(({ label, type, onClick }) =>
                  type === 'icon' ? (
                    <IconButton
                      key={label}
                      background="white"
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        onClick(appointment)
                      }}
                    >
                      <TopIcon />
                    </IconButton>
                  ) : (
                    <Button
                      key={label}
                      theme={theme.palette}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        onClick(appointment)
                      }}
                    >
                      {label}
                    </Button>
                  )
                )}
              </HoverWrapper>
            </WrapperItem>
          ))
        )}
      </List>
    </Wrapper>
  )
}

export default AppointmentsList
