import styled from 'styled-components'

export const Canvas = styled.div`
  display: grid;
  grid-template-rows: 60px auto;
  width: 100%;
  max-height: calc(100vh - 60px);
  height: 100%;
  overflow: hidden;

  .rbc-overflowing {
    margin-right: -1px !important;
  }
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
`
