import styled from 'styled-components'
import { TOPBAR_HEIGHT } from '@/v2/constants'
import Typography from '@/v2/components/common/Typography'

export const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  height: ${TOPBAR_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

export const NavigationWrapper = styled.div`
  display: flex;
  column-gap: 7px;
  justify-content: center;
  align-items: center;
`

export const DateWrapper = styled(Typography)`
  && {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: center;

  && > button,
  && > a {
    width: 134px;
    text-transform: capitalize;
  }
`

export const Filters = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 7px;

  && > button,
  && > a {
    width: 134px;
    text-transform: uppercase;
  }
`
