import React from 'react'
import { format } from 'date-fns'
import GalleryItem from '@/v2/components/common/GalleryItem'
import Typography from '@/v2/components/common/Typography'
import Document from '#/assets/images/v2/document.jpg'
import { INTAKE_FORM_STATUS, DATE_FORMAT } from '@/v2/constants'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useSendIntakeForm } from '@/v2/hooks/documents/useIntakeForm'

const IntakeForm = ({ intakeForm }) => {
  const intakeStatus = INTAKE_FORM_STATUS[intakeForm?.status]
  const { sendIntakeForm } = useSendIntakeForm(intakeForm?.id)
  const { onOpenSnackbar } = useSnackbarContext()

  if (!intakeForm) return <></>

  const handleSend = async () => {
    try {
      const { error } = await sendIntakeForm()

      if (!error) {
        onOpenSnackbar('Health history form successfully sent.')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  const intakeOptions =
    intakeForm.status === 'completed'
      ? [
          {
            label: 'View',
            href: `/intake_forms/${intakeForm.id}`,
            target: '_blank',
          },
        ]
      : [{ label: 'Send for Signature', onClick: handleSend }]

  const createdAt = getDateTimeWithoutTimezone(intakeForm.createdAt)

  return (
    <>
      <GalleryItem
        preview={Document}
        contractStatus={intakeStatus}
        options={intakeOptions}
      >
        <Typography fontWeight="500" fontSize="16px">
          Health History {`#${intakeForm.identifierToken}`}
        </Typography>
        <Typography fontSize="14px">
          {format(createdAt, DATE_FORMAT)}
        </Typography>
      </GalleryItem>
    </>
  )
}

export default IntakeForm
