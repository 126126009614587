import React from 'react'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'

import Modal from '@/v2/components/common/Modal'
import { useParams } from 'react-router-dom'

import { AdvancedPaymentForm } from './components/AdvancedPaymentForm'

const AdvancedPayment = ({ isOpen, onClose, plan }) => {
  const { patientId } = useParams()

  const { creditCards, bankAccounts } = usePatientFinancePaymentMethods({
    patientId,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={<></>}
      title={'Down Payment'}
      maxWidth="xs"
      fullWidth
    >
      <AdvancedPaymentForm
        plan={plan}
        onClose={onClose}
        patientId={patientId}
        creditCards={creditCards}
        bankAccounts={bankAccounts}
      />
    </Modal>
  )
}

export default AdvancedPayment
