import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MobileProfile from '@/v2/containers/patient-profile/mobile'
import Imaging from '@/v2/containers/patient-imaging'

const MobilePatientRoutes = ({ patient }) => {
  return (
    <Switch>
      <Route path="/v2/patient/:id?/profile">
        <MobileProfile patient={patient} />
      </Route>
      <Route exact path="/v2/patient/:id/imaging/progression/:progression">
        <Imaging patient={patient} />
      </Route>
      <Route
        exact
        path="/v2/patient/:id/imaging/progression/:progression/series/:series"
      >
        <Imaging patient={patient} />
      </Route>
      <Route path="/v2/patient/:id?/imaging">
        <Imaging patient={patient} />
      </Route>
      <Route
        path="*"
        render={() => {
          window.location.href = '/v2'
          return null
        }}
      />
    </Switch>
  )
}

export default MobilePatientRoutes
