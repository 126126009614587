import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import NoteForm from '@/v2/containers/notes/NoteForm'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import SubItemBG from '#/assets/images/v2/subitem.png'

const Wrapper = styled.div`
  margin-left: 40px;
  margin-top: 16px;
  padding: 25px 26px 30px 28px;
  border: 1px solid ${({ theme }) => theme.silver.dark};
  border-radius: 3px;
  position: relative;

  & .MuiTextField-root {
    margin-top: 0;
    max-width: inherit;
  }

  & .note-input-buttons {
    max-width: inherit;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0px;
  left: -30px;
`

const AddNote = ({ appointmentId, note, onSave, onEdit, onCancel }) => {
  const theme = useTheme()
  const { patient } = usePatientContext()
  return (
    <Wrapper theme={theme.palette}>
      <Image src={SubItemBG} />
      <NoteForm
        showAlertBeforeLeaving
        appointmentId={appointmentId}
        patientId={patient.id}
        note={note}
        onSave={onSave}
        onEdit={onEdit}
        onCancel={onCancel}
      />
    </Wrapper>
  )
}

export default AddNote
