import React from 'react'
import styled from 'styled-components'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import Typography from '@/v2/components/common/Typography'
import { getDimensions } from '../Image'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  gap: 1vw;
  overflow-y: auto;
  width: ${({ $isMobile }) => ($isMobile ? '100%' : 'calc(900px + 2vw)')};
  padding-bottom: 50px;
`
export const Logo = styled.div`
  ${({ $isMobile }) => getDimensions($isMobile, 4)}
  display: flex;
  flex-direction: column;
  justify-content: end;
`

export const Title = ({ children }) => {
  const { isMobile } = useDetectDevice()
  return (
    <Typography
      variant="h7"
      fontSize={isMobile ? '10px' : '16px'}
      sx={isMobile ? {} : { mb: '6px' }}
    >
      {children}
    </Typography>
  )
}

export const SubTitle = ({ children }) => {
  const { isMobile } = useDetectDevice()
  return (
    <Typography variant="h6" fontSize={isMobile ? '10px' : '16px'}>
      {children}
    </Typography>
  )
}
