import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import Tag from '@/v2/components/common/Tag'
import { INTAKE_FORM_STATUS } from '@/v2/constants'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSendIntakeForm } from '@/v2/hooks/documents/useIntakeForm'

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const HealthHistory = () => {
  const theme = useTheme()
  const { patient } = usePatientContext()
  const { intakeForm } = patient
  const { sendIntakeForm } = useSendIntakeForm(intakeForm?.id)
  const { onOpenSnackbar } = useSnackbarContext()

  if (!intakeForm) return null

  const intakeStatus = INTAKE_FORM_STATUS[intakeForm?.status]

  const handleSend = async () => {
    try {
      const { error } = await sendIntakeForm()

      if (!error) {
        onOpenSnackbar('Health history form successfully sent.')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Line>
        <Typography variant="h3" sx={{ mb: '12px' }}>
          Health history form
        </Typography>
        <Typography fontWeight="400" fontSize="15px">
          {`ID# ${intakeForm.identifierToken}`}
        </Typography>
      </Line>
      <Line>
        <Tag color={intakeStatus.color} label={intakeStatus.label} />
        {intakeForm.status === 'completed' ? (
          <Button
            variant="text"
            href={`/intake_forms/${intakeForm.id}`}
            rel="noopener noreferrer"
            target="_blank"
            sx={{ color: theme.palette.gray.dark }}
          >
            <Typography variant="h5">View form</Typography>
          </Button>
        ) : (
          <Button
            variant="text"
            onClick={handleSend}
            sx={{ color: theme.palette.gray.dark }}
          >
            <Typography variant="h5">Send for Signature</Typography>
          </Button>
        )}
      </Line>
    </Paper>
  )
}

export default HealthHistory
