import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@/v2/components/common/Typography'

const CheckboxField = ({
  label,
  error,
  checked = false,
  color = 'primary',
  onChange,
}) => {
  return (
    <FormControl variant="standard">
      <FormControlLabel
        control={
          <Checkbox checked={checked} color={color} onChange={onChange} />
        }
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          '& > span': { fontSize: '14px', fontWeight: 400 },
        }}
        label={label}
      />
      {error && <Typography variant="error">{error}</Typography>}
    </FormControl>
  )
}

export default CheckboxField
