import { useState, useMemo, useCallback } from 'react'

const FIRST_HOUR_DAY = 0
const LAST_HOUR_DAY = 1425

export const usePeriodOfTime = () => {
  const [startHour, setStartHour] = useState(540)
  const [endHour, setEndHour] = useState(1080)

  const changeStartHour = useCallback(
    newHour => {
      const roundHour = newHour - (newHour % 60)
      const minHour = roundHour < FIRST_HOUR_DAY ? FIRST_HOUR_DAY : roundHour

      if (newHour < startHour) {
        setStartHour(minHour)
      }
    },
    [startHour]
  )

  const changeEndHour = useCallback(
    newHour => {
      const roundHour = newHour + (newHour % 60)
      const maxHour = roundHour > LAST_HOUR_DAY ? LAST_HOUR_DAY : roundHour

      if (newHour > endHour) {
        setEndHour(maxHour)
      }
    },
    [endHour]
  )

  return useMemo(
    () => ({ startHour, endHour, changeStartHour, changeEndHour }),
    [startHour, endHour, changeStartHour, changeEndHour]
  )
}
