import { useMemo } from 'react'
import { useLoadData } from '@/v2/containers/calendar/PeriodOfTime/hooks/useLoadData'
import { usePeriodOfTime } from '@/v2/containers/calendar/PeriodOfTime/hooks/usePeriodOfTime'

export const useAvailableTimeSlots = date => {
  const { loading, error, startHour, endHour } = useLoadData(date)
  const {
    startHour: defaultStartHour,
    endHour: defaultEndHour,
    changeStartHour,
    changeEndHour,
  } = usePeriodOfTime()

  if (!!startHour) changeStartHour(startHour)
  if (!!endHour) changeEndHour(endHour)

  const appointmentDuration = 15

  const arrayRange = Array.from(
    {
      length: Math.ceil(
        (defaultEndHour - defaultStartHour) / appointmentDuration
      ),
    },
    (x, i) => i * appointmentDuration + defaultStartHour
  )

  return useMemo(() => ({ loading, error, timeSlots: arrayRange }), [
    loading,
    error,
    arrayRange,
  ])
}
