import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Profile from '@/v2/containers/patient-profile'
import Treatment from '@/v2/containers/patient-treatment'
import Documents from '@/v2/containers/patient-documents'
import Finance from '@/v2/containers/patient-finance'
import Imaging from '@/v2/containers/patient-imaging'
import Insurance from '../containers/patient-insurance'
import { usePatientContext } from '@/v2/contexts/PatientContext'

const PatientRoutes = () => {
  const { patient } = usePatientContext()
  return (
    <Switch>
      <Route path="/v2/patient/:id?/profile" component={Profile} />
      <Route
        path="/v2/patient/:id?/treatment/:seatAppointmentId?"
        render={({ location, ...others }) => (
          <Treatment
            appointments={[
              ...patient.nextAppointments,
              ...patient.previousAppointments,
            ]}
            {...(location.state || {})}
            {...(others?.match?.params || {})}
          />
        )}
      ></Route>
      <Route path="/v2/patient/:id?/documents">
        <Documents
          patientId={patient.id}
          email={patient.email}
          intakeForm={patient.intakeForm}
          contracts={patient.contracts}
          currentContract={patient.contract}
        />
      </Route>
      <Route exact path="/v2/patient/:id/imaging/progression/:progression">
        <Imaging patient={patient} />
      </Route>
      <Route
        exact
        path="/v2/patient/:id/imaging/progression/:progression/series/:series"
      >
        <Imaging patient={patient} />
      </Route>
      <Route path="/v2/patient/:id?/imaging">
        <Imaging patient={patient} />
      </Route>
      <Route path="/v2/patient/:id?/finance">
        <Finance patient={patient} />
      </Route>
      <Route path="/v2/patient/:id?/insurance">
        <Insurance patient={patient} />
      </Route>
    </Switch>
  )
}

export default PatientRoutes
