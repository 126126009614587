import { useState, useMemo, useCallback } from 'react'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const baseUrl = window.location.origin
const meta = document.querySelector('meta[name=csrf-token]')
const csrfToken = meta && meta.getAttribute('content')
const headers = {
  'X-CSRF-Token': csrfToken,
}

export const useUploadImage = () => {
  const { onOpenSnackbar } = useSnackbarContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const uploadImage = useCallback(
    async (patientId, seriesId, image, onCallback) => {
      setError(false)
      setLoading(true)
      try {
        if (!patientId) return console.error('The patient id must be informed')
        if (!seriesId) return console.error('The series id must be informed')

        const res = await fetch(
          `${baseUrl}/v2/rest/patients/${patientId}/imaging/series/${seriesId}/images`,
          {
            method: 'POST',
            body: image,
          }
        )
        const data = await res.json()
        onCallback && onCallback(data)

        return data
      } catch (err) {
        console.error(err)
        setError(true)
        onOpenSnackbar('Image upload failed', 'error')
      } finally {
        setLoading(false)
      }
    },
    [onOpenSnackbar]
  )

  return useMemo(() => ({ loading, error, uploadImage }), [
    loading,
    error,
    uploadImage,
  ])
}

export const useEditImage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const editImage = useCallback(
    async (patientId, seriesId, image, onCallback) => {
      setError(false)
      setLoading(true)

      if (!patientId) return
      if (!seriesId) return
      if (!image.id) return

      try {
        const res = await fetch(
          `${baseUrl}/v2/rest/patients/${patientId}/imaging/series/${seriesId}/images/${image.id}`,
          {
            method: 'PATCH',
            headers,
            body: image.file,
          }
        )

        const data = await res.json()
        onCallback && onCallback(data)

        return data
      } catch (err) {
        console.error(err)
        setError(true)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return useMemo(() => ({ loading, error, editImage }), [
    loading,
    error,
    editImage,
  ])
}

export const useDeleteImage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const deleteImage = useCallback(
    async (patientId, seriesId, image, onCallback) => {
      setError(false)
      setLoading(true)

      if (!patientId) return
      if (!seriesId) return
      if (!image.id) return

      try {
        return await fetch(
          `${baseUrl}/v2/rest/patients/${patientId}/imaging/series/${seriesId}/images/${image.id}`,
          { method: 'DELETE' }
        )
      } catch (err) {
        console.error(err)
        setError(true)
      } finally {
        setLoading(false)
        onCallback && onCallback()
      }
    },
    []
  )

  return useMemo(() => ({ loading, error, deleteImage }), [
    loading,
    error,
    deleteImage,
  ])
}
