import React from 'react'
import { NoteActions, NoteContainer, NoteContent, NoteWrapper } from './styles'
import { useTheme } from '@mui/material'
import IconMenu from '@/v2/components/common/IconMenu'
import Typography from '@/v2/components/common/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useToggle from '@/v2/hooks/useToggle'
import useNotes from '@/v2/hooks/useNotes'
import NoteForm from './NoteForm'
import { format, differenceInDays } from 'date-fns'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import { parseHtmlToString } from '@/v2/utils/helpers'

const Note = ({ note, appointmentId, doctorId }) => {
  const theme = useTheme()
  const { open: showForm, handleToggle: handleShowForm } = useToggle()
  const { mutationDelete } = useNotes()
  const { mutate: deleteNote } = mutationDelete
  const currentDate = nowOnClinicTimezone()
  const createdAt = getDateTimeWithoutTimezone(note.createdAt)
  const daysOld = differenceInDays(currentDate, createdAt)

  const isEditable = daysOld < 1

  const options = isEditable
    ? [
        {
          label: 'Edit',
          onClick: () => handleShowForm(),
        },
        {
          label: 'Delete',
          onClick: () =>
            confirm('Are you sure you want to delete this note?') &&
            deleteNote({ appointmentId, id: note.id }),
        },
      ]
    : null

  const renderNoteContent = () => {
    if (!showForm) {
      return (
        <>
          <NoteContent>
            <Typography wordBreak variant="h4">
              {parseHtmlToString(note.annotation)}
            </Typography>
          </NoteContent>
          <NoteActions>
            <IconMenu
              options={options}
              sx={{
                color: !isEditable
                  ? theme.palette.grey[400]
                  : theme.palette.grey[900],
              }}
              readOnly={!isEditable}
              icon={<MoreVertIcon fontSize="small" />}
              aria-label={
                isEditable
                  ? 'Options'
                  : 'You can only edit notes that are less than 24 hours old'
              }
              title={
                isEditable
                  ? 'Options'
                  : 'You can only edit notes that are less than 24 hours old'
              }
            />
          </NoteActions>
        </>
      )
    }
    return (
      <NoteForm
        note={note}
        appointmentId={appointmentId}
        doctorId={doctorId}
        setShowNoteForm={handleShowForm}
      />
    )
  }

  return (
    <NoteContainer>
      <NoteWrapper theme={theme.palette}>{renderNoteContent()}</NoteWrapper>
      <Typography color={theme.palette.grey[500]} variant="h5">
        {note.doctor
          ? `Dr. ${note.doctor.fullName}`
          : `User ${note.user.fullName}`}{' '}
        | {format(new Date(note.updatedAt), 'MM/dd/yyyy HH:mm a')}
      </Typography>
    </NoteContainer>
  )
}

export default Note
