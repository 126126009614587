import React, { createContext, useContext, useState, useMemo } from 'react'
import Snackbar from '@/v2/components/common/Snackbar'
import { useCallback } from 'react'

export const SnackbarContext = createContext([])

const snackInitialState = {
  open: false,
  message: '',
  status: 'success',
}

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState(snackInitialState)

  const handleOpenSnackbar = useCallback((message = '', status = 'success') => {
    setSnackbar({ open: true, message, status })
  }, [])

  const snackbarInfo = useMemo(
    () => ({ snackbar, onOpenSnackbar: handleOpenSnackbar }),
    [snackbar, handleOpenSnackbar]
  )
  return (
    <SnackbarContext.Provider value={snackbarInfo}>
      {children}
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        status={snackbar.status}
        onClose={() => setSnackbar(snackInitialState)}
      />
    </SnackbarContext.Provider>
  )
}

export const useSnackbarContext = () => {
  const { snackbar, onOpenSnackbar } = useContext(SnackbarContext)
  return { snackbar, onOpenSnackbar }
}
