import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@/v2/components/common/Button'
import Grid from '@mui/material/Grid'

const PatientNote = ({ note, onSave, onClose }) => {
  const ref = useRef(null)
  const [value, setValue] = useState(note?.annotation || '')

  let debounce
  const handleChange = value => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      setValue(value)
    }, 300)
  }

  return (
    <>
      <TextField
        multiline
        autoFocus
        className="note-input"
        inputRef={ref}
        rows={7}
        placeholder="Write a new note..."
        defaultValue={value}
        onChange={({ target }) => handleChange(target.value)}
      />
      <Grid container justifyContent="flex-end" gap={2}>
        <Button color="secondary" variant="text" onClick={() => onClose()}>
          Cancel
        </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={() => onSave(value)}
        >
          Save
        </Button>
      </Grid>
    </>
  )
}

export default PatientNote
