import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Modal from '@/v2/components/common/Modal'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'
import InputField from '@/v2/components/common/InputField'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import useInvoiceActions from '@/v2/hooks/useInvoiceActions'
import { fromCurrencyToNumber } from '@/v2/utils/helpers'
import usePayments from '@/v2/hooks/usePayments'
import SelectInputField from '@/v2/components/common/SelectInputField'
import ActionButton from '@/v2/components/common/ActionButton'
import { toCurrency, toCamelCase } from '@/v2/utils/helpers'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormErrorMessage from '@/v2/components/common/FormErrorMessage'

const schema = z
  .object({
    total: z.number().min(0),
    amount: z
      .string()
      .refine(value => fromCurrencyToNumber(value) > 0.5, {
        message: 'Amount must be greater than $0.50',
      })
      .transform(value => fromCurrencyToNumber(value)),
    paymentId: z.number().min(0),
    message: z.string().nullish(),
  })
  .refine(
    data => {
      if (!data.total) return true
      return data.amount <= data.total
    },
    {
      message: "Amount can't be greater than the charged amount",
      path: ['amount'],
    }
  )

const RefundPayment = ({ isOpen, onClose, invoice }) => {
  const { patientId } = useParams()

  const {
    refund: { mutateAsync: onRefund },
  } = useInvoiceActions({ patientId })
  const { onOpenSnackbar } = useSnackbarContext()

  const { payments } = usePayments({
    invoiceId: invoice.id,
  })

  const processedPayments = useMemo(() => {
    return payments.filter(
      payment =>
        payment.status !== 'refunded' &&
        payment.status !== 'declined' &&
        payment.status !== 'canceled' &&
        payment.paymentMethod !== 'check'
    )
  }, [payments])

  const {
    control,
    setError,
    setValue,
    register,
    formState: { errors, isSubmitting },
    handleSubmit: handleValidForm,
    watch,
  } = useForm({
    defaultValues: {
      amount: '',
      message: '',
      total: invoice.total,
    },
    resolver: zodResolver(schema),
  })

  const watchPaymentId = watch('paymentId')

  useEffect(() => {
    if (processedPayments?.length > 0 && !watchPaymentId) {
      setValue('paymentId', processedPayments[0].id)
    }
  }, [processedPayments, setValue, watchPaymentId])

  const payment = useMemo(() => {
    return processedPayments.find(payment => payment.id === watchPaymentId)
  }, [watchPaymentId, processedPayments])

  useEffect(() => {
    if (payment) {
      setValue('total', parseFloat(payment.amountCharged))
    }
  }, [payment, setValue])

  const handleSubmit = async data => {
    try {
      const res = await onRefund({
        id: invoice.id,
        amount: data.amount,
        message: data.message,
        paymentId: data.paymentId,
      })
      if (res.error) {
        throw new Error(res.error)
      } else {
        onClose()
        onOpenSnackbar('Invoice refunded')
      }
    } catch (error) {
      setError('root.refundInvoice', {
        type: 'manual',
        message: error.message,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={<></>}
      title="Refund Payment"
      maxWidth="xs"
      fullWidth
    >
      <FormControl
        component="form"
        onSubmit={handleValidForm(handleSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <SelectInputField
          noMargin
          label="Payments"
          id="payment-plan-paymentId"
          options={processedPayments.map(payment => ({
            label: `$${toCurrency(
              parseFloat(payment.amountCharged)
            )} - ${toCamelCase(payment.paymentMethod)}`,
            value: payment.id,
          }))}
          color="secondary"
          error={errors?.paymentId?.message}
          {...register('paymentId', {
            valueAsNumber: true,
          })}
        />

        <CurrencyInputField
          autoFocus
          label="Amount"
          name="amount"
          placeholder="$0.00"
          prefix="$"
          error={errors?.amount?.message}
          control={control}
        />

        <InputField
          label="Message"
          name="message"
          placeholder="What's the reason for the refund?"
          error={errors?.message?.message}
          control={control}
          {...register('message')}
        />

        <FormErrorMessage errors={errors} name="root.refundInvoice" />

        <Grid container gap={2} justifyContent="flex-end">
          <ActionButton
            variant="text"
            color="primary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </ActionButton>

          <ActionButton
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleValidForm(handleSubmit)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {payment?.paymentMethod === 'cash' ||
            payment?.paymentMethod === 'check'
              ? 'Mark as refunded'
              : 'Refund'}
          </ActionButton>
        </Grid>
      </FormControl>
    </Modal>
  )
}

export default RefundPayment
