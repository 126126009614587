import React, { useEffect, useRef } from 'react'
import Modal from '@/v2/components/common/Modal'
import useModals from '@/v2/hooks/useModals'
import RadioField from '@/v2/components/common/RadioField'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { subWeeks, parseISO, format } from 'date-fns'
import { zodResolver } from '@hookform/resolvers/zod'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Label from '@/v2/components/common/FieldLabel'

const dateInit = subWeeks(new Date(), 2)
const dateMax = new Date()
const formattedInitDate = format(dateInit, 'yyyy-MM-dd')
const formattedMaxDate = format(dateMax, 'yyyy-MM-dd')

const schema = z
  .object({
    start_date: z
      .string()
      .transform(d => parseISO(d))
      .refine(d => d <= dateMax, {
        message: 'Start date must be less than today',
      }),

    end_date: z
      .string()
      .transform(d => parseISO(d))
      .refine(d => d <= dateMax, {
        message: 'End date must be less than today',
      }),
    type: z.enum(['print']),
  })
  .refine(data => data.start_date <= data.end_date, {
    message: 'Start date must be less than end date',
    path: ['start_date'],
  })

const Receipts = ({ isOpen, onClose, patient }) => {
  const modals = useModals()
  const dateStartRef = useRef(null)
  const dateEndRef = useRef(null)

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      start_date: formattedInitDate,
      end_date: formattedMaxDate,
      type: 'print',
    },
  })
  const { ref: startDateRef, ...startDateRest } = register('start_date')
  const { ref: endDateRef, ...endDateRest } = register('end_date')
  const watchStartDate = watch('start_date')
  const watchEndDate = watch('end_date')

  useEffect(() => {
    const startDateInput = dateStartRef.current
    const endDateInput = dateEndRef.current

    if (watchStartDate && endDateInput) {
      endDateInput.min = watchStartDate
      endDateInput.ariaValueMin = watchStartDate
    }

    if (watchEndDate && startDateInput) {
      startDateInput.max = watchEndDate
      startDateInput.ariaValueMax = watchEndDate
    }
  }, [watchStartDate, watchEndDate])

  const onSubmit = data => {
    const receiptURL = `/v2/billing/patients/${patient.id}/receipts`
    const params = Object.entries(data)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const receipt = window.open(
      [receiptURL, params].join('?'),
      'noopener,noreferrer'
    )

    if (receipt) {
      modals.close()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      title="HSA/FSA Receipt"
      confirmLabel="Create Receipt"
      maxWidth="xs"
      fullWidth
    >
      <FormControl
        component="form"
        variant="filled"
        sx={{
          '.MuiRadio-root': {
            padding: 1,
          },
        }}
      >
        <FormControl variant="standard">
          <Label htmlFor="receipt-start-date">Start date</Label>
          <TextField
            id="receipt-start-date"
            fullWidth
            error={!!errors.start_date}
            helperText={errors.start_date?.message}
            type="date"
            inputRef={e => {
              startDateRef(e)
              dateStartRef.current = e
            }}
            name="start_date"
            {...startDateRest}
          />
        </FormControl>
        <FormControl variant="standard">
          <Label htmlFor="receipt-end-date">End date</Label>
          <TextField
            id="receipt-end-date"
            fullWidth
            error={!!errors.end_date}
            helperText={errors.end_date?.message}
            type="date"
            name="end_date"
            inputRef={e => {
              endDateRef(e)
              dateEndRef.current = e
            }}
            {...endDateRest}
          />
        </FormControl>
        <RadioField
          name="type"
          color="secondary"
          id="receipt-types"
          defaultValue="print"
          options={[{ label: 'Print', value: 'print' }]}
          {...register('type')}
        />
      </FormControl>
    </Modal>
  )
}

export default Receipts
