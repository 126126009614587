import { useEffect, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'
import useNotes from '@/v2/hooks/useNotes'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

export const useSaveNote = () => {
  const { mutationCreate, mutationUpdate } = useNotes()
  const [updateAppointment] = useMutation(UpdateAppointment)
  const { onOpenSnackbar } = useSnackbarContext()

  const { mutate: createNote, error: createNoteError } = mutationCreate
  const { mutate: editNote, error: editNoteError } = mutationUpdate

  useEffect(() => {
    if (!!createNoteError || !!editNoteError) {
      onOpenSnackbar('Something went wrong when saving notes!', 'error')
    }
  }, [createNoteError, editNoteError, onOpenSnackbar])

  const saveNote = useCallback(
    async ({ value, doctor, edit, id }, appointmentId) => {
      try {
        if (edit) {
          const newNote = { id: id, doctor: doctor.id, note: value }
          if (id === 0) {
            const updatedAppointment = await updateAppointment({
              variables: { appointment: { id: appointmentId, notes: value } },
            })
            if (updatedAppointment.updateAppointment.errors !== '{}') {
              throw Error(updatedAppointment.updateAppointment.errors)
            }
          } else {
            await editNote({ ...newNote, appointmentId })
          }
        } else {
          await createNote(
            { doctor: doctor.id, note: value, appointmentId },
            () => {
              onOpenSnackbar('Created note.')
            }
          )
        }
      } catch (e) {
        onOpenSnackbar('Something went wrong when saving notes!', 'error')
      }
    },
    [createNote, editNote, onOpenSnackbar, updateAppointment]
  )

  return { saveNote }
}
