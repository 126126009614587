import React from 'react'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@/v2/components/common/Typography'
import LabelPicker from '@/v2/components/common/LabelPicker'
import { Label, LabelsSection, LabelsWrapper } from './styles'
import useLabels from '@/v2/hooks/useLabels'

const Labels = ({ appointment }) => {
  const theme = useTheme()
  const { handleUpdateLabels: onUpdateLabels } = useLabels({
    appointment,
  })

  const limit = 3

  const handleUpdateLabels = (label, action) => {
    if (action === 'add') {
      const newLabels = [...appointment.labels, label]
      onUpdateLabels(newLabels)
    } else {
      const newLabels = appointment.labels.filter(l => l.id !== label.id)
      onUpdateLabels(newLabels)
    }
  }

  return (
    <LabelsSection>
      <Typography fontSize="16px" variant="h3">
        Labels
      </Typography>
      <LabelsWrapper>
        {appointment.labels.map(label => (
          <Label key={label.id} color={label.color} theme={theme.palette}>
            <Typography key={label.id} color="white" fontSize="12px">
              {label.name}
            </Typography>
            <CloseIcon
              fontSize="12px"
              color="white"
              onClick={() => handleUpdateLabels(label, 'remove')}
            />
          </Label>
        ))}
        {appointment.labels.length < limit ? (
          <LabelPicker
            selectedLabels={appointment.labels}
            hideLabels={['reschedule', 'no show']}
            onChange={label => handleUpdateLabels(label, 'add')}
          />
        ) : null}
      </LabelsWrapper>
    </LabelsSection>
  )
}

export default Labels
