import { useMutation } from '@tanstack/react-query'
import { baseURL, headers } from '@/v2/constants/requests'

const loginUrl = `${baseURL}/v2/rest/sessions.json`

export const useLogin = () => {
  const { mutate: login } = useMutation({
    mutationFn: async session => {
      const res = await fetch(loginUrl, {
        method: 'POST',
        body: JSON.stringify(session),
        headers,
      })
      if (!res.ok) {
        throw new Error('We could not log you in.')
      }
      return res.json()
    },
  })
  return { login }
}

export const useLogout = () => {
  const { mutate: logout } = useMutation({
    mutationFn: async () => {
      const res = await fetch(loginUrl, {
        method: 'DELETE',
        headers,
      })
      if (!res.ok) {
        throw new Error('We could not log you out.')
      }
      return res.json()
    },
  })
  return { logout }
}

export const useNewPassword = () => {
  const { mutate: newPassword } = useMutation({
    mutationFn: async session => {
      const res = await fetch(`${baseURL}/v2/rest/sessions/password`, {
        method: 'POST',
        body: JSON.stringify(session),
        headers,
      })
      if (!res.ok) {
        throw new Error('We could not reset your password.')
      }
      return res.json()
    },
  })
  return { newPassword }
}

export const useResetPassword = () => {
  const { mutate: resetPassword } = useMutation({
    mutationFn: async session => {
      const res = await fetch(`${baseURL}/v2/rest/sessions/password`, {
        method: 'PUT',
        body: JSON.stringify(session),
        headers,
      })
      if (!res.ok) {
        throw new Error('We could not reset your password.')
      }
      return res.json()
    },
  })
  return { resetPassword }
}
