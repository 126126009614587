import styled from 'styled-components'
import { HEADER_HEIGHT } from '@/v2/constants'

export const Wrapper = styled.div`
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 0px 24px;
  outline: 1px solid ${({ theme }) => theme.primary.main};
`

export const Content = styled.div`
  display: flex;
  height: calc(100% - ${HEADER_HEIGHT});
`

export const ConversationWrapper = styled.div`
  width: calc(100% - 282px);
  display: flex;
  justify-content: center;
  align-items: center;
`
