import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { TOPBAR_MOBILE_HEIGHT } from '@/v2/constants'
import Typography from '@/v2/components/common/Typography'
import MobileSearch from './MobileSearch'

const Wrapper = styled.div`
  padding: 20px 24px;
  height: calc(100vh - ${TOPBAR_MOBILE_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SearchBar = styled.div`
  padding: 4px 16px 16px 16px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.divider.main};
`

const SearchMobile = () => {
  const [showSearch, setShowSearch] = useState(false)
  const theme = useTheme()

  if (showSearch) return <MobileSearch />

  return (
    <Wrapper>
      <Typography variant="h2" fontSize="20px" sx={{ mb: '20px' }}>
        Look for a patient
      </Typography>
      <SearchBar theme={theme.palette} onClick={() => setShowSearch(true)}>
        <SearchIcon sx={{ mr: '20px' }} />
        <Typography color={theme.palette.gray.main}>Search...</Typography>
      </SearchBar>
    </Wrapper>
  )
}

export default SearchMobile
