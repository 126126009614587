import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import {
  PATIENT_BOTTOM_MOBILE_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
  INFOBAR_HEIGHT,
} from '@/v2/constants'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import { useGetProgressions } from '@/v2/hooks/useProgressions.js'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import Imaging from './Imaging'

const barsHeight =
  parseInt(PATIENT_BOTTOM_MOBILE_HEIGHT, 10) +
  parseInt(TOPBAR_MOBILE_HEIGHT, 10)

const Wrapper = styled.div`
  display: flex;
  ${({ theme, $isMobile }) =>
    $isMobile
      ? `
  height: calc(100vh - ${barsHeight}px);
  flex-direction: column;
  `
      : `
  background: ${theme.silver.main};
  height: 100%;
  width: 100%;
  max-height: calc(100% - ${INFOBAR_HEIGHT});
  overflow-y: auto;
  `}
`

const PatientName = styled.div`
  padding: 16px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.divider.main};
`

const ImagingPage = () => {
  const { id: patientId } = useParams()
  const { isMobile } = useDetectDevice()
  const theme = useTheme()
  const { loading, error } = useGetProgressions(patientId)
  const { patient } = usePatientContext()
  return (
    <Wrapper theme={theme.palette} $isMobile={isMobile}>
      {isMobile && (
        <PatientName theme={theme.palette}>
          <Typography variant="h2" fontSize="20px">
            {patient.fullName}
          </Typography>
        </PatientName>
      )}
      {error && !loading && <Typography>Something went wrong!</Typography>}
      {loading && (
        <Typography sx={{ margin: '10px 20px' }}>Loading...</Typography>
      )}
      {!loading && !error && <Imaging />}
    </Wrapper>
  )
}

export default ImagingPage
