import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@/v2/components/common/TextField'
import { formatPhone } from '@/v2/utils/format'

const Form = ({
  values = {
    phoneNumber: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
  },
  error = {},
  onChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="address1"
          label="Address"
          value={values.address1}
          onChange={value => onChange({ address1: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="address2"
          label="Address 2"
          value={values.address2}
          onChange={value => onChange({ address2: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="zipcode"
          label="Postal Code"
          value={values.zipcode}
          onChange={value => onChange({ zipcode: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="city"
          label="City"
          value={values.city}
          onChange={value => onChange({ city: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="state"
          label="State"
          value={values.state}
          onChange={value => onChange({ state: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="country"
          label="Country"
          value={values.country}
          onChange={value => onChange({ country: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="phoneNumber"
          label="Mobile"
          value={values.phoneNumber}
          error={error.phoneNumber}
          onChange={value => onChange({ phoneNumber: formatPhone(value) })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="email"
          label="Email"
          type="email"
          value={values.email}
          error={error.email}
          onChange={value => onChange({ email: value })}
        />
      </Grid>
    </Grid>
  )
}

export default Form
