import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'

const combineValidators = validators => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const validate = (value, validator) => {
  if (isFunction(validator)) return validator(value)
  else if (isArray(validator)) return combineValidators(validator)(value)
  else if (isObject(validator)) {
    const _errors = {}
    Object.keys(validator).forEach(
      key => (_errors[key] = validate(get(value, key), get(validator, key)))
    )
    const errors = omitBy(_errors, isUndefined)
    return isEmpty(errors) ? undefined : errors
  }
}

export default validate
