import React from 'react'
import { useParams } from 'react-router-dom'
import Modal from '@/v2/components/common/Modal'
import EditPatientNote from '@/v2/containers/patient-notes/Edit'

const UpdatePatientNote = ({ note, isOpen, onClose, onSave }) => {
  const { id: patientId } = useParams()
  return (
    <Modal
      isOpen={isOpen}
      title={`Edit ${note.classification} note`}
      footer={<></>}
      onClose={onClose}
      minWidth={'xs'}
      fullWidth
    >
      <EditPatientNote
        patientId={patientId}
        note={note}
        onSave={note => {
          onSave && onSave(note)
          onClose()
        }}
      />
    </Modal>
  )
}

export default UpdatePatientNote
