import { useMutation, useQueryClient } from '@tanstack/react-query'
import PatientFinanceInfo from '@/v2/hooks/usePatientFinanceInfo/graphql/queries/PatientFinanceInfo.gql'
import { baseURLGraphql, headers, baseURL } from '@/v2/constants/requests'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const useInvoiceActions = (props = {}) => {
  const { patientId } = props
  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()

  const sendPaymentLink = useMutation({
    mutationKey: ['sendPaymentLink', baseURL],
    mutationFn: async invoice => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoice.id}/links/new.json`,
        { method: 'GET', headers }
      ).then(response => response.text())
      return res ? JSON.parse(res) : {}
    },
  })

  const refund = useMutation({
    mutationKey: ['refundInvoice', baseURL],
    mutationFn: async invoice => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoice.id}/payments/${invoice.paymentId}/refund.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            amount: invoice.amount,
            message: invoice.message,
          }),
        }
      ).then(response => response.text())
      return res ? JSON.parse(res) : {}
    },
    onError: () =>
      onOpenSnackbar('We were not able to refund the invoice', 'error'),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  const cancelInvoice = useMutation({
    mutationKey: ['cancelInvoice', baseURL],
    mutationFn: async invoice => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoice.id}/cancel.json`,
        {
          method: 'DELETE',
          headers,
        }
      ).then(response => response.text())
      return res ? JSON.parse(res) : {}
    },
    onError: () => onOpenSnackbar('Error cancelling invoice', 'error'),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: changeCardPaymentMethod,
    isLoading: isChangingCardPaymentMethod,
    isError: isChangingCardPaymentMethodError,
  } = useMutation({
    mutationKey: ['changeCardPaymentMethod', baseURL],
    mutationFn: async ({ invoiceId, paymentMethodId, isDefaultMethod }) =>
      fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoiceId}/payment_method.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({
            invoice: {
              payment_method_id: paymentMethodId,
              default: isDefaultMethod ? String(isDefaultMethod) : 'false',
            },
          }),
        }
      ),
    onMutate: async ({ invoiceId, paymentMethodId, isDefaultMethod }) => {
      await queryClient.cancelQueries([
        'patient-finance-info',
        baseURLGraphql,
        headers,
        PatientFinanceInfo,
        patientId,
      ])
      const previousData = queryClient.getQueryData([
        'patient-finance-info',
        baseURLGraphql,
        headers,
        PatientFinanceInfo,
        patientId,
      ])

      if (!isDefaultMethod) {
        queryClient.setQueryData(
          [
            'patient-finance-info',
            baseURLGraphql,
            headers,
            PatientFinanceInfo,
            patientId,
          ],
          old => ({
            ...old,
            patientFinanceInfo: {
              ...old.patientFinanceInfo,
              paymentPlans: old.patientFinanceInfo.paymentPlans.map(p => {
                return {
                  ...p,
                  invoices: p.invoices.map(i => {
                    if (parseInt(i.id) === parseInt(invoiceId)) {
                      return {
                        ...i,
                        paymentMethodId,
                      }
                    }
                    return i
                  }),
                }
              }),
            },
          })
        )
      } else {
        queryClient.setQueryData(
          [
            'patient-finance-info',
            baseURLGraphql,
            headers,
            PatientFinanceInfo,
            patientId,
          ],
          old => {
            const findPaymentPlanWithInvoice = old.patientFinanceInfo.paymentPlans.find(
              p => {
                return p.invoices.find(
                  i => parseInt(i.id) === parseInt(invoiceId)
                )
              }
            )

            if (findPaymentPlanWithInvoice) {
              return {
                ...old,
                patientFinanceInfo: {
                  ...old.patientFinanceInfo,
                  paymentPlans: old.patientFinanceInfo.paymentPlans.map(p => {
                    if (
                      parseInt(p.id) === parseInt(findPaymentPlanWithInvoice.id)
                    ) {
                      return {
                        ...p,
                        invoices: p.invoices.map(i => {
                          return {
                            ...i,
                            paymentMethodId,
                          }
                        }),
                      }
                    }
                    return p
                  }),
                },
              }
            }

            return old
          }
        )
      }

      return { previousData }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
        context.previousData
      )
    },
  })

  const {
    mutateAsync: chargeInvoice,
    mutate: chargeInvoiceSync,
    isLoading: isChargingInvoice,
    isError: isChargingInvoiceError,
  } = useMutation({
    mutationKey: ['chargeInvoice', baseURL],
    mutationFn: async variables => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${variables.invoiceId}/payments.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            payment_method: variables.paymentMethod,
            amount_targeted: variables.amountTargeted,
            payment_method_id: variables.paymentMethodId,
            gateway: variables.gateway,
            additional_info: variables.additionalInfo,
          }),
        }
      )

      return res.json()
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: makeAdvancePayment,
    isLoading: isMakingAdvancePayment,
    isError: isMakingAdvancePaymentError,
  } = useMutation({
    mutationKey: ['makeAdvancePayment', baseURL],
    mutationFn: async variables => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${variables.patientId}/finance/payment_plans/${variables.paymentPlanId}/advance_payment.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            patient_id: variables.patientId,
            payment_plan_id: variables.paymentPlanId,
            advance_payment: {
              amount: variables.amountTargeted,
              payment: {
                payment_method: variables.paymentMethod,
                payment_method_id: variables.paymentMethodId,
                gateway: variables.gateway,
                additional_info: variables.additionalInfo,
              },
            },
          }),
        }
      )

      return res.json()
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: scheduleInvoiceForToday,
    isLoading: isSchedulingInvoiceForToday,
    isError: isSchedulingInvoiceForTodayError,
  } = useMutation({
    mutationKey: ['scheduleInvoice', baseURL],
    mutationFn: async ({ invoiceId }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoiceId}/awaiting_process.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({
            invoice_id: invoiceId,
          }),
        }
      )

      return res.json()
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: declineCheck,
    isLoading: isDecliningCheck,
    isError: isDecliningCheckError,
  } = useMutation({
    mutationKey: ['declineCheck', baseURL],
    mutationFn: async ({ invoiceId, paymentId }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoiceId}/payments/${paymentId}/decline.json`,
        {
          method: 'PUT',
          headers,
        }
      )

      return res.json()
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          patientId,
        ],
      })
    },
  })

  return {
    sendPaymentLink,
    refund,
    cancelInvoice,
    changeCardPaymentMethod,
    isChangingCardPaymentMethod,
    isChangingCardPaymentMethodError,
    chargeInvoice,
    chargeInvoiceSync,
    isChargingInvoice,
    isChargingInvoiceError,
    scheduleInvoiceForToday,
    isSchedulingInvoiceForToday,
    isSchedulingInvoiceForTodayError,
    declineCheck,
    isDecliningCheck,
    isDecliningCheckError,
    makeAdvancePayment,
    isMakingAdvancePayment,
    isMakingAdvancePaymentError,
  }
}

export default useInvoiceActions
