import React from 'react'
import { useTheme } from '@mui/material/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconMenu from '@/v2/components/common/IconMenu'
import styled from 'styled-components'
import Tag from '@/v2/components/common/Tag'

const Wrapper = styled.div`
  border-radius: 3px;
  width: 100%;
  background-color: ${({ theme }) => theme.gray.light};
`
const Footer = styled.div`
  height: 100px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const GalleryItem = ({ children, options, contractStatus }) => {
  const theme = useTheme()

  return (
    <Wrapper theme={theme.palette}>
      <Footer>
        <Content>{children}</Content>

        <Row>
          {contractStatus ? (
            <Tag color={contractStatus.color} label={contractStatus.label} />
          ) : null}
          {options && (
            <IconMenu
              options={options}
              icon={<MoreVertIcon fontSize="small" />}
              aria-label="More"
              title="More"
            />
          )}
        </Row>
      </Footer>
    </Wrapper>
  )
}

export default GalleryItem
