import styled from 'styled-components'
import { INFOBAR_HEIGHT } from '@/v2/constants'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${INFOBAR_HEIGHT};
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
`

export const TabsAndButtonsWrapper = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;

  && a {
    max-width: 134px;
    height 34px;
    border-color: ${({ theme }) => theme.primary.main};
  }
`
