import { useState, useEffect, useMemo } from 'react'

const baseUrl = window.location.origin
const headers = { 'Content-Type': 'application/json' }

// PS: Series === SubFolder

export const useGetSeries = (patientId, progressionId, onCallback) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const callAPI = async () => {
      setError(false)
      setLoading(true)
      try {
        await fetch(
          `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progressionId}/series`
        )
          .then(response => response.json())
          .then(data => {
            setData(data || [])
            onCallback && onCallback(data)
          })
          .finally(() => setLoading(false))
      } catch (err) {
        console.error(err)
        setError(true)
      }
    }

    callAPI()
  }, [])

  return useMemo(() => ({ data, loading, error }), [data, loading, error])
}

export const useCreateSeries = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const createSeries = async (patientId, progressionId, name, onCallback) => {
    setError(false)
    setLoading(true)
    try {
      if (!patientId) return console.error('The patient id must be informed')
      if (!progressionId)
        return console.error('The progression id must be informed')
      if (!name) return console.error('The name must be informed')

      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progressionId}/series`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ series: { name } }),
        }
      )
        .then(response => response.json())
        .then(data => onCallback && onCallback(data))
    } catch (err) {
      console.error(err)
      setError(true)
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, createSeries }), [loading, error])
}

export const useEditSeries = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const editSeries = async (patientId, progressionId, series, onCallback) => {
    setError(false)
    setLoading(true)

    if (!patientId) return console.error('The patient id must be informed')
    if (!progressionId)
      return console.error('The progression id must be informed')

    try {
      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progressionId}/series/${series.id}`,
        {
          method: 'PATCH',
          headers,
          body: JSON.stringify({
            series: {
              name: series.name,
            },
          }),
        }
      )
        .then(response => response.json())
        .then(data => onCallback && onCallback(data))
    } catch (err) {
      console.error(err)
      setError(true)
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, editSeries }), [
    loading,
    error,
    editSeries,
  ])
}

export const useDeleteSeries = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const deleteSeries = async (patientId, progressionId, series, onCallback) => {
    setError(false)
    setLoading(true)

    if (!patientId) return console.error('The patient id must be informed')
    if (!progressionId)
      return console.error('The progression id must be informed')
    if (!series.id) return console.error('The series id must be informed')

    try {
      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progressionId}/series/${series.id}`,
        { method: 'DELETE' }
      ).then(() => onCallback && onCallback())
    } catch (err) {
      console.error(err)
      setError(true)
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, deleteSeries }), [
    loading,
    error,
    deleteSeries,
  ])
}
