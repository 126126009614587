import React from 'react'
import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import Typography from '@/v2/components/common/Typography'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Tasks = ({}) => {
  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Tasks (0)
        </Typography>
      </Header>
      <Typography
        sx={{
          background: '#e4e4e4',
          textAlign: 'center',
          padding: '20px 0px',
          opacity: 0.6,
        }}
      >
        Comming soon...
      </Typography>
    </Paper>
  )
}

export default Tasks
