import React from 'react'
import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DefaultFooter from './defaults/Footer'
import DefaultLoader from './defaults/Loader'
import DefaultHeader from './defaults/Header'
import Box from '@mui/material/Box'

const Modal = ({
  isOpen = false,
  loading = false,
  header,
  loader,
  children,
  footer,
  title,
  cancelLabel = 'Cancel',
  confirmLabel = 'Save',
  extraButtonLabel,
  showExtraButton = false,
  disableBackdropClick = false,
  showConfirm = true,
  showClose = true,
  onClose,
  onCancel,
  onConfirm,
  onExtraButtonClick,
  submitDisabled,
  maxWidth = 'sm',
  fixedHeight,
  fixedWidth,
  fullWidth,
}) => {
  const theme = useTheme()

  const handleClose = () => {
    if (!disableBackdropClick) {
      onClose({ header: true, footer: false })
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...(!fixedWidth ? { maxWidth } : {})}
      fullWidth={fullWidth}
      PaperProps={{
        style: {
          position: 'relative',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          overflow: 'hidden',
          ...(fixedHeight ? { height: fixedHeight } : { maxHeight: '70vh' }),
          ...(fixedWidth ? { width: fixedWidth } : {}),
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DefaultHeader
        title={title}
        header={header}
        onClose={() => onClose({ header: true, footer: false })}
      />
      <Box sx={{ height: '16px' }} />
      <DialogContent
        sx={{
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '24px',
        }}
      >
        {loading ? loader || <DefaultLoader /> : children}
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          position: 'sticky',
          bottom: 0,
          backgroundColor: theme.palette.background.paper,
          paddingX: '24px',
        }}
      >
        {loading
          ? null
          : footer || (
              <>
                <DefaultFooter
                  cancelLabel={cancelLabel}
                  confirmLabel={confirmLabel}
                  showConfirm={showConfirm}
                  showClose={showClose}
                  onClose={() => onClose({ footer: true, header: false })}
                  onCancel={onCancel}
                  onConfirm={onConfirm}
                  submitDisabled={submitDisabled}
                />
                {showExtraButton && (
                  <Button onClick={onExtraButtonClick} color="primary">
                    {extraButtonLabel}
                  </Button>
                )}
              </>
            )}
      </DialogActions>
    </Dialog>
  )
}

export default Modal
