import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useGetSeries } from '@/v2/hooks/useSeries.js'
import Typography from '@/v2/components/common/Typography'
import { usePatientContext } from '@/v2/contexts/PatientContext'

const parse = value => parseInt(value, 10)

const SubFolders = ({ folder, isFirst }) => {
  const history = useHistory()
  const { id, progression, series } = useParams()
  const [subFolders, setSubFolders] = useState([])
  const { patient, progressions, onAddProgressionSeries } = usePatientContext()
  const { loading, error } = useGetSeries(patient.id, folder, data => {
    setSubFolders(data)
  })

  useEffect(() => {
    const hasSeries = !!progressions.find(
      ({ id }) => parse(id) === parse(folder)
    )?.series
    if (!loading && !error && !hasSeries) {
      onAddProgressionSeries(subFolders, folder)
      if (
        !series &&
        (progression ? parse(progression) === parse(folder) : isFirst)
      ) {
        history.push(
          `/v2/patient/${id}/imaging/progression/${folder}/series/${subFolders[0].id}`
        )
      }
    }
  }, [subFolders, loading, error, id, progression, series, folder])

  return subFolders
    .filter(({ name }) => name !== 'Images')
    .map(serie => (
      <Typography
        capitalize
        key={serie.id}
        bold={
          parse(serie.id) === parse(series) &&
          parse(progression) === parse(folder)
        }
        fontSize="12px"
        lineHeight="24px"
        cursor="pointer"
        onClick={() =>
          history.push(
            `/v2/patient/${id}/imaging/progression/${folder}/series/${serie.id}`
          )
        }
      >
        {serie.name}
      </Typography>
    ))
}

export default SubFolders
