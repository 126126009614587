import React, { useMemo } from 'react'
import SVGInline from 'react-svg-inline'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { format, startOfWeek, endOfWeek } from 'date-fns'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import Clearworks from '#/assets/images/v2/clearworks.svg?inline'
import MiniCalendar from '@/v2/components/common/MiniCalendar'
import AppointmentSections from '@/v2/containers/sidebar/AppointmentSections'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import { SidebarWrapper, Topbar } from './styles'

const Sidebar = () => {
  const theme = useTheme()
  const history = useHistory()
  const { params } = useRouteMatch('/v2/calendar/:view?/:date?') || {}
  const { view = 'day', date } = params || {}

  const calendarDate = useMemo(() => {
    const day = date ? new Date(date.replace(/-/g, '/')) : nowOnClinicTimezone()
    return view === 'day'
      ? day
      : view === 'week'
      ? [startOfWeek(day), endOfWeek(day)]
      : null
  }, [view, startOfWeek, endOfWeek, date, nowOnClinicTimezone])

  return (
    <SidebarWrapper theme={theme.palette}>
      <Topbar theme={theme.palette}>
        <SVGInline svg={Clearworks} />
      </Topbar>
      <MiniCalendar
        key={`${view}-${date}`}
        date={calendarDate}
        onSelect={newDate => {
          const formattedDate = format(newDate, DATE_PARAM_FORMAT)
          return history.push(`/v2/calendar/day/${formattedDate}`)
        }}
        onClickMonth={date =>
          history.push(`/v2/calendar/month/${format(date, DATE_PARAM_FORMAT)}`)
        }
      />
      <AppointmentSections />
    </SidebarWrapper>
  )
}

export default Sidebar
