import React from 'react'
import Button from '@/v2/components/common/Button'
import PassField from '@/v2/components/common/PassField'
import { Form } from '@/v2/components/layouts/Authentication'

const ResetPasswordForm = ({
  values,
  errors,
  isLoading,
  isSubmitting,
  setValues,
  onSubmitForm,
}) => {
  const handleForm = e => {
    e.preventDefault()
    onSubmitForm()
  }
  return (
    <Form onSubmit={e => handleForm(e)}>
      <PassField
        fullWidth
        label="New password"
        name="password"
        labelAlignment="left"
        placeholder="New password"
        value={values.password}
        error={errors?.password}
        onChange={e => setValues({ ...values, password: e })}
      />
      <PassField
        fullWidth
        label="Confirm new password"
        name="password"
        labelAlignment="left"
        placeholder="Confirm new password"
        value={values.password_confirmation}
        error={errors?.password_confirmation}
        onChange={e => setValues({ ...values, password_confirmation: e })}
      />
      <Button
        disabled={isLoading || isSubmitting}
        loading={`${isLoading || isSubmitting}`}
        type="submit"
        variant="contained"
        color="secondary"
        fullWidth
      >
        Change my password
      </Button>
    </Form>
  )
}
export default ResetPasswordForm
