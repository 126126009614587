import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import TextField from '@/v2/components/common/TextField'
import SelectCalendar from '@/v2/components/common/SelectCalendar'
import Typography from '@/v2/components/common/Typography'
import { RELATIONSHIP_OPTIONS } from '@/v2/constants/insurance'
import { useInsuranceSubscribers } from '@/v2/hooks/insurance/useInsurances'
import getRandomId from '@/v2/utils/random-id'
import { genderOptions, sequenceCodeOptions } from '../../utils'

const formatRelationshipOptions = (array = []) =>
  array.map(item => ({
    value: item,
    label: item.charAt(0).toUpperCase() + item.slice(1),
  }))

const formatSubscribersOptions = (subscribers = []) =>
  subscribers.map(item => ({
    value: getRandomId(),
    label: item.firstName,
    ...item,
  }))

const Subscriber = ({
  isEdit = false,
  editAllowed = false,
  insuranceId,
  values = {},
  error = {},
  onChange,
}) => {
  const { subscribers } = useInsuranceSubscribers(
    values?.insuranceId || insuranceId
  )
  const subscribersOptions = useMemo(
    () => formatSubscribersOptions(subscribers),
    [subscribers]
  )
  const handleChangeSubscriber = value => {
    const subscriber = subscribersOptions.find(item => item.value === value)
    const genders = { M: 'male', F: 'female' }
    onChange({
      subscriber: value,
      firstName: subscriber?.firstName || '',
      lastName: subscriber?.lastName || '',
      gender: genders[subscriber?.gender] || '',
      dob: subscriber?.dob || '',
      memberId: subscriber?.plan?.subscriberId || '',
    })
  }

  const subscriber = isEdit ? values.subscriber : subscriber || {}

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Typography variant="h5" bold>
          Subscriber
        </Typography>
      </Grid>
      {(!isEdit || editAllowed) && (
        <>
          <Grid item xs={8}>
            <SelectField
              fullWidth
              noMargin
              name="subscriber"
              label="Subscriber"
              placeholder="Choose an option"
              value={subscriber}
              options={subscribersOptions}
              onChange={handleChangeSubscriber}
            />
          </Grid>
          <Grid item xs={4} />
        </>
      )}

      {subscriber && (
        <>
          <Grid item xs={3}>
            <TextField
              fullWidth
              name="firstName"
              label="First Name"
              placeholder="First Name"
              value={values.subscriber.firstName}
              error={error['subscriber.firstName']}
              onChange={value => onChange({ firstName: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              value={values.subscriber.lastName}
              error={error['subscriber.lastName']}
              onChange={value => onChange({ lastName: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectCalendar
              label="Date of birth"
              labelFormat="MM/dd/yyyy"
              hasOccupancy={false}
              value={values.subscriber.dob}
              error={error['subscriber.dob']}
              onChange={value => onChange({ dob: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              fullWidth
              noMargin
              name="gender"
              label="Gender"
              placeholder="Choose an option"
              options={genderOptions}
              value={values.subscriber.gender}
              error={error['subscriber.gender']}
              onChange={value => onChange({ gender: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              fullWidth
              noMargin
              name="relationship"
              label="Relationship"
              placeholder="Choose an option"
              options={formatRelationshipOptions(RELATIONSHIP_OPTIONS)}
              value={values.subscriber.relationship}
              error={error['subscriber.relationship']}
              onChange={value => onChange({ relationship: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              name="memberId"
              label="Member ID"
              placeholder="E.g.: 123456789"
              value={values.subscriber.memberId}
              error={error['subscriber.memberId']}
              onChange={value => onChange({ memberId: value })}
              disabled={!editAllowed}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              fullWidth
              noMargin
              name="sequenceCode"
              label="Sequence Code"
              placeholder="Choose an option"
              options={sequenceCodeOptions}
              value={values.subscriber.sequenceCode}
              error={error['subscriber.sequenceCode']}
              onChange={value => onChange({ sequenceCode: value })}
              disabled={!editAllowed}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default Subscriber
