import styled from 'styled-components'
import { INFOBAR_HEIGHT } from '@/v2/constants'
import TabsMui from '@mui/material/Tabs'
import TabMui from '@mui/material/Tab'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.silver.main};
  padding: 38px 64px 60px 48px;
  height: calc(100% - ${INFOBAR_HEIGHT});
  overflow-y: auto;
`
export const Body = styled.div`
  background: ${({ theme }) => theme.white.main};
  border-radius: 3px;
  min-height: calc(100% - 100px);
`
export const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: end;
  padding-left: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.silver.dark};
`

export const Content = styled.div`
  padding: 37px 28px;
`

export const Tabs = styled(TabsMui)`
  margin-right: 25px;
  display: inline-flex;

  & .MuiTabs-indicator {
    height: 3px;
  }
`
export const Tab = styled(TabMui)`
  && {
    padding: 2px;
    margin-right: 30px;
  }
`
