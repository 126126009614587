import React from 'react'
import RealTime from './RealTime'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useQueryClient } from '@tanstack/react-query'

const RTEligibilities = () => {
  const queryClient = useQueryClient()
  const { patient } = usePatientContext()
  const handleEvent = ({ action, subject, data }) => {
    queryClient.invalidateQueries({
      queryKey: ['Eligibilities', `${patient.id}`],
      queryKey: ['ShowEligibility', `${data.id}`],
    })
  }

  return (
    <RealTime
      channel="PatientProfile::Insurance::EligibilitiesChannel"
      onEvent={handleEvent}
      patientId={patient.id}
    />
  )
}

export default RTEligibilities
