import styled from 'styled-components'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'

export const Wrapper = styled.div`
  min-width: 100px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const FakeInput = styled.div`
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? 'rgba(0, 0, 0, 0.26)' : theme.primary.main};
  border-radius: 4px;
  padding: 11px 8px 11px 14px;
  font-size: 14px;
  position: relative;
  user-select: none;

  ${({ open }) =>
    open &&
    `
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  `}
`
export const ArrowDown = styled(ArrowDownIcon)`
  position: absolute;
  right: 3px;
  top: 6px;
  color: ${({ theme, disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.26)' : theme.gray.dark};
`
export const CalendarWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primary.main};
  margin-top: -1px;
  background: ${({ theme }) => theme.background.default};
  position: absolute;
  z-index: 99;
  display: block;
  ${({ $top }) => ($top ? `top: ${$top}px;` : '')}
  transition: 0.3s;
`
export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`
