import React from 'react'
import styled from 'styled-components'
import Button from '@/v2/components/common/Button'
import Actions from './Actions'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ModalButtons = styled.div`
  display: flex;
  column-gap: 16px;
`

const Footer = ({
  rotation,
  zoom,
  flip,
  onRotation,
  onZoom,
  onFlip,
  onConfirm,
  onReset,
  isCropped,
}) => {
  return (
    <Wrapper>
      <Actions
        rotation={rotation}
        zoom={zoom}
        flip={flip}
        onRotation={onRotation}
        onZoom={onZoom}
        onFlip={onFlip}
      />
      <ModalButtons>
        {isCropped && (
          <Button variant="outlined" color="secondary" onClick={onReset}>
            Reset
          </Button>
        )}
        <Button variant="contained" color="secondary" onClick={onConfirm}>
          Save
        </Button>
      </ModalButtons>
    </Wrapper>
  )
}

export default Footer
