import { useState } from 'react'
import { useResetPassword } from '@/v2/hooks/auth/useAuthentication'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

const resetPasswordSchema = z
  .object({
    password: z.string().min(6, 'Password must be at least 6 characters long'),
    password_confirmation: z
      .string()
      .nonempty('Password confirmation is required'),
  })
  .refine(
    data =>
      data.password_confirmation.length === 0 ||
      data.password === data.password_confirmation,
    {
      message: "Passwords don't match",
      path: ['password_confirmation'],
    }
  )

export const useResetPasswordForm = () => {
  const { search } = useLocation()
  const { push } = useHistory()
  const { resetPassword, isLoading } = useResetPassword()
  const { onOpenSnackbar } = useSnackbarContext()

  const [values, setValues] = useState({
    password: '',
    password_confirmation: '',
  })
  const [errors, setErrors] = useState({})

  const {
    register,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(resetPasswordSchema),
  })

  const handleResetPassword = async () => {
    const [reset_password_token] = search.split('=').slice(-1)
    const resetPwdBody = {
      session: {
        reset_password_token,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
    }
    await resetPassword(resetPwdBody, {
      onSuccess: data => {
        push('/v2/login')
        onOpenSnackbar(data.message, 'success')
      },
      onError: error => {
        onOpenSnackbar(`${error}`, 'error')
      },
    })
  }

  const handleSubmitForm = () => {
    const validErrors =
      resetPasswordSchema.safeParse(values).error?.formErrors.fieldErrors || {}
    setErrors(validErrors)
    if (Object.keys(validErrors).length === 0) {
      handleResetPassword()
    }
  }

  return {
    values,
    errors,
    isLoading,
    isSubmitting,
    register,
    setValues,
    handleSubmitForm,
  }
}
