import styled from 'styled-components'
import CurrencyInputField from 'react-currency-input-field'
import { FormControl } from '@mui/material'

export const CurrencyInput = styled(CurrencyInputField)`
  height: 38px;
  border: 1px solid #eeeded;
  letter-spacing: 0.5px;
  font-family: NeueHaasDisplay;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4375em;
  border-radius: 4px;

  :focus {
    outline: none;
  }
`

export const Wrapper = styled(FormControl)`
  &.prefixed input {
    padding-left: 10px;
  }
`
