import React, { useState } from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import ShowIcon from '@mui/icons-material/VisibilityOutlined'
import HideIcon from '@mui/icons-material/VisibilityOffOutlined'
import Button from '@/v2/components/common/Button'
import IconButton from '@/v2/components/common/IconButton'
import Typography from '@/v2/components/common/Typography'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { GENDER, DAY_IN_US_FORMAT } from '@/v2/constants'
import useModals from '@/v2/hooks/useModals'

const PatientId = styled(Typography)`
  color: ${({ theme }) => theme.gray.dark};

  & > b {
    color: ${({ theme }) => theme.black.main};
  }
`
const Patient = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 54px;
  border-left: 1px solid ${({ theme }) => theme.divider.main};
`
const MedicalAlert = styled.div`
  background: ${({ theme }) => theme.indigo.medicalAlert};
  color: ${({ theme }) => theme.background.default};
  margin-top: 8px;
  padding: 2px 16px;
  border-radius: 3px;
`

const Card = () => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { patient, medicalAlerts, onSetPatient } = usePatientContext()
  const [showSSN, setShowSSN] = useState(!patient.ssn)

  if (!patient?.id) return null

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px', display: 'flex' }}>
      <Patient>
        <PatientAvatar patient={patient} size="100px" />
        <Typography variant="h2" fontSize="20px" sx={{ mt: '12px', mb: '8px' }}>
          {patient.fullName || 'Not informed'}
        </Typography>
        <PatientId variant="h6" theme={theme.palette}>
          PATIENT ID <b>#{patient.id}</b>
        </PatientId>
        {medicalAlerts.length > 0 && (
          <MedicalAlert theme={theme.palette}>
            <Typography bold variant="h5">
              Medical Alerts ({medicalAlerts.length})
            </Typography>
          </MedicalAlert>
        )}
      </Patient>
      <Info theme={theme.palette}>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/edit/basic`, {
              patient,
              onSuccess: onSetPatient,
            })
          }
          sx={{ alignSelf: 'end', color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Edit</Typography>
        </Button>
        <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
          DATE OF BIRTH
        </Typography>
        <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
          {patient.dob
            ? format(parseISO(patient.dob), DAY_IN_US_FORMAT)
            : 'Not informed'}
        </Typography>
        <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
          GENDER
        </Typography>
        <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
          {patient?.gender?.charAt(0).toUpperCase() + patient?.gender?.slice(1) || 'Not informed'}
        </Typography>

        <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
          SSN
        </Typography>
        <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
          {showSSN ? patient.ssn || 'Not informed' : '**************'}
          <IconButton
            title={`${showSSN ? 'Hide' : 'Show'} SSN`}
            background="primary"
            onClick={() => setShowSSN(!showSSN)}
            sx={{
              marginLeft: '18px',
              marginTop: '-3px',
              height: '25px',
              width: '25px',
            }}
          >
            {showSSN ? (
              <HideIcon
                fontSize="small"
                sx={{ color: theme.palette.gray.dark }}
              />
            ) : (
              <ShowIcon
                fontSize="small"
                sx={{ color: theme.palette.gray.dark }}
              />
            )}
          </IconButton>
        </Typography>
        <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
          HEARD ABOUT US
        </Typography>
        <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
          {patient.referredBy || 'Not informed'}
        </Typography>
      </Info>
    </Paper>
  )
}

export default Card
