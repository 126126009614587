import React from 'react'
import { useWatch } from 'react-hook-form'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'

const InitialPaymentField = ({ control, errors, info }) => {
  const watch = useWatch({
    control,
    name: ['classification'],
  })

  const watchClassification = watch[0]

  return watchClassification !== 'one_time_payment' ? (
    <CurrencyInputField
      label="Initial Payment"
      name="downPayment"
      placeholder="$0.00"
      prefix="$"
      error={errors?.downPayment?.message}
      control={control}
      info={info}
    />
  ) : null
}

export default InitialPaymentField
