import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined'
import Option from './Option'
const ITEM_HEIGHT = 48

const MenuButton = ({ children, options, endIcon, ...others }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleOpen = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleClick = event => onClick => {
    event.stopPropagation()
    setAnchorEl(null)
    onClick && onClick()
  }

  const defaultStartIcon = () => {
    if (!open) {
      return <ExpandMoreOutlined />
    }
    return <ExpandLessOutlined />
  }

  return (
    <>
      <Button
        id="options"
        aria-controls={open ? 'menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        bordercolor="transparent"
        endIcon={endIcon ? endIcon : defaultStartIcon()}
        {...others}
        onClick={handleOpen}
      >
        {children}
      </Button>

      {options && options.length > 0 ? (
        <Menu
          id="menu"
          MenuListProps={{ 'aria-labelledby': 'options' }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map(({ label, href, target, onClick }) => (
            <Option
              key={label}
              label={label}
              href={href}
              target={target}
              onClick={event => handleClick(event)(onClick)}
            />
          ))}
        </Menu>
      ) : null}
    </>
  )
}

export default MenuButton
