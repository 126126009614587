import React from 'react'
import styled from 'styled-components'
import { useLocation, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/CloseOutlined'

const Wrapper = styled(Link)`
  display: flex;
  text-decoration: none;
`

const Content = styled.div`
  border-right: 1px solid ${({ theme }) => theme.primary.main};
  padding: ${({ $hasClose }) => ($hasClose ? '5px 32px 5px 14px' : '5px 14px')};
  display: flex;
  align-items: ${({ $justify }) => $justify || 'end'};
  cursor: pointer;
  min-width: 60px;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.primary.main};
    color: ${({ theme }) => theme.primary.contrastText};
  }

  &:hover path {
    fill: ${({ theme }) => theme.primary.contrastText};
  }

  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.primary.main};
    color: ${theme.primary.contrastText};
    path {
      fill: ${theme.primary.contrastText};
    }
  `
      : `
    color: ${theme?.tab.main || theme.primary.main};
    path {
      fill: ${theme?.tab.main || theme.primary.main};
    }
  `}
`

const CloseButton = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
`

const Tab = ({
  hasClose,
  children,
  title,
  justify,
  href,
  style,
  className,
  onClose,
  onClick,
}) => {
  const theme = useTheme()
  const { pathname } = useLocation()

  const handleClose = e => {
    e.preventDefault()
    e.stopPropagation()
    onClose && onClose()
  }

  return (
    <Wrapper to={href} style={style} className={className} onClick={onClick}>
      <Content
        theme={theme.palette}
        selected={pathname.indexOf(href) >= 0}
        $hasClose={hasClose ? 1 : 0}
        $justify={justify}
      >
        {title ? <Tooltip title={title}>{children}</Tooltip> : children}
        {hasClose && (
          <Tooltip title="Close">
            <CloseButton onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </CloseButton>
          </Tooltip>
        )}
      </Content>
    </Wrapper>
  )
}
export default Tab
