import { useEffect, useState } from 'react'

const useDetectDevice = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 768)

  const handleResize = () => {
    setMobile(window.innerWidth <= 768)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return { isMobile }
}

export default useDetectDevice
