import React from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'
import MenuItem from '@mui/material/MenuItem'

const LinkWithoutStyle = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary.main};
`

const Option = ({ label, onClick }) => {
  const theme = useTheme()
  return (
    <MenuItem key={label} onClick={onClick}>
      <Typography fontSize="14px" color={theme.palette.black.main}>
        {label}
      </Typography>
    </MenuItem>
  )
}

const GalleryOption = ({ label, href, target, onClick }) => {
  const theme = useTheme()
  return href ? (
    <LinkWithoutStyle
      theme={theme.palette}
      to={href}
      target={target || '_self'}
      rel="noopener noreferrer"
    >
      <Option label={label} onClick={onClick} />
    </LinkWithoutStyle>
  ) : (
    <Option label={label} onClick={onClick} />
  )
}

export default GalleryOption
