import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import IconButton from '@/v2/components/common/IconButton'
import FlagOutlineIcon from '#/assets/images/icons/flag-outline.svg?inline'
import FlagIcon from '#/assets/images/icons/flag.svg?inline'
import OpenConversationIcon from '#/assets/images/icons/open-conversation.svg?inline'
import CloseConversationIcon from '#/assets/images/icons/close-conversation.svg?inline'
import MarkUnreadIcon from '#/assets/images/icons/mark-unread.svg?inline'
import { useUpdateConversation } from '@/v2/hooks/messages/useConversations'

const Wrapper = styled.div`
  display: flex;
  column-gap: 6px;
  flex: 1;
  justify-content: end;
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  padding: ${({ $padding }) => $padding || '0px'};

  && svg,
  && svg path {
    fill: ${({ theme }) => theme.black.main};
    margin-bottom: 4px;
  }

  &&:hover svg,
  &&:hover svg path {
    fill: ${({ theme }) => theme.background.default};
  }
`

const ConversationActions = ({ conversation }) => {
  const theme = useTheme()
  const history = useHistory()
  const { updateConversation } = useUpdateConversation(conversation.id)
  const toggleFlagged = () =>
    updateConversation({ flagged: !conversation.flagged })

  const toggleState = () => updateConversation({ open: !conversation.open })

  const markAsUnread = async () => {
    await history.push('/v2/messages')
    updateConversation({ read: false })
  }

  return (
    <Wrapper theme={theme.palette}>
      <IconButton
        title="Flag conversation"
        aria-label="Flag conversation"
        onClick={toggleFlagged}
      >
        <Icon theme={theme.palette} $padding="0px 4px">
          <SVGInline svg={conversation.flagged ? FlagIcon : FlagOutlineIcon} />
        </Icon>
      </IconButton>
      <IconButton
        title={conversation.open ? 'Mark as closed' : 'Mark as open'}
        aria-label={conversation.open ? 'Mark as closed' : 'Mark as open'}
        onClick={toggleState}
      >
        <Icon theme={theme.palette} $padding="0px 1px">
          <SVGInline
            svg={
              conversation.open ? CloseConversationIcon : OpenConversationIcon
            }
          />
        </Icon>
      </IconButton>
      {conversation.open && (
        <IconButton
          title="Mark as unread"
          aria-label="Mark as unread"
          onClick={markAsUnread}
        >
          <Icon theme={theme.palette} $padding="0px 1px">
            <SVGInline svg={MarkUnreadIcon} />
          </Icon>
        </IconButton>
      )}
    </Wrapper>
  )
}

export default ConversationActions
