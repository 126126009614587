import React from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Typography from '@/v2/components/common/Typography'
import IconButton from '@/v2/components/common/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconMenu from '@/v2/components/common/IconMenu'
import useToggle from '@/v2/hooks/useToggle'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { currency } from '@/v2/utils/format'
import InvoiceLabel from '../shared/InvoiceLabel'
import Invoice from '../Invoice'
import Box from '@mui/material/Box'
import useModals from '@/v2/hooks/useModals'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
`
const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const Body = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  display: flex;
  flex-direction: column;
  flex: 1;
`

const InstallmentsPayment = ({ plan, paymentMethods, patientId }) => {
  const theme = useTheme()
  const { open, handleToggle } = useToggle()
  const { goTo } = useModals()

  if (plan.invoices.length === 1) {
    const invoice = plan.invoices[0]
    return (
      <Invoice
        key={`invoice-${invoice.id}`}
        plan={plan}
        invoice={invoice}
        paymentMethods={paymentMethods}
        isSingle
      />
    )
  }

  return (
    <Wrapper theme={theme}>
      <Head open={open}>
        <Box padding={'26px 34px'} display={'flex'} width={1}>
          <Grid container spacing={1} columns={8}>
            <Grid item xs={2}>
              <Box display={'flex'} gap={1} flexDirection={'row'}>
                <Typography wordBreak bold lineHeight="23px">
                  {`${plan.description}`}
                </Typography>
                <Typography
                  fontSize="10px"
                  lineHeight="23px"
                  color={theme.palette.gray.dark}
                >
                  {`(${plan.installments} ${
                    plan.installments.length > 1 ? 'MONTHS' : 'MONTH'
                  })`}
                </Typography>
              </Box>
              <InvoiceLabel status={plan.status} />
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                START DATE
              </Typography>
              <Typography fontSize="15px">
                {format(parseISO(plan.startedAt), DAY_IN_US_FORMAT)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                TOTAL
              </Typography>
              <Typography fontSize="15px">
                {currency(plan.finalAmount.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                PAID
              </Typography>
              <Typography fontSize="15px">
                {currency(plan.paid.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                REMAINING
              </Typography>
              <Typography fontSize="15px">
                {currency(plan.remaining.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                REFUNDED
              </Typography>
              <Typography fontSize="15px">
                {currency(plan.totalRefunded.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                fontSize="10px"
                lineHeight="23px"
                color={theme.palette.gray.dark}
              >
                INSTALMENTS PAID
              </Typography>
              <Typography fontSize="15px">
                {`${plan.installmentsProcessed}/${plan.installments}`}
              </Typography>
            </Grid>
          </Grid>
          <IconMenu
            icon={<MoreVertIcon fontSize="small" />}
            aria-label="More"
            title="More"
            style={{ marginLeft: 'auto', marginRight: '0', marginTop: 'auto' }}
            options={[
              {
                label: 'Down Payment',
                onClick: () =>
                  goTo(`/v2/patient/${patientId}/finance/advanced-payment`, {
                    plan: plan,
                  }),
              },
            ]}
          />
        </Box>
        <Grid
          container
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flex={1}
          bgcolor={theme.palette.gray.default}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => handleToggle()}
        >
          <IconButton title="Show More" bordercolor="transparent">
            <>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
          </IconButton>
        </Grid>
      </Head>

      {open && (
        <Body theme={theme}>
          {plan.invoices.map((invoice, key) => (
            <Invoice
              key={`invoice-${invoice.id}`}
              plan={plan}
              invoice={invoice}
              paymentMethods={paymentMethods}
              showBorder={key !== plan.invoices.length - 1}
            />
          ))}
        </Body>
      )}
    </Wrapper>
  )
}

export default InstallmentsPayment
