import React from 'react'
import {
  UploadWrapper,
  UploadImageContainer,
  UploadImage,
  UploadDetails,
  UploadInfo,
  UploadActions,
  UploadButton,
} from './styles'
import { useTheme } from '@mui/material'
import Document from '#/assets/images/v2/document.jpg'
import Typography from '@/v2/components/common/Typography'
import { DeleteOutline, VisibilityOutlined } from '@mui/icons-material'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/v2/constants'

const Upload = ({ upload, deleteArchive }) => {
  const theme = useTheme()

  const fileNameWithExtension = upload.url.split('/').pop()
  const createdAt = getDateTimeWithoutTimezone(upload.createdAt)

  const handleVisibility = () => {
    window.open(upload.url, '_blank')
  }

  const handleDelete = () => {
    return (
      confirm('Are you sure that you want to delete this document?') &&
      deleteArchive({ id: upload.id })
    )
  }

  return (
    <UploadWrapper theme={theme.palette}>
      <UploadImageContainer>
        <UploadImage src={Document} />
      </UploadImageContainer>
      <UploadDetails>
        <Typography fontWeight="500" wordBreak fontSize="16px">
          {upload.name}
        </Typography>
        <UploadInfo>
          <Typography fontWeight="300" wordBreak fontSize="12px">
            {fileNameWithExtension
              ? fileNameWithExtension.replace(/%20/g, ' ')
              : 'No file name'}
          </Typography>
          <Typography fontWeight="300" fontSize="12px">
            {format(createdAt, DATE_FORMAT)}
          </Typography>
        </UploadInfo>
        <UploadActions>
          <UploadButton
            themecolors={theme.palette}
            onClick={() => handleVisibility()}
          >
            <VisibilityOutlined color="action" />
          </UploadButton>
          <UploadButton
            themecolors={theme.palette}
            onClick={() => handleDelete()}
          >
            <DeleteOutline color="action" />
          </UploadButton>
        </UploadActions>
      </UploadDetails>
    </UploadWrapper>
  )
}

export default Upload
