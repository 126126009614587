import { format } from 'date-fns'

const addZero = number => `0${number}`

// Ex: time = 740
export const convertMinutesToHours = time => {
  const hours = Math.floor(time / 60)
  const hh = hours > 12 ? hours - 12 : hours
  const minutes = time % 60
  const mm = minutes === 0 ? addZero(minutes) : minutes
  return `${hh < 10 ? addZero(hh) : hh}:${mm} ${hours >= 12 ? 'P' : 'A'}M`
}

// Ex: time = '17:40'
export const convertHoursToMinutes = time => {
  const [hours, minutes] = time.split(':')
  return Number(hours) * 60 + Number(minutes)
}

// Ex: date = '2022-05-16T18:00:00-04:00'
export const convertDateToMinutes = date => {
  const time = date.substring(11, 16)
  return convertHoursToMinutes(time)
}

// Ex: minutes = 740
export const convertMinutesToDate = minutes => {
  const date = format(nowOnClinicTimezone(), 'yyyy/MM/dd')
  return `${date} ${convertMinutesToHours(minutes)}`
}

export const nowOnTimezone = (format = 'minutes') => {
  const timeZoneMeta = document.querySelector('meta[name="time-zone"]').content
  const timeOptions = {
    timeZone: timeZoneMeta || 'America/New_York',
    timeStyle: 'short',
  }

  const options =
    format === 'day' ? { ...timeOptions, dateStyle: 'medium' } : timeOptions

  const time = new Intl.DateTimeFormat(
    format === 'day' ? 'en-US' : 'pt-BR',
    options
  ).format(new Date())

  return format === 'minutes' ? convertHoursToMinutes(time) : time
}

export const nowOnClinicTimezone = () => {
  return new Date(nowOnTimezone('day'))
}

export const getDateTimeWithoutTimezone = date => {
  if (typeof date !== 'string') return date
  return new Date(
    date
      .substring(0, 16)
      .replace(/-/g, '/')
      .replace('T', ' ')
  )
}
