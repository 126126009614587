import { useQuery } from '@tanstack/react-query'
import { baseURLGraphql, headers } from '@/v2/constants/requests'
import request from 'graphql-request'
import ClinicQuery from './graphql/queries/Clinic.gql'

const useClinic = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['clinic', baseURLGraphql, headers, ClinicQuery],
    queryFn: async () => request(baseURLGraphql, ClinicQuery, null, headers),
  })

  return { clinic: data?.clinic || null, loading: isLoading, error: isError }
}

export default useClinic
