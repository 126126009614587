import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@/v2/components/common/Typography'
import { useFindPatient } from '@/v2/hooks/patient/useFindPatient'
import { formatSearch } from '@/v2/utils/format'
import { getHighlightPhone, getHightlightText } from '@/v2/utils/highlight'

const SearchField = styled.div`
  min-width: 200px;

  && .MuiOutlinedInput-root {
    height: 34px;
  }

  &&& .MuiInputBase-input {
    padding: 0;
  }

  && .MuiAutocomplete-endAdornment {
    display: none;
  }
`

const PatientSearch = ({ showSearchIcon, autoFocus, error, onChange }) => {
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const { patients = [], isLoading, isError } = useFindPatient(searchTerm)

  let debounce
  const handleChangeSearch = (e, term) => {
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      setSearchTerm(formatSearch(term))
    }, 300)
  }

  return (
    <SearchField>
      <Autocomplete
        fullWidth
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={x => x}
        options={patients}
        loading={searchTerm ? isLoading : false}
        renderInput={params => (
          <TextField
            autoFocus={autoFocus}
            placeholder="Search for patient"
            {...params}
            sx={{
              ...params.sx,
              '& .MuiInputBase-root': { paddingRight: '9px !important' },
            }}
            InputProps={
              showSearchIcon
                ? {
                    ...params.InputProps,
                    endAdornment: <SearchIcon sx={{ marginTop: '-3px' }} />,
                  }
                : params.InputProps
            }
          />
        )}
        onInputChange={handleChangeSearch}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.fullName
        }
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        noOptionsText="There are no results"
        onChange={(e, patient) => {
          onChange(patient)
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ display: 'block' }}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {getHightlightText(option.fullName, searchTerm)}
            </Typography>
            <Typography variant="h5">
              {getHighlightPhone(option?.phoneNumber, searchTerm)}
            </Typography>
            <Typography variant="h5">
              {getHightlightText(option?.email, searchTerm)}
            </Typography>
          </li>
        )}
      />
      {(error || isError) && (
        <Typography
          fontSize="10px"
          color={theme.palette.error.main}
          sx={{ mt: '4px !important' }}
        >
          {error || 'Something went wrong'}
        </Typography>
      )}
    </SearchField>
  )
}

export default PatientSearch
