import { format } from 'date-fns'

const fromForm = formValues => {
  const patient =
    formValues.patientType === 'registered'
      ? formValues.registeredPatient || {}
      : formValues.newPatient || {}

  const startsAtDate = format(formValues.startsAtDate, 'yyyy-M-d')
  const startsAt = `${startsAtDate} ${formValues.startsAtTime}`

  return {
    ...(formValues.id && { id: formValues.id }),
    appointmentTypeId: formValues.typeId,
    doctorId: formValues.doctorId || null,
    duration: parseInt(formValues.duration, 10),
    startsAt: startsAt,
    roomId: formValues.roomId,
    patient: {
      ...(patient.id && { id: patient.id }),
      firstName: patient.firstname || patient.firstName,
      lastName: patient.lastname || patient.lastName,
      email: patient.email,
      phoneNumber: patient.phone || patient.phoneNumber,
      referredBy: patient.heardFrom || patient.referredBy,
      ...(formValues.patientType !== 'registered' && {
        contactMethod: formValues.contactMethod || null,
      }),
    },
    virtual: formValues.virtual || false,
  }
}

export default fromForm
