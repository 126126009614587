import { format } from 'date-fns'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'

const getHour = datetime =>
  format(getDateTimeWithoutTimezone(datetime), 'hh:mm aaa')

export const getSentAt = (type, createdAt, user = {}) => {
  const hour = getHour(createdAt)
  if (type === 'Outgoing') return `${user.fullName} • ${hour}`
  else if (type === 'Reminder') return `Automated • ${hour}`
  else return hour
}
