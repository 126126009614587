import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import ExpandMoreIcon from '#/assets/images/v2/expand-more.svg?inline'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import Typography from '@/v2/components/common/Typography'
import TextField from '@/v2/components/common/TextField'
import FolderActions from './FolderActions'
import SubFolders from '../SubFolders'

const FieldWrapper = styled(FormControl)`
  &&& .MuiInput-input {
    font-size: 15px;
    font-weight: 500;
    padding-right: 10px;
    background-color: transparent;
  }
`
const MenuWrapper = styled.div`
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
`

const SubFoldersWrapper = styled.div`
  margin: 7px 20px;
  display: flex;
  justify-content: space-between;
`

const parse = value => parseInt(value, 10)

const getFolderName = (progressions, id) =>
  progressions.find(progression => parse(progression.id) === parse(id)).name

const Folders = props => {
  const theme = useTheme()
  const history = useHistory()
  const { id } = useParams()
  const {
    folderId,
    folderDate,
    isCreationMode,
    isEditMode,
    onCreate,
    onEdit,
    onSetFolderId,
    onSetFolderDate,
    onOpenDeleteConfirmation,
    onSetIsCreationMode,
    onSetIsEditMode,
    enableDelete,
  } = props
  const { progressions } = usePatientContext()

  return (
    <>
      <MenuWrapper>
        <FieldWrapper variant="standard">
          {isCreationMode || isEditMode ? (
            <TextField
              autoFocus
              name="new-folder"
              placeholder="Progression"
              defaultValue={
                isEditMode ? getFolderName(progressions, folderId) : ''
              }
              onBlur={({ target }) => {
                const value = target.value
                return isEditMode ? onEdit(value) : onCreate(value)
              }}
            />
          ) : (
            <Select
              disableUnderline
              key={folderId}
              value={folderId}
              onChange={({ target }, { props }) => {
                onSetFolderId(target.value)
                onSetFolderDate(props.date)
                history.push(
                  `/v2/patient/${id}/imaging/progression/${target.value}`
                )
              }}
              IconComponent={() => (
                <SVGInline svg={ExpandMoreIcon} width="10px" />
              )}
            >
              {progressions.map(({ id, name, startDate }) => (
                <MenuItem value={id} key={id} date={startDate}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}

          {folderDate && (
            <Typography variant="h6" color={theme.palette.gray.dark}>
              {format(
                new Date(folderDate.replace(/-/g, '/')),
                DAY_IN_US_FORMAT
              )}
            </Typography>
          )}
        </FieldWrapper>
        <FolderActions
          onAdd={onSetIsCreationMode}
          onDelete={onOpenDeleteConfirmation}
          onEdit={onSetIsEditMode}
          enableDelete={enableDelete}
        />
      </MenuWrapper>
      <SubFoldersWrapper>
        {folderId && (
          <SubFolders
            key={folderId}
            folder={folderId}
            isFirst={parse(progressions[0].id) === parse(folderId)}
          />
        )}
      </SubFoldersWrapper>
    </>
  )
}

export default Folders
