import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import { MessagesProvider } from '@/v2/contexts/MessagesContext'
import { useConversation } from '@/v2/hooks/messages/useConversations'
import { useUpdateConversation } from '@/v2/hooks/messages/useConversations'
import PatientHeader from '../PatientHeader'
import MessagesHistory from '../MessagesHistory'
import ContactInfo from '../ContactInfo'
import Appointments from '../Appointments'
import AssignPatient from '../AssignPatient'
import {
  ConversationWrapper,
  ConversationContent,
  RightBar,
  SelectConversation,
} from './styles'

const MessagesPage = ({ newConversation }) => {
  const theme = useTheme()
  const { conversationId } = useParams()
  const { conversation = {}, isLoading } = useConversation(conversationId)
  const { read = true, messages = {} } = conversation
  const { records, currentPage, totalPages } = messages
  const { updateConversation } = useUpdateConversation(conversationId)

  useEffect(() => {
    if (!read) {
      const setReadMessage = async () => {
        await updateConversation({ read: true })
      }
      setReadMessage()
    }
  }, [read])

  if (isLoading && !!conversationId)
    return (
      <ConversationWrapper>
        <SelectConversation>
          <Typography>Loading...</Typography>
        </SelectConversation>
      </ConversationWrapper>
    )

  const patient = newConversation?.patient || {
    ...conversation?.patient,
    phoneNumber: conversation.phoneNumber,
  }

  return (
    <MessagesProvider patient={patient}>
      <ConversationWrapper>
        <PatientHeader patient={patient} conversation={conversation} />
        <ConversationContent>
          <MessagesHistory
            historyMessages={records}
            currentPage={currentPage}
            totalPages={totalPages}
          />
          <RightBar theme={theme.palette}>
            {patient?.id ? (
              <>
                <ContactInfo patient={patient} />
                <Appointments patient={patient} />
              </>
            ) : (
              <AssignPatient phone={patient.phoneNumber} />
            )}
          </RightBar>
        </ConversationContent>
      </ConversationWrapper>
    </MessagesProvider>
  )
}

export default MessagesPage
