import React, { forwardRef } from 'react'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import getRandomId from '@/v2/utils/random-id'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import InputAdornment from '@mui/material/InputAdornment'

const InputField = forwardRef(
  (
    { fullWidth, label, noMargin = true, prefix, error, warning, ...others },
    ref
  ) => {
    const id = getRandomId()
    return (
      <FormControl
        sx={{
          mb: noMargin ? '0px' : '23px',
          '&.prefixed input': {
            pl: 0,
          },
        }}
        className={`${prefix ? 'prefixed' : ''}`}
        fullWidth={fullWidth}
        variant="standard"
      >
        {label && <Label htmlFor={id}>{label}</Label>}
        <OutlinedInput
          id={id}
          inputRef={ref}
          startAdornment={
            prefix ? (
              <InputAdornment position="start">{prefix}</InputAdornment>
            ) : null
          }
          {...others}
        />
        {error && <Typography variant="error">{error}</Typography>}
        {warning && <Typography variant="warning">{warning}</Typography>}
      </FormControl>
    )
  }
)

InputField.displayName = 'InputField'

export default InputField
