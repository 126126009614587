import styled from 'styled-components'
import Accordion from '@/v2/components/common/Accordion'

export const Wrapper = styled.div`
  margin-bottom: 3px;
`

export const ContentWrapper = styled.div`
  width: 600px;
`

export const Item = styled.div`
  margin-bottom: 20px;
`

export const AccordionStyled = styled(Accordion)`
  width: 98%;
`
