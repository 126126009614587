import { Box, Skeleton, useTheme } from '@mui/material'
import React, { memo } from 'react'

const SkeletonUpload = () => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={210}
      height={314}
      border={`1px solid ${theme.palette.grey[400]}`}
    >
      <Box p={3}>
        <Skeleton variant="rectangular" width="100%" height={160} />
        <Box sx={{ pt: 0.5 }}>
          <Skeleton />
          <Skeleton width="60%" />
        </Box>
      </Box>
    </Box>
  )
}

export default memo(SkeletonUpload)
