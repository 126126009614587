import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import OffBackground from '#/assets/images/v2/off-hours.png'
import Label from '@/v2/components/common/Label'
import { Wrapper, Description, Labels } from './styles'
import SVGInline from 'react-svg-inline'
import icon from '#/assets/images/icons/v2/checkmark.svg?inline'

const formatStyle = ({ top, width, xOffset, height }) => ({
  top: `${top}%`,
  width: `${width}`,
  height: `${height.replace('- 2px)', '- 0px)')}`, // remove unnecessary margin
  left: `${xOffset}`,
})

const Event = props => {
  const { style, event, label, onClick } = props
  const {
    type,
    room,
    doctor,
    appointmentType,
    labels = [],
    patient,
    virtual,
    reschedule,
    noShow,
    isConfirmed,
  } = event.event

  const { palette } = useTheme()

  const extraLabels = []

  if (reschedule) {
    extraLabels.push({
      id: 'reschedule',
      name: 'Rescheduled',
      color: 'link',
    })
  }

  if (noShow) {
    extraLabels.push({
      id: 'no-show',
      name: 'No Show',
      color: 'black',
    })
  }

  const eventLabels = [...labels, ...extraLabels]

  const timeOffTitle =
    room !== null ? 'Room' : doctor !== null ? 'Doctor' : 'Clinic'

  const timeOffSubtitle = timeOffTitle === 'Doctor' && doctor.fullName

  const background =
    type === 'appointment'
      ? palette[appointmentType.color].type
      : `url(${OffBackground})`

  const title =
    type === 'appointment'
      ? appointmentType?.title
      : `${timeOffTitle} Blocked Time ${virtual ? '(Virtual)' : ''}`

  const subtitle =
    type === 'appointment' ? patient.fullName : timeOffSubtitle || ''

  return (
    <Wrapper
      style={formatStyle(style)}
      $bg={background}
      type={type}
      title={label}
      onClick={onClick}
    >
      <Description>
        {isConfirmed && (
          <SVGInline
            svg={icon}
            style={{
              fill: palette[appointmentType.color].label,
              width: '16px',
              height: '16px',
              marginLeft: '-6px',
            }}
          />
        )}
        <Typography variant="h5" fontWeight="500" title={title}>
          {title}
        </Typography>
        <Typography variant="h5" fontWeight="400" title={subtitle}>
          {subtitle}
        </Typography>
      </Description>
      <Labels>
        {eventLabels.map(({ id, color, name }) => (
          <Label key={id} title={name} color={palette[color].label} />
        ))}
      </Labels>
    </Wrapper>
  )
}
export default Event
