import React from 'react'

const useToggle = (props = {}) => {
  const { defaultOpen = false } = props
  const [open, setOpen] = React.useState(defaultOpen)

  const handleToggle = () => {
    setOpen(!open)
  }

  return { open, handleToggle }
}

export default useToggle
