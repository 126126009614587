export const filterConversations = (conversations = [], filter) => {
  switch (filter) {
    case 'all':
      return conversations
    case 'unread':
      return conversations.filter(({ read }) => !read)
    case 'unanswered':
      return conversations.filter(
        ({ lastMessage }) => lastMessage.type === 'Incoming'
      )
    case 'flagged':
      return conversations.filter(({ flagged }) => flagged)
    default:
      return conversations
  }
}
