import { format } from 'date-fns'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { TIME_IN_US_FORMAT } from '@/v2/constants'

const getType = ({ clinic, room, doctor }) => {
  if (clinic) return 'clinic'
  if (room) return 'room'
  if (doctor) return 'doctor'
  return 'unknown'
}

const toForm = timeOff => {
  const type = getType(timeOff)
  const startsAt = getDateTimeWithoutTimezone(timeOff.startsAt)
  const endsAt = getDateTimeWithoutTimezone(timeOff.endsAt)

  return {
    id: timeOff.id,
    type: type,
    clinicId: type === 'clinic' ? timeOff.clinic.id : undefined,
    roomId: type === 'room' ? timeOff.room.id : undefined,
    doctorId: type === 'doctor' ? timeOff.doctor.id : undefined,
    virtual: timeOff.virtual,
    startsAtDate: startsAt,
    startsAtTime: startsAt ? format(startsAt, TIME_IN_US_FORMAT) : '',
    endsAtTime: endsAt ? format(endsAt, TIME_IN_US_FORMAT) : '',
    notes: timeOff.notes || '',
  }
}

export default toForm
