import React, { useMemo } from 'react'
import { LabelsSection, LabelsWrapper, NoteHeader, NoteWrapper } from './styles'
import { Skeleton, useTheme } from '@mui/material'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import useNotes from '@/v2/hooks/useNotes'
import useToggle from '@/v2/hooks/useToggle'
import Note from './Note'
import NoteForm from './NoteForm'

const NoteList = ({ appointmentId, doctorId }) => {
  const { open, handleToggle } = useToggle()
  const theme = useTheme()
  const { query } = useNotes({
    appointmentId,
  })

  const { data: notes, loading } = query

  const notesList = useMemo(
    () =>
      notes?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      }),
    [notes]
  )

  if (loading)
    return <Skeleton variant="rectangular" width={295} height={150} />

  const renderNotes = () => {
    if (notesList && notesList.length > 0) {
      return notesList.map(note => {
        return (
          <Note
            note={note}
            appointmentId={appointmentId}
            doctorId={doctorId}
            key={note.id}
          />
        )
      })
    }
    return (
      <NoteWrapper theme={theme.palette}>
        <Typography variant="h4">No notes yet</Typography>
      </NoteWrapper>
    )
  }

  return (
    <LabelsSection>
      <NoteHeader>
        <Typography fontSize="16px" variant="h3">
          Notes
        </Typography>
        <Button onClick={handleToggle} variant="text">
          {open ? 'Cancel' : 'Add Note'}
        </Button>
      </NoteHeader>
      <LabelsWrapper>
        {open && (
          <NoteForm
            appointmentId={appointmentId}
            doctorId={doctorId}
            setShowNoteForm={handleToggle}
          />
        )}
        {renderNotes()}
      </LabelsWrapper>
    </LabelsSection>
  )
}

export default NoteList
