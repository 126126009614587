import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '@/v2/components/common/Modal'
import PatientForm from '@/v2/components/forms/Patient/Form'
import validator from '@/v2/components/forms/Patient/validator'
import fromForm from '@/v2/components/forms/Patient/fromForm'
import CreatePatient from '@/v2/graphql/mutations/CreatePatient.gql'
import apolloClient from '@/v2/graphql/configs/ApolloClient'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useUpdateConversation } from '@/v2/hooks/messages/useConversations'
import { errorsFromJson } from '@/v2/utils/forms'
import { formatPhone } from '@/v2/utils/format'

const NewPatient = ({ phone, conversationId, refetch, isOpen, onClose }) => {
  const [patient, setPatient] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: phone ? formatPhone(phone) : '',
    heardFrom: '',
  })
  const [error, setError] = useState({})
  const [createPatient] = useMutation(CreatePatient)
  const { updateConversation } = useUpdateConversation(conversationId)
  const { onOpenSnackbar } = useSnackbarContext()

  const handleCreatePatient = async () => {
    const response = await createPatient({
      variables: { patient: fromForm(patient) },
    })

    const asyncErrors = errorsFromJson(response.data.createPatient.errors)

    if (asyncErrors) {
      const { phoneNumber, ...others } = asyncErrors
      setError({ phone: phoneNumber, ...others })
      onOpenSnackbar('Something went wrong.', 'error')
      return
    }

    onOpenSnackbar('Patient created.')

    await updateConversation({
      patientId: response.data.createPatient.patient.id,
    })

    onClose()

    if (refetch) {
      await apolloClient.refetchQueries({
        include: [...refetch],
      })
    }
  }

  const handleSubmit = () => {
    const validErrors = validator(patient)
    setError(validErrors || {})

    if (!validErrors) {
      handleCreatePatient()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="New Patient"
      confirmLabel="Create"
      cancelLabel="Cancel"
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <PatientForm
        values={patient}
        error={error}
        onChange={values => setPatient({ ...patient, ...values })}
      />
    </Modal>
  )
}

export default NewPatient
