import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLogout } from '@/v2/hooks/auth/useAuthentication'
import IconMenu from '@/v2/components/common/IconMenu'
import MenuIcon from '@mui/icons-material/Menu'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useAuthContext } from '@/v2/contexts/AuthContext'

const SettingsMenu = () => {
  const { setIsLoggedIn } = useAuthContext()
  const { push } = useHistory()
  const { logout } = useLogout()
  const { onOpenSnackbar } = useSnackbarContext()

  const handleLogout = () => {
    logout(null, {
      onSuccess: () => {
        setIsLoggedIn(false)
        push('/v2/login')
      },
      onError: error => {
        onOpenSnackbar(`${error}`, 'error')
      },
    })
  }

  return (
    <IconMenu
      icon={<MenuIcon />}
      title="Settings"
      hovercolor="secondary"
      bordercolor="topbarIconBorder"
      aria-label="Settings"
      options={[
        { label: 'Settings', href: '/settings' },
        {
          label: 'Sign out',
          onClick: handleLogout,
        },
      ]}
    />
  )
}

export default SettingsMenu
