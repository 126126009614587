export const MARGIN_IN_PIXELS = 80

export const PIXELS_PER_MINUTE = 1.25

export const BIRDS_EYE_PIXELS_PER_MINUTE = 1.083

export const TIME_FORMAT = 'hh:mma'

export const HOUR_FORMAT = 'hha'

export const INPUT_TIME_FORMAT = 'hh:mm a'

export const SHORT_TIME_FORMAT = 'h:mm a'
