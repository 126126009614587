import React from 'react'
import useNotes from '@/v2/hooks/useNotes'
import Typography from '@/v2/components/common/Typography'
import Notes from './Notes'
import NoItems from './NoItems'
import NoteForm from './NoteForm'
import { NoteContainer } from './styles'

// This is a simpler version from @/v2/containers/notes
const NotesContainer = ({ id, onChange }) => {
  const { query, mutationDelete } = useNotes({
    appointmentId: id,
  })
  const { mutate: deleteNote } = mutationDelete

  const { data, loading } = query

  const notes = data || []

  if (loading) return <Typography fontSize="14px">Loading...</Typography>

  const handleDeleteNote = async noteId => {
    try {
      await deleteNote({ appointmentId: id, id: noteId })
      onChange()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <NoteContainer>
      {notes.length > 0 ? (
        <Notes
          notes={notes}
          appointmentId={id}
          onChange={onChange}
          onRemove={removedNote => handleDeleteNote(removedNote.id)}
        />
      ) : (
        <NoItems />
      )}
      <NoteForm appointmentId={id} onChange={onChange} />
    </NoteContainer>
  )
}

export default NotesContainer
