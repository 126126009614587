import React, { useState, useEffect, useMemo } from 'react'
import useModals from '@/v2/hooks/useModals'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'
import Typography from '@/v2/components/common/Typography'
import Modal from '@/v2/components/common/Modal'
import validator from '@/v2/components/forms/Appointment/validator'
import DeleteAppointment from '@/v2/graphql/mutations/DeleteAppointment.gql'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'
import UpdatePatient from '@/v2/graphql/mutations/UpdatePatient.gql'
import formatBody from '@/v2/components/forms/Appointment/fromForm'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { errorsFromJson } from '@/v2/utils/forms'
import getDayInfo from '@/v2/graphql/queries/DaySchedule.gql'
import AppointmentQuery from '@/v2/graphql/queries/Appointment.gql'
// TODO: remove lodash
import isEqual from 'lodash/isEqual'
import BaseForm from './BaseForm'
import { useLoadData } from './hooks/useLoadData'
import { useSaveNote } from './hooks/useSaveNote'

const appointmentState = {
  patientType: 'registered',
  registeredPatient: {},
  doctorId: '',
  typeId: null,
  roomId: '',
  startsAtDate: null,
  startsAtTime: null,
  duration: '',
}

const EditAppointment = ({
  id,
  isOpen,
  onClose,
  onEdit,
  onCancel,
  onlyRead = false,
}) => {
  const { goTo } = useModals()
  const { loading, error, types, rooms, doctors, appointment } = useLoadData(id)
  const { saveNote } = useSaveNote()
  const [updateAppointment] = useMutation(UpdateAppointment)
  const [deleteAppointment] = useMutation(DeleteAppointment)
  const [updatePatient] = useMutation(UpdatePatient, {
    refetchQueries: [AppointmentQuery],
  })

  const initialValues = useMemo(() => {
    if (id) {
      return appointment.virtual
        ? {
            ...appointment,
            contactMethod: appointment.registeredPatient.contactMethod,
          }
        : {
            ...appointment,
          }
    }
    return appointmentState
  }, [appointment, id])
  const [values, setValues] = useState(initialValues)
  const [notes, setNotes] = useState([])
  const [formError, setFormError] = useState({})
  const { onOpenSnackbar } = useSnackbarContext()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  if (error) return <Typography>Something went wrong.</Typography>

  const filteredTypes = isLoading
    ? types
    : types.filter(type => type.isRemote === appointment.virtual)

  const handleChange = fields => setValues({ ...values, ...fields })

  const handleChangeNotes = newNote => {
    const noteExists = !!notes.find(note => note.id === newNote.id)
    const newNotes = noteExists
      ? notes.map(note => (note.id === newNote.id ? newNote : note))
      : [...notes, newNote]
    setNotes(newNotes)
  }

  const handleSubmit = async () => {
    const validErrors = validator(values)
    setFormError(validErrors || {})
    // TODO: create hooks for the functions
    if (!validErrors) {
      try {
        const { patient, ...otherFields } = formatBody(values)

        if (values.virtual) {
          await updatePatient({
            variables: {
              patient: {
                id: patient.id,
                contactMethod: values.contactMethod,
              },
            },
          })
        }

        const response = await updateAppointment({
          variables: {
            appointment: {
              ...otherFields,
              patientId: patient.id,
            },
          },
          refetchQueries: ['GetPatient'],
        })
        const returnedAppointment = response.data.updateAppointment.appointment

        const asyncErrors = errorsFromJson(
          response.data.updateAppointment.errors
        )

        if (asyncErrors) {
          onOpenSnackbar('Something went wrong!', 'error')
        } else {
          onOpenSnackbar('Updated appointment.')
        }

        const validNotes = notes.filter(
          note => !(!note.id && note.value === '')
        )
        // save each note
        validNotes.forEach(note => saveNote(note, appointment.id))

        onEdit && onEdit()

        goTo(`/v2/appointment/${appointment.id}`, {
          initialValues: { appointment: { ...returnedAppointment } },
        })

        window.analytics.track('Updated appointment', {
          category: 'Clearworks',
          label: 'Appointment updated by staff',
          datetime: returnedAppointment.startsAt,
          appointmentType: returnedAppointment.appointmentType.title,
          room: returnedAppointment.room?.title,
        })
      } catch (e) {
        onOpenSnackbar('Something went wrong!', 'error')
      }
    }
  }

  const handleDelete = () => {
    try {
      deleteAppointment({
        variables: { appointment: { id } },
        refetchQueries: [
          {
            query: getDayInfo,
            variables: { date: format(values.startsAtDate, 'yyyy-MM-dd') },
          },
          'GetPatient',
        ],
      })

      const type = types.find(({ id }) => id === initialValues.typeId)
      const room = rooms.find(({ id }) => id === initialValues.roomId)

      window.analytics.track('Deleted appointment', {
        category: 'Clearworks',
        label: 'Appointment deleted by staff',
        datetime: values.startsAtDate,
        appointmentType: type?.title,
        room: room?.title,
      })
    } finally {
      onCancel && onCancel()
      onClose()
      onOpenSnackbar('Appointment canceled.')
    }
  }

  const handleCancelAppointment = () =>
    confirm('Are you sure you want to cancel this appointment?') &&
    handleDelete()

  return (
    <Modal
      isOpen={isOpen}
      loading={isLoading || loading}
      title={onlyRead ? 'View Appointment' : 'Edit Appointment'}
      cancelLabel="Cancel Appointment"
      footer={onlyRead ? <div style={{ paddingTop: '20px' }}></div> : undefined}
      onConfirm={handleSubmit}
      onClose={({ header, footer }) => {
        if (header) {
          if (!isEqual(initialValues, values)) {
            return (
              confirm(
                'Are you sure you want to leave without saving changes?'
              ) && onClose()
            )
          } else {
            onClose()
          }
        }
        if (footer) {
          return handleCancelAppointment()
        }
      }}
    >
      <BaseForm
        edit
        onlyRead={onlyRead}
        error={formError}
        values={values}
        types={filteredTypes}
        rooms={rooms}
        doctors={doctors}
        onChange={handleChange}
        onChangeNotes={notes => handleChangeNotes(notes)}
      />
    </Modal>
  )
}

export default EditAppointment
