import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import IconButton from '@/v2/components/common/IconButton'
import useModals from '@/v2/hooks/useModals'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import Note from './Note'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const Title = styled.div`
  display: flex;
  align-items: start;
`
const IconWrapper = styled.div`
  display: flex;
`
const Content = styled.div`
  margin-top: 28px;
`

const Notes = () => {
  const theme = useTheme()
  const { officeNotes } = usePatientContext()
  const [show, setShow] = useState(false)
  const { goTo } = useModals()
  const { id: patientId } = useParams()

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Title>
          <Typography variant="h3">
            {`Office Notes (${officeNotes.length})`}{' '}
          </Typography>
          {officeNotes.length > 0 && (
            <IconButton
              title={show ? 'Show less' : 'Expand'}
              bordercolor="transparent"
              hovercolor="transparent"
              hovercolortext="black"
              onClick={() => setShow(!show)}
              sx={{ marginTop: '-9px', marginLeft: '-4px' }}
            >
              {/* Workaround to inhibit warning log for changing icons while using the tooltip */}
              <IconWrapper>
                {show ? <ArrowDownIcon /> : <ArrowLeftIcon />}
              </IconWrapper>
            </IconButton>
          )}
        </Title>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patientId}/notes/new/green`, {
              onSave: () => {
                setShow(true)
              },
            })
          }
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Add note</Typography>
        </Button>
      </Header>
      {officeNotes.length === 0 && (
        <Content>
          <Typography variant="h5" textAlign="center">
            No notes
          </Typography>
        </Content>
      )}
      {show && (
        <Content>
          {officeNotes.map(note => (
            <Note key={note.id} note={note} />
          ))}
        </Content>
      )}
    </Paper>
  )
}

export default Notes
