import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper } from '../styles'

const formatRelationship = relationship => {
  if (!relationship) return relationship
  return relationship.charAt(0).toUpperCase() + relationship.slice(1)
}

const Payer = ({ insurance }) => {
  if (!insurance)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Insurance data found
      </Typography>
    )

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Group Number:{' '}
          <Typography variant="span" fontWeight={200}>
            {insurance.groupNumber}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Member Id:{' '}
          <Typography variant="span" fontWeight={200}>
            {insurance.memberId}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Name:{' '}
          <Typography variant="span" fontWeight={200}>
            {insurance?.payer?.name}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Code:{' '}
          <Typography variant="span" fontWeight={200}>
            {insurance?.payer?.code}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Relationship:{' '}
          <Typography variant="span" fontWeight={200}>
            {formatRelationship(insurance?.relationship)}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
    </Wrapper>
  )
}

export default Payer
