import React, { useEffect } from 'react'
import useInvoiceActions from '@/v2/hooks/useInvoiceActions'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ActionButton from '@/v2/components/common/ActionButton'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import SelectPaymentCardField from '@/v2/components/common/SelectPaymentCardField'
import SelectPaymentAccountField from '@/v2/components/common/SelectPaymentAccountField'
import ControlRadioField from '@/v2/components/common/ControlRadioField'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'
import InputField from '@/v2/components/common/InputField'
import FormControl from '@mui/material/FormControl'
import Divider from '@/v2/components/common/Divider'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { fromCurrencyToNumber, toCurrency } from '@/v2/utils/helpers'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import FormErrorMessage from '@/v2/components/common/FormErrorMessage'

const AdvancedPaymentForm = ({
  plan,
  onClose,
  patientId,
  creditCards,
  bankAccounts,
}) => {
  const { onOpenSnackbar } = useSnackbarContext()

  const { makeAdvancePayment } = useInvoiceActions({
    patientId,
  })

  const hasPaymentMethods = creditCards?.length > 0

  const hasBankAccounts = bankAccounts?.length > 0

  const hasOnlyOnePaymentMethod = creditCards?.length === 1

  const paymentMethod = () => {
    if (hasOnlyOnePaymentMethod) {
      return 'credit_card'
    }

    if (hasPaymentMethods) {
      return 'credit_card'
    }

    if (hasBankAccounts) {
      return 'ach'
    }

    return 'cash'
  }

  const paymentMethodId = hasOnlyOnePaymentMethod ? creditCards[0].id : null

  const schema = z
    .object({
      paymentMethodId: z.string().optional(),
      paymentMethod: z.enum(['cash', 'credit_card', 'check', 'ach']),
      additionalInfo: z.string().nullish(),
      amountTargeted: z
        .string()
        .transform(value => fromCurrencyToNumber(value))
        .optional(),
      paymentPlanId: z.string().nonempty({
        message: 'Payment plan is required',
      }),
    })
    .refine(
      data => {
        return data.amountTargeted > 0.5
      },
      {
        message: 'Amount must be greater than $0.50',
        path: ['amountTargeted'],
      }
    )
    .refine(
      data => {
        if (data.paymentMethod === 'check' && !data.additionalInfo) {
          return false
        }
        return true
      },
      {
        message: 'Additional payment info is required for this payment method',
        path: ['additionalInfo'],
      }
    )
  const {
    control,
    register,
    setError,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit: handleSubmitForm,
    watch,
  } = useForm({
    defaultValues: {
      paymentMethodId,
      status: 'charge',
      paymentMethod: paymentMethod(),
    },
    resolver: zodResolver(schema),
  })

  const watchPaymentMethod = watch('paymentMethod')

  const onSubmit = async data => {
    try {
      const isStripePaymentMethod =
        data.paymentMethod === 'credit_card' || data.paymentMethod === 'ach'

      const res = await makeAdvancePayment({
        patientId,
        paymentPlanId: data.paymentPlanId,
        paymentMethod: data.paymentMethod,
        amountTargeted: data.amountTargeted,
        paymentMethodId: isStripePaymentMethod ? data.paymentMethodId : null,
        gateway: isStripePaymentMethod ? 'stripe' : 'on_site',
        additionalInfo: data.additionalInfo,
      })

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (e) {
      setError('root.changePaymentMethod', {
        type: 'manual',
        message: e.message,
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const action = e.nativeEvent.submitter.name

    setValue('status', action)

    handleSubmitForm(data => onSubmit({ ...data, status: action }))()
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose()
      const message = 'Payment plan updated successfully'
      onOpenSnackbar(message)
    }
  }, [isSubmitSuccessful, onClose, onOpenSnackbar])

  const paymentMethodOptions = [
    { label: 'Cash', value: 'cash' },
    { label: 'Check', value: 'check' },
  ]

  if (hasPaymentMethods) {
    paymentMethodOptions.unshift({
      label: 'Credit Card',
      value: 'credit_card',
    })
  }

  if (hasBankAccounts) {
    paymentMethodOptions.push({
      label: 'ACH',
      value: 'ach',
    })
  }

  useEffect(() => {
    if (plan) {
      setValue('paymentPlanId', plan.id)
    }
  }, [plan, setValue])

  return (
    <FormControl component="form" onSubmit={handleSubmit} variant="filled">
      <FormErrorMessage errors={errors} name="root.changePaymentMethod" />

      {plan && (
        <>
          <Box display={'flex'} gap={1}>
            <Typography fontSize="14px">Remaining Balance:</Typography>
            <Typography bold fontSize="14px">
              ${toCurrency(plan.remainingPayable)}
            </Typography>
          </Box>
          <Divider />
        </>
      )}

      <CurrencyInputField
        label={'Amount'}
        name="amountTargeted"
        placeholder="$0.00"
        prefix="$"
        error={errors?.amountTargeted?.message}
        control={control}
      />

      <FormControl variant="standard">
        <Label>Payment Method</Label>

        <ControlRadioField
          id="paymentMethod"
          options={paymentMethodOptions}
          color="secondary"
          column
          property="value"
          name="paymentMethod"
          control={control}
        />
      </FormControl>

      <FormControl variant="standard">
        {watchPaymentMethod === 'credit_card' ? (
          <Label>Select a credit card</Label>
        ) : watchPaymentMethod === 'check' ? (
          <Label>Add check payment additional info</Label>
        ) : null}

        {watchPaymentMethod === 'credit_card' ? (
          <SelectPaymentCardField
            control={control}
            name="paymentMethodId"
            paymentMethods={creditCards}
            error={errors?.paymentMethodId}
          />
        ) : null}

        {watchPaymentMethod === 'ach' ? (
          <SelectPaymentAccountField
            control={control}
            name="paymentMethodId"
            paymentMethods={bankAccounts}
            error={errors?.paymentMethodId}
          />
        ) : null}

        {watchPaymentMethod === 'check' ? (
          <InputField
            {...register('additionalInfo')}
            error={errors?.additionalInfo?.message}
            fullWidth
            multiline
            sx={{ padding: '4px 0 5px; !important' }}
            label="Additional info"
            autoFocus
            placeholder="Write a payment additional info here..."
          />
        ) : null}
      </FormControl>

      <Grid container justifyContent={'flex-end'}>
        <ActionButton
          variant="contained"
          color="secondary"
          type="submit"
          name="chargeAndPay"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {watchPaymentMethod === 'check' || watchPaymentMethod === 'cash'
            ? 'Mark as Paid'
            : 'Charge Now'}
        </ActionButton>
      </Grid>
    </FormControl>
  )
}

export default AdvancedPaymentForm
