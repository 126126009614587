import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { useMutation } from '@apollo/client'
import Button from '@/v2/components/common/Button'
import Modal from '@/v2/components/common/Modal'
import AppointmentForm from '@/v2/modals/appointment/AppointmentEdit/BaseForm'
import AppointmentValid from '@/v2/components/forms/Appointment/validator'
import formatAppointment from '@/v2/components/forms/Appointment/fromForm'
import TimeOffForm from '@/v2/components/forms/TimeOff/Form'
import TimeOffValid from '@/v2/components/forms/TimeOff/validator'
import formatTimeOff from '@/v2/components/forms/TimeOff/fromForm'
import CreateAppointment from '@/v2/graphql/mutations/CreateAppointment.gql'
import UpdatePatient from '@/v2/graphql/mutations/UpdatePatient.gql'
import CreateTimeOff from '@/v2/graphql/mutations/CreateTimeOff.gql'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import getDayInfo from '@/v2/graphql/queries/DaySchedule.gql'
import { errorsFromJson } from '@/v2/utils/forms'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
// TODO: remove lodash
import isEqual from 'lodash/isEqual'
import { useLoadData } from './hooks/useLoadData'
import { getAppointmentState, getTimeOffState } from './utils'
import { useUser } from '@/v2/hooks/useUser'

const Header = styled.div`
  margin-bottom: -8px;
  display: flex;
  column-gap: 20px;

  & > button {
    font-size: 16px;
    font-weight: 300;
    width: 108px;
  }

  & > button.selected {
    font-weight: 500;
  }

  & > button.selected::before {
    content: '';
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 1px;
    border-bottom: 2px solid black;
  }
`

const CreateEvent = ({
  id,
  start,
  resourceId = '',
  virtual = false,
  patient,
  isOpen,
  onClose,
  onCreate,
}) => {
  const { loading, rooms, doctors, clinic, types } = useLoadData(id)
  const columnInfo = resourceId.split('-')
  const [tab, setTab] = useState('appointment')
  const [formError, setFormError] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [createAppointment] = useMutation(CreateAppointment)
  const [createTimeOff] = useMutation(CreateTimeOff)
  const [updatePatient] = useMutation(UpdatePatient)
  const appointmentInitalState = getAppointmentState({
    ...(resourceId ? { [`${columnInfo[0]}Id`]: columnInfo[1] } : {}),
    ...(patient ? { registeredPatient: patient } : {}),
    ...(patient ? { contactMethod: patient.contactMethod } : {}),
    startDate: start,
    virtual,
  })
  const [appointment, setAppointment] = useState(appointmentInitalState)
  const timeOffInitialState = getTimeOffState(clinic, {
    [`${columnInfo[0]}Id`]: columnInfo[1],
    startDate: start,
  })
  const [timeOff, setTimeOff] = useState(timeOffInitialState)
  const { onOpenSnackbar } = useSnackbarContext()
  const { user } = useUser()

  useEffect(() => {
    if (clinic) {
      setTimeOff(state => ({ ...state, clinicId: clinic }))
    }
  }, [clinic])

  const handleChangeAppointment = items => {
    const { newPatient, ...values } = items
    setAppointment({
      ...appointment,
      ...values,
      newPatient: {
        ...appointment.newPatient,
        ...newPatient,
      },
    })
  }

  const handleCreateAppointment = async () => {
    const validErrors = AppointmentValid(appointment)
    setFormError(validErrors || {})

    if (!validErrors) {
      setIsLoading(true)

      if (appointment.patientType !== 'new' && appointment.virtual) {
        updatePatient({
          variables: {
            patient: {
              id: appointment.registeredPatient.id,
              contactMethod: appointment.contactMethod,
            },
          },
        })
      }

      const response = await createAppointment({
        variables: { appointment: formatAppointment(appointment) },
        refetchQueries: [
          {
            query: getDayInfo,
            variables: {
              date: format(appointment.startsAtDate, DATE_PARAM_FORMAT),
            },
          },
          'GetPatient',
        ],
      })

      const asyncErrors = errorsFromJson(response.data.createAppointment.errors)

      if (asyncErrors) {
        const { patient, ...otherErrors } = asyncErrors
        const fieldName =
          appointment.patientType === 'new' ? 'newPatient' : 'registeredPatient'
        setFormError({
          ...otherErrors,
          [fieldName]: {
            ...patient,
            phone: (patient.phoneNumber || [])[0],
          },
        })
        setIsLoading(false)
        onOpenSnackbar('Something went wrong!', 'error')
        return
      } else {
        onOpenSnackbar('Appointment created.')
      }

      const createdAppointment = response.data.createAppointment.appointment

      window.analytics.track('New appointment', {
        category: 'Clearworks',
        label: 'New appointment from staff',
        datetime: createdAppointment.startsAt,
        appointmentType: createdAppointment.appointmentType.title,
        room: createdAppointment.room?.title || 'virtual',
      })

      // TODO: this is hardcoded for CW-710
      if (appointment.typeId === '83' || appointment.typeId === '84') {
        window.analytics.track(
          `Started treatment from ${
            appointment?.registeredPatient?.fromBookingPage
              ? 'Bookin Page'
              : 'Clearworks'
          } patient`,
          {
            category: 'Clearworks',
            label: 'Started treatment',
            datetime: createdAppointment.startsAt,
          }
        )
      }

      onCreate && onCreate()

      onClose()
    }
  }

  const handleCreateTimeOff = async () => {
    const validErrors = TimeOffValid(timeOff)
    setFormError(validErrors || {})

    if (!validErrors) {
      setIsLoading(true)

      const response = await createTimeOff({
        variables: { timeOff: formatTimeOff(timeOff) },
        refetchQueries: [
          {
            query: getDayInfo,
            variables: {
              date: format(timeOff.startsAtDate, DATE_PARAM_FORMAT),
            },
          },
          'DaySchedule',
        ],
      })

      const asyncErrors = errorsFromJson(response.data.createTimeOff.errors)

      if (asyncErrors) {
        setFormError({ ...asyncErrors })
        setIsLoading(false)
        onOpenSnackbar('Something went wrong!', 'error')
        return
      } else {
        onOpenSnackbar('Blocked time created.')
      }

      onCreate && onCreate()

      onClose()
    }
  }

  const handleSubmit = () => {
    if (tab === 'appointment') {
      handleCreateAppointment()
    } else {
      handleCreateTimeOff()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      loading={isLoading || loading}
      header={
        <Header>
          <Button
            variant="text"
            className={tab === 'appointment' ? 'selected' : ''}
            onClick={() => setTab('appointment')}
          >
            Appointment
          </Button>
          {user?.admin && (
            <Button
              variant="text"
              className={tab !== 'appointment' ? 'selected' : ''}
              onClick={() => setTab('timeoff')}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Blocked time
            </Button>
          )}
        </Header>
      }
      confirmLabel="Create"
      cancelLabel="Cancel"
      onConfirm={handleSubmit}
      onClose={() => {
        if (
          (tab === 'appointment' &&
            !isEqual(appointmentInitalState, appointment)) ||
          (tab === 'timeoff' && !isEqual(timeOffInitialState, timeOff))
        ) {
          return (
            confirm('Are you sure you want to leave without saving changes?') &&
            onClose()
          )
        } else {
          onClose()
        }
      }}
    >
      {tab === 'appointment' ? (
        <AppointmentForm
          error={formError}
          values={appointment}
          canRemove={true}
          types={types}
          rooms={rooms}
          doctors={doctors}
          onChange={handleChangeAppointment}
        />
      ) : (
        <TimeOffForm
          values={timeOff}
          clinic={clinic}
          rooms={rooms.map(({ id, title }) => ({
            value: id,
            label: title,
          }))}
          doctors={doctors.map(({ id, fullName }) => ({
            value: id,
            label: fullName,
          }))}
          error={formError}
          onChange={value => setTimeOff({ ...timeOff, ...value })}
        />
      )}
    </Modal>
  )
}

export default CreateEvent
