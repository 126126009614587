import React from 'react'
import Button from '@/v2/components/common/Button'
import TextField from '@/v2/components/common/TextField'
import PassField from '@/v2/components/common/PassField'
import { Form } from '@/v2/components/layouts/Authentication'

const LoginForm = ({
  values,
  errors,
  isLoading,
  isSubmitting,
  setValues,
  onSubmitForm,
}) => {
  const handleForm = e => {
    e.preventDefault()
    onSubmitForm()
  }
  return (
    <Form onSubmit={e => handleForm(e)}>
      <TextField
        fullWidth
        label="Email"
        name="email"
        labelAlignment="left"
        placeholder="example@example.com"
        value={values.email}
        error={errors?.email}
        onChange={e => setValues({ ...values, email: e })}
      />
      <PassField
        fullWidth
        label="Password"
        name="password"
        labelAlignment="left"
        placeholder="Password"
        value={values.password}
        error={errors?.password}
        onChange={e => setValues({ ...values, password: e })}
      />
      <Button
        disabled={isLoading || isSubmitting}
        loading={`${isLoading || isSubmitting}`}
        type="submit"
        variant="contained"
        color="secondary"
        fullWidth
      >
        Log in
      </Button>
    </Form>
  )
}
export default LoginForm
