import React, { useMemo } from 'react'
import Typography from '@/v2/components/common/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import { hideUnregister } from '@/v2/utils/helpers'

const DefaultMethod = ({ paymentMethods, invoice }) => {
  const theme = useTheme()

  const defaultPaymentCard = useMemo(() => {
    if (paymentMethods && paymentMethods.length > 0) {
      return paymentMethods.find(
        paymentMethod => paymentMethod.id === invoice.paymentMethodId
      )
    }
    return null
  }, [paymentMethods, invoice.paymentMethodId])

  if (defaultPaymentCard) {
    return (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="23px"
          color={theme.palette.gray.dark}
        >
          DEFAULT METHOD
        </Typography>
        <Typography fontSize="15px">
          {`${defaultPaymentCard.type === 'credit_card' ? 'Card' : 'ACH'} (${
            defaultPaymentCard.last4
          })`}
        </Typography>
      </Grid>
    )
  }

  if (!defaultPaymentCard && invoice.paymentMethodId) {
    return (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="23px"
          color={theme.palette.gray.dark}
        >
          DEFAULT METHOD
        </Typography>
        <Grid
          item
          display="flex"
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <Typography
            wordBreak
            textAlign={'left'}
            variant={'body'}
            fontSize="15px"
          >
            {hideUnregister(invoice) ? 'None' : 'Unregistered'}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  if (invoice.sendPaymentLink && !invoice.paymentMethodId) {
    return (
      <Grid item xs={1}>
        <Typography
          fontSize="10px"
          lineHeight="23px"
          color={theme.palette.gray.dark}
        >
          DEFAULT METHOD
        </Typography>
        <Typography wordBreak fontSize="15px">
          None
          <Typography wordBreak fontSize="12px">
            (Payment link sent)
          </Typography>
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid item xs={1}>
      <Typography
        fontSize="10px"
        lineHeight="23px"
        color={theme.palette.gray.dark}
      >
        DEFAULT METHOD
      </Typography>
      <Typography wordBreak fontSize="15px">
        None
      </Typography>
    </Grid>
  )
}

export default DefaultMethod
