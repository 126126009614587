import React, { useState } from 'react'
import MoreIcon from '@mui/icons-material/MoreVert'
import { useTheme } from '@mui/material/styles'
import { format, differenceInDays } from 'date-fns'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import SubItemBG from '#/assets/images/v2/subitem.png'
import { TIME_IN_US_FORMAT } from '@/v2/constants'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useDeleteNote } from '@/v2/hooks/appointments/useNotes'
import { parseHtmlToString } from '@/v2/utils/helpers'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import AddNote from '../AddNote'
import { Wrapper, DateTime, Image, Content } from './styles'

const Note = ({ note, appointmentId }) => {
  const theme = useTheme()
  const { patient } = usePatientContext()
  const { onOpenSnackbar } = useSnackbarContext()
  const { id, annotation, user, createdAt } = note
  const { deleteNote } = useDeleteNote(patient.id, appointmentId, id)
  const [edit, setEdit] = useState(false)
  const date = getDateTimeWithoutTimezone(createdAt)
  const currentDate = nowOnClinicTimezone()
  const daysOld = differenceInDays(currentDate, date)
  const isEditable = daysOld < 1

  const handleConfirmDeletion = () => {
    confirm('Are you sure that you want to delete this note?') && handleDelete()
  }

  const handleDelete = async () => {
    try {
      const { error } = await deleteNote()

      if (!error) {
        onOpenSnackbar('Note deleted.')
      }
    } catch (err) {
      onOpenSnackbar('Something went wrong!', 'error')
    }
  }

  if (!!edit) {
    return (
      <AddNote
        key={id}
        note={edit ? note : undefined}
        appointmentId={appointmentId}
        onEdit={() => setEdit(false)}
        onCancel={() => setEdit(false)}
      />
    )
  }

  return (
    <Wrapper key={id} theme={theme.palette} className="note-item">
      <Image src={SubItemBG} />
      <DateTime>
        <Typography bold fontSize="12px" sx={{ mb: '2px' }}>
          {format(date, 'MMMM dd, yyyy')}
        </Typography>
        <Typography uppercase fontSize="12px">
          {format(date, TIME_IN_US_FORMAT)}
        </Typography>
      </DateTime>
      <Content>
        <Typography fontSize="14px" lineHeight="20px" sx={{ mb: '12px' }}>
          {parseHtmlToString(annotation)}
        </Typography>
        <Typography fontSize="14px" fontWeight={500}>
          {`Dr. ${user?.fullName || 'Not Assigned'}`}
        </Typography>
      </Content>
      <IconMenu
        options={
          isEditable
            ? [
                { label: 'Edit', onClick: () => setEdit(true) },
                { label: 'Remove', onClick: () => handleConfirmDeletion() },
              ]
            : null
        }
        sx={{
          color: !isEditable
            ? theme.palette.grey[400]
            : theme.palette.grey[900],
        }}
        readOnly={!isEditable}
        icon={<MoreIcon fontSize="small" />}
        aria-label={
          isEditable
            ? 'Options'
            : 'You can only edit notes that are less than 24 hours old'
        }
        title={
          isEditable
            ? 'Options'
            : 'You can only edit notes that are less than 24 hours old'
        }
      />
    </Wrapper>
  )
}

export default Note
