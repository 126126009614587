import Box from '@mui/material/Box'

import React from 'react'
import { Controller } from 'react-hook-form'
import SavedCard from './SavedCard'
import Typography from '@/v2/components/common/Typography'
import { RadioGroup } from './styles'

const SelectPaymentAccountField = ({
  control,
  name,
  defaultValue,
  paymentMethods,
  error,
  ...props
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...props} {...field}>
            {paymentMethods.map(card => (
              <SavedCard
                key={`selectPaymentCardField-${card.id}`}
                card={card}
                isSelected={field.value === card.id}
              />
            ))}
          </RadioGroup>
        )}
      />

      {error && <Typography variant="error">{error.message}</Typography>}
    </Box>
  )
}

export default SelectPaymentAccountField
