import React from 'react'

const useInterval = (callback, delay) => {
  const cachedCallback = React.useRef()

  React.useEffect(() => {
    cachedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    if (delay !== 0) {
      const id = setInterval(() => cachedCallback?.current?.(), delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
