import React from 'react'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import Monthly from './Monthly'
import Daily from './PeriodOfTime/Daily'
import Weekly from './PeriodOfTime/Weekly'

const today = format(nowOnClinicTimezone(), 'yyyy-MM-dd')

const Calendar = () => {
  const history = useHistory()
  const { view = 'day', date = today } = useParams()

  // when date comes as '2022-9-01', adjust to '2022-09-01'
  if (/[0-9]{4}-[0-9]{1}-[0-9]{2}/.test(date)) {
    const fixedDate = date.slice(0, 5) + '0' + date.slice(5)
    history.push(`/v2/calendar/${view}/${fixedDate}`)
    return null
  }

  return view === 'month' ? (
    <Monthly date={date} />
  ) : view === 'day' ? (
    <Daily />
  ) : (
    <Weekly />
  )
}

export default Calendar
