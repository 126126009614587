import React, { createContext, useContext, useState } from 'react'

export const initCreatePaymentValues = {
  classification: 'one_time_payment',
  description: '',
  amount: '',
  interestRate: 0,
  discountRate: 0,
  amountWithDiscount: 0,
  totalDue: 0,
  installmentAmount: 0,
  installments: 1,
  updatedInstallments: null,
  step: 1,
  error: null,
  paymentMethodId: null,
  paymentMethodType: 'card',
  contractId: null,
}

export const CreatePaymentStateContext = createContext({})

export function CreatePaymentProvider({ children }) {
  const value = useState(initCreatePaymentValues)

  return (
    <CreatePaymentStateContext.Provider value={value}>
      {children}
    </CreatePaymentStateContext.Provider>
  )
}

export function useCreatePaymentState() {
  const context = useContext(CreatePaymentStateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppProvider')
  }
  return context
}
