import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const useContracts = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Contracts', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/contracts.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the contracts")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
  })

  return { contracts: data, isLoading, isError }
}

export const useContract = (patientId, contractId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Contract', patientId, contractId],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/contracts/${contractId}.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the contract")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId && !!contractId,
  })

  return { constract: data, isLoading, isError }
}

export const useCreateContract = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: createContract, ...others } = requestMiddleware({
    key: ['CreateContract', patientId],
    request: async values =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/contracts.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({
            down_payment: values.downPayment,
            description: values.description,
            total_cost: values.totalCost,
            interest_rate: values.interestRate,
            discount_rate: values.discountRate,
            monthly_payment_count: values.monthlyPaymentCount,
            monthly_payment_amount: values.monthlyPaymentAmount,
          }),
        }
      ),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['Contracts', `${patientId}`],
      })
      queryClient.invalidateQueries({
        queryKey: ['PatientProfile', `${patientId}`],
      })
    },
  })

  return { createContract, ...others }
}

export const useArchiveContract = (patientId, contractId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: archiveContract, ...others } = requestMiddleware({
    key: ['ArchiveContract', patientId, contractId],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/contracts/${contractId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['Contracts', `${patientId}`],
      })
      queryClient.invalidateQueries({
        queryKey: ['PatientProfile', `${patientId}`],
      })
    },
  })

  return { archiveContract, ...others }
}

export const useSendContract = contractId => {
  const { mutateAsync: sendContract, ...others } = requestMiddleware({
    key: ['SendContract', contractId],
    request: async () =>
      await fetch(`${baseURL}/v2/rest/contracts/${contractId}/emails`, {
        method: 'POST',
        headers,
      }),
  })

  return { sendContract, ...others }
}
