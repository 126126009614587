import React from 'react'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import Typography from '@/v2/components/common/Typography'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import Image from '../Image'
import { Wrapper, Logo, Title, SubTitle } from './styles'

const StandardImages = ({ series, folderDate }) => {
  const theme = useTheme()
  const { isMobile } = useDetectDevice()
  const { patient } = usePatientContext()
  const clinicName = document.querySelector('meta[name="clinic-name"]').content
  const findImage = position => {
    return series.images.find(image => image.position === position)
  }

  return (
    <Wrapper $isMobile={isMobile ? 1 : 0}>
      <Image
        id={findImage(1)?.id}
        src={findImage(1)?.url}
        originalImageUrl={findImage(1)?.originalImageUrl}
        position={1}
      >
        <Title>Facial</Title>
        <SubTitle>frontal</SubTitle>
      </Image>
      <Image
        id={findImage(2)?.id}
        src={findImage(2)?.url}
        originalImageUrl={findImage(2)?.originalImageUrl}
        position={2}
      >
        <Title>Facial</Title>
        <SubTitle>frontal smiling</SubTitle>
      </Image>
      <Image
        id={findImage(3)?.id}
        src={findImage(3)?.url}
        originalImageUrl={findImage(3)?.originalImageUrl}
        position={3}
      >
        <Title>Facial</Title>
        <SubTitle>lateral</SubTitle>
      </Image>
      <Image
        id={findImage(4)?.id}
        src={findImage(4)?.url}
        originalImageUrl={findImage(4)?.originalImageUrl}
        position={4}
      >
        <Title>Occlusal</Title>
        <SubTitle>upper</SubTitle>
      </Image>
      <Logo $isMobile={isMobile ? 1 : 0}>
        <Typography bold fontSize={isMobile ? '12px' : '18px'}>
          {clinicName}
        </Typography>
        <Typography fontSize={isMobile ? '12px' : '18px'}>
          {patient.fullName}
        </Typography>
        {folderDate && (
          <Typography variant="h6" color={theme.palette.gray.dark}>
            {format(new Date(folderDate.replace(/-/g, '/')), DAY_IN_US_FORMAT)}
          </Typography>
        )}
      </Logo>
      <Image
        id={findImage(5)?.id}
        src={findImage(5)?.url}
        originalImageUrl={findImage(5)?.originalImageUrl}
        position={5}
      >
        <Title>Occlusal</Title>
        <SubTitle>lower</SubTitle>
      </Image>
      <Image
        id={findImage(6)?.id}
        src={findImage(6)?.url}
        originalImageUrl={findImage(6)?.originalImageUrl}
        position={6}
      >
        <Title>Buccal</Title>
        <SubTitle>right</SubTitle>
      </Image>
      <Image
        id={findImage(7)?.id}
        src={findImage(7)?.url}
        originalImageUrl={findImage(7)?.originalImageUrl}
        position={7}
      >
        <Title>Buccal</Title>
        <SubTitle>center</SubTitle>
      </Image>
      <Image
        id={findImage(8)?.id}
        src={findImage(8)?.url}
        originalImageUrl={findImage(8)?.originalImageUrl}
        position={8}
      >
        <Title>Buccal</Title>
        <SubTitle>left</SubTitle>
      </Image>
      {series.name === 'Standard 10' && (
        <>
          <Image
            id={findImage(9)?.id}
            src={findImage(9)?.url}
            originalImageUrl={findImage(9)?.originalImageUrl}
            position={9}
          >
            <Title>X-Ray</Title>
            <SubTitle>cephalometric</SubTitle>
          </Image>
          <Image
            twoColumns
            id={findImage(10)?.id}
            src={findImage(10)?.url}
            originalImageUrl={findImage(10)?.originalImageUrl}
            position={10}
          >
            <Title>X-Ray</Title>
            <SubTitle>panoramic</SubTitle>
          </Image>
        </>
      )}
    </Wrapper>
  )
}

export default StandardImages
