import React, { useMemo, useState } from 'react'
import Typography from '@/v2/components/common/Typography'
import { Wrapper, Header, Body, Tabs, Tab, Content } from './styles'
import { useTheme } from '@mui/material/styles'
import { useContracts } from '@/v2/hooks/documents/useContracts'
import { useIntakeForm } from '@/v2/hooks/documents/useIntakeForm'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import ContractsTab from './ContractsTab'
import UploadsTab from './UploadsTab'

const Documents = () => {
  const theme = useTheme()
  const [tab, setTab] = useState('contracts')
  const { patient } = usePatientContext()
  const { intakeForm, isLoading: isLoadingForms } = useIntakeForm(patient.id)
  const { contracts, isLoading: isLoadingContracts } = useContracts(patient.id)
  const hasActiveContract = useMemo(() => {
    if (!contracts) return false
    return contracts.find(contract => contract.active === true)
  }, [contracts])

  if (isLoadingContracts || isLoadingForms) return null

  return (
    <Wrapper theme={theme.palette}>
      <Typography variant="h1" fontSize="26px" sx={{ mb: '30px' }}>
        Documents
      </Typography>
      <Body theme={theme.palette}>
        <Header theme={theme.palette}>
          <Tabs
            value={tab}
            className="treatment-navigation"
            onChange={(e, newTab) => setTab(newTab)}
          >
            <Tab value="contracts" label="Forms & Contracts" />
            <Tab value="upload" label="Uploads" />
          </Tabs>
        </Header>
        <Content>
          {tab === 'contracts' && (
            <ContractsTab
              patientId={patient.id}
              email={patient.email}
              intakeForm={intakeForm}
              contractList={contracts}
              hasActiveContract={hasActiveContract}
            />
          )}
          {tab === 'upload' && <UploadsTab patientId={patient.id} />}
        </Content>
      </Body>
    </Wrapper>
  )
}

export default Documents
