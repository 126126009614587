import styled from 'styled-components'
import { TOPBAR_MOBILE_HEIGHT } from '@/v2/constants'

export const Wrapper = styled.div`
  height: calc(100vh - ${TOPBAR_MOBILE_HEIGHT});
  display: block;
`

export const SearchBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.divider.main};
  margin-bottom: 20px;

  & .MuiOutlinedInput-root fieldset {
    border: none;
  }
  & .MuiOutlinedInput-root input::placeholder {
    font-size: 20px;
    font-weight: 300;
  }
`

export const Patient = styled.div`
  margin: 24px;
  height: 60px;
  display: flex;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 15px;
`
