import React from 'react'
import styled from 'styled-components'
import ArrowIcon from '@mui/icons-material/ArrowRight'
import FormControl from '@mui/material/FormControl'
import Accordion from '@/v2/components/common/Accordion'
import IconButton from '@/v2/components/common/IconButton'
import TextField from '@/v2/components/common/TextField'
import AddIcon from '@mui/icons-material/Add'

const SmallButton = styled(IconButton)`
  & .SVGInline {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CreateFolder = props => {
  const { isCreationMode, onCreate, onSetIsCreationMode } = props

  return isCreationMode ? (
    <Accordion
      noBackground
      key="create"
      title={
        <FormControl variant="standard">
          <TextField
            autoFocus
            name="new-folder"
            placeholder="Progression"
            defaultValue=""
            onBlur={({ target }) => {
              const value = target.value
              return onCreate(value)
            }}
            onKeyDown={event => {
              const enter = event.key === 'Enter'
              const value = event.target.value

              if (enter) {
                event.preventDefault()
                onCreate(value)
              }
            }}
            sx={{ marginTop: '-8px' }}
          />
        </FormControl>
      }
      content={<></>}
      icon={<ArrowIcon color="black" />}
      iconPosition="left"
    />
  ) : (
    <SmallButton
      title="Create a new folder"
      background="white"
      bordercolor="transparent"
      onClick={onSetIsCreationMode}
      sx={{ ml: 'calc(3% + 32px)', mt: '20px' }}
    >
      <AddIcon />
    </SmallButton>
  )
}

export default CreateFolder
