import * as yup from 'yup'

const insuranceSchema = yup.object().shape({
  relationship: yup.string().required('Relationship is required'),
  memberId: yup.string().required('Member ID is required'),
  payerId: yup.string().required('Payer is required'),
})

export const validateInsurance = async values => {
  try {
    await insuranceSchema.validate(values, { abortEarly: false })
    return null
  } catch (err) {
    const errors = {}
    err.inner.forEach(error => {
      errors[error.path] = error.message
    })
    return errors
  }
}
