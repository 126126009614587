import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import useModals from '@/v2/hooks/useModals'
import styled from 'styled-components'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Alert = styled.div`
  background: ${({ theme }) => theme.silver.main};
  padding: 12px 18px;
`

const AssignPatient = ({ phone }) => {
  const theme = useTheme()
  const { conversationId } = useParams()
  const { goTo } = useModals()

  return (
    <Wrapper>
      <div>
        <Typography fontSize="20px" lineHeight="26px" variant="h2">
          Contact info
        </Typography>
        <Typography fontSize="14px" fontWeight="300" sx={{ my: '10px' }}>
          This phone number is not associated to patient records yet.
        </Typography>
        <Alert theme={theme.palette}>
          <Typography fontSize="12px" fontWeight="300">
            If this number belongs to an existing patient please update the
            patient details so the contact info can update accordingly.
          </Typography>
        </Alert>
      </div>
      <div>
        <Button
          fullWidth
          variant="outlined"
          sx={{ my: '8px' }}
          onClick={() =>
            goTo(`/v2/patient/new`, {
              initialValues: { phone, conversationId },
              refetch: ['Conversations'],
            })
          }
        >
          New Patient
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ my: '8px' }}
          onClick={() => goTo(`/v2/event/new`)}
        >
          New Appointment
        </Button>
      </div>
    </Wrapper>
  )
}

export default AssignPatient
