import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const useConversation = conversationId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Conversation', `${conversationId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/conversations/${conversationId}`
      )
      if (!res.ok) {
        throw new Error('We could not find the conversation')
      }
      return res.json()
    },
    enabled: !!conversationId,
  })

  return { conversation: data, isLoading, isError }
}

export const useSearchConversations = (query = '') => {
  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    ...others
  } = useInfiniteQuery({
    queryKey: ['Conversations', query],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/conversations?page=${pageParam}${
          query ? `&query=${query}` : ''
        }`
      )
      if (!res.ok) {
        throw new Error('We could not find the conversations list')
      }
      return res.json()
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage = {}) => {
      const { currentPage, totalPages } = lastPage

      return totalPages === 0 || currentPage === totalPages
        ? undefined
        : (currentPage || 0) + 1
    },
    enabled: query !== '',
  })
  const conversations = [...(data?.pages || [])].reduce(
    (list, conversation) => [...list, ...conversation.records] || [],
    []
  )

  return {
    conversations,
    isLoading,
    isError,
    fetchNextPage,
    ...others,
  }
}

export const useOpenConversations = (query = '') => {
  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    ...others
  } = useInfiniteQuery({
    queryKey: ['Conversations', 'open', query],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/conversations?page=${pageParam}&status=open${
          query !== '' ? `&query=${query}` : ''
        }`
      )
      if (!res.ok) {
        throw new Error('We could not find the conversations list')
      }
      return res.json()
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage = {}) => {
      const { currentPage, totalPages } = lastPage

      return totalPages === 0 || currentPage === totalPages
        ? undefined
        : (currentPage || 0) + 1
    },
  })
  const conversations = [...(data?.pages || [])].reduce(
    (list, conversation) => [...list, ...conversation.records] || [],
    []
  )

  return {
    conversations,
    isLoading,
    isError,
    fetchNextPage,
    ...others,
  }
}

export const useClosedConversations = (query = '') => {
  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    ...others
  } = useInfiniteQuery({
    queryKey: ['Conversations', 'closed', query],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await fetch(
        `${baseURL}/v2/rest/conversations?page=${pageParam}&status=closed${
          query !== '' ? `&query=${query}` : ''
        }`
      )
      if (!res.ok) {
        throw new Error('We could not find the conversations list')
      }
      return res.json()
    },
    refetchOnMount: 'always',
    initialPageParam: 1,
    getNextPageParam: (lastPage = {}) => {
      const { currentPage, totalPages } = lastPage

      return totalPages === 0 || currentPage === totalPages
        ? undefined
        : (currentPage || 0) + 1
    },
  })
  const conversations = [...(data?.pages || [])].reduce(
    (list, conversation) => [...list, ...conversation.records] || [],
    []
  )

  return {
    conversations,
    isLoading,
    isError,
    fetchNextPage,
    ...others,
  }
}

export const useUpdateConversation = conversationId => {
  const { mutate: updateConversation, ...others } = requestMiddleware({
    key: ['UpdateConversation', conversationId],
    request: async info =>
      await fetch(`${baseURL}/v2/rest/conversations/${conversationId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ conversation: info }),
      }),
  })

  return { updateConversation, ...others }
}

export const useCreateConversation = () => {
  const { mutate: createConversation, data, ...others } = requestMiddleware({
    key: ['CreateConversation'],
    request: async ({ phoneNumber, patientId }) =>
      await fetch(`${baseURL}/v2/rest/conversations`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          conversation: { phoneNumber, patientId },
        }),
      }),
  })

  return { createConversation, newConversation: data, ...others }
}

export const useFindConversation = params => {
  const { phoneNumber, name, status = '' } = params || {}
  const { data, isLoading, isError } = useQuery({
    queryKey: ['GetConversation', phoneNumber || name || status || '', baseURL],
    queryFn: async () => {
      const query = phoneNumber || name || ''
      const res = await fetch(
        `${baseURL}/v2/rest/conversations/?query=${query}&status=${status}`
      )
      if (!res.ok) {
        throw new Error('We could not find the conversation')
      }
      const conversation = await res.json()

      return conversation
    },
    enabled: !!params,
  })

  return { conversation: data, isLoading, isError }
}
