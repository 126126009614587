import React, { createContext } from 'react'
import { Router } from 'react-router-dom'
import { createMemoryHistory } from 'history'
import MainRoutes from '@/v2/routes/MainRoutes'
import ModalRoutes from '@/v2/routes/ModalRoutes'

export const ModalHistoryContext = createContext({})

const history = createMemoryHistory({
  getUserConfirmation: (message, canContinue) => canContinue(confirm(message)),
})

export const ModalProvider = ({ children }) => (
  // TODO: after we kill the clearworks old design, upgrade react-router-dom
  //        and use like this:
  //        https://stackblitz.com/github/remix-run/react-router/tree/main/examples/modal?file=src%2FApp.tsx
  <ModalHistoryContext.Provider value={history}>
    {children(
      <>
        <MainRoutes />
        <Router history={history}>
          <ModalRoutes />
        </Router>
      </>
    )}
  </ModalHistoryContext.Provider>
)
