import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { TOPBAR_HEIGHT } from '@/v2/constants'
import Tabs from './Tabs'
import ActionButtons from './ActionButtons'

const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  height: ${TOPBAR_HEIGHT};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
`

const Topbar = () => {
  const theme = useTheme()
  return (
    <Wrapper theme={theme.palette}>
      <Tabs />
      <ActionButtons />
    </Wrapper>
  )
}

export default Topbar
