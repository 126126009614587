import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  padding: 10px;
`
const Note = styled.div`
  border-radius: 3px;
  padding: 20px 26px;
  background: ${({ theme }) => theme.green.note};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 470px;
`
const MedicalAlert = styled.div`
  border-radius: 3px;
  padding: 20px 26px;
  background: ${({ theme, color }) => theme[color].medicalAlert};
  color: ${({ theme }) => theme.background.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 470px;
`

const Alert = ({ alerts, onClose }) => {
  const theme = useTheme()

  return (
    <Backdrop open sx={{ zIndex: '99' }}>
      <Wrapper>
        {alerts.map(item => {
          return item?.classification === 'green' ? (
            <Note key={item.id} theme={theme.palette}>
              <Typography fontSize="18px">
                <b>{'Warning: '}</b>
                {item.annotation}
              </Typography>
              <Button variant="text" onClick={onClose}>
                <Typography
                  fontSize="14px"
                  lineHeight="11px"
                  sx={{
                    textTransform: 'none',
                    borderBottom: '1px solid',
                  }}
                >
                  Okay, noted
                </Typography>
              </Button>
            </Note>
          ) : (
            <MedicalAlert
              key={item.name}
              theme={theme.palette}
              color={item.color}
            >
              <Typography fontSize="18px">
                <b>{'Warning: '}</b>
                {item.label}
              </Typography>
              <Button variant="text" onClick={onClose}>
                <Typography
                  fontSize="14px"
                  lineHeight="11px"
                  color={theme.palette.background.default}
                  sx={{
                    textTransform: 'none',
                    borderBottom: '1px solid',
                  }}
                >
                  Okay, noted
                </Typography>
              </Button>
            </MedicalAlert>
          )
        })}
      </Wrapper>
    </Backdrop>
  )
}

export default Alert
