import React, { useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Popover from '@mui/material/Popover'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import useLabels from '@/v2/hooks/useLabels'

const Label = styled.div`
  padding: 6px 10px;
  transition: 0.3s;
  background: ${({ theme, color }) => theme[color].label};
  color: ${({ theme }) => theme.white.main};
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
const Wrapper = styled.div`
  display: flex;
  gap: 8px 10px;
  flex-flow: row wrap;
  width: 320px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.primary.main};
  border-radius: 4px;
`

const LabelPicker = ({ selectedLabels, hideLabels = [], onChange }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const { labels, loading, error } = useLabels()
  const filteredLabels = useMemo(
    () =>
      !error && !loading
        ? labels.filter(
            ({ name }) =>
              !hideLabels.find(label => label === name.toLowerCase())
          )
        : [],
    [labels, loading, error, hideLabels]
  )

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button variant="outlined" onClick={handleClick}>
        + Add Label
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ elevation: 0, style: { marginTop: '4px' } }}
      >
        <Wrapper theme={theme.palette}>
          {filteredLabels.map(label => {
            if (selectedLabels.find(({ id }) => id === label.id)) return

            return (
              <Label
                key={label.id}
                color={label.color}
                theme={theme.palette}
                onClick={() => onChange(label)}
              >
                <Typography key={label.id} fontSize="12px">
                  {label.name}
                </Typography>
              </Label>
            )
          })}
        </Wrapper>
      </Popover>
    </>
  )
}

export default LabelPicker
