import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { useTabContext } from '@/v2/contexts/TabsContext'
import { useMessagesContext } from '@/v2/contexts/MessagesContext'
import { formatPhone } from '@/v2/utils/format'

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 26px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
`

const ContactInfo = ({ patient }) => {
  const theme = useTheme()
  const { onAddTab } = useTabContext()
  const { onAppendToMessage } = useMessagesContext()

  return (
    <Wrapper theme={theme.palette}>
      <Typography fontSize="20px" lineHeight="26px" variant="h2">
        Contact info
      </Typography>
      <Button variant="text" color="link" href={`/v2/patient/${patient.id}`}>
        VIEW DETAILS
      </Button>

      {patient.intakeForm && patient.intakeForm?.status !== 'completed' && (
        <Button
          variant="contained"
          onClick={() => onAppendToMessage('{HH form link}')}
          sx={{ marginTop: '20px', fontWeight: '400' }}
        >
          Insert Health History form link
        </Button>
      )}

      <Typography
        fontSize="14px"
        lineHeight="18px"
        variant="h2"
        sx={{ mt: '20px' }}
      >
        Email
      </Typography>
      <Typography
        fontSize="14px"
        lineHeight="18px"
        variant="h4"
        sx={{ mb: '22px' }}
      >
        {patient.email || 'Not informed'}
      </Typography>

      <Typography fontSize="14px" lineHeight="18px" variant="h2">
        Phone
      </Typography>
      <Typography
        fontSize="14px"
        lineHeight="18px"
        variant="h4"
        sx={{ mb: '22px' }}
      >
        {formatPhone(patient.phoneNumber) || 'Not informed'}
      </Typography>

      <Typography fontSize="14px" lineHeight="18px" variant="h2">
        Heard about us
      </Typography>
      <Typography fontSize="14px" lineHeight="18px" variant="h4">
        {patient.referredBy || 'Not Informed'}
      </Typography>
    </Wrapper>
  )
}

export default ContactInfo
