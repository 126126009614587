import React from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import { useInsurances } from '@/v2/hooks/insurance/useInsurances'
import Subscriber from './subforms/Subscriber'
import Providers from './subforms/Providers'
import Procedures from './subforms/Procedures'

const Form = ({ isEdit = false, values = {}, error = {}, onChange }) => {
  const { id } = useParams()
  const { insurances } = useInsurances(id)
  const insuranceOptions = (insurances = []) =>
    insurances.map(insurance => ({
      value: insurance.id,
      label: insurance.payer.name,
    }))
  const handleChangeInsurance = value => {
    const insurance = insurances.find(({ id }) => `${id}` === value)
    onChange({
      insuranceId: value,
      subscriber: {
        ...values.subscriber,
        relationship: insurance?.relationship || '',
      },
    })
  }

  const handleChangeSubscriber = value =>
    onChange({
      ...values,
      subscriber: { ...values.subscriber, ...value },
    })

  return (
    <Grid container spacing={2}>
      <Grid item xs={isEdit ? 6 : 8}>
        <SelectField
          fullWidth
          noMargin
          label="Insurance"
          name="insurance"
          placeholder="Choose an option"
          options={insuranceOptions(insurances)}
          value={values.insuranceId}
          error={error?.insuranceId}
          onChange={handleChangeInsurance}
          disabled={isEdit}
        />
      </Grid>

      {values?.insuranceId && (
        <Subscriber
          editAllowed={true}
          isEdit={isEdit}
          values={values}
          error={error}
          onChange={handleChangeSubscriber}
        />
      )}
    </Grid>
  )
}

export default Form
