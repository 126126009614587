import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSendMessage } from '@/v2/hooks/messages/useMessages'
import SendMessage from './SendMessage'

const SendReply = ({ onSend }) => {
  const { conversationId } = useParams()
  const { sendMessage, isSuccess } = useSendMessage(conversationId)

  useEffect(() => {
    if (isSuccess) {
      onSend()
    }
  }, [isSuccess])

  return <SendMessage onSend={message => sendMessage(message)} />
}

export default SendReply
