import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const usePatient = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Patient', patientId],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/patients/${patientId}`)
      if (!res.ok) {
        throw new Error("We couldn't find the patient")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
  })

  return { patient: data, isLoading, isError }
}

export const usePatientProfile = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['PatientProfile', patientId],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/infos`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
  })

  return { patient: data, isLoading, isError }
}

export const useUpdatePatient = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: updatePatient, ...others } = requestMiddleware({
    key: ['UpdatePatient', patientId],
    request: async values =>
      await fetch(`${baseURL}/v2/rest/patients/${patientId}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(values),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Patient', `${patientId}`],
      })
      queryClient.invalidateQueries({
        queryKey: ['PatientProfile', `${patientId}`],
      })
    },
  })

  return { updatePatient, ...others }
}

export default usePatient
