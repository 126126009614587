import styled from 'styled-components'
import TextField from '@mui/material/TextField'

export const TextArea = styled(TextField)`
  && {
    width: 100%;
    max-width: 800px;
    margin-top: 54px;
    margin-bottom: 20px;
  }
`

export const InlineButtons = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: 800px;
`
