import React, { useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import RealTime from './RealTime'

const RTNotifications = () => {
  const queryClient = useQueryClient()
  const audioPlayerRef = useRef(null)

  const handleSendPush = () => {
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
      return
    }

    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        const notification = new Notification('Clearworks', {
          body: 'A new message has arrived!',
          icon: '/favicons/apple-icon-180x180.png',
          badge: '/favicons/apple-icon-180x180.png',
          requireInteraction: true,
          renotify: true,
          tag: 'new-message',
        })

        notification.onclick = () => {
          notification.close()
          window.parent.focus()
        }
      }
    })
  }

  const handleEvent = ({ data }) => {
    const queryKey = ['Notification']
    const previousData = queryClient.getQueryData(queryKey)

    queryClient.setQueryData(queryKey, () => data)

    if (
      data.messagesUnreadCount > 0 &&
      data.messagesUnreadCount !== previousData.messagesUnreadCount
    ) {
      try {
        audioPlayerRef.current.play()
        handleSendPush()
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <>
      <audio ref={audioPlayerRef} src="/sounds/notification.mp3" />
      <RealTime channel="Chat::NotificationsChannel" onEvent={handleEvent} />
    </>
  )
}

export default RTNotifications
