import { useMutation, useQueryClient } from '@tanstack/react-query'
import request from 'graphql-request'
import { baseURLGraphql, headers } from '@/v2/constants/requests'
import PatientFinanceInfo from '@/v2/hooks/usePatientFinanceInfo/graphql/queries/PatientFinanceInfo.gql'
import CreatePaymentPlan from './graphql/mutations/CreatePaymentPlan.gql'
import PatientFinanceDuplicateCard from './graphql/queries/PatientFinanceDuplicateCard.gql'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const usePaymentPlan = props => {
  const { patientId, tokenId } = props || {}
  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()

  const {
    mutateAsync: createPaymentPlan,
    isLoading: isCreatingPaymentPlan,
    isError: isCreatingPaymentPlanError,
  } = useMutation({
    mutationKey: ['createPaymentPlan'],
    mutationFn: async variables =>
      request(baseURLGraphql, CreatePaymentPlan, variables, headers),
    onError: () => {
      onOpenSnackbar('Error creating payment plan', 'error')
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-info',
          baseURLGraphql,
          headers,
          PatientFinanceInfo,
          variables.patientId,
        ],
      })
    },
  })

  const {
    mutateAsync: checkDuplicatePaymentPlan,
    isLoading: isCheckingDuplicatePaymentPlan,
    isError: isCheckingDuplicatePaymentPlanError,
  } = useMutation({
    mutationKey: [
      'checkDuplicatePaymentPlan',
      baseURLGraphql,
      headers,
      PatientFinanceDuplicateCard,
      { tokenId, patientId },
    ],
    mutationFn: async variables =>
      request(baseURLGraphql, PatientFinanceDuplicateCard, variables, headers),
  })

  return {
    createPaymentPlan,
    isCreatingPaymentPlan,
    isCreatingPaymentPlanError,
    checkDuplicatePaymentPlan,
    isCheckingDuplicatePaymentPlan,
    isCheckingDuplicatePaymentPlanError,
  }
}

export default usePaymentPlan
