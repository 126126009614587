import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@/v2/components/common/Typography'
import { Fragment } from 'react'
import getRandomId from '@/v2/utils/random-id'
import { Controller } from 'react-hook-form'

const ControlRadioField = ({
  options,
  label,
  color,
  error,
  sx,
  column,
  name,
  control,
  defaultValue,
  disabled,
  ...others
}) => {
  const id = getRandomId()
  return (
    <FormControl variant="standard" disabled={disabled} sx={sx}>
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup
            row={!column}
            sx={{ px: 1 }}
            aria-labelledby={id}
            {...others}
            {...field}
          >
            {options.map(({ label, value, info }) => (
              <Fragment key={value}>
                <FormControlLabel
                  sx={{ '& > span': { fontSize: '14px', fontWeight: 400 } }}
                  value={value}
                  control={<Radio color={color} sx={{ padding: '2px 4px' }} />}
                  label={label}
                />
                {info && (
                  <Typography px={2.5} variant="info">
                    {info}
                  </Typography>
                )}
              </Fragment>
            ))}
          </RadioGroup>
        )}
      />
      {error && <Typography variant="error">{error}</Typography>}
    </FormControl>
  )
}

export default ControlRadioField
