import React, { useState, useCallback, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Cropper from 'react-easy-crop'
import Modal from '@/v2/components/common/Modal'
import { useUploadImage, useDeleteImage } from '@/v2/hooks/useSeriesImages'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import { getCroppedImg, DataURIToBlob } from './utils'
import Footer from './Footer'

const Wrapper = styled.div`
  position: relative;
  height: 500px;
  width: 500px;
  max-width: 100%;

  & .reactEasyCrop_Container {
    background: ${({ theme }) => theme.gray.dark};
  }

  & .reactEasyCrop_CropArea {
    border: 3px dashed ${({ theme }) => theme.background.default};
    color: ${({ theme }) => theme.background.default}57;
  }
`

const CropImage = ({
  isOpen,
  image,
  position = 0,
  patientId,
  progressionId,
  seriesId,
  aspect = 1,
  originalImage = '',
  onUploadImage,
  onClose,
  imageId,
}) => {
  const theme = useTheme()
  const { uploadImage } = useUploadImage()
  const { deleteImage } = useDeleteImage()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [flip, setFlip] = useState({ horizontal: false, vertical: false })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [loadedImage, setLoadedImage] = useState(null)
  const [isCropped, setIsCropped] = useState(false)
  const { isMobile } = useDetectDevice()

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  useEffect(() => {
    const handleReadFile = async () => {
      let imageDataUrl =
        typeof image === 'object' ? await readFile(image) : image

      setIsCropped(typeof image === 'string')
      setLoadedImage(imageDataUrl)
    }
    handleReadFile()
  }, [image])

  const handleCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleSaveCrop = useCallback(async () => {
    if (!croppedAreaPixels) {
      return
    }

    try {
      const croppedImage = await getCroppedImg(
        loadedImage,
        croppedAreaPixels,
        rotation,
        flip,
        isCropped
      )

      const file = DataURIToBlob(croppedImage)

      const formData = new FormData()
      formData.append('image[file]', file, 'image.jpg')
      formData.append('image[position]', position)
      formData.append('image[original_image_url]', originalImage)

      if (patientId && seriesId) {
        if (imageId) {
          await deleteImage(patientId, seriesId, { id: imageId })
        }

        const image = await uploadImage(patientId, seriesId, formData)

        onUploadImage(progressionId, seriesId, image)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }, [
    croppedAreaPixels,
    rotation,
    flip,
    loadedImage,
    position,
    originalImage,
    onClose,
    uploadImage,
    patientId,
    seriesId,
    progressionId,
    onUploadImage,
    deleteImage,
    imageId,
    isCropped,
  ])

  const handleOnResetImage = () => {
    setLoadedImage(originalImage)
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Crop Image"
      onClose={onClose}
      footer={
        <Footer
          rotation={rotation}
          zoom={zoom}
          flip={flip}
          onRotation={setRotation}
          onZoom={setZoom}
          onFlip={setFlip}
          onConfirm={handleSaveCrop}
          onClose={onClose}
          isCropped={isCropped}
          onReset={handleOnResetImage}
        />
      }
    >
      <Wrapper theme={theme.palette}>
        <Cropper
          image={loadedImage}
          transform={[
            `translate(${crop.x}px, ${crop.y}px)`,
            `rotateZ(${rotation}deg)`,
            `rotateY(${flip.horizontal ? 180 : 0}deg)`,
            `rotateX(${flip.vertical ? 180 : 0}deg)`,
            `scale(${zoom})`,
          ].join(' ')}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          zoomSpeed={isMobile ? 1 : 0.5}
          aspect={aspect}
          restrictPosition={false}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={handleCropComplete}
          onZoomChange={setZoom}
        />
      </Wrapper>
    </Modal>
  )
}

export default CropImage
