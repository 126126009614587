import React, { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from '@hookform/error-message'
import Box from '@mui/material/Box'
import Modal from '@/v2/components/common/Modal'
import Typography from '@/v2/components/common/Typography'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import useInvoiceActions from '@/v2/hooks/useInvoiceActions'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import usePayments from '@/v2/hooks/usePayments'
import SelectInputField from '@/v2/components/common/SelectInputField'
import ActionButton from '@/v2/components/common/ActionButton'
import { toCurrency, toCamelCase } from '@/v2/utils/helpers'
import { Form } from '../styles'
import { Grid } from '@mui/material'

const schema = z.object({
  paymentId: z.number().min(0),
})

const DeclinePayment = ({ isOpen, onClose, invoice }) => {
  const theme = useTheme()
  const { patientId } = useParams()
  const { declineCheck } = useInvoiceActions({ patientId })
  const { onOpenSnackbar } = useSnackbarContext()

  const { payments } = usePayments({
    invoiceId: invoice.id,
  })

  const processedPayments = useMemo(() => {
    return payments.filter(
      payment =>
        payment.status !== 'refunded' &&
        payment.status !== 'declined' &&
        payment.status !== 'canceled' &&
        payment.paymentMethod === 'check'
    )
  }, [payments])

  const {
    setError,
    register,
    formState: { errors, isSubmitting },
    handleSubmit: handleValidForm,
  } = useForm({
    defaultValues: {
      amount: '',
      message: '',
      total: invoice.total,
      paymentId: processedPayments[0]?.id,
      payment_method: processedPayments[0]?.payment_method,
    },
    resolver: zodResolver(schema),
  })

  const handleSubmit = async data => {
    try {
      const res = await declineCheck({
        invoiceId: invoice.id,
        paymentId: data.paymentId,
      })
      if (res.error) {
        throw new Error(res.error)
      } else {
        onClose()
        onOpenSnackbar('Invoice refunded')
      }
    } catch (error) {
      setError('root.refundInvoice', {
        type: 'manual',
        message: error.message,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={<></>}
      title="Decline Check Payment"
    >
      <Form onSubmit={handleValidForm(handleSubmit)}>
        <SelectInputField
          noMargin
          label="Payments"
          id="payment-plan-paymentId"
          options={processedPayments.map(payment => ({
            label: `$${toCurrency(
              parseFloat(payment.amountCharged)
            )} - ${toCamelCase(payment.paymentMethod)}`,
            value: payment.id,
          }))}
          color="secondary"
          error={errors?.paymentId?.message}
          {...register('paymentId', {
            valueAsNumber: true,
          })}
        />

        <ErrorMessage
          errors={errors}
          name="root.refundInvoice"
          render={({ message }) => (
            <Box
              bgcolor={theme.palette.error.dark}
              color={theme.palette.common.white}
              p={1}
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              <ErrorOutline />
              <Typography wordBreak fontSize="14px">
                {message}
              </Typography>
            </Box>
          )}
        />

        <Grid container gap={1} justifyContent="flex-end">
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </ActionButton>

          <ActionButton
            variant="contained"
            color="secondary"
            type="submit"
            onClick={handleValidForm(handleSubmit)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Decline
          </ActionButton>
        </Grid>
      </Form>
    </Modal>
  )
}

export default DeclinePayment
