import React from 'react'
import { useTheme } from '@mui/material/styles'
import Button from '@/v2/components/common/Button'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import NextAppointment from '../NextAppointment'
import NavigationTabs from '../NavigationTabs'
import { TabsAndButtonsWrapper, Wrapper } from './styles'

const PatientBar = () => {
  const theme = useTheme()
  const { patient } = usePatientContext()

  return (
    <Wrapper theme={theme.palette}>
      <NextAppointment />
      <TabsAndButtonsWrapper theme={theme.palette}>
        <NavigationTabs />
        <Button
          className="patient-messages-button"
          variant="outlined"
          href={`/v2/messages/start-conversation/${patient.id}`}
          rel="noopener noreferrer"
          target="_blank"
          onClick={e => {
            if (patient.phoneNumber === null) {
              e.preventDefault()
              e.stopPropagation()
              alert(
                "This patient doesn't have a phone number!\nPlease, update the patient contact info."
              )
            }
          }}
        >
          Messages
        </Button>
      </TabsAndButtonsWrapper>
    </Wrapper>
  )
}

export default PatientBar
