import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import Box from '@mui/material/Box'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Typography from '@/v2/components/common/Typography'

const FormErrorMessage = ({ errors, name }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Box
          bgcolor={'error.dark'}
          color={'common.white'}
          p={1}
          display={'flex'}
          alignItems={'center'}
          gap={1}
        >
          <ErrorOutline />
          <Typography wordBreak fontSize="14px">
            {message}
          </Typography>
        </Box>
      )}
    />
  )
}

export default FormErrorMessage
