import React from 'react'
import PaymentCardForm from '@/v2/components/forms/PaymentCardForm'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'

const CardPaymentForm = ({ patient, params, handleClose, setAction }) => {
  return (
    <PaymentCardForm
      patientId={patient.id}
      backButton={() => (
        <Button size="small" onClick={handleClose}>
          <Typography fontWeight={400} variant="body">
            {params ? 'Back' : 'Cancel'}
          </Typography>
        </Button>
      )}
      onSuccess={() => {
        setAction('create')
      }}
    />
  )
}

export default CardPaymentForm
