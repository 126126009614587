import React, { useEffect } from 'react'
import Typography from '@/v2/components/common/Typography'
import SelectInputField from '@/v2/components/common/SelectInputField'
import {
  range,
  fromCurrencyToNumber,
  updateContractAmounts,
  geThisDayOnNextMonth,
} from '@/v2/utils/helpers'
import { toUSNumberFormat } from '@/v2/utils/format'
import { useWatch } from 'react-hook-form'
import { InitialPaymentField } from '../InitialPaymentField'
import Row from '@/v2/components/common/Row'
import Box from '@mui/material/Box'
import Warning from '@mui/icons-material/Warning'

const InstallmentFields = ({
  register,
  errors,
  control,
  setValue,
  setState,
  state,
  onBlurChange,
  getFieldState,
}) => {
  const watch = useWatch({
    control,
    name: [
      'classification',
      'amountWithDiscount',
      'discountRate',
      'installments',
      'installmentAmount',
      'interestRate',
      'downPayment',
      'amount',
    ],
  })
  const watchClassification = watch[0]
  const watchAmountWithDiscount = watch[1]
  const watchDiscountRate = watch[2]
  const watchInstallments = watch[3]
  const watchInstallmentAmount = watch[4]
  const watchInterestRate = watch[5]
  const watchDownPayment = watch[6]
  const watchAmount = watch[7]

  const isDownPaymentFieldModified = getFieldState('downPayment').isDirty

  useEffect(() => {
    if (watchDownPayment && isDownPaymentFieldModified) {
      const res = updateContractAmounts({
        amount: watchAmount,
        discountRate: watchDiscountRate / 100,
        interestRate: watchInterestRate / 100,
        installments: watchInstallments,
        downPayment: watchDownPayment,
      })
      setState(s => ({ ...s, updatedInstallments: res.updatedInstallments }))
      setValue('amountWithDiscount', res.amountWithDiscount)
      setValue('totalDue', res.totalDue)
      setValue('installmentAmount', res.installmentAmount)
    }
  }, [
    watchAmount,
    watchDownPayment,
    watchDiscountRate,
    watchInterestRate,
    watchInstallments,
    isDownPaymentFieldModified,
    setValue,
    setState,
  ])

  const downPayment =
    typeof watchDownPayment === 'string'
      ? fromCurrencyToNumber(watchDownPayment)
      : watchDownPayment

  const isDownPaymentGreater =
    watchInstallments > 1 && downPayment > watchAmountWithDiscount

  useEffect(() => {
    if (watchClassification === 'installments' && watchInstallments === 1) {
      setValue('installments', 2)
    }
  }, [watchClassification, watchInstallments, setValue])

  const installments = state.updatedInstallments
    ? state.updatedInstallments
    : watchInstallments

  return watchClassification === 'installments' ? (
    <>
      <Row>
        <SelectInputField
          noMargin
          label="Installments"
          name="installments"
          id="payment-plan-installments"
          options={range(2, 12, 1).map(installments => ({
            label: installments,
            value: installments,
          }))}
          color="secondary"
          error={errors?.installments?.message}
          {...register('installments', {
            valueAsNumber: true,
          })}
          onBlur={onBlurChange}
        />

        <InitialPaymentField
          control={control}
          errors={errors}
          info={
            'Increasing initial payment will lower monthly installment payments.'
          }
        />
      </Row>

      {watchInstallments > 1 && watchAmount > 0 ? (
        <Box
          bgcolor={isDownPaymentGreater ? 'error.main' : 'gray.light'}
          borderRadius={2}
          px={2}
          py={2}
          mb={2}
        >
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            {!isDownPaymentGreater ? (
              <>
                <Box display={'flex'} gap={1}>
                  <Typography variant={'body2'}>Total due today:</Typography>
                  <Typography variant={'body2'} bold>
                    {toUSNumberFormat(downPayment, true, true)}
                  </Typography>
                </Box>

                {watchInstallmentAmount > 0 ? (
                  <>
                    <Box display={'flex'} gap={1}>
                      <Typography variant={'body2'}>{`${installments} more ${
                        installments === 1 ? 'payment' : 'payments'
                      } of ${toUSNumberFormat(
                        watchInstallmentAmount,
                        true,
                        true
                      )}`}</Typography>
                    </Box>

                    <Box display={'flex'} gap={1}>
                      <Typography
                        fontWeight="light"
                        variant="body3"
                        color="fieldLabel.main"
                      >
                        Next payment {geThisDayOnNextMonth()}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </>
            ) : (
              <Typography
                variant={'body2'}
                color={'white.main'}
                fontWeight="light"
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Warning />
                Initial payment should be less than invoice amount
              </Typography>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  ) : null
}

export default InstallmentFields
