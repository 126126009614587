import React from 'react'
import { useWatch } from 'react-hook-form'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import DefaultLoader from '@/v2/components/common/Modal/defaults/Loader'
import SelectPaymentCardField from '@/v2/components/common/SelectPaymentCardField'
import SelectPaymentAccountField from '@/v2/components/common/SelectPaymentAccountField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const SelectCardField = ({
  control,
  patientId,
  name,
  defaultValue,
  errors,
}) => {
  const watchPaymentMethodType = useWatch({
    control,
    name: 'paymentMethodType',
  })

  const {
    creditCards,
    bankAccounts,
    isLoading,
    isError,
  } = usePatientFinancePaymentMethods({
    patientId,
  })

  if (isLoading) return <DefaultLoader />
  if (isError) return 'Unable to load saved payment methods'

  if (watchPaymentMethodType === 'ach') {
    if (bankAccounts.length === 0)
      return (
        <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
          <Typography variant="body2" textAlign={'center'}>
            You don't have any bank accounts saved.
          </Typography>
        </Box>
      )
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        alignItems={'start'}
      >
        <SelectPaymentAccountField
          control={control}
          name={name}
          defaultValue={defaultValue}
          paymentMethods={bankAccounts}
          error={errors[name]}
        />
      </Box>
    )
  }

  if (watchPaymentMethodType === 'card') {
    if (creditCards.length === 0) {
      return (
        <>
          <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
            <Typography variant="body2" textAlign={'center'}>
              You don't have any credit cards saved.
            </Typography>
          </Box>
          {errors[name] && (
            <Typography variant="error">{errors[name]?.message}</Typography>
          )}
        </>
      )
    }

    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        alignItems={'start'}
      >
        <SelectPaymentCardField
          control={control}
          name={name}
          defaultValue={defaultValue}
          paymentMethods={creditCards}
          error={errors[name]}
        />
      </Box>
    )
  }

  return null
}

export default SelectCardField
