import { useQuery } from '@tanstack/react-query'
import { baseURL, STALE_TIME } from '@/v2/constants/requests'

export const usePatientAppointments = (patientId, amount = '2') => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['PatientAppointments', patientId, amount],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/appointments?limit=${amount}`
      )
      if (!res.ok) {
        throw new Error("We couldn't find a patient")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
    staleTime: STALE_TIME,
  })

  return {
    nextAppointments: data?.next,
    previousAppointments: data?.previous,
    isLoading,
    isError,
  }
}

export default usePatientAppointments
