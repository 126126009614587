import { useMemo } from 'react'
import { format } from 'date-fns'
import { useQuery } from '@apollo/client'
import AppointmentWarnings from '@/v2/graphql/queries/AppointmentWarnings.gql'

export const getAppointmentFormat = appointment => {
  const isValidHour = /^([0-1]\d):([0-5]\d)\s?(?:AM|PM)?$/i.test(
    appointment.startsAtTime
  )
  const startsAt = `${format(appointment.startsAtDate, 'yyyy-M-dd')} ${
    isValidHour ? appointment.startsAtTime : ''
  }`
  const appointmentInfo = appointment.id
    ? { id: appointment.id, patientId: appointment.registeredPatient.id }
    : {}
  return {
    ...appointmentInfo,
    doctorId: appointment.doctorId,
    roomId: appointment.roomId,
    duration: appointment.duration !== '' ? parseInt(appointment.duration) : 15,
    startsAt: startsAt,
  }
}

export const getWarnings = data => {
  if (!data) return {}

  const { doctorId, startsAt } = data?.checkAppointmentWarnings
  const warnings = {
    ...(doctorId && doctorId.length ? { doctorId } : {}),
    ...(startsAt && startsAt.length ? { startsAt } : {}),
  }
  return warnings
}

export const useCheckAppointmentWarnings = appointment => {
  const values = getAppointmentFormat(appointment)

  const { data, loading, error } = useQuery(AppointmentWarnings, {
    variables: values,
  })

  const warnings = getWarnings(data)

  return useMemo(() => ({ warnings, loading, error }), [
    warnings,
    loading,
    error,
  ])
}
