import React from 'react'
import Modal from '@/v2/components/common/Modal'
import PlanForm from './components/PlanForm'
import { useCreatePaymentState } from './components/CreatePaymentStateContext'
import PlanConfirm from './components/PlanConfirm'

const CreatePaymentPlans = ({ isOpen, onClose, patient }) => {
  const [state, setState] = useCreatePaymentState()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={'Create Payment'}
      footer={<></>}
      disableBackdropClick={true}
      maxWidth="xs"
      fullWidth
    >
      {state.step === 2 ? (
        <PlanConfirm
          patient={patient}
          onClose={onClose}
          state={state}
          setState={setState}
        />
      ) : (
        <PlanForm
          patient={patient}
          state={state}
          setState={setState}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}

export default CreatePaymentPlans
