import * as yup from 'yup'

const claimSchema = yup.object().shape({
  insuranceId: yup.string().required('Insurance is required'),
  subscriber: yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    dob: yup.string().required('The date of birth is required'),
    gender: yup.string().required('Gender is required'),
    relationship: yup.string().required('Relationship is required'),
    memberId: yup.string().required('Member ID is required'),
    sequenceCode: yup.string().required('Sequence code is required'),
  }),
})

export const validateClaim = async values => {
  try {
    await claimSchema.validate(values, { abortEarly: false })
    return null
  } catch (err) {
    const errors = {}
    err.inner.forEach(error => {
      errors[error.path] = error.message
    })
    return errors
  }
}
