import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { format, parseISO } from 'date-fns'
import { capitalize } from '@/v2/utils/helpers'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const Wrapper = styled.div`
  display: flex;
  column-gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`

const formatString = string => capitalize(string).replace('_', ' ')

const getStatus = (status, dueDate, paidAt, overdue, payments, theme) => {
  const lastPayment = payments.slice(-1)[0]
  const lastPaymentDeclined = lastPayment?.status === 'declined'
  const lastErrorMessage = lastPayment?.lastErrorMessage

  switch (status) {
    case 'declined':
      return {
        status: 'Awaiting Payment (Declined)',
        color: theme.error.dark,
        warning: lastErrorMessage,
      }
    case 'capturing':
      return {
        status: 'Processing',
        color: theme.gray.dark,
        warning: "The customer's bank is processing this payment",
      }
    case 'awaiting_payment':
      if (overdue) {
        return { status: 'Overdue', color: theme.error.dark }
      } else if (lastPaymentDeclined) {
        return {
          status: 'Awaiting Payment (Declined)',
          color: theme.error.dark,
          warning: lastErrorMessage,
        }
      } else {
        return { status: formatString(status), color: theme.link.main }
      }
    case 'awaiting_process':
      return { status: formatString(status), color: theme.gray.dark }
    case 'payment_confirmed':
      return { status: 'Paid', color: theme.green.main, paidAt: paidAt }
    case 'partially_refunded':
      return {
        status: 'Partially Refunded',
        color: theme.green.main,
        dueDate: paidAt,
      }
    case 'refunded':
      return { status: 'Refunded', color: theme.error.dark, dueDate: paidAt }
    case 'canceled':
      return { status: 'Canceled', color: theme.error.dark }
    case 'voided':
      return { status: 'Voided', color: theme.gray.dark }
    default:
      return { status: 'Pending', color: theme.link.main }
  }
}

const PaymentStatus = ({ status, dueDate, paidAt, overdue, payments }) => {
  const theme = useTheme()
  const statusInfo = getStatus(
    status,
    dueDate,
    paidAt,
    overdue,
    payments,
    theme.palette
  )

  return (
    <Wrapper>
      <Typography
        color={statusInfo.color}
        sx={{ display: 'inline-block' }}
        fontSize="15px"
      >
        {statusInfo.status}
        {statusInfo?.warning && (
          <Typography
            sx={{ display: 'inline-block', marginLeft: '0.5ch' }}
            title={statusInfo.warning}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: '15px',
                fontWeight: 600,
                color: theme.palette.error.dark,
                cursor: 'help',
              }}
            />
          </Typography>
        )}
      </Typography>
      {statusInfo?.paidAt && (
        <Typography fontSize="12px" color={theme.palette.gray.dark}>
          {`(${format(parseISO(statusInfo.paidAt), DAY_IN_US_FORMAT)})`}
        </Typography>
      )}
    </Wrapper>
  )
}

export default PaymentStatus
