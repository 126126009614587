import React, { forwardRef } from 'react'
import useToggle from '@/v2/hooks/useToggle'
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessOutlined from '@mui/icons-material/ExpandLessOutlined'
import PaymentCardForm from '@/v2/components/forms/PaymentCardForm'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const AddNewCard = forwardRef(({ patientId }, ref) => {
  const { open, handleToggle } = useToggle()

  return (
    <Box
      display={'flex'}
      ref={ref}
      aria-current={open ? 'opened' : 'closed'}
      flexDirection={'column'}
      gap={2}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onClick={() => handleToggle()}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant="h4" fontWeight={500}>
          + Add New Card
        </Typography>

        {open ? (
          <ExpandLessOutlined fontSize="medium" />
        ) : (
          <ExpandMoreOutlined fontSize="medium" />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <PaymentCardForm
          reverse
          patientId={patientId}
          onSuccess={() => handleToggle()}
        />
      </Collapse>
    </Box>
  )
})

AddNewCard.displayName = 'AddNewCard'

export default AddNewCard
