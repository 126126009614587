import { useEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import { wsUrl } from '@/v2/constants/requests'

const RealTime = ({ channel, onEvent, ...connectionProps }) => {
  const { lastJsonMessage, readyState, sendMessage } = useWebSocket(wsUrl, {
    shouldReconnect: () => true,
    reconnectAttempts: 5,
    filter: ({ data }) => {
      const { type } = JSON.parse(data)
      if (type === 'disconnect') {
        console.log(JSON.parse(data))
      }
      return (
        type !== 'ping' &&
        type !== 'welcome' &&
        type !== 'confirm_subscription' &&
        type !== 'disconnect'
      )
    },
  })

  useEffect(() => {
    if (readyState === 1) {
      console.log(`WebSocket connected to ${channel}.`, connectionProps)
      sendMessage(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel,
            ...connectionProps,
          }),
        })
      )
    }
  }, [readyState])

  useEffect(() => {
    if (!lastJsonMessage) return

    const { message } = lastJsonMessage
    console.log(`${channel}:`, message)

    onEvent && onEvent(message)
  }, [lastJsonMessage])

  return null
}

export default RealTime
