import React from 'react'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'

import Modal from '@/v2/components/common/Modal'
import { useParams } from 'react-router-dom'

import { ChangePaymentMethodForm } from './components/ChangePaymentMethodForm'

const ChangePaymentMethod = ({
  isOpen,
  onClose,
  invoice,
  type,
  isInstallPayment,
}) => {
  const { patientId } = useParams()

  const { creditCards, bankAccounts } = usePatientFinancePaymentMethods({
    patientId,
  })

  // Renders title based on type
  const renderTitle = () => {
    switch (type) {
      case 'charge':
        return 'Charge now'
      case 'change':
        return 'Change Payment Method'
      default:
        return 'Change Payment Method'
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      footer={<></>}
      title={renderTitle()}
      maxWidth="xs"
      fullWidth
    >
      <ChangePaymentMethodForm
        invoice={invoice}
        onClose={onClose}
        patientId={patientId}
        creditCards={creditCards}
        bankAccounts={bankAccounts}
        type={type}
        isInstallPayment={isInstallPayment}
      />
    </Modal>
  )
}

export default ChangePaymentMethod
