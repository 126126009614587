import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import SVGInline from 'react-svg-inline'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import Typography from '@/v2/components/common/Typography'
import {
  FULL_DATETIME_FORMAT,
  PATIENT_BOTTOM_MOBILE_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
} from '@/v2/constants'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { formatPhone } from '@/v2/utils/format'
import MailIcon from '#/assets/images/v2/email.svg?inline'
import PhoneIcon from '#/assets/images/v2/phone.svg?inline'

const barsHeight =
  parseInt(PATIENT_BOTTOM_MOBILE_HEIGHT, 10) +
  parseInt(TOPBAR_MOBILE_HEIGHT, 10)

const Wrapper = styled.div`
  height: calc(100vh - ${barsHeight}px);
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 40px 36px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Info = styled.div`
  padding: 26px 20px;
  border-top: 1px solid ${({ theme }) => theme.divider.main};
  display: flex;
  align-items: center;
`

const Content = styled.div`
  margin-left: 22px;
`

const ProfileMobile = ({ patient }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Header>
        <PatientAvatar
          patient={patient}
          size="100px"
          sx={{ margin: '0 auto', mb: '10px' }}
        />
        <Typography variant="h1" fontSize="20px" lineHeight="26px">
          {patient.fullName}
        </Typography>
        {patient.nextAppointments.length > 0 && (
          <>
            <Typography bold variant="h4" lineHeight="20px">
              Next Appointment:
            </Typography>
            <Typography variant="h4" lineHeight="20px">
              Retainer Delivery
            </Typography>
            <Typography variant="h4" lineHeight="20px">
              {format(
                new Date(
                  getDateTimeWithoutTimezone(
                    patient.nextAppointments[0].startsAt
                  )
                ),
                FULL_DATETIME_FORMAT
              )}
            </Typography>
          </>
        )}
      </Header>
      <Info theme={theme.palette}>
        <SVGInline svg={MailIcon} width="22px" />
        <Content>
          <Typography variant="h2" fontSize="14px">
            Email
          </Typography>
          <Typography variant="h5" fontSize="14px">
            {patient.email}
          </Typography>
        </Content>
      </Info>
      <Info theme={theme.palette}>
        <SVGInline svg={PhoneIcon} width="20px" />
        <Content>
          <Typography variant="h2" fontSize="14px">
            Phone
          </Typography>
          <Typography variant="h5" fontSize="14px">
            {formatPhone(patient.phoneNumber)}
          </Typography>
        </Content>
      </Info>
    </Wrapper>
  )
}

export default ProfileMobile
