import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import Switch from '@/v2/components/common/Switch'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'
import { formatPhone } from '@/v2/utils/format'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const Body = styled.div`
  display: flex;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactInformation = () => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { patient, onSetPatient } = usePatientContext()

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Contact Information
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/edit/contact-info`, {
              patient,
              onSuccess: onSetPatient,
            })
          }
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Edit</Typography>
        </Button>
      </Header>
      <Body>
        <Column>
          <Typography
            variant="h6"
            sx={{
              color:
                patient.phoneNumber === null
                  ? theme.palette.error.main
                  : theme.palette.gray.dark,
            }}
          >
            MOBILE
          </Typography>
          <Typography
            bold
            fontSize="14px"
            sx={{
              mb: '16px',
              mt: '2px',
              color:
                patient.phoneNumber === null
                  ? theme.palette.error.main
                  : theme.palette.black.main,
            }}
          >
            {formatPhone(patient.phoneNumber) || 'Not informed'}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color:
                patient.email === null
                  ? theme.palette.error.main
                  : theme.palette.gray.dark,
            }}
          >
            EMAIL
          </Typography>
          <Typography
            bold
            fontSize="14px"
            sx={{
              mb: '16px',
              mt: '2px',
              color:
                patient.email === null
                  ? theme.palette.error.main
                  : theme.palette.black.main,
            }}
          >
            {patient.email || 'Not informed'}
          </Typography>
        </Column>
        <Column>
          <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
            ADDRESS
          </Typography>
          <Typography bold fontSize="14px">
            {patient.address1 || 'Not informed'} <br />
            {patient.address2 && (
              <>
                {patient.address2}
                <br />
              </>
            )}
            {`${patient.zipcode || ''} ${patient.city || ''}`} <br />
            {patient.country && (
              <>
                {patient.country} <br />
              </>
            )}
            {patient.state && (
              <>
                {patient.state} <br />
              </>
            )}
          </Typography>
        </Column>
      </Body>
      <Footer>
        <Switch
          disabled
          checked
          label="SMS alerts"
          title="Not possible to change"
        />
        <Switch
          disabled
          checked
          label="Email alerts"
          title="Not possible to change"
        />
        <Switch
          disabled
          checked={false}
          label="Newsletter/marketing communication"
          title="Not possible to change"
        />
      </Footer>
    </Paper>
  )
}

export default ContactInformation
