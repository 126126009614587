import get from 'lodash/get'
import isFinite from 'lodash/isFinite'

export const greaterOrEqual = opts => value => {
  const number = parseInt(value, 10)
  const reference = get(opts, 'reference', 0)

  if (!isFinite(number) || number < reference) {
    return get(opts, 'message', `Must be greater than or equal to ${reference}`)
  }
}

export const lessOrEqual = opts => value => {
  const number = parseInt(value, 10)
  const reference = get(opts, 'reference', 0)

  if (!isFinite(number) || number > reference) {
    return get(
      opts,
      'message',
      `The value must be less than or equal to ${reference}`
    )
  }
}

export const greaterThan = opts => value => {
  const number = parseInt(value, 10)
  const reference = get(opts, 'reference', 0)

  if (!isFinite(number) || number <= reference) {
    return get(opts, 'message', `The value must be greater than ${reference}`)
  }
}
