import React from 'react'
import Grid from '@mui/material/Grid'

const Row = ({ children }) => {
  return (
    <Grid
      display={'grid'}
      gridAutoFlow={'column'}
      columnGap={4}
      gridTemplateColumns={'1fr 1fr'}
      rowGap={4}
      width={'100%'}
    >
      {children}
    </Grid>
  )
}

export default Row
