import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { PATIENT_BOTTOM_MOBILE_HEIGHT } from '@/v2/constants'
import NavigationTabs from '../../NavigationTabs/mobile'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: ${PATIENT_BOTTOM_MOBILE_HEIGHT};
  width: 100%;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.divider.main};
`

const PatientBarMobile = () => {
  const theme = useTheme()
  const history = useHistory()

  return (
    <Wrapper theme={theme.palette}>
      <NavigationTabs />
      <SearchIcon onClick={() => history.push('/v2/search')} />
    </Wrapper>
  )
}

export default PatientBarMobile
