import React from 'react'
import SVGInline from 'react-svg-inline'
import Button from '@/v2/components/common/Button'

import IconClearworks from '#/assets/images/icons/clearworks-logo.svg?inline'

class MainBoundary extends React.Component {
  state = { error: null }

  componentDidCatch(error) {
    this.setState({ error })
  }

  renderErrorScreen() {
    const { showSVG = true } = this.props
    return (
      <div className="error-boundary wrapper">
        <div className="error-boundary content">
          {showSVG && (
            <SVGInline svg={IconClearworks} className="filled icon" />
          )}
          <h1>Something went wrong</h1>
          <p>Clearworks is currently unavailable.</p>
          <p>
            You can either reload the page or go to the{' '}
            <a href="/calendar">calendar view</a>.
          </p>
          <div>
            <Button.Primary
              label="Reload"
              onClick={() => window.location.reload(true)}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { children } = this.props
    const { error } = this.state
    const hasError = !!error

    return hasError ? this.renderErrorScreen() : children
  }
}

export default MainBoundary
