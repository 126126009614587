import React, { useEffect } from 'react'
import SelectInputField from '@/v2/components/common/SelectInputField'
import { range } from '@/v2/utils/helpers'
import { useWatch } from 'react-hook-form'
import { updateAmounts } from '@/v2/utils/helpers'

const DiscountField = ({
  register,
  errors,
  control,
  setValue,
  contract,
  ...props
}) => {
  const watch = useWatch({
    control,
    name: ['discountRate', 'amount', 'interestRate', 'installments'],
  })
  const watchDiscountRate = watch[0]
  const watchAmount = watch[1]
  const watchInterestRate = watch[2]
  const watchInstallments = watch[3]

  useEffect(() => {
    if (
      !contract &&
      (watchDiscountRate > 0 || watchInstallments > 0 || watchInterestRate > 0)
    ) {
      const res = updateAmounts({
        amount: watchAmount,
        discountRate: watchDiscountRate,
        interestRate: watchInterestRate,
        installments: watchInstallments,
      })
      setValue('amountWithDiscount', res.amountWithDiscount)
      setValue('totalDue', res.totalDue)
      setValue('installmentAmount', res.installmentAmount)
    }
  }, [
    contract,
    watchAmount,
    watchDiscountRate,
    watchInterestRate,
    watchInstallments,
    setValue,
  ])

  return (
    <SelectInputField
      noMargin
      label="Apply discount"
      name="discount-rate"
      id="payment-plan-discount"
      options={range(0, 50, 5).map(discount => ({
        label: `${discount}%`,
        value: discount,
      }))}
      color="secondary"
      error={errors?.discountRate?.message}
      {...register('discountRate', {
        valueAsNumber: true,
      })}
      {...props}
    />
  )
}

export default DiscountField
