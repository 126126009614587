import styled from 'styled-components'
import BadgeMUI from '@mui/material/Badge'

export const TabWrapper = styled.div`
  display: flex;
  max-width: calc(100% - 362px);
`

export const Icon = styled.div`
  width: 20px;
  margin: 0 auto;
`

export const Badge = styled(BadgeMUI)`
  margin-top: 4px;
  margin-left: 5px;
`
