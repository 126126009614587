import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import FlagCircleIcon from '@mui/icons-material/FlagCircle'
import Typography from '@/v2/components/common/Typography'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { formatPhone } from '@/v2/utils/format'
import MessageStatus from '@/v2/containers/messages/MessageStatus'
import {
  Item,
  PatientSection,
  LastMessageWrapper,
  LastMessage,
  Badge,
} from './styles'
import PatientAvatar from '@/v2/components/common/PatientAvatar'

const Conversation = ({
  id,
  flagged,
  read,
  patient,
  phoneNumber,
  lastMessage,
  unreadMessagesCount,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const { conversationId } = useParams()

  return (
    <Item
      key={id}
      theme={theme.palette}
      selected={id === conversationId}
      onClick={() => history.push(`/v2/messages/${id}`)}
    >
      <PatientAvatar
        patient={patient}
        size={50}
        sx={{ mr: '10px', width: 50, height: 50 }}
      />
      <PatientSection>
        <Typography fontSize="14px" fontWeight="500" lineHeight="18px">
          {patient?.fullName || formatPhone(phoneNumber)}
        </Typography>
        <LastMessageWrapper>
          <LastMessage>
            <Typography
              fontSize="14px"
              variant="h4"
              lineHeight="18px"
              className="last-message"
            >
              <MessageStatus
                type={lastMessage?.type}
                status={lastMessage?.deliveryStatus}
              />
              {lastMessage?.body || 'No message found'}
            </Typography>
          </LastMessage>
          {flagged && <FlagCircleIcon sx={{ fontSize: 18 }} />}
          {(!read || unreadMessagesCount > 0) && (
            <Badge theme={theme.palette}>{unreadMessagesCount || ''}</Badge>
          )}
        </LastMessageWrapper>
        <Typography
          fontSize="12px"
          variant="h4"
          lineHeight="18px"
          color={theme.palette.gray.main}
        >
          {lastMessage?.createdAt
            ? format(
                getDateTimeWithoutTimezone(lastMessage.createdAt),
                DAY_IN_US_FORMAT
              )
            : ''}
        </Typography>
      </PatientSection>
    </Item>
  )
}

export default Conversation
