import React from 'react'
import styled from 'styled-components'
import useModals from '@/v2/hooks/useModals'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import AppointmentCard from './AppointmentCard'
import { usePatientAppointments } from '@/v2/hooks/patient/usePatientAppointments'

const Wrapper = styled.div`
  width: 100%;
`
const NextAppointment = styled.div`
  margin-bottom: 22px;
`

const Appointments = ({ patient }) => {
  const { goTo } = useModals()
  const {
    nextAppointments,
    previousAppointments,
    isLoading,
  } = usePatientAppointments(patient.id)

  if (isLoading) {
    return (
      <Typography
        fontSize="12px"
        lineHeight="18px"
        sx={{ marginTop: '10px', textAlign: 'center' }}
      >
        Loading...
      </Typography>
    )
  }

  return (
    <Wrapper>
      <Typography
        fontSize="20px"
        lineHeight="26px"
        variant="h2"
        sx={{ padding: '30px 26px 22px 26px' }}
      >
        Appointments
      </Typography>
      {nextAppointments.length > 0 && (
        <NextAppointment>
          <Typography
            fontSize="12px"
            lineHeight="18px"
            sx={{ padding: '0px 30px 4px 30px' }}
          >
            NEXT APPOINTMENT
          </Typography>
          {nextAppointments.map(appointment => (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          ))}
        </NextAppointment>
      )}
      <Typography
        fontSize="12px"
        lineHeight="18px"
        sx={{ padding: '0px 30px 4px 30px' }}
      >
        PREVIOUS APPOINTMENT
      </Typography>
      {previousAppointments.map(appointment => (
        <AppointmentCard key={appointment.id} appointment={appointment} />
      ))}
      {previousAppointments.length === 0 && (
        <Typography
          fontSize="12px"
          lineHeight="18px"
          sx={{ padding: '0px 30px 4px 30px' }}
        >
          No appointments
        </Typography>
      )}
      <Button
        variant="contained"
        color="secondary"
        sx={{ m: '16px', width: 'calc(100% - 32px)' }}
        onClick={() => goTo('/v2/event/new', { initialValues: { patient } })}
      >
        New Appointment
      </Button>
    </Wrapper>
  )
}

export default Appointments
