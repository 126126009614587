import React from 'react'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import TextField from '@/v2/components/common/TextField'
import { RELATIONSHIP_OPTIONS } from '@/v2/constants/insurance'
import PayerSearch from '@/v2/components/common/PayerSearch'

const formatOptions = array =>
  array.map(item => ({
    value: item,
    label: item.charAt(0).toUpperCase() + item.slice(1),
  }))

const Form = ({
  values = {
    groupNumber: '',
    payerId: null,
    relationship: '',
    memberId: '',
  },
  error = {},
  onChange,
  disableFields = false,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <SelectField
        fullWidth
        noMargin
        name="relationship"
        label="Patient relationship with insurance owner"
        placeholder="Choose an option"
        options={formatOptions(RELATIONSHIP_OPTIONS)}
        value={values.relationship}
        error={error.relationship}
        onChange={value => onChange({ relationship: value })}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        name="memberId"
        label="Member ID"
        placeholder="E.g.: 123456789"
        value={values.memberId}
        error={error.memberId}
        onChange={value => onChange({ memberId: value })}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        name="groupNumber"
        label="Group Number"
        placeholder="E.g.: 123456789"
        value={values.groupNumber}
        error={error.groupNumber}
        onChange={value => onChange({ groupNumber: value })}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12}>
      {disableFields ? (
        <TextField
          fullWidth
          name="payer"
          label="Payer"
          value={values.payer}
          disabled={true}
        />
      ) : (
        <PayerSearch
          onChange={value => onChange({ payerId: value.id })}
          error={error.payerId}
          disabled={disableFields}
        />
      )}
    </Grid>
  </Grid>
)

export default Form
