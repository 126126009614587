const primary = '#eeeded'
const background = '#ffffff'
const link = '#0074FE'
const black = '#111111'
const darkGrey = '#7D7D7D'
const red = '#EB001B'
const blackLabel = '#323232'
const successDark = '#05AD48'
const statusButtonSize = '24px'
const mediumButtonSize = '30px'
const error = '#FC7777'
const warning = '#f08249'
const info = '#D8D8D8'

export const themeOptions = {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '& .MuiAccordionSummary-content *': {
            fontWeight: 500,
          },
          '&:hover .show-on-hover': {
            display: 'block',
          },
          '&.Mui-expanded': {
            '& .show-on-open': {
              display: 'block',
            },
          },
        },
      },
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          color: primary,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: '0.5px',
          minWidth: '120px',
          textTransform: 'capitalize',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.patient-messages-button': {
            fontWeight: 500,
          },
          '&.today-button, &.week-button, &.month-button': {
            textTransform: 'capitalize !important',
          },
        },
        outlined: {
          color: black,
          borderColor: primary,
          paddingTop: '7px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: primary,
          },
        },
        contained: {
          paddingTop: '7px',
          boxShadow: 'none',
        },
        contrast: {
          color: black,
          backgroundColor: background,
          paddingTop: '7px',
          fontWeight: '400',
          '&:hover': {
            backgroundColor: primary,
          },
        },
        text: {
          color: black,
          background: 'transparent',
          fontSize: '14px',
          minWidth: '10px',
          padding: '4px 5px 0px 5px',
          marginLeft: '-5px',
          marginTop: '-3px',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'transparent',
            color: `${black}80`,
          },
        },
      },
      variants: [
        {
          props: { color: 'link' },
          style: {
            color: link,
            '&:hover': {
              color: link,
            },
          },
        },
        {
          props: { color: 'error', variant: 'text' },
          style: {
            color: red,
            fontSize: '14px',
            '&:hover': {
              color: red,
            },
          },
        },
        {
          props: { variant: 'action' },
          style: {
            padding: '0px',
            minWidth: '80px',
            color: blackLabel,
            background: 'transparent',
            border: `1px solid ${darkGrey}`,
            fontWeight: 300,
            fontSize: '12px',
            height: statusButtonSize,

            '&.no-show, &.rescheduled': {
              color: background,
              background: blackLabel,
              fontWeight: 500,
            },

            '&.confirmed': {
              minWidth: '85px',
              color: background,
              background: successDark,
              borderColor: blackLabel,
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'row',
              gap: '2px',
              '&:disabled': {
                color: background,
                border: 'none',
              },
            },

            '&:hover': {
              border: `1px solid ${blackLabel}`,
              background: 'transparent',
              color: blackLabel,
              fontWeight: 300,
            },
          },
        },
        {
          props: { variant: 'profile' },
          style: {
            color: blackLabel,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: '300',
            fontSize: '12px',
            width: '50%',
            border: '1px solid #e7e7e7',
            height: mediumButtonSize,
          },
        },
      ],
    },
    MuiChip: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: '10px',
          textTransform: 'uppercase',
          height: '16px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          color: black,
          '&:hover': {
            backgroundColor: primary,
          },
        },
      },
    },
    MuiModal: {
      defaultProps: {
        disableAutoFocus: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontSize: '15px',
          fontWeight: 400,
          minWidth: 'inherit',
          color: black,
          '&.Mui-selected': {
            color: black,
            fontWeight: 500,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.patient-navigation .MuiTab-root': {
            fontWeight: 500,
          },
          '& .MuiTabs-indicator': {
            backgroundColor: black,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '8px 8px 6px 14px',
          fontSize: '14px',
          lineHeight: '16px !important',
          '&::placeholder': {
            fontSize: '14px',
          },
        },
        notchedOutline: {
          borderColor: primary,
          borderWidth: '1px !important',
        },
        root: {
          height: '38px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#dfdfdf',
          },
        },
        multiline: {
          height: 'inherit',
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          },
        },
      ],
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: primary,
    },
    success: {
      main: '#BDDC8B',
      dark: '#05AD48',
    },
    error: {
      main: error,
      dark: red,
    },
    secondary: {
      main: '#076AFF',
    },
    background: {
      default: background,
    },
    warning: {
      main: warning,
    },
    fieldLabel: {
      main: darkGrey,
    },
    calendar: {
      main: '#F9F9F9',
      border: '1px solid #D4D4D4',
      contrastText: black,
      red: '#FC7777',
      yellow: '#FEE591',
      green: '#BDDC8B',
    },
    tab: {
      main: black,
    },
    checkin: {
      main: black,
    },
    topbarIconBorder: {
      main: 'transparent',
    },
    divider: {
      main: '#EEEDED',
    },
    transparent: {
      main: 'transparent',
    },
    black: {
      label: '#323232',
      main: black,
      dark: black,
    },
    white: {
      main: '#ffffff',
      dark: '#ffffff',
    },
    messageBalloon: {
      main: '#C0DCFE',
    },
    link: {
      main: link,
      label: link,
    },
    yellow: {
      main: '#F9D96B',
      note: '#FFF6BE',
      label: '#FFB800',
    },
    red: {
      label: red,
      main: red,
    },
    green: {
      note: '#E7F2CF',
      main: '#63B662',
    },
    aqua: {
      main: '#92F7EC',
    },
    gray: {
      main: '#C4C4C4',
      light: '#F4F4F4',
      default: '#D8D8D8',
      dark: darkGrey,
      label: darkGrey,
      info: info,
    },
    silver: {
      main: '#F4F4F4',
      dark: '#E7E7E7',
    },
    // Labels and appointments colors
    ivory: {
      type: '#f4f0e8',
      label: '#bbad8f',
    },
    blush: {
      type: '#f7e9e3',
      label: '#ca8f88',
    },
    cherry: {
      type: '#ffdede',
      label: '#d15252',
    },
    peach: {
      type: '#fff0dd',
      label: '#f1864b',
    },
    mustard: {
      type: '#fff9ce',
      label: '#dcb52b',
    },
    lemon: {
      type: '#eef7e1',
      label: '#799a4f',
    },
    turquoise: {
      type: '#e3f4f7',
      label: '#66adb6',
      medicalAlert: '#2BBEA4',
    },
    water: {
      type: '#daf0ff',
      label: '#5ea3e7',
    },
    lavender: {
      type: '#e8dff9',
      label: '#6a7bd8',
    },
    violet: {
      type: '#efdeff',
      label: '#9779cc',
    },
    rose: {
      type: '#ffe8fe',
      label: '#dc92c3',
      medicalAlert: '#D196C0',
    },
    indigo: {
      type: '#dae3ee',
      label: '#77819c',
      medicalAlert: '#265697',
    },
  },
  typography: {
    fontFamily: 'NeueHaasDisplay',
    letterSpacing: '0.5px',
    label: {
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      display: 'block',
      color: darkGrey,
    },
    error: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      color: error,
    },
    warning: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      color: warning,
    },
    info: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '400',
      color: info,
    },
    body: {
      fontSize: '14px',
      letterSpacing: '0.5px',
    },
    body1: {
      letterSpacing: '0.5px',
    },
    body3: {
      fontSize: '12px',
      letterSpacing: '0.5px',
    },
    h1: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '0.5px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    h6: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '15px',
      letterSpacing: '0.5px',
    },
    h7: {
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    tabs: {
      fontWeight: 500,
    },
  },
}
