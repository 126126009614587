import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import RealTime from './RealTime'

const RTConversations = () => {
  const queryClient = useQueryClient()

  const updateConversation = newData => {
    const queryKey = ['Conversation', `${newData.id}`]

    queryClient.setQueryData(queryKey, conversation => {
      if (!conversation) return conversation
      return { ...conversation, ...newData }
    })
  }

  const updateConversations = (open, newData) => {
    queryClient.setQueryData(
      ['Conversations', open ? 'open' : 'closed', ''],
      conversations => {
        const { pageParams, pages = [] } = { ...conversations }
        return {
          pageParams,
          pages: pages.map(page => {
            const { records, ...others } = page
            const updatedRecords = records.map(record =>
              record.id === newData.id ? { ...record, ...newData } : record
            )
            return { ...others, records: updatedRecords }
          }),
        }
      }
    )
  }

  const addConversation = (status = 'open', newData) => {
    queryClient.setQueryData(['Conversations', status, ''], conversations => {
      const { pageParams, pages = [] } = { ...conversations }
      return {
        pageParams,
        pages: pages.map(page => {
          const { records, ...others } = page
          const filteredRecords = records.filter(
            record => record.id !== newData.id
          )
          return {
            ...others,
            records: [newData, ...filteredRecords],
          }
        }),
      }
    })
  }
  const removeConversation = (status = 'open', newData) => {
    queryClient.setQueryData(['Conversations', status, ''], conversations => {
      const { pageParams, pages = [] } = { ...conversations }
      return {
        pageParams,
        pages: pages.map(({ records, ...others }) => ({
          ...others,
          records: records.filter(({ id }) => id !== newData.id),
        })),
      }
    })
  }

  const handleEvent = ({ action, subject, data }) => {
    if (action === 'update') {
      switch (subject) {
        case 'opened':
          updateConversation(data)
          removeConversation('closed', data)
          addConversation('open', data)
          break
        case 'closed':
          updateConversation(data)
          removeConversation('open', data)
          addConversation('closed', data)
          break
        case 'readed':
        case 'unreaded':
        case 'flagged':
        case 'unflagged':
          updateConversation(data)
          updateConversations(data.open, data)
          break
        case 'new_message_reminder':
        case 'opened_and_unread':
          if (data.unreadMessagesCount > 1) {
            queryClient.invalidateQueries({
              queryKey: ['Conversation', `${data.id}`],
              refetchType: 'all',
            })
          } else {
            updateConversation(data)
          }
          removeConversation('open', data)
          addConversation('open', data)
          break
        default:
          updateConversation(data)
          removeConversation('open', data)
          addConversation('open', data)
          break
      }
    }
  }

  return <RealTime channel="Chat::ConversationsChannel" onEvent={handleEvent} />
}

export default RTConversations
