import validate from '@/v2/validations/validate'
import { required, isNotEmpty } from '@/v2/validations/validators/general'
import { greaterOrEqual } from '@/v2/validations/validators/numbers'
import { valid as validTime } from '@/v2/validations/validators/time'

import { validators as patientValidators } from '@/v2/components/forms/Patient/validator'

const validator = values => {
  const { patientType, virtual } = values

  return validate(values, {
    doctorId: virtual ? required({ message: 'Choose a doctor' }) : null,
    typeId: required({ message: 'Pick an appointment type' }),
    roomId: virtual ? null : required({ message: 'Pick a room' }),
    startsAtDate: [required({ message: 'Date is required' })],
    startsAtTime: [
      required({ message: 'Time is required' }),
      validTime({ message: 'Invalid time' }),
    ],
    duration: [
      required({ message: 'Duration is required' }),
      greaterOrEqual({ reference: 15 }),
    ],
    registeredPatient:
      patientType === 'registered' &&
      isNotEmpty({ message: 'Choose a patient' }),
    newPatient: patientType === 'new' && patientValidators,
    contactMethod: virtual
      ? required({ message: 'Choose a contact method' })
      : null,
  })
}

export default validator
