import React from 'react'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

const Cell = styled.div`
  display: flex;
  flex-direction: column;
`

const PaidWith = ({ invoice }) => {
  const theme = useTheme()
  const filteredSet = new Set()

  invoice.payments.forEach(payment => {
    if (payment.paymentMethod === 'credit_card') {
      filteredSet.add(`Card (${payment.last4})`)
    } else if (payment.paymentMethod === 'ach') {
      filteredSet.add(`ACH (${payment.last4})`)
    } else {
      filteredSet.add(payment.paymentMethod)
    }
  })

  return (
    <Grid item xs={1}>
      <Typography
        fontSize="10px"
        lineHeight="23px"
        color={theme.palette.gray.dark}
      >
        PAID WITH
      </Typography>
      <Cell>
        {Array.from(filteredSet).length === 0 ? (
          <Typography fontSize="15px">None</Typography>
        ) : (
          Array.from(filteredSet).map((method, index) => (
            <Typography
              fontSize="15px"
              key={`payment-method-index-${index}`}
              sx={{ textTransform: 'capitalize' }}
            >
              {method}
            </Typography>
          ))
        )}
      </Cell>
    </Grid>
  )
}

export default PaidWith
