import { useQuery } from '@tanstack/react-query'
import { baseURL, STALE_TIME } from '@/v2/constants/requests'

export const useFindPatient = query => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['FindPatient', query || ''],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients?query=${query}&limit=10`
      )
      if (!res.ok) {
        throw new Error('We could not find a patient')
      }
      return res.json()
    },
    enabled: !!query,
    staleTime: STALE_TIME,
  })

  return { patients: data, isLoading, isError }
}

export default useFindPatient
