import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { convertDateToMinutes } from '@/v2/utils/convert'
import getDayInfo from '@/v2/graphql/queries/DaySchedule.gql'

export const useLoadData = date => {
  const { data, loading, error } = useQuery(getDayInfo, {
    variables: { date },
  })

  const appointments = data?.daySchedule?.appointments || []
  const timeOffs = data?.daySchedule?.timeOffs || []
  const clinicHours = data?.daySchedule?.hours || {}

  const startHour = loading
    ? null
    : getFirstHour(appointments, timeOffs, clinicHours)
  const endHour = loading
    ? null
    : getLastHour(appointments, timeOffs, clinicHours)

  return useMemo(
    () => ({
      loading,
      error,
      appointments,
      timeOffs,
      clinicHours,
      startHour,
      endHour,
    }),
    [loading, error, appointments, timeOffs, clinicHours, startHour, endHour]
  )
}

export const getFirstHour = (appointments, timeOffs, clinicHours) => {
  if (!clinicHours.isOpen) return 540

  const eventsStarts = [
    appointments[0] ? convertDateToMinutes(appointments[0].startsAt) : 540,
    timeOffs[0] ? convertDateToMinutes(timeOffs[0].startsAt) : 540,
  ]
  const hours = clinicHours.isOpen
    ? [...eventsStarts, convertDateToMinutes(clinicHours.opensAt)]
    : eventsStarts
  const sort = hours.sort((a, b) => (a > b ? 1 : -1))

  return sort[0] % 60 !== 0 ? sort[0] - (sort[0] % 60) : sort[0]
}

export const getLastHour = (appointments, timeOffs, clinicHours) => {
  if (!clinicHours.isOpen) return 1080

  const eventsStarts = [
    appointments[appointments.length - 1]
      ? convertDateToMinutes(appointments[appointments.length - 1].startsAt)
      : 1080,
    timeOffs[timeOffs.length - 1]
      ? convertDateToMinutes(timeOffs[timeOffs.length - 1].startsAt)
      : 1080,
  ]
  const hours = clinicHours.isOpen
    ? [...eventsStarts, convertDateToMinutes(clinicHours.closesAt)]
    : eventsStarts
  const sort = hours.sort((a, b) => (a < b ? 1 : -1))

  return sort[0] % 60 !== 45 ? sort[0] + 45 - (sort[0] % 60) : sort[0]
}
