import React from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Check from '@mui/icons-material/Check'

const ActionButton = ({ loading = false, done = false, ...other }) => {
  if (done) {
    return (
      <Button {...other} disabled>
        <Check />
      </Button>
    )
  } else if (loading) {
    return (
      <Button endIcon={<CircularProgress size={20} color="info" />} {...other}>
        Processing
      </Button>
    )
  } else {
    return <Button {...other} />
  }
}

export default ActionButton
