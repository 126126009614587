import { baseURL, headers } from '@/v2/constants/requests'
import { useQuery } from '@tanstack/react-query'
import { format, isDate } from 'date-fns'
import { DATE_PARAM_FORMAT } from '@/v2/constants'

const useCalendarOccupancy = ({ from: fromParam, to: toParam }) => {
  const from = format(fromParam, DATE_PARAM_FORMAT)
  const to = format(toParam, DATE_PARAM_FORMAT)

  const queryKey = ['calendarOccupancy', from, to]

  const { data: occupancy, isLoading, isError, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/calendars/occupancy?from=${from}&to=${to}`,
        {
          headers,
        }
      )
      if (!res.ok) {
        throw new Error('We could not load the calendar occupancy')
      }

      const data = await res.json()
      return data
    },
    enabled: isDate(fromParam) && isDate(toParam),
  })

  return { occupancy, isLoading, isError, refetch }
}

export default useCalendarOccupancy
