import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TabsMui from '@mui/material/Tabs'
import TabMui from '@mui/material/Tab'
import styled from 'styled-components'

const Tabs = styled(TabsMui)`
  margin-right: 25px;
  display: inline-flex;

  && {
    min-height: auto;
  }
`
const Tab = styled(TabMui)`
  && {
    padding: 0;
    min-height: auto;
    margin: 2px 22px 2px 0px;
  }
`

const NavigationTabs = () => {
  const { id, view } = useParams()
  const history = useHistory()
  const [tab, setTab] = useState(view)

  const handleChange = (e, newTab) => {
    setTab(newTab)
    history.push(`/v2/patient/${id}/${newTab}`)
  }

  return (
    <Tabs
      value={tab}
      className="patient-navigation"
      onChange={handleChange}
      aria-label="nav tabs"
    >
      <Tab value="profile" label="Profile" />
      <Tab value="imaging" label="Imaging" />
    </Tabs>
  )
}

export default NavigationTabs
