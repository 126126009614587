import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@/v2/components/common/Typography'
import { formatPhone } from '@/v2/utils/format'

const CloseButton = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;

  &:hover {
    opacity: 0.6;
  }
`

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primary.main};
  border-radius: 4px;
  padding: 10px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`
const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`
const Info = styled.div``

const SelectedPatient = ({ registeredPatient, canRemove = true, onChange }) => {
  const theme = useTheme()
  return (
    <Wrapper theme={theme.palette}>
      {canRemove && (
        <CloseButton alt="Remove patient" fontSize="small" onClick={onChange} />
      )}
      <Content>
        <Typography fontSize="13px">{registeredPatient.fullName}</Typography>
        <Info>
          <Typography fontSize="13px">{registeredPatient.email}</Typography>
          <Typography fontSize="13px">
            {formatPhone(registeredPatient.phoneNumber)}
          </Typography>
        </Info>
      </Content>
    </Wrapper>
  )
}

export default SelectedPatient
