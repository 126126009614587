import styled from 'styled-components'

export const AppointmentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  align-items: center;
  margin: auto 0;
  padding: 15px 0;
  column-gap: 20px;
`

export const AppointmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`

export const LinkWrapper = styled.div`
  grid-row: 2;
  grid-column: 2;
  margin-top: 5px;

  && > a {
    text-decoration: none;
  }
`
