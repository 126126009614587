import React from 'react'
import { List } from './styles'
import Upload from './Upload'
import Typography from '@/v2/components/common/Typography'

const UploadList = ({ uploads, deleteArchive }) => {
  return (
    <List>
      {uploads?.length > 0 ? (
        uploads.map(upload => (
          <Upload
            key={upload.id}
            deleteArchive={deleteArchive}
            upload={upload}
          />
        ))
      ) : (
        <Typography
          fontWeight="300"
          wordBreak
          textAlign="center"
          width="100%"
          margin="40px 0"
          fontSize="14px"
        >
          You still don't have documents. Click on "Upload New" and upload your
          first document
        </Typography>
      )}
    </List>
  )
}

export default UploadList
