import React from 'react'
import { Controller } from 'react-hook-form'
import { CurrencyInput, Wrapper } from './styles'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'

import TooltipInfo from '../TooltipInfo'

const CurrencyInputField = ({
  name,
  control,
  defaultValue,
  fullWidth,
  label,
  noMargin = true,
  prefix,
  error,
  warning,
  info = '',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { value, onChange, ...others } = field
        const handleChange = value => {
          onChange(value)
        }

        return (
          <Wrapper
            sx={{ mb: noMargin ? '0px' : '23px' }}
            className={`${prefix ? 'prefixed' : ''}`}
            fullWidth={fullWidth}
            variant="standard"
          >
            {label && (
              <Label sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
                {label}
                <TooltipInfo info={info} />
              </Label>
            )}
            <CurrencyInput
              value={value}
              decimalsLimit={2}
              onValueChange={handleChange}
              prefix={prefix}
              {...others}
              {...rest}
            />

            {error && <Typography variant="error">{error}</Typography>}
            {warning && <Typography variant="warning">{warning}</Typography>}
          </Wrapper>
        )
      }}
    />
  )
}

export default CurrencyInputField
