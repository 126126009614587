import styled from 'styled-components'
import FormControl from '@mui/material/FormControl'

export const SearchField = styled.div`
  min-width: 200px;

  &&& .MuiInputBase-input {
    padding: 0;
  }

  && .MuiAutocomplete-endAdornment {
    display: none;
  }
`

export const Wrapper = styled(FormControl)`
  &.prefixed input {
    padding-left: 0;
  }
`
