import React from 'react'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'
import { TOPBAR_HEIGHT_NUMBER, HEADER_HEIGHT_NUMBER } from '@/v2/constants'

const numberToGetMaxHeight = TOPBAR_HEIGHT_NUMBER + HEADER_HEIGHT_NUMBER + 50

export const Wrapper = styled.div`
  max-height: calc(100vh - ${numberToGetMaxHeight}px);
  overflow: auto;
`

export const NoResult = styled.div`
  margin: 15px;
`

export const SectionTitle = ({ children, theme }) => (
  <Typography
    sx={{
      textAlign: 'center',
      display: 'block',
      marginTop: '10px',
      borderBottom: `1px solid ${theme.primary.main}`,
      paddingBottom: '10px',
    }}
  >
    {children}
  </Typography>
)
