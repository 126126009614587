import React from 'react'
import TextFieldMui from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import getRandomId from '@/v2/utils/random-id'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'

// TODO: standardize noMargin default value with the other fields
const SelectField = ({
  fullWidth,
  group,
  label,
  name,
  placeholder,
  error,
  warning,
  options,
  noMargin,
  onChange,
  ...others
}) => {
  const id = getRandomId()

  return (
    <FormControl
      sx={{ mb: noMargin ? '0px' : '23px' }}
      variant="standard"
      fullWidth={fullWidth}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <TextFieldMui
        select
        id={id}
        name={name}
        SelectProps={{ native: true }}
        onChange={event => onChange && onChange(event.target.value)}
        {...others}
      >
        {placeholder && (
          <option aria-label={placeholder} value="">
            {placeholder}
          </option>
        )}
        {group
          ? options.map(
              group =>
                group.options.length > 0 && (
                  <optgroup key={group.label} label={group.label}>
                    {group.options.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </optgroup>
                )
            )
          : options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
      </TextFieldMui>
      {error && <Typography variant="error">{error}</Typography>}
      {warning && <Typography variant="warning">{warning}</Typography>}
    </FormControl>
  )
}

export default SelectField
