import { useQuery } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const useMessages = (conversationId, page = 1) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Messages', conversationId, page],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/conversations/${conversationId}/messages?page=${page}`
      )
      if (!res.ok) {
        throw new Error('We could not find messages')
      }
      const result = await res.json()

      return result
    },
    enabled: !!conversationId,
  })

  return { messageHistory: data, isLoading, isError }
}

export const useSendMessage = conversationId => {
  const { mutate: sendMessage, ...others } = requestMiddleware({
    key: ['SendMessage', conversationId],
    request: async message =>
      await fetch(
        `${baseURL}/v2/rest/conversations/${conversationId}/messages`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ message: { body: message } }),
        }
      ),
  })

  return { sendMessage, ...others }
}

export const useDeleteMessage = (conversationId, messageId) => {
  const { mutate: deleteMessage, ...others } = requestMiddleware({
    key: ['DeleteMessage', conversationId, messageId],
    request: async () =>
      await fetch(`${baseURL}/v2/rest/messages/${messageId}`, {
        method: 'DELETE',
        headers,
      }),
  })

  return { deleteMessage, ...others }
}
