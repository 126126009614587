import React from 'react'
import styled from 'styled-components'
import { TOPBAR_HEIGHT } from '@/v2/constants'
import Skeleton from '@mui/material/Skeleton'
import { Wrapper } from '../PatientBar/styles'
import {
  AppointmentDetails,
  AppointmentWrapper,
  LinkWrapper,
} from '../NextAppointment/styles'
import { useTheme } from '@mui/material/styles'

const PatientWrapper = styled.div`
  display: block;
  max-height: calc(100vh - ${TOPBAR_HEIGHT});
  height: 100%;
  overflow: hidden;
`

const ViewLoader = () => {
  const theme = useTheme()
  return (
    <PatientWrapper>
      <Wrapper theme={theme.palette}>
        <AppointmentWrapper>
          <Skeleton variant="circular" width={75} height={75} />
          <AppointmentDetails width="320px">
            <Skeleton height="1.5rem" width="100px" />
            <Skeleton width="260px" />
            <Skeleton width="160px" />
            <LinkWrapper>
              <Skeleton height="1.5rem" width="80px" />
            </LinkWrapper>
          </AppointmentDetails>
        </AppointmentWrapper>
      </Wrapper>
    </PatientWrapper>
  )
}

export default ViewLoader
