import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useShowClaim } from '@/v2/hooks/insurance/useClaims'
import { AccordionStyled, ContentWrapper } from './styles'
import Payer from './items/Payer'
import Subscriber from './items/Subscriber'
import Providers from './items/Providers'
import Procedures from './items/Procedures'

const Show = ({ claimId, insuranceId }) => {
  const { claimInfo, isLoading, isError } = useShowClaim(insuranceId, claimId)
  const [expandedPanel, setExpandedPanel] = useState('insurance')
  const [status, setStatus] = useState('')
  const [providers, setProviders] = useState([])
  const [subscriber, setSubscriber] = useState({})
  const [procedures, setProcedures] = useState([])
  const [insurance, setInsurance] = useState({})

  const formatStatus = status => {
    if (!status) return status
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  useEffect(() => {
    if (claimInfo) {
      const { status, providers, subscriber, items, insurance } = claimInfo
      setStatus(status)
      setProviders(providers)
      setSubscriber(subscriber)
      setProcedures(items)
      setInsurance(insurance)
    }
  }, [claimInfo])

  if (isLoading && !isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          Loading claim...
        </Typography>
      </ContentWrapper>
    )

  if (isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          It wasn't possible to load the claim.
        </Typography>
      </ContentWrapper>
    )

  return (
    <ContentWrapper>
      <Typography variant="body1" mb={2} style={{ fontWeight: 'bold' }}>
        Status: {formatStatus(status)}
      </Typography>{' '}
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'insurance'}
        noBackground
        onChange={() =>
          setExpandedPanel(expandedPanel === 'insurance' ? null : 'insurance')
        }
        title={<Typography>Insurance</Typography>}
        content={<Payer insurance={insurance} />}
      />
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'subscriber'}
        noBackground
        onChange={() =>
          setExpandedPanel(expandedPanel === 'subscriber' ? null : 'subscriber')
        }
        title={<Typography>Subscriber</Typography>}
        content={<Subscriber subscriber={subscriber} />}
      />
      {!!providers.length && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'provider'}
          noBackground
          onChange={() =>
            setExpandedPanel(expandedPanel === 'provider' ? null : 'provider')
          }
          title={<Typography>Providers</Typography>}
          content={<Providers providers={providers} />}
        />
      )}
      {!!procedures.length && (
        <AccordionStyled
          noPaddingTitle
          expanded={expandedPanel === 'procedures'}
          noBackground
          onChange={() =>
            setExpandedPanel(
              expandedPanel === 'procedures' ? null : 'procedures'
            )
          }
          title={<Typography>Procedures</Typography>}
          content={<Procedures procedures={procedures} />}
        />
      )}
    </ContentWrapper>
  )
}

export default Show
