import React from 'react'
import StripeElements from '@/v2/components/common/StripeElements'
import CardForm from './CardForm'

const PaymentCardForm = props => {
  return (
    <StripeElements>
      <CardForm {...props} />
    </StripeElements>
  )
}

export default PaymentCardForm
