import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'

const getThemeColor = (color, theme) =>
  ({
    red: theme.error.main,
    yellow: theme.yellow.main,
    green: theme.success.dark,
    gray: theme.gray.main,
  }[color])

const Wrapper = styled.div`
  border-radius: 3px;
  padding: 2px 12px;
  width: fit-content;
  background: ${({ theme, color = 'gray' }) => getThemeColor(color, theme)};
  color: ${({ theme }) => theme.background.default};
`

const Tag = ({ color, label }) => {
  const theme = useTheme()
  return (
    <Wrapper color={color || 'gray'} theme={theme.palette}>
      <Typography fontSize="10px">{label}</Typography>
    </Wrapper>
  )
}

export default Tag
