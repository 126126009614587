import React from 'react'
import styled from 'styled-components'
import InstallmentsPayment from '../InstallmentsPayment'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const PaymentPlans = ({ plans, paymentMethods, patientId }) => {
  return (
    <Wrapper>
      {plans.map(plan => (
        <InstallmentsPayment
          paymentMethods={paymentMethods}
          key={`plan-${plan.id}`}
          plan={plan}
          patientId={patientId}
        />
      ))}
    </Wrapper>
  )
}

export default PaymentPlans
