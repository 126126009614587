import * as yup from 'yup'

const eligibilitySchema = yup.object().shape({
  providerNPI: yup.string().required('Provider is required'),
  insuranceId: yup.string().required('Insurance is required'),
})

export const validateEligibility = async values => {
  try {
    await eligibilitySchema.validate(values, { abortEarly: false })
    return null
  } catch (err) {
    const errors = {}
    err.inner.forEach(error => {
      errors[error.path] = error.message
    })
    return errors
  }
}
