import React from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import FailedIcon from '@mui/icons-material/RemoveCircle'
import MessageBalloon from '@/v2/components/common/MessageBalloon'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import MessageStatus from '@/v2/containers/messages/MessageStatus'
import {
  useSendMessage,
  useDeleteMessage,
} from '@/v2/hooks/messages/useMessages'
import { getSentAt } from './utils'
import {
  MessageWrapper,
  MessageBody,
  MessageFooter,
  ActionButtons,
} from './styles'

const Message = ({ message }) => {
  const theme = useTheme()
  const { conversationId } = useParams()
  const { id, body, user, type, deliveryStatus: status, createdAt } = message
  const side = type === 'Incoming' ? 'left' : 'right'
  const { sendMessage } = useSendMessage(conversationId)
  const { deleteMessage } = useDeleteMessage(conversationId, id)

  const handleDelete = () => {
    deleteMessage()
  }

  const handleResend = () => {
    handleDelete()
    sendMessage(body)
  }

  return (
    <MessageWrapper $align={side}>
      <MessageBody $align={side}>
        <MessageBalloon
          color={side === 'right' ? 'silver' : 'messageBalloon'}
          direction={side}
        >
          <Typography
            fontSize="14px"
            dangerouslySetInnerHTML={{
              __html: body.replace(/\n/g, '<br/>'),
            }}
          />
        </MessageBalloon>
        {status === 'failed' && (
          <FailedIcon
            sx={{
              fontSize: '15px',
              alignSelf: 'center',
              color: theme.palette.error.main,
            }}
          />
        )}
      </MessageBody>
      <MessageFooter>
        <Typography fontSize="12px" sx={{ mx: '10px' }}>
          <MessageStatus type={type} status={status} />
          {status === 'failed'
            ? 'Message not sent'
            : getSentAt(type, createdAt, user)}
        </Typography>
        {status === 'failed' && (
          <ActionButtons>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleResend()}
            >
              Resend
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </ActionButtons>
        )}
      </MessageFooter>
    </MessageWrapper>
  )
}

export default Message
