import React from 'react'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import useModals from '@/v2/hooks/useModals'
import SeatPatient from '@/v2/modals/check-in'
import Event from '@/v2/modals/event'
import AppointmentDetails from '@/v2/modals/appointment/AppointmentDetails'
import AppointmentEdit from '@/v2/modals/appointment/AppointmentEdit'
import TimeOffEdit from '@/v2/modals/time-off/Edit'
import NewPatient from '@/v2/modals/new-patient'
import CreateContract from '@/v2/modals/patient-documents/CreateContract'
import ContractDetails from '@/v2/modals/patient-documents/ContractDetails'
import ConsentFormDetails from '@/v2/modals/patient-documents/ConsentFormDetails'
import ConsentFormConfirmation from '@/v2/modals/patient-documents/ConsentFormConfirmation'
import ManageCards from '@/v2/modals/patient-finance/ManageCards'
import ManageAccounts from '@/v2/modals/patient-finance/ManageAccounts'
import Receipts from '@/v2/modals/patient-finance/Receipts'
import CreatePaymentPlans from '@/v2/modals/patient-finance/CreatePaymentPlans'
import RefundPayment from '@/v2/modals/patient-finance/RefundPayment'
import DeclinePayment from '@/v2/modals/patient-finance/DeclinePayment'
import NewPatientNotes from '@/v2/modals/new-patient-note'
import EditPatientNotes from '@/v2/modals/edit-patient-note'
import EditPatientBasicInfo from '@/v2/modals/edit-patient-basic-info'
import EditPatientContactInfo from '@/v2/modals/edit-patient-contact-info'
import EditPatientEmergencyContact from '@/v2/modals/edit-patient-emergency-contact'
import CropImage from '@/v2/modals/patient-imaging/CropImage'
import MedicalAlerts from '@/v2/modals/edit-patient-medical-alerts'
import { CreatePaymentProvider } from '@/v2/modals/patient-finance/CreatePaymentPlans/components/CreatePaymentStateContext'
import ChangePaymentMethod from '@/v2/modals/patient-finance/ChangePaymentMethod'
import AdvancedPayment from '@/v2/modals/patient-finance/AdvancedPayment'
import { CreateContractProvider } from '@/v2/modals/patient-documents/CreateContract/CreateContractStateContext'
import InsuranceForm from '@/v2/modals/insurance'
import EligibilityForm from '@/v2/modals/eligibility'
import ClaimForm from '@/v2/modals/claim'

const ModalRoutes = () => {
  const Modals = useModals()
  const location = useLocation()
  const isOpen = location.pathname && location.pathname !== '/'

  return (
    <Switch>
      <Route
        path="/v2/event/new"
        render={({ match, location }) => (
          <Event
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state?.initialValues || {})}
            {...location.state?.onCreate}
          />
        )}
      />
      <Route
        path="/v2/appointment/:id/edit"
        render={({ match }) => (
          <AppointmentEdit
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
            onEdit={location.state?.onEdit}
            onCancel={location.state?.onCancel}
          />
        )}
      />
      <Route
        path="/v2/appointment/:id"
        render={({ match, location }) => (
          <AppointmentDetails
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state?.initialValues || {})}
            onEdit={location.state?.onEdit}
            onCancel={location.state?.onCancel}
          />
        )}
      />
      <Route
        path="/v2/time-off/:id/edit"
        render={({ match, location }) => (
          <TimeOffEdit
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state?.initialValues || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/new"
        render={({ match, location }) => (
          <NewPatient
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state?.initialValues || {})}
            refetch={location.state?.refetch}
          />
        )}
      />
      <Route
        path="/v2/seat-patient/:appointmentId"
        render={({ match }) => (
          <SeatPatient
            {...match.params}
            isOpen={isOpen}
            onClose={Modals.close}
          />
        )}
      />
      <Route
        path="/v2/create-contract/:patientId"
        render={({ match, location }) => (
          <CreateContractProvider>
            <CreateContract
              isOpen={isOpen}
              onClose={Modals.close}
              {...match.params}
              {...(location.state?.initialValues || {})}
            />
          </CreateContractProvider>
        )}
      />
      <Route
        path="/v2/contract-details/:id"
        render={({ match, location }) => (
          <ContractDetails
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state?.initialValues || {})}
          />
        )}
      />
      <Route
        path="/v2/consent-form-details/:id"
        render={({ match, location }) => (
          <ConsentFormDetails
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/consent-form-confirmation"
        render={({ match, location }) => (
          <ConsentFormConfirmation
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/manage-accounts/new"
        render={({ location }) => (
          <ManageAccounts
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state?.initialValues || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/manage-cards/new"
        render={({ location }) => (
          <ManageCards
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state?.initialValues || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/receipts"
        render={({ location }) => (
          <Receipts
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state?.initialValues || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/payment-plan/new"
        render={({ location }) => (
          <CreatePaymentProvider>
            <CreatePaymentPlans
              isOpen={isOpen}
              onClose={Modals.close}
              {...(location.state?.initialValues || {})}
            />
          </CreatePaymentProvider>
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/refund-payment"
        render={({ location }) => (
          <RefundPayment
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/decline-payment"
        render={({ location }) => (
          <DeclinePayment
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/change-payment-method"
        render={({ location }) => (
          <ChangePaymentMethod
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/finance/advanced-payment"
        render={({ location }) => (
          <AdvancedPayment
            isOpen={isOpen}
            onClose={Modals.close}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/medical-alerts"
        render={({ match }) => (
          <MedicalAlerts
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/notes/new/:type?"
        render={({ match }) => (
          <NewPatientNotes
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/notes/:noteId"
        render={({ match }) => (
          <EditPatientNotes
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/edit/basic"
        render={({ match }) => (
          <EditPatientBasicInfo
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/edit/contact-info"
        render={({ match }) => (
          <EditPatientContactInfo
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/edit/emergency-contact"
        render={({ match }) => (
          <EditPatientEmergencyContact
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:patientId/crop-image/"
        render={({ match }) => (
          <CropImage
            isOpen={isOpen}
            image={location.state.image}
            seriesId={location.state.seriesId}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/insurance/new"
        render={({ match, location }) => (
          <InsuranceForm
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/eligibility/check"
        render={({ match, location }) => (
          <EligibilityForm
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/eligibility/show"
        render={({ match, location }) => (
          <EligibilityForm
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/insurance/view"
        render={({ match, location }) => (
          <InsuranceForm
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/claim"
        render={({ match, location }) => (
          <ClaimForm
            isEdit={false}
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/claim/view"
        render={({ match, location }) => (
          <ClaimForm
            isEdit
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route
        path="/v2/patient/:id/claim/edit"
        render={({ match, location }) => (
          <ClaimForm
            isEdit
            isOpen={isOpen}
            onClose={Modals.close}
            {...match.params}
            {...(location.state || {})}
          />
        )}
      />
      <Route>
        {({ location }) => {
          if (location.pathname !== '/') {
            window.location.href = location.pathname
            return null
          }
          return <Redirect to="/" />
        }}
      </Route>
    </Switch>
  )
}

export default ModalRoutes
