import React, { useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { useForm, useWatch } from 'react-hook-form'
import { range, fromCurrencyToNumber } from '@/v2/utils/helpers'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import ControlRadioField from '@/v2/components/common/ControlRadioField'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'
import DiscountField from './components/DiscountField'
import InputField from '@/v2/components/common/InputField'
import InstallmentFields from './components/InstallmentFields'
import Typography from '@/v2/components/common/Typography'
import ActionButton from '@/v2/components/common/ActionButton'
import Box from '@mui/material/Box'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Row from '@/v2/components/common/Row'
import {
  calcInstallmentAmountWithDiscount,
  updateContractAmounts,
} from '@/v2/utils/helpers'

const CreateContractForm = ({ state, setState, onClose }) => {
  const theme = useTheme()
  const errorContainerRef = useRef(null)

  const schema = z
    .object({
      classification: z.enum(['one_time_payment', 'installments']),
      description: z.string().min(3, { message: 'Description is required' }),
      amount: z
        .string()
        .transform(value => fromCurrencyToNumber(value))
        .refine(value => value > 0.5, {
          message: 'Amount must be greater than $0.50',
        }),
      downPayment: z
        .string({
          required_error:
            'Initial payment is required and must be greater than $0.00',
        })
        .optional()
        .transform(value => value && fromCurrencyToNumber(value)),
      interestRate: z
        .number()
        .min(0)
        .transform(value => value / 100),
      discountRate: z
        .number()
        .min(0)
        .transform(value => value / 100),
      amountWithDiscount: z.number().min(0),
      totalDue: z.number().min(0),
      installmentAmount: z.number().min(0),
      installments: z.number().min(0),
    })
    .transform(data => ({
      ...data,
      downPayment:
        data.classification !== 'one_time_payment' ? data.downPayment : '',
      installments:
        data.classification === 'one_time_payment' ? 1 : data.installments,
    }))
    .refine(
      data => {
        if (data.downPayment && data.amount) {
          return data.downPayment <= data.amount
        }
        return true
      },
      {
        message: 'Initial payment should not be greater than amount',
        path: ['downPayment'],
      }
    )
    .refine(
      data => {
        if (
          data.classification === 'installments' &&
          (!data.downPayment || data.downPayment === 0)
        ) {
          return false
        }
        if (data.downPayment && data.installmentAmount) {
          return (
            data.downPayment.toFixed(2) >= data.installmentAmount.toFixed(2)
          )
        }
        return true
      },
      {
        message: 'Initial payment should not be less than installment amount',
        path: ['downPayment'],
      }
    )

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: state,
    resolver: zodResolver(schema),
  })

  const onSubmit = data => {
    setState(s => ({
      ...s,
      ...data,
    }))
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()

      setState(s => {
        if (s.classification === 'one_time_payment') {
          return {
            ...s,
            ...updateContractAmounts(s),
            step: 2,
            error: null,
          }
        }
        return {
          ...s,
          step: 2,
          error: null,
        }
      })
    }
  }, [isSubmitSuccessful, setState, reset])

  useEffect(() => {
    if (state.error) {
      errorContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [state.error])

  const watch = useWatch({
    control,
    name: ['amount', 'installments', 'discountRate', 'classification'],
  })

  const onBlurChange = () => {
    if (watch[3] === 'installments' && watch[0] && watch[0] > 0) {
      setValue(
        'downPayment',
        calcInstallmentAmountWithDiscount(watch[0], watch[1], watch[2])
      )
    }
  }

  return (
    <FormControl
      component={'form'}
      onSubmit={handleSubmit(onSubmit)}
      variant="filled"
    >
      {state.error && (
        <Box
          bgcolor={theme.palette.error.dark}
          color={theme.palette.common.white}
          p={1}
          display={'flex'}
          alignItems={'center'}
          gap={1}
          ref={errorContainerRef}
        >
          <ErrorOutline />
          <Typography wordBreak>{state.error}</Typography>
        </Box>
      )}

      <ControlRadioField
        id="classification"
        control={control}
        options={[
          { label: 'Single', value: 'one_time_payment' },
          { label: 'Installments', value: 'installments' },
        ]}
        error={errors?.classification?.message}
        name="classification"
        color="secondary"
        property="value"
      />

      <InputField
        fullWidth
        label="Description"
        name="description"
        autoFocus
        placeholder="Write a description here..."
        error={errors?.description?.message}
        {...register('description')}
      />

      <Row>
        <CurrencyInputField
          label="Amount"
          name="amount"
          placeholder="$0.00"
          prefix="$"
          error={errors?.amount?.message}
          control={control}
          onBlur={onBlurChange}
        />

        <DiscountField
          noMargin
          label="Apply discount"
          name="discount-rate"
          id="payment-plan-discount"
          options={range(0, 50, 5).map(discount => ({
            label: `${discount}%`,
            value: discount,
          }))}
          color="secondary"
          errors={errors}
          register={register}
          setValue={setValue}
          control={control}
          onBlurChange={onBlurChange}
          getFieldState={getFieldState}
          setState={setState}
        />
      </Row>

      <InstallmentFields
        errors={errors}
        register={register}
        setValue={setValue}
        control={control}
        setState={setState}
        state={state}
        getFieldState={getFieldState}
        onBlurChange={onBlurChange}
      />

      <Grid container justifyContent="flex-end" gap={2}>
        <ActionButton
          variant="text"
          color="primary"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </ActionButton>
      </Grid>
    </FormControl>
  )
}

export default CreateContractForm
