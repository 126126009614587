import React from 'react'
import styled from 'styled-components'
import IconButton from '@/v2/components/common/IconButton'
import SVGInline from 'react-svg-inline'
import AddIcon from '#/assets/images/v2/plus.svg?inline'
import EditIcon from '#/assets/images/v2/edit.svg?inline'
import DeleteIcon from '#/assets/images/v2/trash-mobile.svg?inline'

const Actions = styled.div``

const SmallButton = styled(IconButton)`
  & .SVGInline {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const FolderActions = ({ onAdd, onEdit, onDelete, enableDelete }) => {
  return (
    <Actions>
      <SmallButton
        background="silver"
        bordercolor="transparent"
        onClick={onEdit}
        sx={{ marginLeft: '10px' }}
      >
        <SVGInline svg={EditIcon} width="24px" height="16px" />
      </SmallButton>
      <SmallButton
        background="silver"
        bordercolor="transparent"
        onClick={onAdd}
        sx={{ marginLeft: '10px' }}
      >
        <SVGInline svg={AddIcon} width="24px" height="24px" />
      </SmallButton>
      {enableDelete ? (
        <SmallButton
          background="silver"
          bordercolor="transparent"
          onClick={onDelete}
          sx={{ marginLeft: '10px' }}
        >
          <SVGInline svg={DeleteIcon} width="24px" height="24px" />
        </SmallButton>
      ) : null}
    </Actions>
  )
}

export default FolderActions
