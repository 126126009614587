import { format } from 'date-fns'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { TIME_IN_US_FORMAT } from '@/v2/constants'

const toForm = appointment => {
  if (!appointment) return null
  const startsAt = getDateTimeWithoutTimezone(appointment.startsAt)

  return {
    id: appointment.id,
    typeId: appointment.appointmentType.id,
    duration: appointment.duration,
    startsAtDate: startsAt,
    startsAtTime: format(startsAt, TIME_IN_US_FORMAT),
    roomId: appointment.room?.id || '',
    doctorId: appointment.doctor?.id || '',
    patientType: 'registered',
    registeredPatient: appointment.patient,
    virtual: appointment.virtual,
    reschedule: appointment?.reschedule,
    noShow: appointment.noShow,
  }
}

export default toForm
