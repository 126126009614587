import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import IconButton from '@/v2/components/common/IconButton'
import SliderButton from '@/v2/components/common/SliderButton'
import RotateDegreesIcon from '@mui/icons-material/RotateRight'
import ZoomIcon from '@mui/icons-material/ZoomIn'
import FlipIcon from '#/assets/images/v2/flip-icon.svg?inline'
import RotateIcon from '#/assets/images/v2/rotate-icon.svg?inline'
import useDetectDevice from '@/v2/hooks/useDetectDevice'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ $isMobile }) =>
    $isMobile
      ? `
        gap: 6px;
        margin-right: 12px;
        margin-left: -10px;
        width: 186px;
      `
      : 'gap: 8px;'}
`
const Icon = styled.div`
  width: 24px;
  height: 24px;
`

const Actions = ({ rotation, zoom, flip, onRotation, onZoom, onFlip }) => {
  const [openSlider, setOpenSlider] = useState(null)
  const { isMobile } = useDetectDevice()
  const theme = useTheme()

  const handleSetOpenSlider = name => {
    if (!isMobile) return
    setOpenSlider(name === openSlider ? null : name)
  }

  return (
    <Wrapper $isMobile={isMobile ? 1 : 0}>
      {openSlider === null && (
        <IconButton
          title="Rotate 90 degrees"
          onClick={() => onRotation(rotation === 180 ? -90 : rotation + 90)}
        >
          <Icon>
            <SVGInline className="rotate-icon" svg={RotateIcon} />
          </Icon>
        </IconButton>
      )}
      {openSlider === null && (
        <IconButton
          title="Flip"
          onClick={() =>
            onFlip({ horizontal: !flip.horizontal, vertical: false })
          }
        >
          <Icon>
            <SVGInline className="flip-icon" svg={FlipIcon} />
          </Icon>
        </IconButton>
      )}
      {openSlider !== 'rotation' && (
        <SliderButton
          title="Zoom"
          icon={<ZoomIcon sx={{ color: theme.palette.gray.dark }} />}
          value={zoom}
          min={1}
          max={7}
          step={0.1}
          valueLabelDisplay="off"
          onClick={() => handleSetOpenSlider('zoom')}
          onChange={value => onZoom(value)}
        />
      )}
      {openSlider !== 'zoom' && (
        <SliderButton
          title="Rotate"
          icon={<RotateDegreesIcon sx={{ color: theme.palette.gray.dark }} />}
          value={rotation}
          min={-180}
          max={180}
          valueLabelDisplay="off"
          onClick={() => handleSetOpenSlider('rotation')}
          onChange={value => onRotation(value)}
        />
      )}
    </Wrapper>
  )
}

export default Actions
