import React, { useState } from 'react'
import styled from 'styled-components'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import getRandomId from '@/v2/utils/random-id'

const Wrapper = styled(FormControl)`
  &.prefixed input {
    padding-left: 0;
  }
`

const PassField = ({
  fullWidth,
  label,
  labelAlignment = 'left',
  value = '',
  noMargin = true,
  error,
  onChange,
  ...others
}) => {
  const [show, setShow] = useState(false)
  const id = getRandomId()

  const handleChange = ({ target }) => {
    onChange(target.value)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Wrapper
      variant="standard"
      fullWidth={fullWidth}
      sx={{ mb: noMargin ? '0px' : '23px' }}
    >
      {label && (
        <Label htmlFor={id} align={labelAlignment}>
          {label}
        </Label>
      )}
      <OutlinedInput
        id={id}
        type={show ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShow(!show)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {show ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...others}
      />
      {error && <Typography variant="error">{error}</Typography>}
    </Wrapper>
  )
}
export default PassField
