import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  margin-top: 46px;
  margin-bottom: 26px;
`

const Divider = styled.div`
  width: 100%;
  margin-left: 12px;
  height: 2px;
  background-color: ${({ theme }) => theme.divider.main};
`

const DayDivider = ({ date }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Typography fontSize="12px" fontWeight="500">
        {date}
      </Typography>
      <Divider theme={theme.palette} />
    </Wrapper>
  )
}

export default DayDivider
