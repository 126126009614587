import React from 'react'
import Typography from '@/v2/components/common/Typography'
import Modal from '@/v2/components/common/Modal'
import Wrapper from '@/v2/components/common/Wrapper'
import { useConsentFormsDetails } from '@/v2/hooks/documents/useConsentForm'

const ConsentFormDetails = ({ isOpen, id, signature, onClose }) => {
  const { consentForm } = useConsentFormsDetails(id)

  return (
    <Modal
      fullWidth
      isOpen={isOpen}
      title={consentForm?.title || 'Consent Form Details'}
      confirmLabel="Close"
      showClose={false}
      onConfirm={onClose}
      onClose={onClose}
      maxWidth="md"
    >
      <Wrapper>
        <pre>
          <Typography variant="body2" sx={{ textWrap: 'balance' }}>
            {consentForm?.content || ''}
          </Typography>
        </pre>

        {signature && (
          <img src={signature} style={{ width: '140px', margin: '0 auto' }} />
        )}
      </Wrapper>
    </Modal>
  )
}

export default ConsentFormDetails
