export const RELATIONSHIP_OPTIONS = ['self', 'spouse', 'child', 'other']
export const PROVIDER_KIND_OPTIONS = [
  'Billing',
  'Rendering',
  'Referring',
  'Pay to',
]
export const SPECIALTY_OPTIONS = [
  'GP',
  'ENDO',
  'ORALMAX',
  'ORTHO',
  'PEDI',
  'PERI',
  'PROS',
  'PUBLIC',
  'HYG',
  'DTS',
  'DEN',
  'ORALMAXP',
  'ORALMAXR',
  'MSPEC',
  'SSPEC',
  'IMP',
  'OPER',
  'RESTO',
  'COS',
  'DENT',
  'ORALMAX1',
  'ORALMAXC',
]

export const providerKindOptions = PROVIDER_KIND_OPTIONS.map(item => ({
  label: item,
  value: item.replace(' ', '').toUpperCase(),
}))

export const specialtyOptions = SPECIALTY_OPTIONS.map(item => ({
  label: item,
  value: item,
}))
