import React from 'react'
import Typography from '@/v2/components/common/Typography'
import Modal from '@/v2/components/common/Modal'
import { formatCurrency } from '@/v2/utils/format'
import Wrapper from '@/v2/components/common/Wrapper'
import Box from '@mui/material/Box'
import { updateContractAmounts } from '@/v2/utils/helpers'

const ContractDetails = ({ isOpen, contract, onClose }) => {
  const contractValues = updateContractAmounts({
    amount: contract?.totalCost,
    discountRate: contract?.discountRate,
    installments: contract?.monthlyPaymentCount,
    interestRate: contract?.interestRate || 0,
    downPayment: contract?.downPayment || 0,
  })

  const installments = contractValues.updatedInstallments
    ? contractValues.updatedInstallments
    : contract.installments

  const installmentAmount = contractValues.installmentAmount

  const totalAmount = contractValues.amountWithDiscount || contractValues.amount

  const downPayment = contractValues.downPayment || 0

  return (
    <Modal
      isOpen={isOpen}
      title="Contract Details"
      confirmLabel="Close"
      showClose={false}
      onConfirm={onClose}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Box
        bgcolor={'gray.light'}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        borderRadius={2}
        px={2}
        py={2}
      >
        <Wrapper>
          <Box>
            <Typography variant="body2">Status</Typography>
            <Typography variant="body2" bold>
              {contract.signed ? 'Signed' : 'Out for sign'}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">Total Cost</Typography>
            <Typography variant="body2" bold>
              {formatCurrency(parseFloat(totalAmount).toFixed(2))}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2">Down Payment</Typography>
            <Typography variant="body2" bold>
              {formatCurrency(parseFloat(downPayment).toFixed(2))}
            </Typography>
          </Box>

          {installments > 1 && (
            <>
              <Box>
                <Typography variant="body2">Installments</Typography>
                <Typography variant="body2" bold>
                  {installments}
                </Typography>
              </Box>

              <Box>
                <Typography variant="body2">Monthly Payments</Typography>
                <Typography variant="body2" bold>
                  {formatCurrency(parseFloat(installmentAmount).toFixed(2))}
                </Typography>
              </Box>
            </>
          )}
          <Box>
            <Typography
              variant="body2"
              bold
            >{`CID# ${contract.identifierToken}`}</Typography>
          </Box>
        </Wrapper>
      </Box>
    </Modal>
  )
}

export default ContractDetails
