import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import request from 'graphql-request'
import PatientFinancePaymentMethods from './graphql/queries/PatientFinancePaymentMethods.gql'
import DeletePaymentMethod from './graphql/mutations/DeletePaymentMethod.gql'
import { baseURLGraphql, headers } from '@/v2/constants/requests'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useMemo } from 'react'

const usePatientFinancePaymentMethods = props => {
  const { patientId } = props || {}
  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()
  const key = [
    'patient-finance-payment-methods',
    baseURLGraphql,
    headers,
    PatientFinancePaymentMethods,
    patientId,
  ]

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: key,
    queryFn: async () =>
      request(
        baseURLGraphql,
        PatientFinancePaymentMethods,
        {
          patientId,
        },
        headers
      ),
    enabled: !!patientId,
  })

  const {
    mutateAsync: deletePaymentMethod,
    isLoading: isDeleteLoading,
    isError: isDeleteError,
  } = useMutation({
    mutationKey: [
      'delete-payment-method',
      baseURLGraphql,
      headers,
      DeletePaymentMethod,
    ],
    mutationFn: async variables =>
      request(baseURLGraphql, DeletePaymentMethod, variables, headers),
    onError: () => {
      onOpenSnackbar('Error deleting payment method')
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-payment-methods',
          baseURLGraphql,
          headers,
          PatientFinancePaymentMethods,
          variables.patientId,
        ],
      })
    },
  })

  const creditCards = useMemo(() => {
    return data && data.patientFinancePaymentMethods
      ? data?.patientFinancePaymentMethods.filter(
          paymentMethod => paymentMethod.type === 'credit_card'
        )
      : []
  }, [data])

  const bankAccounts = useMemo(() => {
    return data && data.patientFinancePaymentMethods
      ? data.patientFinancePaymentMethods.filter(
          paymentMethod => paymentMethod.type === 'ach'
        )
      : []
  }, [data])

  return {
    key,
    creditCards: creditCards,
    bankAccounts: bankAccounts,
    paymentMethods: data?.patientFinancePaymentMethods || [],
    isLoading,
    isError,
    refetch,
    deletePaymentMethod,
    isDeleteLoading,
    isDeleteError,
  }
}

export default usePatientFinancePaymentMethods
