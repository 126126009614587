import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import styled from 'styled-components'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import FormControl from '@mui/material/FormControl'
import Typography from '@/v2/components/common/Typography'
import TextField from '@/v2/components/common/TextField'

const Title = styled.div`
  flex-direction: column;
  height: 24px;

  && > h6 {
    font-weight: 400;
  }

  & > .folder-name {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .folder-name:hover {
    outline: 1px solid ${({ theme }) => theme.gray.main};
    border-radius: 3px;
    padding: 3px 5px 0px 5px;
    margin: -3px -5px 0px -5px;
  }
`

const FolderTitle = props => {
  const theme = useTheme()
  const [editing, setEditing] = useState(false)
  const { progression, isEditMode, onSetIsEditMode, onEdit } = props
  const { id, name, startDate } = progression

  const handleEnter = event => {
    const enter = event.key === 'Enter'
    const value = event.target.value

    if (enter) {
      event.preventDefault()
      handleSaveEdit(value)
    }
  }

  const handleSaveEdit = value => {
    setEditing(false)
    return onEdit(value, id)
  }

  return (
    <Title theme={theme.palette}>
      {isEditMode && editing ? (
        <FormControl variant="standard">
          <TextField
            autoFocus
            name="new-folder"
            placeholder="Progression"
            defaultValue={name}
            onBlur={({ target }) => {
              const value = target.value
              return handleSaveEdit(value)
            }}
            onKeyDown={handleEnter}
            sx={{ marginTop: '-8px' }}
          />
        </FormControl>
      ) : (
        <Typography
          className="folder-name"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setEditing(true)
            onSetIsEditMode()
          }}
          title={name}
        >
          {name}
        </Typography>
      )}

      {startDate && (
        <Typography variant="h6" color={theme.palette.gray.dark}>
          {format(new Date(startDate.replace(/-/g, '/')), DAY_IN_US_FORMAT)}
        </Typography>
      )}
    </Title>
  )
}

export default FolderTitle
