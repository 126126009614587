import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import RealTime from './RealTime'

const RTMessages = () => {
  const queryClient = useQueryClient()
  const { conversationId } = useParams()

  const addMessage = message => {
    const queryKey = ['Conversation', `${conversationId}`]

    queryClient.setQueryData(queryKey, conversation => {
      const { messages, ...others } = conversation
      const { records, ...pagesInfo } = messages
      const newRecords = [message, ...records]

      return {
        ...others,
        messages: { ...pagesInfo, records: newRecords },
      }
    })
  }

  const updateMessage = message => {
    const queryKey = ['Conversation', `${conversationId}`]

    queryClient.setQueryData(queryKey, ({ messages, ...others }) => {
      const { records, ...pagesInfo } = messages
      return {
        ...others,
        messages: {
          ...pagesInfo,
          records: records.map(record =>
            record.id === message.id ? message : record
          ),
        },
      }
    })
  }

  const removeMessage = message => {
    const queryKey = ['Conversation', `${conversationId}`]

    queryClient.setQueryData(queryKey, ({ messages, ...others }) => {
      const { records, ...pagesInfo } = messages
      const newRecords = [...records].filter(record => record.id !== message.id)
      return {
        ...others,
        messages: { ...pagesInfo, records: newRecords },
      }
    })
  }

  const handleEvent = ({ subject, data }) => {
    switch (subject) {
      case 'created':
        addMessage(data)
        break
      case 'status_updated':
        updateMessage(data)
        break
      case 'message_removed':
        removeMessage(data)
        break
      default:
        break
    }
  }

  return (
    <RealTime
      channel="Chat::ConversationMessagesChannel"
      conversationId={conversationId}
      onEvent={handleEvent}
    />
  )
}

export default RTMessages
