import { useQuery } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const useIntakeForm = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['IntakeForm', patientId],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/intake_form.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find forms")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
  })

  return { intakeForm: data, isLoading, isError }
}

export const useSendIntakeForm = patientId => {
  const { mutateAsync: sendIntakeForm, ...others } = requestMiddleware({
    key: ['SendIntakeForm', patientId],
    request: async () =>
      await fetch(`${baseURL}/v2/rest/intake_forms/${patientId}/emails`, {
        method: 'POST',
        headers,
      }),
  })

  return { sendIntakeForm, ...others }
}
