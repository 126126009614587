import { useMutation, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import AppointmentQuery from '@/v2/graphql/queries/Appointment.gql'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'
import { nowOnClinicTimezone } from '../utils/convert'

const useAppointment = ({ appointmentId }) => {
  const { data, loading, error } = useQuery(AppointmentQuery, {
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  })
  const appointment = data?.appointment || null
  const [updateAppointment] = useMutation(UpdateAppointment)
  const currentDate = nowOnClinicTimezone()

  const bookingCheckIn = async (value = true, id) => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId || id,
            checkedIn: value,
            checkedInTime: value
              ? format(currentDate, 'yyyy-M-d hh:mm aa')
              : null,
          },
        },
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const seatPatient = async values => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId || values.id,
            doctorId: values.doctorId,
            roomId: values.roomId,
            seated: values.seated,
            seatedTime: values.seated
              ? format(currentDate, 'yyyy-M-d hh:mm aa')
              : null,
          },
        },
        refetchQueries: ['NextAppointments'],
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const confirmPatient = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            completed: value,
          },
        },
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const toogleReschedule = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            reschedule: value,
          },
        },
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const toogleNoShow = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            noShow: value,
          },
        },
      })
    } catch (e) {
      throw Error(e)
    }
  }

  return {
    appointment,
    loading,
    error,
    bookingCheckIn,
    confirmPatient,
    seatPatient,
    toogleReschedule,
    toogleNoShow,
  }
}

export default useAppointment
