import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import IconButtonMui from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import useDetectDevice from '@/v2/hooks/useDetectDevice'

const Button = styled(IconButtonMui)`
  && {
    border: 1px solid ${({ colors, bordercolor }) => colors[bordercolor].main};
    border-radius: 3px;
    padding: ${({ $isMobile }) => ($isMobile ? '8px' : '3px')};
    transition: 0.2s;

    ${({ colors, background }) =>
      background && `background-color: ${colors[background].main};`}
  }

  ${({ hovercolor, colors, hovercolortext }) =>
    hovercolor
      ? `
      &&:hover {
        background-color: ${colors[hovercolor].main};
        border-color: ${colors[hovercolor].main};
        color: ${
          hovercolortext
            ? colors[hovercolortext].main
            : colors.background.default
        };
      }
    `
      : ''}
`

const IconButton = ({
  children,
  title,
  href,
  hovercolor,
  hovercolortext,
  bordercolor = 'primary',
  ...others
}) => {
  const { isMobile } = useDetectDevice()
  const theme = useTheme()
  const linkProps = href ? { component: Link, to: href } : {}
  return (
    <Button
      colors={theme.palette} // if colors is renamed to theme, it'll give an error
      hovercolor={hovercolor}
      hovercolortext={hovercolortext}
      bordercolor={bordercolor}
      $isMobile={isMobile ? 1 : 0}
      {...linkProps}
      {...others}
    >
      {title ? <Tooltip title={title}>{children}</Tooltip> : children}
    </Button>
  )
}

export default IconButton
