import React, { Fragment } from 'react'
import { formatPhone } from '@/v2/utils/format'

const phoneEnd = [0, 2, 3, 4, 7, 8, 9, 11, 12, 13]
export const getHighlightPhone = (phone, highlight) => {
  if (!phone) return

  const startTerm = phone.indexOf(highlight)

  if (startTerm < 0) return formatPhone(phone)

  const endHightlight = phoneEnd[startTerm + highlight.length]
  const formatedPhone = formatPhone(phone)

  return highlight.length >= 10 ? (
    <b key="middle">{formatedPhone}</b>
  ) : (
    [
      <b key="middle">{formatedPhone.slice(0, endHightlight)}</b>,
      <Fragment key="end">{formatedPhone.slice(endHightlight)}</Fragment>,
    ]
  )
}

export const getHightlightText = (word, highlight) => {
  if (!word) return

  const lowerCaseWord = word.toLocaleLowerCase()
  const lowerCaseHighlight = highlight.toLocaleLowerCase()
  const startHightlight = lowerCaseWord.indexOf(lowerCaseHighlight)

  if (startHightlight < 0) return word

  const endHightlight = startHightlight + highlight.length
  return [
    <Fragment key="start">{lowerCaseWord.slice(0, startHightlight)}</Fragment>,
    <b key="middle">{lowerCaseHighlight}</b>,
    <Fragment key="end">{lowerCaseWord.slice(endHightlight)}</Fragment>,
  ]
}
