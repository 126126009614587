import { format } from 'date-fns'
import {
  convertDateToMinutes,
  getDateTimeWithoutTimezone,
} from '@/v2/utils/convert'

export const getColumnsByRoom = (rooms, doctors) => {
  return [
    ...rooms.map(({ id, title }) => ({
      id: `room-${id}`,
      value: id,
      title,
      type: 'room',
    })),
    ...doctors.map(({ id, lastName }) => ({
      id: `doctor-${id}`,
      value: id,
      title: `Dr. ${lastName}'s Virtual Appointments`,
      type: 'doctor',
      virtual: true,
    })),
  ]
}

export const getColumnsByDoctor = doctors => {
  return doctors.map(({ id, lastName }) => ({
    id: `doctor-${id}`,
    value: id,
    title: `Dr. ${lastName}'s Appointments`,
    type: 'doctor',
  }))
}

export const getEvents = (appointments, timeOffs, columns, subjectView) => {
  const formatDate = date => getDateTimeWithoutTimezone(date)
  const events = []

  appointments.map(event => {
    events.push({
      event: { ...event, type: 'appointment' },
      start: formatDate(event.startsAt),
      end: formatDate(event.endsAt),
      resourceId:
        event.virtual || subjectView === 'doctor'
          ? `doctor-${event.doctor?.id}`
          : `room-${event.room?.id}`,
    })
  })
  timeOffs.map(event => {
    if (event.clinic !== null) {
      columns.map(({ id, virtual }) => {
        if (!virtual) {
          events.push({
            event: { ...event, type: 'timeOff' },
            start: formatDate(event.startsAt),
            end: formatDate(event.endsAt),
            resourceId: id,
          })
        }
      })
    } else {
      if (subjectView === 'room' && event.doctor?.id && !event.virtual) return

      events.push({
        event: { ...event, type: 'timeOff' },
        start: formatDate(event.startsAt),
        end: formatDate(event.endsAt),
        resourceId: event.doctor?.id
          ? `doctor-${event.doctor?.id}`
          : `room-${event.room?.id}`,
      })
    }
  })

  return [...events]
}

export const getOffHours = (clinicOffHours, doctorsOffHours, columns, date) => {
  return columns.map(({ id, type, value, virtual }) => {
    if (type === 'doctor' && !!virtual) {
      const doctor = doctorsOffHours.find(({ id }) => value === id)
      const dayOfWeek = format(
        new Date(date.replace(/-/g, '/')),
        'iiii'
      ).toLowerCase()
      const hours = doctor.weekDayHoursVirtual.find(
        ({ day }) => day === dayOfWeek
      )
      return getDoctorOffHours(hours, id)
    }
    return getClinicOffHours(clinicOffHours, id)
  })
}

export const getClinicOffHours = (clinicHours, resourceId) => {
  if (!clinicHours.isOpen) {
    return [
      {
        type: 'clinicOffHours',
        startsAt: 0,
        endsAt: 1439, // 23:59 in minutes
        resourceId,
      },
    ]
  }

  const offHours = [
    {
      type: 'clinicOffHours',
      startsAt: 0,
      endsAt: convertDateToMinutes(clinicHours.opensAt),
      resourceId,
    },
    {
      type: 'clinicOffHours',
      startsAt: convertDateToMinutes(clinicHours.closesAt),
      endsAt: 1439, // 23:59 in minutes
      resourceId,
    },
  ]
  return clinicHours.hasBreak
    ? [
        ...offHours,
        {
          type: 'clinicOffHours',
          startsAt: convertDateToMinutes(clinicHours.breakFrom),
          endsAt: convertDateToMinutes(clinicHours.breakTo),
          resourceId,
        },
      ]
    : offHours
}

export const getDoctorOffHours = (doctorHours, resourceId) => {
  if (!doctorHours.isOpen) {
    return [
      {
        type: 'doctorOffHours',
        startsAt: 0,
        endsAt: 1439, // 23:59 in minutes
        resourceId,
      },
    ]
  }

  const offHours = [
    {
      type: 'doctorOffHours',
      startsAt: 0,
      endsAt: convertDateToMinutes(doctorHours.startAt),
      resourceId,
    },
    {
      type: 'doctorOffHours',
      startsAt: convertDateToMinutes(doctorHours.stopsAt),
      endsAt: 1439, // 23:59 in minutes
      resourceId,
    },
  ]
  return doctorHours.hasBreak
    ? [
        ...offHours,
        {
          type: 'doctorOffHours',
          startsAt: convertDateToMinutes(doctorHours.breakFrom),
          endsAt: convertDateToMinutes(doctorHours.breakTo),
          resourceId,
        },
      ]
    : offHours
}
