import { useMutation, useQueryClient } from '@tanstack/react-query'
import request from 'graphql-request'
import StripeClientSecret from './graphql/mutations/StripeClientSecret.gql'
import { baseURLGraphql, headers } from '@/v2/constants/requests'
import PatientFinancePaymentMethods from '../usePatientFinancePaymentMethods/graphql/queries/PatientFinancePaymentMethods.gql'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const useStripeClientSecret = () => {
  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()

  const {
    mutateAsync: createClientSecret,
    isError: isCreateClientError,
    isLoading: isCreateClientLoading,
  } = useMutation({
    mutationKey: [
      'stripe-client-secret',
      baseURLGraphql,
      headers,
      StripeClientSecret,
    ],
    mutationFn: async variables =>
      request(
        baseURLGraphql,
        StripeClientSecret,
        { patientId: variables.patientId },
        headers
      ),
    onError: () => {
      onOpenSnackbar('Error adding payment method')
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'patient-finance-payment-methods',
          baseURLGraphql,
          headers,
          PatientFinancePaymentMethods,
          variables.patientId,
        ],
      })
    },
  })

  return {
    createClientSecret,
    isCreateClientError,
    isCreateClientLoading,
  }
}

export default useStripeClientSecret
