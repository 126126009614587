import React from 'react'
import Avatar from '@mui/material/Avatar'

const PatientAvatar = ({ patient, size = '75px', sx }) =>
  patient?.photo ? (
    <Avatar
      sx={{ height: size, width: size, ...sx }}
      alt={patient.fullName}
      src={patient.photo}
    />
  ) : (
    <Avatar
      sx={{
        height: size,
        width: size,
        fontSize: parseInt(size, 10) >= 100 ? '2rem' : '20px',
        ...sx,
      }}
      alt={patient ? patient.fullName : 'New Patient'}
    >
      {patient?.firstName
        ? `${patient.firstName[0] + patient.lastName[0]}`
        : 'P'}
    </Avatar>
  )

export default PatientAvatar
