import React, { createContext, useContext, useState, useMemo } from 'react'
import { useCallback } from 'react'
import { MEDICAL_ALERTS } from '@/v2/constants'

export const PatientContext = createContext([])

const parse = value => parseInt(value, 10)

export const PatientProvider = ({ children }) => {
  const [patient, setPatient] = useState({})
  const [progressions, setProgressions] = useState([])
  const [series, setSeries] = useState([])
  const [officeNotes, setOfficeNotes] = useState([])
  const [notes, setNotes] = useState([])
  const [medicalAlerts, setMedicalAlerts] = useState([])

  const handleSetPatient = useCallback(patient => {
    const medicalAlerts =
      MEDICAL_ALERTS.filter(item => patient[item.name]) || []

    if (patient.hasOwnProperty('premedication')) {
      setMedicalAlerts(medicalAlerts)
    }
    setPatient(old => ({ ...old, ...patient }))
  }, [])

  const handleSetProgressions = useCallback(progressions => {
    setProgressions(progressions)
  }, [])

  const handleSetSeries = useCallback(series => {
    setSeries(series)
  }, [])

  const handleSetNotes = useCallback(notes => {
    setNotes(notes)
  }, [])

  const handleAddProgressionSeries = useCallback(
    (series, progressionId) => {
      const updatedProgressions = progressions.map(progression =>
        parse(progression.id) === parse(progressionId)
          ? { ...progression, series }
          : { ...progression }
      )
      setProgressions(updatedProgressions)
    },
    [progressions]
  )

  const handleAddProgression = useCallback(progression => {
    setProgressions(state => [...state, progression])
  }, [])

  const handleRenameProgression = useCallback(
    progression => {
      const newProgressions = progressions.map(item =>
        parse(item.id) === parse(progression.id)
          ? { ...item, name: progression.name }
          : item
      )
      setProgressions(newProgressions)
    },
    [progressions]
  )

  const handleDeleteProgression = useCallback(
    progression => {
      const newProgressions = progressions.filter(
        ({ id }) => parse(id) !== parse(progression.id)
      )
      setProgressions(newProgressions)
    },
    [progressions]
  )

  const handleUploadImage = useCallback(
    (progressionId, seriesId, image) => {
      const updatedProgressions = progressions.map(progression => {
        return parse(progression.id) === parse(progressionId)
          ? {
              ...progression,
              series: progression.series.map(serie => {
                if (parse(serie.id) === parse(seriesId)) {
                  const findImageByPosition = serie.images.find(
                    item => item.position === image.position
                  )
                  if (findImageByPosition) {
                    return {
                      ...serie,
                      images: serie.images.map(item =>
                        item.position === image.position ? image : item
                      ),
                    }
                  }

                  return {
                    ...serie,
                    images: [...serie.images, image],
                  }
                }

                return { ...serie }
              }),
            }
          : { ...progression }
      })

      setProgressions(updatedProgressions)
    },
    [progressions]
  )

  const handleDeleteImage = useCallback(
    (progressionId, seriesId, imageId) => {
      const updatedProgressions = progressions.map(progression => {
        return parse(progression.id) === parse(progressionId)
          ? {
              ...progression,
              series: progression.series.map(serie =>
                parse(serie.id) === parse(seriesId)
                  ? {
                      ...serie,
                      images: serie.images.filter(
                        image => image.id !== imageId
                      ),
                    }
                  : { ...serie }
              ),
            }
          : { ...progression }
      })

      setProgressions(updatedProgressions)
    },
    [progressions]
  )
  const handleAddNote = useCallback(
    note => {
      setNotes([...notes, note])
    },
    [notes]
  )

  const handleUpdateNote = useCallback(
    note => {
      const updatedNotes = notes.map(item =>
        item.id === note.id ? { ...note } : { ...item }
      )
      setNotes(updatedNotes)
    },
    [notes]
  )

  const handleDeleteNote = useCallback(
    noteId => {
      const updatedNotes = notes.filter(({ id }) => id !== noteId)
      setNotes(updatedNotes)
    },
    [notes]
  )

  const patientProfile = useMemo(
    () => ({
      patient,
      progressions,
      series,
      notes,
      officeNotes,
      medicalAlerts,
      onSetPatient: handleSetPatient,
      onSetProgressions: handleSetProgressions,
      onSetSeries: handleSetSeries,
      onSetNotes: handleSetNotes,
      onSetOfficeNotes: setOfficeNotes,
      onAddProgressionSeries: handleAddProgressionSeries,
      onAddProgression: handleAddProgression,
      onRenameProgression: handleRenameProgression,
      onDeleteProgression: handleDeleteProgression,
      onUploadImage: handleUploadImage,
      onDeleteImage: handleDeleteImage,
      onAddNote: handleAddNote,
      onUpdateNote: handleUpdateNote,
      onDeleteNote: handleDeleteNote,
    }),
    [
      patient,
      progressions,
      series,
      notes,
      officeNotes,
      medicalAlerts,
      handleSetPatient,
      handleSetProgressions,
      handleSetSeries,
      handleSetNotes,
      setOfficeNotes,
      handleAddProgressionSeries,
      handleAddProgression,
      handleRenameProgression,
      handleDeleteProgression,
      handleUploadImage,
      handleDeleteImage,
      handleAddNote,
      handleUpdateNote,
      handleDeleteNote,
    ]
  )
  return (
    <PatientContext.Provider value={patientProfile}>
      {children}
    </PatientContext.Provider>
  )
}

export const usePatientContext = () => {
  const {
    patient,
    progressions,
    series,
    notes,
    officeNotes,
    medicalAlerts,
    onSetPatient,
    onSetProgressions,
    onSetSeries,
    onSetNotes,
    onSetOfficeNotes,
    onAddProgressionSeries,
    onAddProgression,
    onRenameProgression,
    onDeleteProgression,
    onUploadImage,
    onDeleteImage,
    onAddNote,
    onUpdateNote,
    onDeleteNote,
  } = useContext(PatientContext)

  return {
    patient,
    progressions,
    series,
    notes,
    officeNotes,
    medicalAlerts,
    onSetPatient,
    onSetProgressions,
    onSetSeries,
    onSetNotes,
    onSetOfficeNotes,
    onAddProgressionSeries,
    onAddProgression,
    onRenameProgression,
    onDeleteProgression,
    onUploadImage,
    onDeleteImage,
    onAddNote,
    onUpdateNote,
    onDeleteNote,
  }
}
