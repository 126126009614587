import React from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import ConversationsList from './ConversationsList'
import MessagesPage from './MessagesPage'
import { Wrapper, Header, Content, ConversationWrapper } from './styles'

const MessagesContainer = () => {
  const { conversationId } = useParams()
  const theme = useTheme()

  return (
    <Wrapper>
      <Header theme={theme.palette}>
        <Typography variant="h1">Messages</Typography>
      </Header>
      <Content>
        <ConversationsList />
        {conversationId ? (
          <MessagesPage key={conversationId} />
        ) : (
          <ConversationWrapper>
            <Typography>Select a conversation to start messaging.</Typography>
          </ConversationWrapper>
        )}
      </Content>
    </Wrapper>
  )
}

export default MessagesContainer
