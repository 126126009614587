import { useTheme } from '@mui/material'
import React from 'react'
import Button from '@/v2/components/common/Button'
import useModals from '@/v2/hooks/useModals'
import useAppointment from '@/v2/hooks/useAppointment'
import { differenceInMinutes } from 'date-fns'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'

const AppointmentButton = ({ appointment, onlyRead }) => {
  const theme = useTheme()
  const { goTo } = useModals()
  const {
    id,
    seated,
    doctor,
    room,
    checkedIn,
    noShow,
    reschedule,
  } = appointment

  const { bookingCheckIn, seatPatient } = useAppointment({
    appointmentId: id,
  })

  const waiting = appointment.checkedInTime
    ? differenceInMinutes(
        nowOnClinicTimezone(),
        getDateTimeWithoutTimezone(appointment.checkedInTime)
      )
    : null

  if (seated) {
    return (
      <>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            '&:disabled': {
              color: theme.palette.white.main,
              background: theme.palette.grey[600],
            },
          }}
          disabled
        >
          Seated
        </Button>
        <Button
          fullWidth
          variant="text"
          color="primary"
          sx={{
            margin: '0px !important',
          }}
          onClick={() =>
            seatPatient({
              id,
              doctorId: doctor?.id,
              roomId: room?.id,
              seated: false,
            })
          }
        >
          Unseat
        </Button>
      </>
    )
  }
  if (reschedule) {
    return (
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => goTo(`/v2/appointment/${id}/edit`, { onlyRead })}
      >
        Reschedule
      </Button>
    )
  }
  if (noShow) {
    return null
  }
  if (checkedIn) {
    return (
      <>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            color: theme.palette.white.main,
            background: theme.palette.success.dark,
          }}
          onClick={() => goTo(`/v2/seat-patient/${id}/`)}
        >
          Seat (Waiting {Math.abs(waiting)} minutes)
        </Button>
        <Button
          fullWidth
          variant="text"
          color="primary"
          sx={{
            margin: '0px !important',
          }}
          onClick={() => bookingCheckIn(false)}
        >
          Undo Check-in
        </Button>
      </>
    )
  }
  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() =>
        confirm(
          'Are you sure you want to do the check-in of this appointment?'
        ) && bookingCheckIn()
      }
    >
      Check-in
    </Button>
  )
}

export default AppointmentButton
