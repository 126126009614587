import React from 'react'

import Label from '@/v2/components/common/FieldLabel'
import SelectInputField from '@/v2/components/common/SelectInputField'
import FormControl from '@mui/material/FormControl'

const ContractField = ({ register, contracts, errors, ...props }) => {
  return (
    <FormControl variant="standard">
      <Label variant="h4" bold color="grey.darkgrey" mb={5}>
        Apply to a contract?
      </Label>

      <SelectInputField
        fullWidth
        {...props}
        label="Contract"
        placeholder="Select a contract"
        options={contracts.map(contract => ({
          label: `${contract.identifierToken} - ${contract.patientLegalName}`,
          value: contract.id,
        }))}
        error={errors?.contractId?.message}
        {...register('contractId', {
          setValueAs: value =>
            value === '' || value === null ? null : parseInt(value, 10),
        })}
      />
    </FormControl>
  )
}

export default ContractField
