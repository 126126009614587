import React from 'react'
import MenuButton from '@/v2/components/common/MenuButton'
import useModals from '@/v2/hooks/useModals'
import {
  useConsentForms,
  useCreateConsentForm,
} from '@/v2/hooks/documents/useConsentForm'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import IntakeForm from './IntakeForm'
import Contract from './Contract'
import ConsentForm from './ConsentForm'
import { Actions, List } from './styles'

const ContractsTab = ({
  patientId,
  email,
  intakeForm,
  contractList,
  hasActiveContract,
}) => {
  const { goTo } = useModals()
  const { consentForms } = useConsentForms()
  const { createConsentForm } = useCreateConsentForm(patientId)
  const { onOpenSnackbar } = useSnackbarContext()

  const contractOption = {
    label: 'Create contract',
    onClick: () =>
      goTo(`/v2/create-contract/${patientId}`, {
        initialValues: { email },
      }),
  }
  const consentOption = {
    label: 'Create consent form',
    onClick: () => {
      goTo('/v2/consent-form-confirmation', {
        title: consentForms[0].title,
        onSave: async () => {
          try {
            const { error } = await createConsentForm(consentForms[0].id)

            if (!error) {
              onOpenSnackbar('Consent form sent', 'success')
            }
          } catch (error) {
            onOpenSnackbar('Something went wrong.', 'error')
          }
        },
      })
    },
  }
  const options = !hasActiveContract
    ? [contractOption, consentOption]
    : [consentOption]

  return (
    <>
      <Actions>
        <MenuButton
          aria-label="+ Add Documents"
          title="+ Add Documents"
          variant="text"
          color="secondary"
          options={options}
          endIcon={<></>}
        >
          + Add Documents
        </MenuButton>
      </Actions>
      <List>
        <IntakeForm intakeForm={intakeForm} patientId={patientId} />
        <ConsentForm />
        {contractList.map(contract => (
          <Contract
            key={contract.id}
            contract={contract}
            patientId={patientId}
          />
        ))}
      </List>
    </>
  )
}

export default ContractsTab
