import React, { forwardRef } from 'react'
import TextFieldMui from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import getRandomId from '@/v2/utils/random-id'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'

// TODO: standardize noMargin default value with the other fields
const SelectInputField = forwardRef(
  (
    {
      fullWidth,
      group,
      label,
      name,
      placeholder,
      error,
      warning,
      options,
      noMargin,
      ...others
    },
    ref
  ) => {
    const id = getRandomId()

    return (
      <FormControl
        variant="standard"
        sx={{
          mb: noMargin ? '0px' : '23px',
        }}
        fullWidth={fullWidth}
      >
        {label && <Label htmlFor={id}>{label}</Label>}
        <TextFieldMui
          select
          id={id}
          name={name}
          ref={ref}
          SelectProps={{ native: true }}
          {...others}
        >
          {placeholder && (
            <option aria-label={placeholder} value="">
              {placeholder}
            </option>
          )}
          {group
            ? options.map(
                group =>
                  group.options.length > 0 && (
                    <optgroup key={group.label} label={group.label}>
                      {group.options.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </optgroup>
                  )
              )
            : options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
        </TextFieldMui>
        {error && <Typography variant="error">{error}</Typography>}
        {warning && <Typography variant="warning">{warning}</Typography>}
      </FormControl>
    )
  }
)

SelectInputField.displayName = 'SelectInputField'

export default SelectInputField
