import React from 'react'
import styled from 'styled-components'
import Button from '@/v2/components/common/Button'
import { useCreateProgression } from '@/v2/hooks/useProgressions.js'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const NoFolders = ({ patient, onCreate }) => {
  const { createProgression } = useCreateProgression()
  const handleCreate = () => {
    createProgression(patient.id, 'Initial', data => onCreate(data))
  }
  return (
    <Wrapper>
      <Button variant="contained" color="secondary" onClick={handleCreate}>
        Create your first Progression here
      </Button>
    </Wrapper>
  )
}

export default NoFolders
