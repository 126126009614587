import validate from '@/v2/validations/validate'
import { required, format } from '@/v2/validations/validators/general'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/

export const validators = {
  emergencyEmail: [
    required({ message: 'Must have an email' }),
    format({
      regex: emailRegex,
      message: 'Must have a valid email.',
    }),
  ],
  emergencyPhoneNumber: [
    required({ message: 'Must have a phone number' }),
    format({
      regex: phoneRegex,
      message: 'Must have a valid phone number.',
    }),
  ],
}

const validator = values => validate(values, validators)

export default validator
