import { useState, useEffect, useMemo } from 'react'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const baseUrl = window.location.origin
const meta = document.querySelector('meta[name=csrf-token]')
const csrfToken = meta && meta.getAttribute('content')
const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken,
}

// PS: Progression === Folder

export const useGetProgressions = patientId => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const { onOpenSnackbar } = useSnackbarContext()
  const [error, setError] = useState(false)
  const { onSetProgressions } = usePatientContext()

  useEffect(() => {
    const callAPI = async () => {
      setError(false)
      setLoading(true)
      try {
        await fetch(
          `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions.json`
        )
          .then(response => response.json())
          .then(data => {
            setData(data || [])
            onSetProgressions(data || [])
          })
          .finally(() => setLoading(false))
      } catch (err) {
        console.error(err)
        setError(true)
        onOpenSnackbar('Something went wrong!', 'error')
      }
    }

    callAPI()
  }, [])

  return useMemo(() => ({ data, loading, error }), [data, loading, error])
}

export const useCreateProgression = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { onOpenSnackbar } = useSnackbarContext()

  const createProgression = async (patientId, name, onCallback) => {
    setError(false)
    setLoading(true)
    try {
      if (!patientId) return console.error('The patient id must be informed')
      if (!name) return console.error('The name must be informed')

      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ progression: { name } }),
        }
      )
        .then(response => response.json())
        .then(data => onCallback && onCallback(data))
    } catch (err) {
      console.error(err)
      setError(true)
      onOpenSnackbar('Something went wrong!', 'error')
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, createProgression }), [
    loading,
    error,
  ])
}

export const useRenameProgression = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { onOpenSnackbar } = useSnackbarContext()

  const renameProgression = async (patientId, progression, onCallback) => {
    setError(false)
    setLoading(true)

    if (!patientId) return console.error('The patient id must be informed')
    if (!progression.id)
      return console.error('The progression id must be informed')

    try {
      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progression.id}.json`,
        {
          method: 'PATCH',
          headers,
          body: JSON.stringify({
            progression: {
              name: progression.name,
            },
          }),
        }
      )
        .then(response => response.json())
        .then(data => onCallback && onCallback(data))
    } catch (err) {
      console.error(err)
      setError(true)
      onOpenSnackbar('Something went wrong!', 'error')
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, renameProgression }), [
    loading,
    error,
    renameProgression,
  ])
}

export const useDeleteProgression = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { onOpenSnackbar } = useSnackbarContext()

  const deleteProgression = async (patientId, progression, onCallback) => {
    setError(false)
    setLoading(true)

    if (!patientId) return console.error('The patient id must be informed')
    if (!progression.id)
      return console.error('The progression id must be informed')

    try {
      await fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/imaging/progressions/${progression.id}.json`,
        { headers, method: 'DELETE' }
      ).then(() => onCallback && onCallback())
    } catch (err) {
      console.error(err)
      setError(true)
      onOpenSnackbar('Something went wrong!', 'error')
    }
    setLoading(false)
  }

  return useMemo(() => ({ loading, error, deleteProgression }), [
    loading,
    error,
    deleteProgression,
  ])
}
