import React from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import Accordion from '@/v2/components/common/Accordion'
import Label from '@/v2/components/common/Label'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { FULL_DATETIME_FORMAT } from '@/v2/constants'
import useModals from '@/v2/hooks/useModals'

const Wrapper = styled.div`
  padding: 15px 20px 15px 20px;
  margin-bottom: 3px;
  background-color: ${({ color }) => color || 'white'};
  min-height: 74px;
  height: auto;
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
    box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
  }
`
const Labels = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
`

const AppointmentsList = ({ title, appointments, defaultExpanded }) => {
  const theme = useTheme()
  const { goTo } = useModals()

  return (
    <Accordion
      noPaddingTitle
      defaultExpanded={defaultExpanded}
      title={<Typography>{`${title} (${appointments.length})`}</Typography>}
      content={appointments.map(appointment => {
        const extraLabels = []

        if (appointment?.reschedule) {
          extraLabels.push({
            id: 'reschedule',
            name: 'Rescheduled',
            color: 'link',
          })
        }

        if (appointment.noShow) {
          extraLabels.push({
            id: 'no-show',
            name: 'No Show',
            color: 'black',
          })
        }

        return (
          <Wrapper
            key={appointment.id}
            color={theme.palette[appointment.appointmentType.color].type}
            onClick={() =>
              goTo(`/v2/appointment/${appointment.id}`, {
                initialValues: { appointment: { ...appointment } },
              })
            }
          >
            <Typography
              bold
              variant="h5"
              title={appointment.appointmentType.title}
            >
              {appointment.appointmentType.title}
            </Typography>
            <Typography variant="h5">
              {format(
                getDateTimeWithoutTimezone(appointment.startsAt),
                FULL_DATETIME_FORMAT
              )}
            </Typography>
            <Typography variant="h5">{appointment.room?.title}</Typography>
            <Labels>
              {([...appointment.labels, ...extraLabels] || []).map(
                ({ id, name, color }) => (
                  <Label
                    key={id}
                    title={name}
                    color={theme.palette[color].label}
                  />
                )
              )}
            </Labels>
          </Wrapper>
        )
      })}
    />
  )
}

export default AppointmentsList
