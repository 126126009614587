import { format, differenceInMinutes } from 'date-fns'

const fromForm = formValues => {
  const startsAtDate = format(formValues.startsAtDate, 'yyyy/M/d')
  const startsAt = `${startsAtDate} ${formValues.startsAtTime}`
  const endsAt = `${startsAtDate} ${formValues.endsAtTime}`

  const duration = differenceInMinutes(new Date(endsAt), new Date(startsAt))
  const timeable = getTimeable(formValues)

  return {
    ...(formValues.id && { id: formValues.id }),
    timeableId: timeable.id,
    timeableType: timeable.type,
    startsAt: startsAt,
    duration: duration,
    virtual: formValues.type === 'doctor' ? !!formValues.virtual : false,
    notes: formValues.notes || null,
  }
}

const getTimeable = values => {
  switch (values.type) {
    case 'clinic':
      return { id: values.clinicId, type: 'CLINIC' }
    case 'room':
      return { id: values.roomId, type: 'ROOM' }
    case 'doctor':
      return { id: values.doctorId, type: 'DOCTOR' }
    default:
      return {}
  }
}

export default fromForm
