import React from 'react'
import SkeletonUpload from './SkeletonUpload'
import { Stack } from '@mui/material'

const SkeletonUploads = () => {
  return (
    <Stack direction={'row'} flexWrap={'wrap'} mt={4} gap={5}>
      {Array.from(new Array(4)).map((_, index) => (
        <SkeletonUpload key={index} />
      ))}
    </Stack>
  )
}

export default SkeletonUploads
