import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper } from './styles'

const ActiveCoverage = ({ activeCoverage }) => {
  if (!activeCoverage)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Active Coverage data found
      </Typography>
    )

  return (
    <Wrapper>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Service Type:{' '}
          <Typography variant="span" fontWeight={200}>
            {activeCoverage?.serviceType}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Coverage Description:{' '}
          <Typography variant="span" fontWeight={200}>
            {activeCoverage?.planCoverageDescription}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Insurance Type:{' '}
          <Typography variant="span" fontWeight={200}>
            {activeCoverage?.insuranceType}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography variant="h4" fontWeight={500}>
          Description:{' '}
          <Typography variant="span" fontWeight={200}>
            {activeCoverage?.message}
          </Typography>
        </Typography>
      </Grid>
      <Divider margin="20px 0px" />
    </Wrapper>
  )
}

export default ActiveCoverage
