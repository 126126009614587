import styled from 'styled-components'

export const MessageWrapper = styled.div`
  display: flex;
  align-items: ${({ $align }) => ($align === 'right' ? 'end' : 'start')};
  flex-direction: column;
  margin-bottom: 15px;
`

export const MessageBody = styled.div`
  display: flex;
  justify-content: ${({ $align }) => ($align === 'right' ? 'end' : 'start')};
`

export const MessageFooter = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButtons = styled.div``
