import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@/v2/components/common/Typography'
import Label from '@/v2/components/common/FieldLabel'
import getRandomId from '@/v2/utils/random-id'

const RadioField = React.forwardRef(
  (
    {
      options,
      label,
      color,
      defaultValue,
      error,
      onChange,
      sx,
      column,
      ...others
    },
    ref
  ) => {
    const id = getRandomId()
    return (
      <FormControl variant="standard" sx={sx}>
        {label && <Label id={id}>{label}</Label>}
        <RadioGroup
          row={!column}
          defaultValue={defaultValue}
          aria-labelledby={id}
          ref={ref}
          {...others}
          onChange={e => onChange(e.target.value)}
        >
          {options.map(({ label, value }) => (
            <FormControlLabel
              sx={{ '& > span': { fontSize: '14px', fontWeight: 400 } }}
              key={value}
              value={value}
              control={<Radio color={color} sx={{ padding: '2px 4px' }} />}
              label={label}
            />
          ))}
        </RadioGroup>
        {error && <Typography variant="error">{error}</Typography>}
      </FormControl>
    )
  }
)

RadioField.displayName = 'RadioField'

export default RadioField
