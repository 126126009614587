import React, { useState, useRef } from 'react'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useModals from '@/v2/hooks/useModals'
import useAppointment from '@/v2/hooks/useAppointment'
import useNextAppointments from '@/v2/hooks/useNextAppointments'
import Typography from '@/v2/components/common/Typography'
import { DATE_QUERY_FORMAT } from '@/v2/constants'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import AppointmentsList from './AppointmentsList'
import useInterval from '@/v2/hooks/useInterval'
import { useTabContext } from '@/v2/contexts/TabsContext'

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.primary.main};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 288px - 60px);
  overflow-y: auto;

  /* hides scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const ErrorWrapper = styled.div`
  text-align: center;
  margin-top: 60px;
`

const AppointmentSections = ({ date }) => {
  const { goTo } = useModals()
  const theme = useTheme()
  const history = useHistory()
  const [dateOnLoad, setDateOnLoad] = useState(date || nowOnClinicTimezone())
  const dateOnLoadRef = useRef(dateOnLoad)
  dateOnLoadRef.current = dateOnLoad
  const { bookingCheckIn, seatPatient } = useAppointment({})
  const { loading, error, appointments: data } = useNextAppointments({
    date: format(dateOnLoadRef.current, DATE_QUERY_FORMAT),
  })
  const { onResetTab } = useTabContext()

  // Check if is already another day and update the list of next appointments.
  // This case happens when the user leave the calendar tab open from one day to another
  useInterval(() => {
    const currentDate = nowOnClinicTimezone()
    const formatedDate = format(currentDate, DATE_QUERY_FORMAT)
    if (format(dateOnLoadRef.current, DATE_QUERY_FORMAT) !== formatedDate) {
      setDateOnLoad(currentDate)
      onResetTab()
    }
  }, 5000 * 60) // 5 minutes

  if (error) {
    return (
      <ErrorWrapper>Sorry! We couldn't load the appointments.</ErrorWrapper>
    )
  }

  const appointments = loading ? [] : data
  const unseatedAppointments = appointments.filter(
    appointment => !appointment.checkedIn && !appointment.seated
  )
  const waitingAppointments = appointments.filter(
    appointment => appointment.checkedIn && !appointment.seated
  )
  const seatedAppointments = appointments
    .filter(appointment => !!appointment.seated)
    .sort((a, b) => new Date(b.seatedTime) - new Date(a.seatedTime))

  return (
    <Wrapper theme={theme.palette}>
      <AppointmentsList
        key="today"
        loading={loading}
        title={
          <Typography>
            <b>Today</b> ({unseatedAppointments.length}/{appointments.length})
          </Typography>
        }
        appointments={unseatedAppointments}
        height="40%"
        hoverButttons={[
          {
            label: 'Check in',
            onClick: ({ id, fromBookingPage }) =>
              id &&
              confirm('Are you sure you want to check in this patient?') &&
              bookingCheckIn(true, id) &&
              fromBookingPage &&
              window.analytics.track('Booking Page Appointment Confirmed', {
                category: 'Clearworks',
                label: 'Appointment booked from Booking Page confirmed',
                date: new Date().toISOString(),
              }),
          },
        ]}
      />
      <AppointmentsList
        key="waiting"
        loading={loading}
        title={
          <Typography>
            <b>Waiting</b> ({waitingAppointments.length})
          </Typography>
        }
        appointments={waitingAppointments}
        height="30%"
        hoverButttons={[
          {
            label: 'Back',
            type: 'icon',
            onClick: ({ id }) =>
              id &&
              confirm('Are you sure you want to undo the patient check in?') &&
              bookingCheckIn(false, id),
          },
          {
            label: 'Seat',
            onClick: ({ id }) => id && goTo(`/v2/seat-patient/${id}`),
          },
        ]}
      />
      <AppointmentsList
        key="seated"
        loading={loading}
        title={
          <Typography>
            <b>Seated</b> ({seatedAppointments.length})
          </Typography>
        }
        appointments={seatedAppointments}
        hoverButttons={[
          {
            label: 'Undo',
            type: 'icon',
            onClick: ({ id }) =>
              id &&
              confirm('Are you sure you want to unseat the patient?') &&
              seatPatient({ id, seated: false }),
          },
          {
            label: 'Notes',
            onClick: ({ id, patient }) =>
              patient &&
              id &&
              history.push(`/v2/patient/${patient.id}/treatment/${id}`, {
                seatAppointmentId: id,
              }),
          },
        ]}
      />
    </Wrapper>
  )
}

export default AppointmentSections
