import React from 'react'

import CheckIcon from '@mui/icons-material/Check'
import { AppointmentInfoActions } from './styles'
import useAppointment from '@/v2/hooks/useAppointment'
import Button from '@/v2/components/common/Button'

const ActionButtons = ({ appointment }) => {
  const { confirmPatient, toogleReschedule, toogleNoShow } = useAppointment({
    appointmentId: appointment.id,
  })

  const { seated, completed, reschedule, noShow } = appointment

  return (
    !seated && (
      <AppointmentInfoActions>
        <Button
          className={completed ? 'confirmed' : 'confirm'}
          variant="action"
          disabled={completed}
          onClick={() => confirmPatient(!completed)}
        >
          {completed ? (
            <CheckIcon
              sx={{
                fontSize: '12px !important',
              }}
            />
          ) : null}
          {completed ? 'Confirmed' : 'Confirm'}
        </Button>
        {!reschedule && (
          <Button
            className={noShow ? 'no-show' : 'show'}
            variant="action"
            onClick={() => toogleNoShow(!noShow)}
          >
            No Show
          </Button>
        )}
        {!noShow && (
          <Button
            className={reschedule ? 'rescheduled' : 'reschedule'}
            variant="action"
            onClick={() => toogleReschedule(!reschedule)}
          >
            Reschedule
          </Button>
        )}
      </AppointmentInfoActions>
    )
  )
}

export default ActionButtons
