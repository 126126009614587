import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import TextField from '@/v2/components/common/TextField'
import { Wrapper, Item } from './styles'
import { debounce } from './debounce'

const EligibilityItem = ({ data, fields, searchLabel }) => {
  const getFieldLabel = field => fields[field]?.label || field
  const getFieldsToShow = () => Object.keys(fields)

  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const handleSearch = useCallback(
    debounce(value => {
      setSearchTerm(value)
    }, 300),
    []
  )

  const filteredResults = useMemo(() => {
    if (!data) return []
    return data?.filter(item => {
      const searchValue = searchTerm.toLowerCase()
      return getFieldsToShow().some(field =>
        (item[field] || '').toLowerCase().includes(searchValue)
      )
    })
  }, [data, searchTerm])

  useEffect(() => {
    setFilteredData(filteredResults)
  }, [filteredResults])

  return (
    <Wrapper>
      <Grid item xs={12} mb={4}>
        <TextField
          fullWidth
          name="search"
          label={searchLabel}
          placeholder="Type to search"
          onChange={value => handleSearch(value)}
        />
      </Grid>
      {filteredData.map((item, index) => (
        <Item key={index}>
          {getFieldsToShow().map(field => (
            <Grid item xs={12} mb={2} key={field}>
              <Typography variant="h4" fontWeight={500}>
                {getFieldLabel(field)}:{' '}
                <Typography variant="span" fontWeight={200}>
                  {item[field]}
                </Typography>
              </Typography>
            </Grid>
          ))}
          <Divider margin="20px 0px" />
        </Item>
      ))}
    </Wrapper>
  )
}

export default EligibilityItem
