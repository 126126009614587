import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Label from '@/v2/components/common/Label'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const namedLabels = ['reschedule', 'no show', 'waiting', 'seated']
const alias = { reschedule: 'R', 'no show': 'NS', waiting: 'W', seated: 'S' }
const colorChanges = { reschedule: 'link', 'no show': 'black' }

const isTextLabel = name =>
  namedLabels.some(item => item === name.toLowerCase())

const LabelList = ({ labels = [] }) => {
  const theme = useTheme()
  return (
    <Wrapper>
      {[...labels]
        .sort(a => (isTextLabel(a.name) ? 1 : -1))
        .map(({ id, name, color }) =>
          isTextLabel(name) ? (
            <Label
              showText
              key={id}
              title={alias[name.toLowerCase()]}
              color={
                theme.palette[
                  colorChanges[name.toLowerCase()]
                    ? colorChanges[name.toLowerCase()]
                    : color
                ].label
              }
            />
          ) : (
            <Label key={id} title={name} color={theme.palette[color].label} />
          )
        )}
    </Wrapper>
  )
}

export default LabelList
