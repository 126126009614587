import React from 'react'
import compose from 'lodash/flowRight'
import { useHistory } from 'react-router-dom'
import { graphql } from '@apollo/client/react/hoc'
import getRooms from '@/v2/graphql/queries/Rooms.gql'
import getDoctors from '@/v2/graphql/queries/Doctors.gql'
import Calendar from './Calendar'
import { Loading } from '../styles'
import { DailyView } from './styles'
import {
  getColumnsByRoom,
  getColumnsByDoctor,
  getOffHours,
  getEvents,
} from '../utils'

const Day = props => {
  const history = useHistory()
  const {
    date,
    subjectView,
    typeFilter,
    defaultStartHour,
    defaultEndHour,
    loading: loadingData,
    error: errorData,
    appointments,
    timeOffs,
    clinicHours,
    rooms: { loading: loadingRooms, error: errorRooms, clinic },
    doctors: { loading: loadingDoctors, error: errorDoctors, doctors },
  } = props
  const loading = loadingData || loadingRooms || loadingDoctors
  const error = errorData || errorRooms || errorDoctors
  const { rooms } = clinic || {}

  if (loading) {
    return (
      <Loading>
        <p>Loading...</p>
      </Loading>
    )
  }
  if (error) {
    return history.push('/500')
  }

  const columns =
    subjectView === 'room'
      ? getColumnsByRoom(rooms, doctors)
      : getColumnsByDoctor(doctors)
  const filteredAppointments = typeFilter.length
    ? appointments.filter(({ appointmentType }) =>
        typeFilter.includes(appointmentType.id)
      )
    : appointments
  const clinicOffHours = getOffHours(clinicHours, doctors, columns, date)
  const events = getEvents(filteredAppointments, timeOffs, columns, subjectView)

  return (
    <DailyView className="daily-view">
      <Calendar
        key={`${subjectView}-${date}`}
        view="day"
        subjectView={subjectView}
        columns={columns}
        events={events}
        date={date}
        startHour={defaultStartHour}
        endHour={defaultEndHour}
        offHours={clinicOffHours}
      />
    </DailyView>
  )
}

export default compose(
  graphql(getRooms, { name: 'rooms' }),
  graphql(getDoctors, { name: 'doctors' })
)(Day)
