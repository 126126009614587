import React from 'react'
import styled from 'styled-components'
import { format, differenceInMinutes } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import { TIME_IN_US_FORMAT } from '@/v2/constants'

const WaitWrapper = styled.div`
  display: flex;
  gap: 4px;
`

const WaitingTime = ({ checkedIn, appointmentTime }) => {
  const theme = useTheme()
  const arrival = getDateTimeWithoutTimezone(checkedIn)
  const arrivalFormatted = format(arrival, TIME_IN_US_FORMAT)
  const diffFromAppointment = differenceInMinutes(
    getDateTimeWithoutTimezone(appointmentTime),
    arrival
  )
  const waiting = differenceInMinutes(nowOnClinicTimezone(), arrival)
  const type =
    diffFromAppointment > 0
      ? 'Early'
      : diffFromAppointment < 0
      ? 'Late'
      : 'On Time'
  const color = {
    Early: theme.palette.success.dark,
    Late: theme.palette.error.dark,
    'On Time': theme.palette.black.main,
  }
  return (
    <WaitWrapper>
      <Typography variant="h5" color={color[type]}>
        {`${type} ${arrivalFormatted}`}
      </Typography>
      <Typography bold variant="h5">
        ({Math.abs(waiting)}m Wait)
      </Typography>
    </WaitWrapper>
  )
}

export default WaitingTime
