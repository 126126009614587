import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import Tag from '@/v2/components/common/Tag'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSendContract } from '@/v2/hooks/documents/useContracts'
import { getContractStatus } from '@/v2/utils/helpers'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Contract = () => {
  const theme = useTheme()
  const { patient } = usePatientContext()
  const { contract } = patient
  const { sendContract } = useSendContract(contract?.id)
  const { onOpenSnackbar } = useSnackbarContext()

  if (!contract) return null

  const contractStatus = getContractStatus(contract)

  const handleResendEmail = async () => {
    try {
      const { error } = await sendContract()

      if (!error) {
        onOpenSnackbar('Email sent successfully', 'success')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Line>
        <Typography variant="h3" sx={{ mb: '12px' }}>
          Treatment Contract
        </Typography>
        <Typography fontWeight="400" fontSize="15px">
          {`ID# ${contract.identifierToken}`}
        </Typography>
      </Line>
      <Line>
        <Tag color={contractStatus.color} label={contractStatus.label} />
        {contract.signed ? (
          <Button
            variant="text"
            href={`/contracts/${contract.id}/pdf`}
            rel="noopener noreferrer"
            target="_blank"
            sx={{ color: theme.palette.gray.dark }}
          >
            <Typography variant="h5">Download PDF</Typography>
          </Button>
        ) : (
          <Button
            variant="text"
            onClick={handleResendEmail}
            sx={{ color: theme.palette.gray.dark }}
          >
            <Typography variant="h5">Send for Signature</Typography>
          </Button>
        )}
      </Line>
    </Paper>
  )
}

export default Contract
