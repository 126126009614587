import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 402px;
  padding-bottom: 20px;

  h5 {
    font-size: 12px;
    color: ${({ theme }) => theme.fieldLabel.main};
  }

  p {
    margin: 0;
  }

  #payment-plan-types {
    display: grid;
    grid-auto-flow: column;
    width: max-content;
  }

  #receipt-types {
    display: grid;
    grid-auto-flow: column;
    column-gap: 20px;
    width: 50%;
  }

  .MuiFormGroup-root label {
    margin: 0;
  }

  .MuiRadio-root {
    padding: 4px;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  max-width: '384px';
`

export const WrapperForm = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
`

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;

  &.row-half {
    grid-template-columns: 1fr 1fr;
  }
`

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  &.flex-center {
    justify-content: center;
    align-items: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 16px;

  .text-background {
    color: ${({ theme }) => theme.background.default};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .MuiFormGroup-root label {
    margin: 0;
  }

  .MuiRadio-root {
    padding: 4px;
  }
`
