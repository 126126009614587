import React from 'react'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { TOPBAR_MOBILE_HEIGHT } from '@/v2/constants'
import Clearworks from '#/assets/images/v2/clearworks.svg?inline'
import IconButton from '@/v2/components/common/IconButton'
import SettingsMenu from '@/v2/components/common/SettingsMenu'
import { nowOnClinicTimezone } from '@/v2/utils/convert'

const Wrapper = styled.div`
  height: ${TOPBAR_MOBILE_HEIGHT};
  border-bottom: 1px solid ${({ theme }) => theme.divider.main};
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`

const Buttons = styled.div``

const TopbarMobile = () => {
  const theme = useTheme()
  const history = useHistory()
  const today = format(nowOnClinicTimezone(), 'yyyy-MM-dd')
  return (
    <Wrapper theme={theme.palette}>
      <SVGInline
        svg={Clearworks}
        height="20px"
        onClick={() => history.push(`/v2/calendar/day/${today}`)}
      />
      <Buttons>
        <IconButton
          title="Search"
          bordercolor="topbarIconBorder"
          aria-label="Search for patient"
          onClick={() => history.push('/v2/search')}
          sx={{ mx: '6px' }}
        >
          <SearchIcon />
        </IconButton>
        <SettingsMenu />
      </Buttons>
    </Wrapper>
  )
}

export default TopbarMobile
