import React from 'react'
import RealTime from './RealTime'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useQueryClient } from '@tanstack/react-query'

const RTClaims = () => {
  const queryClient = useQueryClient()
  const { patient } = usePatientContext()
  const handleEvent = ({ action, subject, data }) => {
    queryClient.invalidateQueries({
      queryKey: ['Claims', `${patient.id}`],
      queryKey: ['ShowClaim', `${data.id}`],
    })
  }

  return (
    <RealTime
      channel="PatientProfile::Insurance::ClaimsChannel"
      onEvent={handleEvent}
      patientId={patient.id}
    />
  )
}

export default RTClaims
