import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { format, endOfWeek, parseISO, startOfWeek } from 'date-fns'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import getWeekSchedule from '@/v2/graphql/queries/WeekSchedules.gql'
import Filterbar from '../Filterbar'
import { Canvas } from '../styles'
import { Week, HourRuleWrapper } from './styles'
import { usePeriodOfTime } from './hooks/usePeriodOfTime'
import { getFirstHour, getLastHour } from './hooks/useLoadData'
import WeekDay from './WeekDay'
import HourRule from './Calendar'

const Weekly = () => {
  const theme = useTheme()
  const { date } = useParams()
  const [subjectView, setSubjectView] = useState('room')
  const [typeFilter, setTypeFilter] = useState([])
  const {
    startHour: defaultStartHour,
    endHour: defaultEndHour,
    changeStartHour,
    changeEndHour,
  } = usePeriodOfTime()

  const startWeek = startOfWeek(parseISO(date))
  const endWeek = endOfWeek(parseISO(date))
  const { data, loading, error } = useQuery(getWeekSchedule, {
    variables: {
      from: format(startWeek, DATE_PARAM_FORMAT),
      to: format(endWeek, DATE_PARAM_FORMAT),
    },
  })
  const days = data?.daySchedules || []

  return (
    <Canvas>
      <Filterbar
        onChangeSubject={setSubjectView}
        onChangeType={setTypeFilter}
      />
      <Week>
        <HourRuleWrapper theme={theme.palette}>
          <HourRule
            isHourRuleOnly
            view="week"
            date={date}
            startHour={defaultStartHour}
            endHour={defaultEndHour}
            columns={[{ id: 'fake', value: '0', title: '' }]}
            events={[
              { start: new Date(), end: new Date(), resourceId: 'fake' },
            ]}
            offHours={[]}
          />
        </HourRuleWrapper>

        {days.map(({ id, appointments, timeOffs, hours: clinicHours }, key) => {
          if (key === 0) return null

          const [day, month, year] = id.split('/')
          const formattedDay = `${year}-${month}-${day}`

          const startHour = getFirstHour(appointments, timeOffs, clinicHours)
          const endHour = getLastHour(appointments, timeOffs, clinicHours)

          if (startHour) changeStartHour(startHour)
          if (endHour) changeEndHour(endHour)

          if (!loading && !error && key === 6 && !clinicHours.isOpen)
            return null

          return (
            <WeekDay
              key={formattedDay}
              date={formattedDay}
              hasHourRule={false}
              hasBorder={key !== 6}
              subjectView={subjectView}
              typeFilter={typeFilter}
              loading={loading}
              error={error}
              appointments={appointments}
              timeOffs={timeOffs}
              clinicHours={clinicHours}
              defaultStartHour={defaultStartHour}
              defaultEndHour={defaultEndHour}
            />
          )
        })}
      </Week>
    </Canvas>
  )
}

export default Weekly
