import styled from 'styled-components'

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`

export const LinkWithoutStyle = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.grey[400]};
  &:hover {
    cursor: pointer;
  }
`

export const AuthenticationCard = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 40px 40px 20px 40px;
  border-radius: 16px;
  text-align: center;
  max-width: 450px;
  width: 100%;

  & > * {
    text-align: left;
  }
`

export const AuthenticationLink = styled.a`
  margin-top: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
