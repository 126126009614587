import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import TabsMui from '@mui/material/Tabs'
import TabMui from '@mui/material/Tab'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import Box from '@mui/material/Box'
import { INFOBAR_HEIGHT } from '@/v2/constants'
import AppointmentsTab from './AppointmentsTab'
import { usePatientContext } from '@/v2/contexts/PatientContext'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.silver.main};
  padding: 38px 64px 60px 48px;
  height: calc(100% - ${INFOBAR_HEIGHT});
  overflow-y: auto;
`
const Body = styled.div`
  background: ${({ theme }) => theme.white.main};
  border-radius: 3px;
  min-height: calc(100% - 100px);
`
const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: end;
  padding-bottom: 4px;
  padding-left: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.silver.dark};
`
const Tabs = styled(TabsMui)`
  margin-right: 25px;
  display: inline-flex;

  & .MuiTabs-indicator {
    height: 3px;
  }
`
const Tab = styled(TabMui)`
  && {
    padding: 2px;
    margin-right: 30px;
  }
`
const Content = styled.div`
  padding: 37px 28px;
`

const Treatment = ({ appointments }) => {
  const theme = useTheme()
  const [tab, setTab] = useState('appointments')
  const { patient, medicalAlerts } = usePatientContext()

  if (!patient.id) return null

  return (
    <Wrapper theme={theme.palette}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        gap={2}
        mb={4}
      >
        <Typography variant="h1" fontSize="26px">
          Treatments
        </Typography>
        <Box display={'flex'} gap={1}>
          {medicalAlerts.map(({ name, label, color }) => (
            <Button
              key={name}
              variant="contained"
              sx={{
                background: theme.palette[color].medicalAlert,
                color: theme.palette.background.default,
                width: 'auto',
                minWidth: '40px',
                height: '20px',
              }}
            >
              {label}
            </Button>
          ))}
        </Box>
      </Box>
      <Body theme={theme.palette}>
        <Header theme={theme.palette}>
          <Tabs
            value={tab}
            className="treatment-navigation"
            onChange={(e, newTab) => setTab(newTab)}
          >
            <Tab value="appointments" label="Appointments" />
            <Tab value="plan" label="Plan" />
            <Tab value="tasks" label="Tasks" />
          </Tabs>
        </Header>
        <Content>
          {tab === 'appointments' ? (
            <AppointmentsTab appointments={appointments} />
          ) : (
            <Typography>Coming soon...</Typography>
          )}
        </Content>
      </Body>
    </Wrapper>
  )
}

export default Treatment
