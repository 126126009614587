import styled from 'styled-components'

export const Item = styled.div`
  padding: 20px 20px 14px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.75;
  }
`

export const PatientSection = styled.div`
  width: 100%;
`
