import TextField from '@mui/material/TextField'
import styled from 'styled-components'

export const InputWrapper = styled.div`
  border: ${({ $error }) => ($error ? '1px solid red' : '1px solid black')};
  border-radius: 4px;
  padding: 8px;
`
export const Disclaimer = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.fieldLabel.main};
  font-weight: 300;
  width: 100%;
  text-align: justify;
`

export const Input = styled(TextField)`
  & div {
    height: 35px;
    & input {
      color: black;
      &::placeholder {
        color: #212121;
        opacity: 0.7;
      }
    }
  }

  & fieldset {
    border-color: ${({ $error }) => ($error ? 'red' : 'black')};
    border-radius: 4px;
  }

  & #zipcode-helper-text {
    margin-top: 3px;
  }
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
`

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;

  &.row-half {
    grid-template-columns: 1fr 1fr;
  }
`

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.flex-center {
    justify-content: center;
    align-items: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: ${({ $reverse }) => ($reverse ? 'flex-start' : 'end')};
  column-gap: 16px;

  .text-background {
    color: ${({ theme }) => theme.background.default};
  }
`
