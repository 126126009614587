import React from 'react'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import { PatientProvider } from '@/v2/contexts/PatientContext'
import DesktopPatient from './DesktopPatient'
import MobilePatient from './mobile'

const Patient = () => {
  const { isMobile } = useDetectDevice()

  return (
    <PatientProvider>
      {isMobile ? <MobilePatient /> : <DesktopPatient />}
    </PatientProvider>
  )
}

export default Patient
