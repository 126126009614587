import React, { useCallback } from 'react'
import styled from 'styled-components'
import Label from '@/v2/components/common/FieldLabel'
import Divider from '@/v2/components/common/Divider'
import PatientSelection from '@/v2/components/forms/Appointment/PatientSelection'
import sortBy from 'lodash/sortBy' // TODO: remove
import AppointmentForm from '@/v2/components/forms/Appointment/Form'
import Notes from '../Notes'
import FormControl from '@mui/material/FormControl'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const NotesCustomizer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & .note-input {
    margin-top: 20px;
  }

  &&& .note-input textarea {
    height: 28px;
  }

  & .note-input-buttons > button {
    height: 30px;
  }
`

const BaseForm = ({
  onlyRead,
  error,
  values,
  edit = false,
  types,
  rooms,
  doctors,
  onChange,
  onChangeNotes,
}) => {
  const typeOptions = useCallback(sortBy(types, 'title'))
  const doctorsOptions = doctors.map(doctor => ({
    ...doctor,
    value: doctor.id,
    label: doctor.fullName,
  }))
  const roomsOptions = rooms.map(({ id, title }) => ({
    value: id,
    label: title,
  }))

  return (
    <Wrapper>
      <FormControl variant="standard">
        <Label>Patient</Label>
        <PatientSelection
          patientType={values.patientType}
          newPatient={values.newPatient}
          registeredPatient={values.registeredPatient}
          canRemove={!edit}
          error={error}
          onChange={onChange}
        />
      </FormControl>
      <Divider margin="20px 0px" />
      <AppointmentForm
        onlyRead={onlyRead}
        values={values}
        typesOptions={typeOptions}
        doctorsOptions={doctorsOptions}
        roomsOptions={roomsOptions}
        error={error}
        onChange={onChange}
      />
      {values?.id && (
        <NotesCustomizer>
          <Label>Notes</Label>
          <Notes id={values.id} onChange={onChangeNotes} />
        </NotesCustomizer>
      )}
    </Wrapper>
  )
}

export default BaseForm
