import { useQuery } from '@tanstack/react-query'
import { baseURL } from '@/v2/constants/requests'

export const useFindPayer = query => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['FindPayers', query || ''],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/insurances/payers.json?query=${query}`
      )
      if (!res.ok) {
        throw new Error('We could not find a payer')
      }
      return res.json()
    },
    enabled: !!query,
  })

  return { payers: data, isLoading, isError }
}
