import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 10px;
  margin: 4px 2px;
  display: inline-flex;
  background-color: ${({ color }) => color || 'white'};
`

const Label = ({ color, showText = false, title }) => {
  const theme = useTheme()
  return showText ? (
    <Wrapper color={color} width="25px" height="12px">
      <Typography
        bold
        color={theme.palette.background.default}
        fontSize="11px"
        lineHeight="13px"
        sx={{ margin: '0 auto' }}
      >
        {title}
      </Typography>
    </Wrapper>
  ) : (
    <Wrapper color={color} title={title} width="15px" height="8px" />
  )
}

export default Label
