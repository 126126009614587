import { useState } from 'react'
import { useLogin } from '@/v2/hooks/auth/useAuthentication'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useHistory } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useAuthContext } from '@/v2/contexts/AuthContext'

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().nonempty('Password is required'),
})

export const useLoginForm = () => {
  const { push } = useHistory()
  const { login, isLoading } = useLogin()
  const { onOpenSnackbar } = useSnackbarContext()
  const { setUser, setIsLoggedIn } = useAuthContext()

  const [values, setValues] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState({})

  const {
    register,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(loginSchema),
  })

  const handleLogin = async () => {
    const credentials = {
      session: {
        email: values.email,
        password: values.password,
      },
    }
    await login(credentials, {
      onSuccess: data => {
        setUser(data)
        setIsLoggedIn(true)
        push('/v2')
      },
      onError: error => {
        onOpenSnackbar(`${error}`, 'error')
      },
    })
  }

  const handleSubmitForm = () => {
    const validErrors =
      loginSchema.safeParse(values).error?.formErrors.fieldErrors || {}
    setErrors(validErrors)
    if (Object.keys(validErrors).length === 0) {
      handleLogin()
    }
  }

  return {
    values,
    errors,
    isLoading,
    isSubmitting,
    register,
    setValues,
    handleSubmitForm,
  }
}
