import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import useModals from '@/v2/hooks/useModals'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@/v2/components/common/IconButton'
import SettingsMenu from '@/v2/components/common/SettingsMenu'
import { useTabContext } from '@/v2/contexts/TabsContext'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import PatientSearch from '@/v2/components/common/PatientSearch'

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;
  margin-right: 20px;

  && > button {
    width: 34px;
    height: 34px;
  }
`

const ActionButtons = () => {
  const { goTo } = useModals()
  const history = useHistory()
  const { onAddTab } = useTabContext()

  return (
    <ButtonsWrapper>
      <PatientSearch
        onChange={patient => {
          if (!patient?.id) return
          history.push(`/v2/patient/${patient.id}`)
        }}
      />
      <SearchIcon />
      <IconButton
        title="Create appointment or blocked time"
        hovercolor="secondary"
        bordercolor="topbarIconBorder"
        aria-label="Create appointment or blocked time"
        onClick={() =>
          goTo('/v2/event/new', {
            initialValues: { start: nowOnClinicTimezone() },
          })
        }
      >
        <AddIcon />
      </IconButton>
      <SettingsMenu />
    </ButtonsWrapper>
  )
}

export default ActionButtons
