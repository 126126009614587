import React from 'react'
import styled from 'styled-components'
import AccordionMui from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Wrapper = styled(AccordionMui)`
  &&:before {
    background-color: ${({ nobackground }) =>
      nobackground ? 'transparent' : '#ffffff'};
  }
  && {
    background-color: ${({ nobackground }) =>
      nobackground ? 'transparent' : '#ffffff'};
  }

  ${({ nobackground }) =>
    nobackground
      ? `
  && .MuiAccordionSummary-root.Mui-focusVisible {
    background-color: transparent;
  }
  `
      : ''}
`
const Title = styled(AccordionSummary)`
  ${({ iconposition }) =>
    iconposition === 'left'
      ? `
    flex-direction: row-reverse;
    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
  `
      : ''}
  ${({ nopaddingtitle }) => (nopaddingtitle ? '&& {padding: 0px;}' : '')}
`

const Accordion = ({
  noBackground,
  noPaddingTitle,
  title,
  content,
  icon,
  iconPosition = 'right',
  ...others
}) => (
  <Wrapper nobackground={noBackground ? 1 : 0} {...others}>
    <Title
      className="accordion-title"
      expandIcon={icon ? icon : <ExpandMoreIcon color="black" />}
      iconposition={iconPosition}
      nopaddingtitle={noPaddingTitle ? 1 : 0}
    >
      {title}
    </Title>
    <AccordionDetails
      className="accordion-details"
      sx={{ padding: '8px 0px 16px' }}
    >
      {content}
    </AccordionDetails>
  </Wrapper>
)

export default Accordion
