export const formatPhone = phone =>
  (phone || '')
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')

export const formatSearch = term =>
  /\([0-9]{3}\)/g.test(term) ? term.replace(/\D/g, '') : term

export const formatCurrency = (amount = '$0.00') => {
  const cleanedAmount = amount.replace(/\D/g, '')
  if (cleanedAmount === '') {
    return '$0.00'
  }

  const cents = cleanedAmount.substr(-2)
  const dolars = cleanedAmount.slice(0, -2)
  const value = cleanedAmount.length === 1 ? `.0${cents}` : `${dolars}.${cents}`

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

// TODO: we have 3 functions that do the same thing: toUSNumberFormat, currency and USNumberFormat
//       we should choose one and remove the others
export const currency = value => {
  const locale = 'en-US' // Util.locale();
  const currency = 'USD' // Util.currency();

  if (value) {
    return Number(value).toLocaleString(locale, {
      style: 'currency',
      currency: currency,
    })
  } else {
    return Number(0).toLocaleString(locale, {
      style: 'currency',
      currency: currency,
    })
  }
}

export const toUSNumberFormat = (
  value = '0.00',
  stripDecimal = false,
  currency = false
) => {
  const style = currency ? { style: 'currency', currency: 'USD' } : {}
  const number = Number(String(value).replace(',', '')).toLocaleString(
    'en-US',
    style
  )

  if (currency && stripDecimal && number.includes('.')) {
    return number.replace('.00', '')
  }

  return number
}

export const formatPorcentage = value => `${value.replace(/\D/g, '')}%`

export const formatTime = value => {
  if (value.length === 2) {
    return value.indexOf(':') >= 0 ? `0${value}` : `${value}:`
  }
  if (value.length <= 5) return value

  const regex = /([0-9]{2}):?([0-9]{2})\s?(AM|PM)/g
  const time = value
    .toUpperCase()
    .replace(' ', '')
    .replace('A', 'AM')
    .replace('P', 'PM')
    .slice(0, 7)
  return regex.test(time) ? time.replace(regex, '$1:$2 $3') : ''
}

export const toCamelCase = (obj = {}) => {
  const camelCaseObj = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    let newKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase())
    newKey = newKey.replace(/_([0-9])/g, g => g[1])
    camelCaseObj[newKey] = value
  })
  return camelCaseObj
}

export const toSnakeCase = obj => {
  const snakeCaseObj = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    let newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
    newKey = newKey.replace(/([0-9])/g, '_$1')
    snakeCaseObj[newKey] = value
  })
  return snakeCaseObj
}
