import React from 'react'
import { useTheme } from '@mui/material/styles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { format, parseISO } from 'date-fns'
import useModals from '@/v2/hooks/useModals'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { currency } from '@/v2/utils/format'
import PaymentPlans from '../PaymentPlans'
import {
  Wrapper,
  Header,
  Summary,
  WhiteBox,
  ActionButtons,
  Row,
} from './styles'

const FinanceInfo = ({
  patient,
  financeInfo,
  creditCards,
  bankAccounts,
  paymentMethods,
}) => {
  const theme = useTheme()
  const { goTo } = useModals()

  return (
    <Wrapper theme={theme.palette}>
      {patient.fakeGfPatientId && (
        <Button
          variant="contained"
          color="secondary"
          sx={{
            margin: '0 0 24px auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '5px',
          }}
          onClick={() => {
            window.open(
              `https://smilebar-data.onrender.com/patients/${patient.fakeGfPatientId}/ledger`,
              '_blank',
              'noreferrer'
            )
          }}
        >
          Legacy Ledger on Smilebar Data
          <ExitToAppIcon />
        </Button>
      )}
      <Header>
        <Typography variant="h1" fontSize="26px">
          Finance
        </Typography>
        <Row>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              goTo(`/v2/patient/${patient.id}/finance/payment-plan/new`, {
                initialValues: { patient: patient },
              })
            }
          >
            + New Payment
          </Button>
        </Row>
      </Header>

      <Summary>
        <WhiteBox theme={theme.palette}>
          <Typography fontSize="16px">Total Paid</Typography>
          <Typography variant="h2" fontSize="20px">
            {currency(financeInfo?.totalPaid.toFixed(2))}
          </Typography>
        </WhiteBox>
        <WhiteBox theme={theme.palette}>
          <Typography fontSize="16px">Total Refunded</Typography>
          <Typography variant="h2" fontSize="20px">
            {currency((financeInfo?.totalRefunded * -1).toFixed(2))}
          </Typography>
        </WhiteBox>
        <WhiteBox theme={theme.palette}>
          <Typography fontSize="16px">Upcoming Payment</Typography>
          <Typography variant="h2" fontSize="20px">
            {financeInfo?.upcomingPayment !== null
              ? format(parseISO(financeInfo?.upcomingPayment), DAY_IN_US_FORMAT)
              : ' - '}
          </Typography>
        </WhiteBox>
        <WhiteBox theme={theme.palette}>
          <Typography fontSize="16px">
            {`Overdue ${
              financeInfo.overdueDays ? `(${financeInfo.overdueDays} days)` : ''
            }`}
          </Typography>
          <Typography
            variant="h2"
            fontSize="20px"
            color={
              theme.palette[financeInfo.overdueAmount < 0 ? 'red' : 'black']
                .main
            }
          >
            {currency(financeInfo.overdueAmount.toFixed(2))}
          </Typography>
        </WhiteBox>
      </Summary>

      <ActionButtons theme={theme.palette}>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/finance/manage-accounts/new`, {
              initialValues: { patient },
            })
          }
        >
          Manage Bank Accounts ({bankAccounts.length})
        </Button>

        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/finance/manage-cards/new`, {
              initialValues: { patient },
            })
          }
        >
          Manage Credit/Debit Cards ({creditCards.length})
        </Button>

        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/finance/receipts`, {
              initialValues: { patient },
            })
          }
        >
          HSA/FSA Receipt
        </Button>
      </ActionButtons>

      <PaymentPlans
        plans={financeInfo.paymentPlans}
        paymentMethods={paymentMethods}
        patientId={patient.id}
      />
    </Wrapper>
  )
}

export default FinanceInfo
