import React, { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Prompt } from 'react-router'
import Button from '@/v2/components/common/Button'
import { TextArea, InlineButtons } from './styles'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'
import { useGetDoctors } from '@/v2/hooks/useDoctors.js'
import { useCreateNote, useUpdateNote } from '@/v2/hooks/appointments/useNotes'
import Typography from '@/v2/components/common/Typography'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const NoteForm = ({
  showAlertBeforeLeaving = false,
  note,
  appointmentId,
  patientId,
  onSave,
  onEdit,
  confirmButtonStyle = 'contained',
  onCancel,
}) => {
  const ref = useRef(null)
  const { data: doctors, loading } = useGetDoctors()
  const { createNote } = useCreateNote(patientId, appointmentId)
  const { updateNote } = useUpdateNote(patientId, appointmentId, note?.id)
  const [updateAppointment] = useMutation(UpdateAppointment)
  const [value, setValue] = useState('')
  const [doctor, setDoctor] = useState()
  const { onOpenSnackbar } = useSnackbarContext()

  // TODO: remove setDoctor once doctor is not a required field on backend
  // TODO: is the previous TODO still valid?
  useEffect(() => {
    if (!loading && doctors.length) setDoctor(doctors[0])
    if (note) {
      setValue(note.annotation)
      ref.current.value = note.annotation
      note.doctor && setDoctor(note.doctor)
    }
  }, [loading, doctors, note])

  const handleSave = async () => {
    try {
      if (note) {
        // old note implementation (note on appointment table)
        // TODO: check if we still have notes like these or if it's already migrated
        if (note.id === 0) {
          const updatedAppointment = await updateAppointment({
            variables: { appointment: { id: appointmentId, notes: value } },
          })
          if (updatedAppointment.updateAppointment.errors !== '{}') {
            throw Error(updatedAppointment.updateAppointment.errors)
          }
          ref.current.value = ''
          onEdit({ ...note, annotation: value })
          onOpenSnackbar('Edited note.')
          setValue('')
        } else {
          // new note implementation (note has its own table)
          const { error, ...data } = await updateNote({
            doctorId: doctor.id,
            annotation: value,
          })

          if (error) {
            throw new Error(error)
          }

          ref.current.value = ''
          onEdit && onEdit(data)
          onOpenSnackbar('Edited note.')
          setValue('')
        }
      } else {
        const { error, ...data } = createNote({
          doctorId: doctor.id,
          annotation: value,
        })

        if (error) {
          throw new Error(error)
        }

        ref.current.value = ''
        onSave && onSave(data)
        onOpenSnackbar('Created note.')
        setValue('')
      }
    } catch (e) {
      console.error(e)
      onOpenSnackbar('Error on save.', 'error')
    }
  }

  let debounce
  const handleChange = value => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      setValue(value)
    }, 300)
  }

  if (!loading && doctors.length === 0)
    return (
      <Typography>Please, create a doctor to start creating notes.</Typography>
    )

  return (
    <>
      <Prompt
        when={showAlertBeforeLeaving && value !== ''}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <TextArea
        multiline
        className="note-input"
        inputRef={ref}
        rows={4}
        placeholder="Write a new note..."
        defaultValue=""
        onChange={({ target }) => handleChange(target.value)}
      />
      <InlineButtons className="note-input-buttons">
        <div>
          {onCancel && (
            <Button
              color="primary"
              variant="text"
              sx={{ fontSize: '14px', mr: '30px' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            color={confirmButtonStyle === 'contained' ? 'secondary' : 'primary'}
            variant={confirmButtonStyle}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </InlineButtons>
    </>
  )
}

export default NoteForm
