import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'

const FieldLabel = ({ children, align, ...otherProps }) => {
  const theme = useTheme()
  return (
    <Typography
      color={theme.palette.fieldLabel.main}
      variant="label"
      align={align}
      {...otherProps}
    >
      {children}
    </Typography>
  )
}

export default FieldLabel
