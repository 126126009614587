export const URL_DATE_FORMAT = 'yyyy-M-d'

export const DAY_DATE_FORMAT = 'd'

export const FULL_DATE_TEXT_FORMAT = 'd MMM, yyyy'

export const ALTERNATE_DATE_TEXT_FORMAT = 'MMMM d, yyyy'

export const FULL_MONTH_YEAR_FORMAT = 'MMMM yyyy'

export const MONTH_YEAR_FORMAT = 'MMM yyyy'

export const QUERY_DATE_FORMAT = 'dd/MM/yyyy'

export const DATE_FORMAT = 'MM/dd/yyyy'

export const MONTH_DATE_FORMAT = 'MMM dd'
