import React, { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { format, differenceInDays } from 'date-fns'
import SVGInline from 'react-svg-inline'
import CalendarIcon from '#/assets/images/v2/calendar-icon.svg?inline'
import TimeIcon from '#/assets/images/v2/clock-icon.svg?inline'
import RoomIcon from '#/assets/images/v2/room-icon.svg?inline'
import DoctorIcon from '#/assets/images/v2/doctor-icon.svg?inline'
import VirtualIcon from '#/assets/images/v2/virtual-icon.svg?inline'
import Typography from '@/v2/components/common/Typography'
import { DAY_IN_US_FORMAT, TIME_IN_US_FORMAT } from '@/v2/constants'
import useModals from '@/v2/hooks/useModals'

import {
  AppointmentInfo,
  Info,
  Item,
  ItemList,
  AppointmentInfoSection,
  AppointmentInfoHeader,
} from './styles'
import Button from '@/v2/components/common/Button'
import Notes from './Notes'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import ActionButtons from './ActionButtons'
import AppointmentButton from './AppointmentButton'

const AppointmentInformation = ({ appointment, onEdit, onCancel }) => {
  const theme = useTheme()
  const { goTo } = useModals()
  const hasEditableLabels = useMemo(
    () => appointment?.reschedule || appointment?.noShow,
    [appointment]
  )

  const {
    appointmentType,
    duration,
    startsAt,
    endsAt,
    room,
    doctor,
    virtual,
    patient,
    seated,
  } = appointment

  const currentDate = nowOnClinicTimezone()
  const startDate = getDateTimeWithoutTimezone(startsAt)
  const endDate = getDateTimeWithoutTimezone(endsAt)

  const daysOld = differenceInDays(currentDate, startDate)
  const onlyRead =
    (daysOld >= 1 && !hasEditableLabels && seated) || (daysOld >= 1 && seated)

  return (
    <AppointmentInfo>
      <AppointmentInfoSection theme={theme.palette}>
        <AppointmentInfoHeader>
          <Typography variant="h3" fontSize="16px">
            {`${appointmentType.title} (${duration} min)`}
          </Typography>
          <Button
            variant="text"
            onClick={() =>
              goTo(`/v2/appointment/${appointment.id}/edit`, {
                onlyRead,
                onEdit,
                onCancel,
              })
            }
            sx={{ color: theme.palette.black.label }}
          >
            {onlyRead ? 'View' : 'Edit'}
          </Button>
        </AppointmentInfoHeader>
        <ActionButtons appointment={appointment} onlyRead={onlyRead} />
        <Info>
          <ItemList>
            <Item>
              <SVGInline
                className="calendar-icon"
                svg={CalendarIcon}
                style={{ width: '14px', height: '14px' }}
              />
              {format(startDate, `EEEE ${DAY_IN_US_FORMAT}`)}
            </Item>
            <Item>
              <SVGInline
                className="time-icon"
                svg={TimeIcon}
                style={{ width: '14px', height: '14px' }}
              />
              {format(startDate, TIME_IN_US_FORMAT)}
              {' - '}
              {format(endDate, TIME_IN_US_FORMAT)}
            </Item>
            <Item>
              <SVGInline
                className="room-icon"
                svg={RoomIcon}
                style={{ width: '14px', height: '14px' }}
              />
              {virtual
                ? patient?.contactMethod || 'No preferred contact method'
                : (room?.title && room.title) || 'No room filled'}
            </Item>
            {!!doctor && (
              <Item>
                <SVGInline
                  className="doctor-icon"
                  svg={DoctorIcon}
                  style={{ width: '14px', height: '14px' }}
                />
                {doctor.fullName}
              </Item>
            )}
            {virtual && (
              <Item fontSize="14px" lineHeight="22px">
                <SVGInline
                  className="virtual-icon"
                  svg={VirtualIcon}
                  style={{ width: '14px', height: '14px' }}
                />
                This appointment is Virtual.
              </Item>
            )}
          </ItemList>
        </Info>
        <AppointmentButton appointment={appointment} onlyRead={onlyRead} />
      </AppointmentInfoSection>
      <Notes doctorId={doctor?.id} appointmentId={appointment?.id} />
    </AppointmentInfo>
  )
}

export default AppointmentInformation
