import React from 'react'
import Typography from '@/v2/components/common/Typography'
import Modal from '@/v2/components/common/Modal'
import Wrapper from '@/v2/components/common/Wrapper'

const ConsentFormConfirmation = ({ isOpen, title, onSave, onClose }) => {
  return (
    <Modal
      fullWidth
      isOpen={isOpen}
      title={'Consent Form Confirmation'}
      confirmLabel="Confirm"
      showClose={true}
      onConfirm={() => {
        onSave()
        onClose()
      }}
      onClose={onClose}
      maxWidth="xs"
    >
      <Wrapper>
        <Typography variant="h4" fontSize="16px" marginBottom="20">
          Would you like to send for signing the Consent Form:
        </Typography>
        <Typography bold variant="body2">
          {title || ''}
        </Typography>
      </Wrapper>
    </Modal>
  )
}

export default ConsentFormConfirmation
