import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useAddClaimItem = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addClaimItem, ...others } = requestMiddleware({
    key: ['AddClaimItem', `${claimId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/items.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { addClaimItem, ...others }
}

export const useUpdateClaimItem = (claimId, itemId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClaimItem, ...others } = requestMiddleware({
    key: ['UpdateClaimItem', `${itemId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/items/${itemId}.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(data),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { updateClaimItem, ...others }
}

export const useRemoveClaimItem = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: removeClaimItem, ...others } = requestMiddleware({
    key: ['RemoveClaimItem', `${claimId}`],
    request: async itemId =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/items/${itemId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { removeClaimItem, ...others }
}
