import React from 'react'
import { useTheme } from '@mui/material/styles'
import SVGInline from 'react-svg-inline/src'
import TrashIcon from '#/assets/images/v2/icons/trash.svg?inline'
import { getCreditCardIcon } from '@/v2/utils/credit-card'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import Typography from '@/v2/components/common/Typography'
import Box from '@mui/material/Box'

import usePaymentMethods from '@/v2/hooks/usePaymentMethods'

const SavedCard = ({ card, patient, setAction }) => {
  const theme = useTheme()
  const { removeInvoicesPaymentMethod } = usePaymentMethods()

  const { refetch } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const handleClick = async e => {
    e.preventDefault()

    if (
      confirm(
        'All invoices using this card will be effected.\n\nAre you sure you want to delete this card?'
      ) === true
    ) {
      await removeInvoicesPaymentMethod({
        patientId: patient.id,
        paymentMethodId: card.id,
      })

      await refetch()

      setAction('delete')
    }
  }

  return (
    <Box px={4} py={3} borderRadius={2} bgcolor={'gray.light'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box>{getCreditCardIcon(card.brand)}</Box>

        <Box>
          <Typography variant="h3">**** **** **** {card.last4}</Typography>
          <Typography
            variant="body3"
            color={theme.palette.gray.dark}
            lineHeight="16px"
          >
            Expires {card.expirationDate}
          </Typography>
        </Box>

        <Box>
          {/* TODO: use modal hook for redirect */}
          <a
            href="app/javascript/v2/containers/patient-finance/ManageCards/SavedCard/index#"
            onClick={handleClick}
          >
            <SVGInline svg={TrashIcon} width="20px" height="20px" />
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default SavedCard
