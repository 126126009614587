import { useQueryClient } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

// Treatment notes === Yellow notes

export const useCreateTreatmentNote = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: createTreatmentNote, ...others } = requestMiddleware({
    key: ['CreateTreatmentNote', patientId],
    request: async annotation =>
      await fetch(`${baseURL}/v2/rest/patients/${patientId}/treatments/notes`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ annotation }),
      }),
    onSuccess: result => {
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        [result, ...old].sort((a, b) => {
          return new Date(a?.startsAt || a?.createdAt) >
            new Date(b?.startsAt || b?.createdAt)
            ? -1
            : 1
        })
      )
    },
  })

  return { createTreatmentNote, ...others }
}

export const useUpdateTreatmentNote = (patientId, noteId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateTreatmentNote, ...others } = requestMiddleware({
    key: ['UpdateTreatmentNote', patientId, noteId],
    request: async annotation =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/notes/${noteId}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({ annotation }),
        }
      ),
    onSuccess: result => {
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        old
          ? old.map(item =>
              item.id === noteId && item?.classification === 'yellow'
                ? { ...item, ...result }
                : item
            )
          : old
      )
    },
  })

  return { updateTreatmentNote, ...others }
}

export const useDeleteTreatmentNote = (patientId, noteId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: deleteTreatmentNote, ...others } = requestMiddleware({
    key: ['DeleteTreatmentNote', patientId, noteId],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/notes/${noteId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        old
          ? old.filter(
              item => !(item.id === noteId && item?.classification === 'yellow')
            )
          : old
      )
    },
  })

  return { deleteTreatmentNote, ...others }
}
