import { DateTime, Settings, Info } from 'luxon'
import get from 'lodash/get'
import slice from 'lodash/slice'

import { URL_DATE_FORMAT, QUERY_DATE_FORMAT } from '@/v2/constants/dates'
import { getWeekStart } from '@/v2/utils/calendar'

class Datetime {
  _date
  dateTime

  constructor(dateTime) {
    this._date = dateTime.toString()
    this.dateTime = dateTime
  }

  static weekStart = 7

  static setDefaultTimeZone(timeZone) {
    if (Info.isValidIANAZone(timeZone)) {
      Settings.defaultZoneName = timeZone
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `Invalid timezone '${timeZone}' provided. Using client's instead.`
      )
    }
  }

  static setDefaultLocale(locale) {
    Settings.defaultLocale = locale
  }

  static now() {
    return new Datetime(DateTime.local())
  }

  static today() {
    return new Datetime(DateTime.local().startOf('day'))
  }

  static hasSame(dateTime1, dateTime2, unit) {
    if (!dateTime1 || !dateTime2) {
      return false
    }

    switch (unit) {
      case 'week':
        return (
          dateTime1.startOf('week').toISO() ===
          dateTime2.startOf('week').toISO()
        )
      default:
        return dateTime1.dateTime.hasSame(dateTime2.dateTime, unit)
    }
  }

  static fromObject(config) {
    return new Datetime(DateTime.fromObject(config))
  }

  static fromISO(text) {
    return new Datetime(DateTime.fromISO(text))
  }

  static fromFormat(text, format) {
    return new Datetime(DateTime.fromFormat(text, format))
  }

  static weekdays(length = 'long') {
    const weekdays = Info.weekdays(length)
    const index = Datetime.weekStart - 1

    return [...slice(weekdays, index), ...slice(weekdays, 0, index)]
  }

  get timestamp() {
    return this.dateTime.ts
  }

  get year() {
    return this.dateTime.year
  }

  get month() {
    return this.dateTime.month
  }

  get day() {
    return this.dateTime.day
  }

  get hour() {
    return this.dateTime.hour
  }

  get minute() {
    return this.dateTime.minute
  }

  get second() {
    return this.dateTime.second
  }

  get weekday() {
    return this.dateTime.weekday
  }

  get weekdayShort() {
    return this.dateTime.weekdayShort
  }

  get weekdayLong() {
    return this.dateTime.weekdayLong
  }

  valueOf() {
    return this.timestamp
  }

  isValid() {
    return this.dateTime.isValid
  }

  isToday() {
    return this.dateTime.hasSame(Datetime.today().dateTime, 'day')
  }

  isBefore(date) {
    return this.dateTime < date.dateTime
  }

  isAfter(date) {
    return this.dateTime > date.dateTime
  }

  isWeekend() {
    return [6, 7].includes(this.dateTime.weekday)
  }

  isWorkDay() {
    return !this.isWeekend()
  }

  toUrlFormat() {
    return this.dateTime.toFormat(URL_DATE_FORMAT)
  }

  toQueryFormat() {
    return this.dateTime.toFormat(QUERY_DATE_FORMAT)
  }

  toJSDate() {
    return this.dateTime.toJSDate()
  }

  toFormat(format) {
    return this.dateTime.toFormat(format)
  }

  toString() {
    return this.dateTime.toString()
  }

  toISO() {
    return this.dateTime.toISO()
  }

  toRelative(opts) {
    return this.dateTime.toRelative(opts)
  }

  set(config) {
    return new Datetime(this.dateTime.set(config))
  }

  get(unit) {
    return this.dateTime.get(unit)
  }

  plus(config) {
    return new Datetime(this.dateTime.plus(config))
  }

  minus(config) {
    return new Datetime(this.dateTime.minus(config))
  }

  startOf(unit) {
    switch (unit) {
      case 'week':
        return new Datetime(getWeekStart(this.dateTime, Datetime.weekStart))
      default:
        return new Datetime(this.dateTime.startOf(unit))
    }
  }

  endOf(unit) {
    switch (unit) {
      case 'week':
        return this.startOf('week')
          .plus({ day: 6 })
          .endOf('day')
      default:
        return new Datetime(this.dateTime.endOf(unit))
    }
  }

  diff(date, opts) {
    return this.dateTime.diff(date.dateTime, opts).toObject()
  }

  relativeTimeDiffInMinutes(date, opts) {
    const sameDayDate = date.set({
      year: this.dateTime.year,
      month: this.dateTime.month,
      day: this.dateTime.day,
    })

    const { minutes } = this.dateTime
      .diff(sameDayDate.dateTime, ['days', 'minutes', 'seconds'])
      .toObject()

    const aroundTheClock = get(opts, 'aroundTheClock', true)

    if (aroundTheClock) {
      return minutes < 0 ? 60 * 24 - Math.abs(minutes) : minutes
    } else {
      return minutes
    }
  }

  secondsSinceMidnight() {
    const midnight = this.startOf('day')
    return this.dateTime.diff(midnight.dateTime, 'seconds').toObject().seconds
  }
}

export default Datetime
