const fromForm = formValues => ({
  ...(formValues.id && { id: formValues.id }),
  firstName: formValues.firstname,
  lastName: formValues.lastname,
  email: formValues.email,
  phoneNumber: formValues.phone,
  referredBy: formValues.heardFrom,
})

export default fromForm
