import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import useModals from '@/v2/hooks/useModals'
import AppointmentsList from './AppointmentsList'
import { usePatientAppointments } from '@/v2/hooks/patient/usePatientAppointments'
import { usePatientContext } from '@/v2/contexts/PatientContext'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Appointments = () => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { patient } = usePatientContext()
  const {
    nextAppointments,
    previousAppointments,
    isLoading,
  } = usePatientAppointments(patient.id)

  if (isLoading) return null

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Appointments
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            goTo('/v2/event/new', {
              initialValues: {
                start: nowOnClinicTimezone(),
                patient: patient,
              },
            })
          }
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Add appointment</Typography>
        </Button>
      </Header>
      <AppointmentsList
        defaultExpanded
        title="Next"
        appointments={nextAppointments}
      />
      <AppointmentsList
        defaultExpanded={false}
        title="Prev"
        appointments={previousAppointments}
      />
    </Paper>
  )
}

export default Appointments
