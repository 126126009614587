import React, { useEffect } from 'react'
import useInvoiceActions from '@/v2/hooks/useInvoiceActions'
import Grid from '@mui/material/Grid'
import ActionButton from '@/v2/components/common/ActionButton'
import Box from '@mui/material/Box'
import Typography from '@/v2/components/common/Typography'
import SelectPaymentCardField from '@/v2/components/common/SelectPaymentCardField'
import SelectPaymentAccountField from '@/v2/components/common/SelectPaymentAccountField'
import ControlRadioField from '@/v2/components/common/ControlRadioField'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'
import InputField from '@/v2/components/common/InputField'
import Divider from '@/v2/components/common/Divider'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { fromCurrencyToNumber, toCurrency } from '@/v2/utils/helpers'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import FormControl from '@mui/material/FormControl'
import Label from '@/v2/components/common/FieldLabel'
import FormErrorMessage from '@/v2/components/common/FormErrorMessage'

const ChangePaymentMethodForm = ({
  invoice,
  onClose,
  patientId,
  creditCards,
  bankAccounts,
  type,
  isInstallPayment,
}) => {
  const { onOpenSnackbar } = useSnackbarContext()

  const { changeCardPaymentMethod, chargeInvoice } = useInvoiceActions({
    patientId,
  })

  const invoicePaymentMethodId = invoice.paymentMethodId || ''

  const hasPaymentMethods = creditCards?.length > 0

  const hasBankAccounts = bankAccounts?.length > 0

  const hasOnlyOnePaymentMethod = creditCards?.length === 1

  const paymentMethod = () => {
    if (hasOnlyOnePaymentMethod) {
      return 'credit_card'
    }

    if (hasPaymentMethods) {
      return 'credit_card'
    }

    if (hasBankAccounts) {
      return 'ach'
    }

    return 'cash'
  }

  const paymentMethodId =
    hasOnlyOnePaymentMethod && !invoicePaymentMethodId
      ? creditCards[0].id
      : invoicePaymentMethodId

  const schema = z
    .object({
      paymentMethodId: z.string().optional(),
      paymentMethod: z.enum(['cash', 'credit_card', 'check', 'ach']).optional(),
      status: z.string().min(0),
      additionalInfo: z.string().nullish(),
      amountTargeted: z
        .string()
        .transform(value => fromCurrencyToNumber(value))
        .optional(),
    })
    .refine(
      data => {
        if (data.status === 'chargeAndPay') {
          return data.amountTargeted > 0.5
        }
        return true
      },
      {
        message: 'Amount must be greater than $0.50',
        path: ['amountTargeted'],
      }
    )
    .refine(
      data => {
        if (data.status === 'change' && !data.paymentMethodId) {
          return false
        }
        return true
      },
      {
        message: 'Payment method is required',
        path: ['paymentMethodId'],
      }
    )
    .refine(
      data => {
        if (data.status === 'chargeAndPay' && !data.paymentMethod) {
          return false
        }
        return true
      },
      {
        message: 'Payment method is required',
        path: ['paymentMethod'],
      }
    )
    .refine(
      data => {
        if (
          data.status === 'chargeAndPay' &&
          (data.paymentMethod === 'credit_card' ||
            data.paymentMethod === 'ach') &&
          !data.paymentMethodId
        ) {
          return false
        }
        return true
      },
      {
        message: 'Payment method is required',
        path: ['paymentMethodId'],
      }
    )
    .refine(
      data => {
        if (data.amountTargeted && data.amountTargeted > invoice.dueAmount) {
          return false
        }
        return true
      },
      {
        message: 'Amount must be less than or equal to the invoice amount',
        path: ['amountTargeted'],
      }
    )
    .refine(
      data => {
        if (
          data.status === 'chargeAndPay' &&
          data.paymentMethod === 'check' &&
          !data.additionalInfo
        ) {
          return false
        }
        return true
      },
      {
        message: 'Additional payment info is required for this payment method',
        path: ['additionalInfo'],
      }
    )
  const {
    control,
    register,
    setError,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit: handleSubmitForm,
    watch,
  } = useForm({
    defaultValues: {
      paymentMethodId,
      status: 'charge',
      paymentMethod: paymentMethod(),
    },
    resolver: zodResolver(schema),
  })
  const watchStatus = watch('status')
  const watchPaymentMethod = watch('paymentMethod')

  const onSubmit = async data => {
    try {
      if (data.status !== 'chargeAndPay') {
        await changeCardPaymentMethod({
          invoiceId: invoice.id,
          paymentMethodId: data.paymentMethodId,
          isDefaultMethod: data.status === 'changeAll',
        })
      }

      const isPaymentMethod =
        data.paymentMethod === 'credit_card' || data.paymentMethod === 'ach'

      if (data.status === 'chargeAndPay') {
        const res = await chargeInvoice({
          invoiceId: invoice.id,
          paymentMethod: data.paymentMethod,
          amountTargeted: data.amountTargeted,
          paymentMethodId: isPaymentMethod ? data.paymentMethodId : null,
          gateway: isPaymentMethod ? 'stripe' : 'on_site',
          additionalInfo: data.additionalInfo,
        })

        if (res.error) {
          throw new Error(res.error)
        }
      }
    } catch (e) {
      setError('root.changePaymentMethod', {
        type: 'manual',
        message: e.message,
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const action = e.nativeEvent.submitter.name

    setValue('status', action)

    handleSubmitForm(data => onSubmit({ ...data, status: action }))()
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose()
      const message =
        type === 'charge'
          ? 'Invoice paid successfully'
          : 'Payment method changed successfully'
      onOpenSnackbar(message)
    }
  }, [isSubmitSuccessful, onClose, onOpenSnackbar, type])

  const paymentMethodOptions = [
    { label: 'Cash', value: 'cash' },
    { label: 'Check', value: 'check' },
  ]

  if (hasPaymentMethods) {
    paymentMethodOptions.unshift({
      label: 'Credit Card',
      value: 'credit_card',
    })
  }

  if (hasBankAccounts) {
    paymentMethodOptions.push({
      label: 'ACH',
      value: 'ach',
    })
  }

  return (
    <FormControl component={'form'} onSubmit={handleSubmit} variant="filled">
      <FormErrorMessage errors={errors} name="root.changePaymentMethod" />

      {type === 'charge' && (
        <Box display={'flex'} gap={1}>
          <Typography fontSize="14px">Due:</Typography>
          <Typography bold fontSize="14px">
            ${toCurrency(invoice.amountPayable)}
          </Typography>
        </Box>
      )}

      <Divider />

      {type === 'charge' && (
        <>
          <CurrencyInputField
            label={'Amount'}
            name="amountTargeted"
            placeholder="$0.00"
            prefix="$"
            error={errors?.amountTargeted?.message}
            control={control}
          />

          <FormControl variant="standard">
            <Label>Payment Method</Label>

            <ControlRadioField
              id="paymentMethod"
              options={paymentMethodOptions}
              color="secondary"
              column
              property="value"
              name="paymentMethod"
              control={control}
            />
          </FormControl>
        </>
      )}

      <FormControl variant="standard">
        {type === 'charge' && watchPaymentMethod === 'credit_card' ? (
          <Label>Select a credit card</Label>
        ) : type === 'charge' && watchPaymentMethod === 'check' ? (
          <Label>Add check payment additional info</Label>
        ) : null}

        {watchPaymentMethod === 'credit_card' || type === 'change' ? (
          <SelectPaymentCardField
            control={control}
            name="paymentMethodId"
            paymentMethods={creditCards}
            error={errors?.paymentMethodId}
          />
        ) : null}

        {watchPaymentMethod === 'ach' || type === 'change' ? (
          <SelectPaymentAccountField
            control={control}
            name="paymentMethodId"
            paymentMethods={bankAccounts}
            error={errors?.paymentMethodId}
          />
        ) : null}

        {watchPaymentMethod === 'check' ? (
          <InputField
            {...register('additionalInfo')}
            error={errors?.additionalInfo?.message}
            fullWidth
            multiline
            sx={{ padding: '4px 0 5px; !important' }}
            label="Additional info"
            autoFocus
            placeholder="Write a payment additional info here..."
          />
        ) : null}
      </FormControl>

      <Grid
        display={'flex'}
        gap={1}
        flexWrap={isInstallPayment ? 'wrap' : 'nowrap'}
        justifyContent={'flex-end'}
      >
        {type === 'charge' && (
          <ActionButton
            variant="contained"
            color="secondary"
            type="submit"
            name="chargeAndPay"
            disabled={isSubmitting}
            loading={watchStatus === 'chargeAndPay' && isSubmitting}
          >
            {watchPaymentMethod === 'cash' || watchPaymentMethod === 'check'
              ? 'Mark as paid'
              : 'Charge Now'}
          </ActionButton>
        )}

        {type === 'change' && (
          <ActionButton
            variant="contained"
            color="secondary"
            type="submit"
            name="change"
            fullWidth={isInstallPayment}
            disabled={isSubmitting}
            loading={watchStatus === 'change' && isSubmitting}
          >
            {isInstallPayment
              ? 'Update only this Payment'
              : 'Change Payment Method'}
          </ActionButton>
        )}

        {type === 'change' && isInstallPayment ? (
          <ActionButton
            variant="contained"
            color="primary"
            type="submit"
            name="changeAll"
            fullWidth
            disabled={isSubmitting}
            loading={watchStatus === 'chargeAll' && isSubmitting}
          >
            Update entire Payment Plan
          </ActionButton>
        ) : null}
      </Grid>
    </FormControl>
  )
}

export default ChangePaymentMethodForm
