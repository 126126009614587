import React, { useEffect } from 'react'
import styled from 'styled-components'
import { isSameDay } from 'date-fns'
import { useParams } from 'react-router-dom'
import { TOPBAR_HEIGHT } from '@/v2/constants'
import PatientRoutes from '@/v2/routes/PatientRoutes'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useTabContext } from '@/v2/contexts/TabsContext'
import { usePatient } from '@/v2/hooks/patient/usePatient'
import { usePatientOfficeNotes } from '@/v2/hooks/patient/usePatientNotes'
import { usePatientAppointments } from '@/v2/hooks/patient/usePatientAppointments'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import PatientBar from './PatientBar'
import ViewLoader from './ViewLoader'

const PatientWrapper = styled.div`
  display: block;
  max-height: calc(100vh - ${TOPBAR_HEIGHT});
  height: 100%;
  overflow: hidden;
`
const ErrorWrapper = styled.div`
  text-align: center;
  margin-top: 60px;
`

const DesktopPatient = () => {
  const { id } = useParams()
  const { onSetPatient, onSetOfficeNotes } = usePatientContext()
  const { onAddTab } = useTabContext()
  const { patient, isLoading, isError } = usePatient(id)
  const {
    notes,
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = usePatientOfficeNotes(id)
  const {
    nextAppointments,
    previousAppointments,
    isLoading: isLoadingAppointments,
  } = usePatientAppointments(id)

  useEffect(() => {
    if (!isLoading && !isError && !isLoadingAppointments) {
      onSetPatient(patient)

      const isToday = date =>
        isSameDay(getDateTimeWithoutTimezone(date), nowOnClinicTimezone())
      const isSeatedToday = appointment =>
        !!appointment?.seated && isToday(appointment?.seatedTime)

      const isSeated =
        (nextAppointments.length > 0 && isSeatedToday(nextAppointments[0])) ||
        (previousAppointments.length > 0 &&
          isSeatedToday(previousAppointments[0]))

      onAddTab({
        id: patient.id,
        name: patient.fullName,
        status: isSeated ? 'SEATED' : undefined,
      })
    }
  }, [isLoading, isError, isLoadingAppointments, patient])

  useEffect(() => {
    if (!isLoadingNotes && !isErrorNotes && notes) {
      onSetOfficeNotes(notes)
    }
  }, [isLoadingNotes, isErrorNotes, notes])

  if (isLoading) return <ViewLoader />
  if (isError) {
    // eslint-disable-next-line no-console
    console.error(isError)
    // eslint-disable-next-line react/no-unescaped-entities
    return <ErrorWrapper>Sorry! We couldn't load the page.</ErrorWrapper>
  }

  return (
    <PatientWrapper>
      <PatientBar />
      <PatientRoutes />
    </PatientWrapper>
  )
}

export default DesktopPatient
