import { useQuery } from '@tanstack/react-query'
import { baseURLGraphql, headers } from '@/v2/constants/requests'
import request from 'graphql-request'
import PatientFinanceInfo from './graphql/queries/PatientFinanceInfo.gql'

const usePatientFinanceInfo = props => {
  const { patientId } = props || {}

  const { data, isError, isLoading } = useQuery({
    queryKey: [
      'patient-finance-info',
      baseURLGraphql,
      headers,
      PatientFinanceInfo,
      patientId,
    ],
    queryFn: async () =>
      request(
        baseURLGraphql,
        PatientFinanceInfo,
        {
          id: patientId,
        },
        headers
      ),
  })

  return {
    patientFinanceInfo: data?.patientFinanceInfo || {},
    isLoading,
    isError,
  }
}

export default usePatientFinanceInfo
