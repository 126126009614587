import React from 'react'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'

export const Wrapper = styled.div`
  height: 31px;
  padding: 8px;
  text-align: start;
`

const ColumnTitle = ({ label }) => (
  <Wrapper>
    <Typography variant="h7" fontSize="13px">
      {label}
    </Typography>
  </Wrapper>
)

export default ColumnTitle
