import React from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { CONSENT_STATUS } from '@/v2/constants'
import GalleryItem from '@/v2/components/common/GalleryItem'
import Typography from '@/v2/components/common/Typography'
import Document from '#/assets/images/v2/document.jpg'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { DATE_FORMAT } from '@/v2/constants'
import {
  useConsentForm,
  useResendConsentForm,
} from '@/v2/hooks/documents/useConsentForm'
import useModals from '@/v2/hooks/useModals'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const ConsentForm = () => {
  const { id: patientId } = useParams()
  const { goTo } = useModals()
  const { onOpenSnackbar } = useSnackbarContext()
  const { consentForms, isLoading } = useConsentForm(patientId)
  const { resendConsentForm } = useResendConsentForm(patientId)
  const handleSend = async formId => {
    try {
      const { error } = await resendConsentForm(formId)

      if (!error) {
        onOpenSnackbar('Consent form sent', 'success')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  if (isLoading) return null

  return consentForms.map(form => {
    const status = CONSENT_STATUS[form.status]
    const viewConsentForm = {
      label: 'View',
      onClick: () =>
        goTo(`/v2/consent-form-details/${form.consentForm.id}`, {
          signature: form.patientSignature,
        }),
      target: '_blank',
    }
    const options =
      form.status !== 'signed'
        ? [
            viewConsentForm,
            { label: 'Send for Signature', onClick: () => handleSend(form.id) },
          ]
        : [
            viewConsentForm,
            {
              label: 'Download PDF',
              href: `/consent_form_signatures/${form.id}/pdf`,
              target: '_blank',
            },
          ]

    const createdAt = getDateTimeWithoutTimezone(form.createdAt)

    return (
      <GalleryItem
        key={form.id}
        preview={Document}
        contractStatus={status}
        options={options}
      >
        <Typography capitalize fontWeight="500" fontSize="16px">
          Consent Form {form.consentForm.title.toLowerCase()}
        </Typography>
        <Typography fontSize="14px">
          {format(createdAt, DATE_FORMAT)}
        </Typography>
      </GalleryItem>
    )
  })
}

export default ConsentForm
