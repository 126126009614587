import styled from 'styled-components'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import { WEEK_DAYS } from '@/v2/constants'

const WeekBarWrapper = styled.div`
  grid-row: 1;
  grid-column: 1 / span 7;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  height: 100%;
  background-color: ${({ theme }) => theme.background.default};
  z-index: 1;

  && > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`

const WeekBar = () => {
  const theme = useTheme()

  return (
    <WeekBarWrapper theme={theme.palette}>
      {WEEK_DAYS.map(weekday => (
        <span key={weekday}>
          <Typography fontSize="small">{weekday}</Typography>
        </span>
      ))}
    </WeekBarWrapper>
  )
}

export default WeekBar
