import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import Typography from '@/v2/components/common/Typography'

const Wrapper = styled.div`
  display: inline-flex;
  border-radius: 4px;
  padding: 1px 8px;
  background-color: ${({ color }) => color};
`

const InvoiceLabel = ({ status }) => {
  const theme = useTheme()
  return (
    <Wrapper
      color={
        status === 'declined'
          ? theme.palette.error.dark
          : status === 'on_going'
          ? theme.palette.link.main
          : theme.palette.gray.dark
      }
    >
      <Typography
        bold
        capitalize
        fontSize="12px"
        color={theme.palette.white.main}
      >
        {status.replace('_', ' ')}
      </Typography>
    </Wrapper>
  )
}

export default InvoiceLabel
