import React from 'react'
import styled from 'styled-components'
import TypographyMui from '@mui/material/Typography'

const Wrapper = styled(TypographyMui)`
  && {
    ${({ bold }) => (bold ? 'font-weight: 500;' : '')}
    ${({ italic }) => (italic ? 'font-style: italic;' : '')}
    ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')}
    ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
    ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
    ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : '')}
    ${({ color }) => (color ? `color: ${color};` : '')}
    ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
    ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
    ${({ wordbreak }) => (wordbreak ? 'word-break: break-word;' : '')}
    ${({ marginBottom }) =>
      marginBottom ? `margin-bottom: ${marginBottom}px;` : ''}
  }
`

const Typography = ({
  children,
  bold,
  italic,
  fontWeight,
  fontSize,
  uppercase,
  capitalize,
  color,
  textAlign,
  wordBreak,
  mb,
  theme,
  cursor,
  ...others
}) => (
  <Wrapper
    bold={bold ? 1 : 0}
    italic={italic ? 1 : 0}
    uppercase={uppercase ? 1 : 0}
    capitalize={capitalize ? 1 : 0}
    fontWeight={fontWeight}
    fontSize={fontSize}
    textAlign={textAlign}
    wordbreak={wordBreak ? 1 : 0}
    color={color}
    cursor={cursor}
    marginBottom={mb}
    {...others}
  >
    {children}
  </Wrapper>
)

export default Typography
