import React, { useEffect, useMemo, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
// import { isAfter } from 'date-fns'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { SelectCardField } from './components/SelectCardField'
import InputField from '@/v2/components/common/InputField'
import ControlRadioField from '@/v2/components/common/ControlRadioField'
import CurrencyInputField from '@/v2/components/common/CurrencyInputField'
import DiscountField from './components/DiscountField'
import InstallmentFields from './components/InstallmentFields'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import Divider from '@/v2/components/common/Divider'
import AddNewCard from '@/v2/components/common/AddNewCard'
import ContractField from './components/ContractField'
import ActionButton from '@/v2/components/common/ActionButton'
import Box from '@mui/material/Box'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import Row from '@/v2/components/common/Row'
import {
  updateContractAmounts,
  range,
  fromCurrencyToNumber,
} from '@/v2/utils/helpers'
import { nowOnClinicTimezone } from '@/v2/utils/convert'

const PlanForm = ({ patient, state, setState, onClose }) => {
  const theme = useTheme()
  const errorContainerRef = useRef(null)
  const contracts = patient.contracts.filter(
    contract => contract.signed && contract.active
  )
  const currentDate = nowOnClinicTimezone()

  const schema = z
    .object({
      classification: z.enum(['one_time_payment', 'installments']),
      description: z.string().min(3, { message: 'Description is required' }),
      amount: z
        .string()
        .refine(value => fromCurrencyToNumber(value) > 0.5, {
          message: 'Amount must be greater than $0.50',
        })
        .transform(value => fromCurrencyToNumber(value)),
      interestRate: z
        .number()
        .min(0)
        .transform(value => value / 100),
      discountRate: z
        .number()
        .min(0)
        .transform(value => value / 100),
      amountWithDiscount: z.number().min(0),
      totalDue: z.number().min(0),
      installmentAmount: z.number().min(0),
      paymentMethodType: z.enum(['card', 'payment_link', 'none', 'ach']),
      paymentMethodId: z.string().nullable(),
      contractId: z.number().nullable(),
      installments: z.number().min(0),
    })
    .refine(
      data => {
        if (!data.contractId) return true
        const contract = contracts.find(
          contract => parseInt(contract.id) === data.contractId
        )
        if (!contract) return true
        return data.amount <= contract.totalCost
      },
      {
        message: 'Amount must be less than or equal to the contract total cost',
        path: ['amount'],
      }
    )
    .refine(
      data => {
        if (data.paymentMethodType === 'card' && !data.paymentMethodId)
          return false
        return true
      },
      {
        message: 'Please select a card to pay with',
        path: ['paymentMethodId'],
      }
    )

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: state,
    resolver: zodResolver(schema),
  })

  const selectedContractId = watch('contractId')

  const contract = useMemo(() => {
    if (!selectedContractId) return null

    return contracts.find(
      contract => parseInt(contract.id) === selectedContractId
    )
  }, [contracts, selectedContractId])

  useEffect(() => {
    if (contract) {
      setValue('amount', contract.totalCost.toString())
      setValue('description', contract.description)
      if (contract.monthlyPaymentCount > 1) {
        setValue('classification', 'installments')
      } else {
        setValue('classification', 'one_time_payment')
      }
      setValue('discountRate', contract.discountRate * 100)
      setValue('interestRate', contract.interestRate * 100)
      setValue('installments', contract.monthlyPaymentCount)
      const res = updateContractAmounts({
        amount: contract.totalCost,
        discountRate: contract.discountRate,
        interestRate: contract.interestRate,
        installments: contract.monthlyPaymentCount,
        downPayment: contract.downPayment,
      })
      setState(s => ({ ...s, updatedInstallments: res.updatedInstallments }))
      setValue('amountWithDiscount', res.amountWithDiscount)
      setValue('installmentAmount', res.installmentAmount)
      setValue('totalDue', res.totalDue)
    }
  }, [contract, setValue, setState])

  const onSubmit = async data => {
    setState(s => ({ ...s, ...data }))
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setState(s => ({ ...s, contract, step: 2, error: null }))
    }
  }, [isSubmitSuccessful, setState, reset, contract])

  useEffect(() => {
    if (state.error) {
      errorContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [state.error])

  return (
    <Grid container gridTemplateColumns={1} rowGap={5}>
      <FormControl
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <ContractField
          name="contractId"
          register={register}
          errors={errors}
          contracts={contracts}
        />

        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Divider />

          <ControlRadioField
            id="classification"
            control={control}
            options={[
              { label: 'Single', value: 'one_time_payment' },
              { label: 'Installments', value: 'installments' },
            ]}
            error={errors?.classification?.message}
            name="classification"
            color="secondary"
            property="value"
            disabled={!!contract}
          />

          <InputField
            fullWidth
            label="Description"
            name="description"
            autoFocus
            placeholder="Write a description here..."
            error={errors?.description?.message}
            {...register('description')}
          />

          <Row>
            <CurrencyInputField
              label="Amount"
              name="amount"
              placeholder="$0.00"
              prefix="$"
              error={errors?.amount?.message}
              control={control}
              disabled={!!contract}
            />

            <DiscountField
              noMargin
              label="Apply discount"
              name="discountRate"
              id="discountRate"
              options={range(0, 50, 5).map(discount => ({
                label: `${discount}%`,
                value: discount,
              }))}
              color="secondary"
              errors={errors}
              register={register}
              setValue={setValue}
              control={control}
              contract={contract}
              disabled={!!contract}
            />
          </Row>

          <InstallmentFields
            errors={errors}
            register={register}
            setValue={setValue}
            control={control}
            theme={theme}
            disabled={!!contract}
            contract={contract}
            state={state}
          />
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          ref={errorContainerRef}
        >
          <Label variant="h4" bold color="grey.darkgrey" mb="5px" mt="10px">
            Payment Method
          </Label>

          {state.error && (
            <Box
              bgcolor={theme.palette.error.dark}
              color={theme.palette.common.white}
              p={1}
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              <ErrorOutline />
              <Typography wordBreak>{state.error}</Typography>
            </Box>
          )}

          <ControlRadioField
            id="payment-method-type"
            options={[
              { label: 'Choose Later', value: 'none' },
              { label: 'Send Payment Link', value: 'payment_link' },
              {
                label: 'Credit Card',
                value: 'card',
                // info: isAfter(currentDate, new Date('2024/06/01'))
                //   ? 'Patient pays 1,9% processing fee, will be added to invoice'
                //   : null,
              },
              {
                label: 'ACH Bank Account',
                value: 'ach',
                // info: isAfter(currentDate, new Date('2024/06/01'))
                //   ? 'No processing fee'
                //   : null,
              },
            ]}
            color="secondary"
            column
            property="value"
            name="paymentMethodType"
            control={control}
            sx={{ '& label:not(:first-of-type)': { marginTop: '10px' } }}
          />

          <SelectCardField
            control={control}
            name="paymentMethodId"
            patientId={patient.id}
            errors={errors}
          />
        </Box>
      </FormControl>

      <Divider />

      <AddNewCard patientId={patient.id} />

      <Grid container justifyContent="flex-end" gap={2}>
        <ActionButton
          variant="text"
          color="primary"
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </ActionButton>
        <ActionButton
          variant="contained"
          color="secondary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </ActionButton>
      </Grid>
    </Grid>
  )
}

export default PlanForm
