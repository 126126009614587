import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const baseUrl = window.location.origin

const useArchives = ({ patientId, folderId }) => {
  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()

  const { mutate: createArchive, isLoading: isLoadingCreate } = useMutation({
    mutationKey: ['createArchive', baseUrl, patientId, folderId],
    mutationFn: async archive =>
      fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/documents/folders/${folderId}/archives`,
        {
          method: 'POST',
          body: archive,
        }
      ),
    onSuccess: () => {
      onOpenSnackbar('Upload is done.')
    },
    onError: () => {
      onOpenSnackbar('Upload is not done. Something went wrong.', 'error')
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['folders', patientId, baseUrl],
      })
    },
  })

  const { mutate: updateArchive, isLoading: isLoadingUpdate } = useMutation({
    mutationKey: ['updateArchive', baseUrl, patientId, folderId],
    mutationFn: async archive =>
      fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/documents/folders/${folderId}/archives/${archive.id}`,
        {
          method: 'PUT',
          body: archive,
        }
      ),
    onSuccess: () => {
      onOpenSnackbar('Upload was updated.')
    },
    onError: () => {
      onOpenSnackbar('Upload was not updated. Something went wrong.', 'error')
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['folders', patientId, baseUrl],
      })
    },
  })

  const { mutate: deleteArchive, isLoading: isLoadingDelete } = useMutation({
    mutationKey: ['deleteArchive', baseUrl, patientId, folderId],
    mutationFn: async archive =>
      fetch(
        `${baseUrl}/v2/rest/patients/${patientId}/documents/folders/${folderId}/archives/${archive.id}`,
        {
          method: 'DELETE',
        }
      ),
    onSuccess: () => {
      onOpenSnackbar('Upload has been deleted.')
    },
    onError: () => {
      onOpenSnackbar(
        'Upload has not been deleted. Something went wrong.',
        'error'
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['folders', patientId, baseUrl],
      })
    },
  })

  return {
    isLoadingCreate,
    isLoadingUpdate,
    isLoadingDelete,
    createArchive,
    updateArchive,
    deleteArchive,
  }
}

export default useArchives
