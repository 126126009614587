import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { format, startOfMonth, endOfMonth, add, sub, isValid } from 'date-fns'
import SVGInline from 'react-svg-inline'
import ChevronLeft from '#/assets/images/v2/chevron-left.svg?inline'
import ChevronRight from '#/assets/images/v2/chevron-right.svg?inline'
import Typography from '@/v2/components/common/Typography'
import { getTileClass } from '@/v2/containers/sidebar/utils'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import useCalendarOccupancy from '@/v2/hooks/calendar/useCalendarOccupancy'

const CalendarWrapper = styled.div`
  margin: 7px 14px 13px 12px;
  justify-content: center;
  display: flex;
`

const NavigationWrapper = styled.div`
  display: flex;
  gap: 3.5px;
`

const NavigationButton = styled(SVGInline)`
  & > svg {
    height: 12px;
    display: flex;
    margin: 0 auto;
  }
`

const MiniCalendar = ({
  date,
  hasOccupancy = true,
  isGrayWhenFull = false,
  onSelect,
  onClickMonth,
}) => {
  const [day, setDay] = useState(new Date())
  const clinicNow = nowOnClinicTimezone()
  const queryDay = !day || !day?.length ? day || clinicNow : day[0]
  const { occupancy, isError } = useCalendarOccupancy({
    from: startOfMonth(queryDay),
    to: endOfMonth(queryDay),
  })

  useEffect(() => {
    const selectedDate = date && (date[0] || date)
    if (!isValid(selectedDate)) return
    setDay(date)
  }, [date, setDay, isValid])

  if (isError) {
    return (
      <CalendarWrapper>
        Sorry! We couldn't load the calendar info.
      </CalendarWrapper>
    )
  }

  const handleChange = ({ action }) => {
    if (!isValid(day)) return
    if (action === 'next') {
      setDay(add(day, { months: 1 }))
    } else {
      setDay(sub(day, { months: 1 }))
    }
  }

  return (
    <CalendarWrapper>
      <Calendar
        locale="en"
        calendarType="gregory"
        minDetail="month"
        maxDetail="month"
        value={day}
        prevLabel={<NavigationButton svg={ChevronLeft} />}
        nextLabel={<NavigationButton svg={ChevronRight} />}
        tileClassName={({ date }) =>
          hasOccupancy ? getTileClass(date, occupancy, isGrayWhenFull) : ''
        }
        navigationLabel={({ date }) => (
          <NavigationWrapper>
            <Typography
              variant="h2"
              onClick={() => onClickMonth && onClickMonth(date)}
              sx={{ cursor: onClickMonth ? 'pointer' : 'default' }}
            >
              {format(date, 'MMMM')}
            </Typography>
            <Typography variant="h2">{format(date, ' yyyy')}</Typography>
          </NavigationWrapper>
        )}
        onChange={date => onSelect(date)}
        onActiveStartDateChange={handleChange}
      />
    </CalendarWrapper>
  )
}

export default MiniCalendar
