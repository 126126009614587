import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@/v2/components/common/Typography'
import { useFindPayer } from '@/v2/hooks/insurance/useFindPayer'
import { formatSearch } from '@/v2/utils/format'
import { getHightlightText } from '@/v2/utils/highlight'
import Label from '@/v2/components/common/FieldLabel'
import { Wrapper, SearchField } from './styles'

const PayerSearch = ({ autoFocus, error, onChange, disabled = false }) => {
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')
  const { payers = [], isLoading, isError } = useFindPayer(searchTerm)

  let debounce
  const handleChangeSearch = (e, term) => {
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      setSearchTerm(formatSearch(term))
    }, 300)
  }

  return (
    <SearchField>
      <Autocomplete
        fullWidth
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={x => x}
        options={payers}
        loading={searchTerm ? isLoading : false}
        disabled={disabled}
        renderInput={params => (
          <Wrapper variant="standard">
            <Label htmlFor="payerId" align="left">
              Payer
            </Label>
            <TextField
              id="payerId"
              autoFocus={autoFocus}
              placeholder="Search for Payer"
              {...params}
              sx={{
                ...params.sx,
                '& .MuiInputBase-root': { paddingRight: '9px !important' },
              }}
              InputProps={params.InputProps}
              disabled={disabled}
            />
          </Wrapper>
        )}
        onInputChange={handleChangeSearch}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        noOptionsText="There are no results"
        onChange={(e, payer) => {
          onChange(payer)
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ display: 'block' }}>
            <Typography variant="h4" style={{ textTransform: 'capitalize' }}>
              {getHightlightText(option.code, searchTerm)} -{' '}
              {getHightlightText(option.name, searchTerm)}
            </Typography>
          </li>
        )}
      />
      {(error || isError) && (
        <Typography
          fontSize="10px"
          color={theme.palette.error.main}
          sx={{ mt: '4px !important' }}
        >
          {error || 'Something went wrong'}
        </Typography>
      )}
    </SearchField>
  )
}

export default PayerSearch
