import React from 'react'
import Box from '@mui/material/Box'

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
          display: 'flex',
          justifyContent: 'space-between',
          '&:last-child': {
            justifySelf: 'end',
          },
        },
      }}
    >
      {children}
    </Box>
  )
}

export default Wrapper
