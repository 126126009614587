import { useMutation, useQuery } from '@apollo/client'
import LabelsQuery from '@/v2/graphql/queries/Labels.gql'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'

export const NO_SHOW_LABEL = 'no show'

export const RESCHEDULE_LABEL = 'reschedule'

const useLabels = (props = {}) => {
  const { appointment } = props
  const { data, loading, error } = useQuery(LabelsQuery)
  const [updateAppointment] = useMutation(UpdateAppointment)
  const labels = data?.labels || []

  const handleUpdateLabels = async (labels, reschedule) => {
    try {
      if (reschedule) {
        return await updateAppointment({
          variables: {
            appointment: {
              id: appointment.id,
              labelIds: labels.map(({ id }) => id),
              reschedule: reschedule,
            },
          },
        })
      }
      await updateAppointment({
        variables: {
          appointment: {
            id: appointment.id,
            labelIds: labels.map(({ id }) => id),
          },
        },
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const addNoShowToAppointment = async () => {
    const noShowLabel = labels.find(label =>
      label.name.toLowerCase().includes(NO_SHOW_LABEL)
    )
    if (noShowLabel) {
      const labels = [...appointment.labels, noShowLabel]
      await handleUpdateLabels(labels)
    }
  }

  const removeNoShowFromAppointment = async () => {
    const noShowLabel = labels.find(label =>
      label.name.toLowerCase().includes(NO_SHOW_LABEL)
    )
    if (noShowLabel) {
      const labels = appointment.labels.filter(
        label => label.id !== noShowLabel.id
      )
      await handleUpdateLabels(labels)
    }
  }

  const addRescheduledToAppointment = async () => {
    const rescheduledLabel = labels.find(label =>
      label.name.toLowerCase().includes(RESCHEDULE_LABEL)
    )
    if (rescheduledLabel) {
      const labels = [...appointment.labels, rescheduledLabel]
      await handleUpdateLabels(labels, true)
    }
  }

  const removeRescheduledFromAppointment = async () => {
    const rescheduledLabel = labels.find(label =>
      label.name.toLowerCase().includes(RESCHEDULE_LABEL)
    )
    if (rescheduledLabel) {
      const labels = appointment.labels.filter(
        label => label.id !== rescheduledLabel.id
      )
      await handleUpdateLabels(labels, false)
    }
  }

  return {
    labels,
    loading,
    error,
    handleUpdateLabels,
    addNoShowToAppointment,
    removeNoShowFromAppointment,
    addRescheduledToAppointment,
    removeRescheduledFromAppointment,
  }
}

export default useLabels
