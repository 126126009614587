import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Typography from '@/v2/components/common/Typography'
import usePatient from '@/v2/hooks/patient/usePatient'
import { useCreateConversation } from '@/v2/hooks/messages/useConversations'

const NewConversation = () => {
  const history = useHistory()
  const { phone, patientId } = useParams()
  const { patient, isLoading: isLoadingPatient } = usePatient(patientId)
  const {
    createConversation,
    newConversation,
    isLoading: isCreatingConversation,
  } = useCreateConversation()

  useEffect(() => {
    if ((!isLoadingPatient && !!patient) || phone) {
      const conversationCreation = async () => {
        await createConversation({
          phoneNumber: phone || patient?.phoneNumber,
          patientId,
        })
      }
      conversationCreation()
    }
  }, [isLoadingPatient, patient])

  useEffect(() => {
    if (newConversation?.id) {
      history.push(`/v2/messages/${newConversation.id}`)
    }
  }, [newConversation])

  if ((patientId && isLoadingPatient) || isCreatingConversation)
    return <Typography sx={{ margin: '20px' }}>Loading...</Typography>

  return null
}

export default NewConversation
