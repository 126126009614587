import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useCallback } from 'react'

export const TabsContext = createContext([])

export const TabProvider = ({ children }) => {
  const [tabs, setTabs] = useState([])

  // Local Storage: setting & getting data on state
  useEffect(() => {
    const tabsData = JSON.parse(localStorage.getItem('tabs'))

    if (tabsData) {
      setTabs(tabsData)
    }
  }, [])
  // Local Storage: setting data on local storage
  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(tabs))
  }, [tabs])

  const handleAddTab = useCallback(
    tab => {
      // remove duplicated tab
      const hasOpenTab = !!tabs.find(
        ({ id }) => parseInt(id, 10) === parseInt(tab.id, 10)
      )
      if (!hasOpenTab) {
        setTabs(state => [...state, tab])
      }
    },
    [tabs]
  )

  const handleCloseTab = useCallback(
    tabId => {
      const newTabs = tabs.filter(item => item.id !== tabId)
      setTabs(newTabs)
    },
    [tabs]
  )

  const handleResetTabs = useCallback(() => {
    setTabs([])
  }, [])

  const activeTabs = useMemo(
    () => ({
      tabs,
      onAddTab: handleAddTab,
      onCloseTab: handleCloseTab,
      onResetTab: handleResetTabs,
    }),
    [tabs, handleAddTab, handleCloseTab, handleResetTabs]
  )
  return (
    <TabsContext.Provider value={activeTabs}>{children}</TabsContext.Provider>
  )
}

export const useTabContext = () => {
  const { tabs, onAddTab, onCloseTab, onResetTab } = useContext(TabsContext)

  return { tabs, onAddTab, onCloseTab, onResetTab }
}
