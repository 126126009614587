import styled from 'styled-components'
import { SIDEBAR_WIDTH, TOPBAR_HEIGHT } from '@/v2/constants'

export const SidebarWrapper = styled.div`
  width: ${SIDEBAR_WIDTH};
  min-width: ${SIDEBAR_WIDTH};
  height: 100vh;
  border-right: 1px solid ${({ theme }) => theme.primary.main};
  overflow: hidden;

  & .react-calendar__tile--active {
    background-color: transparent;
  }

  & .react-calendar__tile--range,
  & .react-calendar__tile--hasActive {
    background-color: ${({ theme }) => theme.calendar.main};
    border: ${({ theme }) => theme.calendar.border};

    &.green-line > abbr,
    &.yellow-line > abbr,
    &.red-line > abbr {
      color: ${({ theme }) => theme.calendar.contrastText} !important;
    }
  }
`

export const Topbar = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  height: ${TOPBAR_HEIGHT};
  display: flex;
  align-items: center;
  padding-left: 20px;
`
