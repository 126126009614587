import React from 'react'
import Modal from '@/v2/components/common/Modal'
import CreateContractForm from './CreateContractForm/CreateContractForm'
import { useCreateContractState } from './CreateContractStateContext/CreateContractStateContext'
import ContractConfirm from './ContractConfirm'

const CreateContractModal = ({ isOpen, patientId, email, onClose }) => {
  const [state, setState] = useCreateContractState()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create Contract"
      footer={<></>}
      disableBackdropClick
      fullWidth
      maxWidth="xs"
    >
      {state.step === 2 ? (
        <ContractConfirm
          patientId={patientId}
          email={email}
          onClose={onClose}
          state={state}
          setState={setState}
        />
      ) : (
        <CreateContractForm
          patientId={patientId}
          state={state}
          setState={setState}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}

export default CreateContractModal
