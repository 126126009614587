import React from 'react'
import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`

const LoadingItem = () => (
  <Wrapper>
    <Skeleton height="30px" />
    <Skeleton height="30px" />
    <Skeleton height="30px" />
  </Wrapper>
)

export default LoadingItem
