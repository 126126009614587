import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  padding: 60px 80px;
  margin: -64px auto 0;
  width: 556px;
  background: ${({ theme }) => theme.silver.main};
  border-radius: 4px;
`

const DisabledFinance = ({ onboardingStatus, refetch }) => {
  const theme = useTheme()

  const handleClick = async () => {
    window.open('/settings/finance/new', '_self')
  }

  return (
    <Wrapper theme={theme.palette}>
      {onboardingStatus === 'pending_verification' ? (
        <Body theme={theme.palette}>
          <Typography align="center" fontSize="18px">
            Your Stripe account is undergoing verification.
          </Typography>
          <Typography align="center" sx={{ mb: '32px' }}>
            Wait few minutes and click refresh button.
          </Typography>
          <Button
            sx={{ width: 'max-content', margin: 'auto' }}
            id="setup-stripe-account"
            key="setup-stripe-account"
            variant="contained"
            color="secondary"
            onClick={() => refetch()}
          >
            Refresh
          </Button>
        </Body>
      ) : (
        <Body theme={theme.palette}>
          <Typography align="center" fontSize="18px">
            No Stripe account defined.
          </Typography>
          <Typography align="center" sx={{ mb: '32px' }}>
            Click on the link below to set up a Stripe Account.
          </Typography>
          <Button
            sx={{ width: 'max-content', margin: 'auto' }}
            id="setup-stripe-account"
            key="setup-stripe-account"
            variant="contained"
            color="secondary"
            onClick={() => handleClick()}
          >
            Setup Stripe Account
          </Button>
        </Body>
      )}
    </Wrapper>
  )
}

export default DisabledFinance
