import React, { createContext, useContext, useState } from 'react'

export const initCreateContractValues = {
  classification: 'one_time_payment',
  description: '',
  amount: '',
  interestRate: 0,
  discountRate: 0,
  amountWithDiscount: 0,
  totalDue: 0,
  downPayment: '',
  installmentAmount: 0,
  installmentAmountWithoutDiscount: 0,
  installments: 1,
  updatedInstallments: null,
  step: 1,
  error: null,
  sendEmail: true,
}

export const CreateContractStateContext = createContext({})

export function CreateContractProvider({ children }) {
  const value = useState(initCreateContractValues)

  return (
    <CreateContractStateContext.Provider value={value}>
      {children}
    </CreateContractStateContext.Provider>
  )
}

export function useCreateContractState() {
  const context = useContext(CreateContractStateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppProvider')
  }
  return context
}
