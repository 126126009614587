import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import { useHistory, useLocation } from 'react-router-dom'
import Tab from '@/v2/components/common/Tab'
import Typography from '@/v2/components/common/Typography'
import { useTabContext } from '@/v2/contexts/TabsContext'

const TabContent = styled.div`
  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80px;
  }
`

const PatientTab = styled.div`
  display: flex;
  overflow-x: auto;

  /* hides scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

const PatientTabs = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { tabs, onCloseTab } = useTabContext()
  const theme = useTheme()

  const handleClose = (id, href) => {
    const isActualPage = pathname.indexOf(href) >= 0
    onCloseTab(id)
    if (isActualPage) history.push('/v2/calendar/day')
  }

  const handleTransformScroll = event => {
    if (!event.deltaY) return

    event.currentTarget.scrollLeft += event.deltaY + event.deltaX
  }

  return (
    <PatientTab onWheel={e => handleTransformScroll(e)}>
      {tabs.map(({ id, name, status }) => {
        const names = name.split(' ')
        const href = `/v2/patient/${id}`
        return (
          <Tab
            hasClose
            key={id}
            href={href}
            title={`Patient ${name}`}
            onClose={() => handleClose(id, href)}
          >
            <TabContent data-info="Patient Tab">
              {status && <Typography variant="h6">{status}</Typography>}
              <Typography
                variant="h4"
                fontWeight={theme.typography?.tabs?.fontWeight || 400}
                fontSize="14px"
              >
                {names[0]}
              </Typography>
              <Typography
                variant="h4"
                fontWeight={theme.typography?.tabs?.fontWeight || 400}
                fontSize="14px"
              >
                {names[names.length - 1]}
              </Typography>
            </TabContent>
          </Tab>
        )
      })}
    </PatientTab>
  )
}

export default PatientTabs
