import { useQuery } from '@tanstack/react-query'
import { baseURL } from '@/v2/constants/requests'

export const usePatientOfficeNotes = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['OfficeNotes', patientId],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/notes`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId,
  })

  return { notes: data, isLoading, isError }
}
