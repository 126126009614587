import get from 'lodash/get'

import Datetime from '@/v2/modules/Datetime'
import { INPUT_TIME_FORMAT } from '@/v2/constants/time'

export const valid = opts => value => {
  const format = get(opts, 'format', INPUT_TIME_FORMAT)
  const _value = Datetime.fromFormat(value, format)

  if (!_value.isValid()) {
    return get(opts, 'message', 'This time must valid')
  }
}

export const isAfter = opts => value => {
  const format = get(opts, 'format', INPUT_TIME_FORMAT)
  const reference = get(opts, 'reference', '00:00 AM')

  const _reference = Datetime.fromFormat(reference, format)
  const _value = Datetime.fromFormat(value, format)

  if (!_value.isAfter(_reference)) {
    return get(opts, 'message', `This time must be after ${reference}`)
  }
}
