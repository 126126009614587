import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import Typography from '@/v2/components/common/Typography'
import { formatPhone } from '@/v2/utils/format'
import { Item, PatientSection } from './styles'
import PatientAvatar from '@/v2/components/common/PatientAvatar'

const Patient = ({ patient }) => {
  const { id, fullName, phoneNumber } = patient
  const theme = useTheme()
  const history = useHistory()

  return (
    <Item
      theme={theme.palette}
      onClick={() => {
        if (phoneNumber === null) {
          alert(
            "This patient doesn't have a phone number!\nPlease, update the patient contact info."
          )
          history.push(`/v2/patient/${id}`)
        } else {
          history.push(`/v2/messages/start-conversation/${id}`)
        }
      }}
    >
      <PatientAvatar
        patient={patient}
        size={50}
        sx={{ mr: '10px', width: 50, height: 50 }}
      />
      <PatientSection>
        <Typography fontSize="14px" fontWeight="500" lineHeight="18px">
          {fullName || 'No name patient'}
        </Typography>
        <Typography fontSize="14px" fontWeight="500" lineHeight="18px">
          {phoneNumber ? formatPhone(phoneNumber) : 'No phone found'}
        </Typography>
      </PatientSection>
    </Item>
  )
}

export default Patient
