import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useShowEligibility } from '@/v2/hooks/insurance/useEligibilities'
import { ContentWrapper, AccordionStyled } from './show-lists/styles'
import ActiveCoverage from './show-lists/ActiveCoverage'
import EligibilityItem from './show-lists/EligibilityItem'

const Show = ({ eligibility }) => {
  const [activeCoverage, setActiveCoverage] = useState({})
  const [expandedPanel, setExpandedPanel] = useState('activeCoverage')
  const [coInsurances, setCoInsurances] = useState([])
  const [deductibles, setDeductibles] = useState([])
  const [limitations, setLimitations] = useState([])
  const { eligibilityInfo, isLoading, isError } = useShowEligibility(
    eligibility?.insurance?.id,
    eligibility?.id
  )

  useEffect(() => {
    if (eligibilityInfo?.response) {
      const {
        coInsurances,
        deductibles,
        limitationAndMaximum,
        activeCoverage,
      } = eligibilityInfo.response

      const [activeCoverageData] = activeCoverage || []

      setLimitations(limitationAndMaximum)
      setDeductibles(deductibles)
      setCoInsurances(coInsurances)
      setActiveCoverage(activeCoverageData)
    }
  }, [eligibilityInfo])

  if (isLoading && !isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          Loading eligibility...
        </Typography>
      </ContentWrapper>
    )

  if (isError)
    return (
      <ContentWrapper>
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          It wasn't possible to load the eligibility.
        </Typography>
      </ContentWrapper>
    )

  const coInsurancesFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    coverageLevel: { label: 'Coverage Level' },
    percent: { label: 'Percentage' },
  }

  const deductiblesFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    amount: { label: 'Amount' },
    coverageLevel: { label: 'Coverage Level' },
  }

  const limitationsFields = {
    insuranceType: { label: 'Insurance Type' },
    serviceType: { label: 'Service Type' },
    network: { label: 'Network' },
    planPeriod: { label: 'Plan Period' },
    amount: { label: 'Amount' },
  }

  return (
    <ContentWrapper>
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'activeCoverage'}
        noBackground
        onChange={() =>
          setExpandedPanel(
            expandedPanel === 'activeCoverage' ? null : 'activeCoverage'
          )
        }
        title={<Typography>Active Coverage</Typography>}
        content={<ActiveCoverage activeCoverage={activeCoverage} />}
      />
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'coInsurances'}
        onChange={() =>
          setExpandedPanel(
            expandedPanel === 'coInsurances' ? null : 'coInsurances'
          )
        }
        title={<Typography>Co-Insurance</Typography>}
        content={
          <EligibilityItem
            data={coInsurances}
            fields={coInsurancesFields}
            searchLabel="Search Co-Insurance"
          />
        }
      />
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'deductibles'}
        onChange={() =>
          setExpandedPanel(
            expandedPanel === 'deductibles' ? null : 'deductibles'
          )
        }
        title={<Typography>Deductible</Typography>}
        content={
          <EligibilityItem
            data={deductibles}
            fields={deductiblesFields}
            searchLabel="Search Deductible"
          />
        }
      />
      <AccordionStyled
        noPaddingTitle
        expanded={expandedPanel === 'limitations'}
        onChange={() =>
          setExpandedPanel(
            expandedPanel === 'limitations' ? null : 'limitations'
          )
        }
        title={<Typography>Limitation and Maximum</Typography>}
        content={
          <EligibilityItem
            data={limitations}
            fields={limitationsFields}
            type="Search Limitation and Maximum"
          />
        }
      />
    </ContentWrapper>
  )
}

export default Show
