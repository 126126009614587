import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useAddClaimProvider = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addClaimProvider, ...others } = requestMiddleware({
    key: ['AddClaimProvider', `${claimId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/providers.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { addClaimProvider, ...others }
}

export const useUpdateClaimProvider = (claimId, providerId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClaimProvider, ...others } = requestMiddleware({
    key: ['UpdateClaimProvider', `${providerId}`],
    request: async body =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/providers/${providerId}.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(body),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { updateClaimProvider, ...others }
}

export const useRemoveClaimProvider = claimId => {
  const queryClient = useQueryClient()
  const { mutateAsync: removeClaimProvider, ...others } = requestMiddleware({
    key: ['RemoveClaimProvider'],
    request: async providerId =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/providers/${providerId}.json`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['ShowClaim', `${claimId}`],
      })
    },
  })

  return { removeClaimProvider, ...others }
}

export const useGetTaxonomyCodes = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['TaxonomyCodes'],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/taxonomies`)
      if (!res.ok) {
        throw new Error("We couldn't find taxonomies")
      }
      const result = await res.json()
      return result
    },
  })

  return { taxonomyCodes: data, isLoading, isError }
}
