import validate from '@/v2/validations/validate'
import { required } from '@/v2/validations/validators/general'
import { valid as validTime } from '@/v2/validations/validators/time'
import { isAfter } from '@/v2/validations/validators/time'

const validator = values => {
  const { type } = values
  return validate(values, {
    clinicId: type === 'clinic' && required({ message: 'Select a clinic' }),
    roomId: type === 'room' && required({ message: 'Pick a room' }),
    doctorId: type === 'doctor' && required({ message: 'Pick a doctor' }),
    startsAtDate: [required({ message: 'Date is required' })],
    startsAtTime: [
      required({ message: 'Start time is required' }),
      validTime({ message: 'Invalid time' }),
    ],
    endsAtTime: [
      required({ message: 'End time is required' }),
      validTime({ message: 'Invalid time' }),
      isAfter({
        reference: values.startsAtTime,
        message: 'Must be after start',
      }),
    ],
  })
}

export default validator
