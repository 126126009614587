import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Loader = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 16px;
  padding: 0 24px;
  flex-direction: column;
  width: 100%;
`

const DefaultLoader = () => {
  return (
    <Loader>
      <CircularProgress
        key="loader"
        sx={{ margin: '10px auto', display: 'block' }}
      />
    </Loader>
  )
}

export default DefaultLoader
