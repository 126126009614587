import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useClaims = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Claims', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/insurances/claims.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient claims")
      }
      const result = await res.json()
      return result
    },
    enabled: !!patientId,
  })

  return { claims: data, isLoading, isError }
}

export const useShowClaim = (insuranceId, claimId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['ShowClaim', `${claimId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/insurances/${insuranceId}/claims/${claimId}.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the claim")
      }
      const result = await res.json()
      return result
    },
    enabled: !!claimId,
  })

  return { claimInfo: data, isLoading, isError }
}

export const useAddClaim = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addClaim, ...others } = requestMiddleware({
    key: ['AddClaim', `${patientId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/${data.insuranceId}/claims.json`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(data.body),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { addClaim, ...others }
}

export const useUpdateClaimInfo = (insuranceId, claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateClaim, ...others } = requestMiddleware({
    key: ['UpdateClaim', `${claimId}`],
    request: async data =>
      await fetch(
        `${baseURL}/v2/rest/insurances/${insuranceId}/claims/${claimId}.json`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(data.body),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { updateClaim, ...others }
}

// START CLAIM ACTIONS
export const useCancelClaim = (claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: cancelClaim, ...others } = requestMiddleware({
    key: ['CancelClaim', `${claimId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/cancel.json`,
        {
          method: 'POST',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { cancelClaim, ...others }
}

export const useApproveClaim = (claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: approveClaim, ...others } = requestMiddleware({
    key: ['ApproveClaim', `${claimId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/approve.json`,
        {
          method: 'POST',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { approveClaim, ...others }
}

export const useDenyClaim = (claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: denyClaim, ...others } = requestMiddleware({
    key: ['DenyClaim', `${claimId}`],
    request: async () =>
      await fetch(`${baseURL}/v2/rest/insurances/claims/${claimId}/deny.json`, {
        method: 'POST',
        headers,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { denyClaim, ...others }
}

export const useSubmitClaim = (claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: submitClaim, ...others } = requestMiddleware({
    key: ['SubmitClaim', `${claimId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/submit.json`,
        {
          method: 'POST',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { submitClaim, ...others }
}

export const useValidateClaim = (claimId, patientId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: validateClaim, ...others } = requestMiddleware({
    key: ['ValidateClaim', `${claimId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/insurances/claims/${claimId}/validate.json`,
        {
          method: 'POST',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Claims', `${patientId}`],
      })
    },
  })

  return { validateClaim, ...others }
}
// END CLAIM ACTIONS
