import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import getRooms from '@/v2/graphql/queries/Rooms.gql'
import getDoctors from '@/v2/graphql/queries/Doctors.gql'
import getAppointment from '@/v2/graphql/queries/Appointment.gql'

export const useLoadData = appointmentId => {
  const {
    data: dataRooms,
    loading: loadingRooms,
    error: errorRooms,
  } = useQuery(getRooms)
  const {
    data: dataDoctors,
    loading: loadingDoctors,
    error: errorDoctors,
  } = useQuery(getDoctors)
  const {
    data: dataAppointment,
    loading: loadingAppointment,
    error: errorAppointment,
  } = useQuery(getAppointment, { variables: { id: appointmentId } })

  const loading = loadingRooms || loadingDoctors || loadingAppointment
  const error = errorRooms || errorDoctors || errorAppointment

  const rooms = dataRooms?.clinic?.rooms || []
  const doctors = dataDoctors?.doctors || []
  const { appointment } = dataAppointment || {}

  return useMemo(() => ({ loading, error, rooms, doctors, appointment }), [
    loading,
    error,
    rooms,
    doctors,
    appointment,
  ])
}
