import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, headers, requestMiddleware } from '@/v2/constants/requests'

export const useNotes = (patientId, appointmentId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Notes', `${appointmentId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/appointments/${appointmentId}/notes`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient notes")
      }
      const result = await res.json()

      return result
    },
    enabled: !!patientId && !!appointmentId,
  })

  return { notes: data, isLoading, isError }
}

export const useCreateNote = (patientId, appointmentId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: createNote, ...others } = requestMiddleware({
    key: ['CreateNote', appointmentId],
    request: async ({ doctorId, annotation }) =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/appointments/${appointmentId}/notes`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify({ doctor_id: doctorId, annotation }),
        }
      ),
    onSuccess: result => {
      queryClient.invalidateQueries({
        queryKey: ['Notes', `${appointmentId}`],
      })
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        old
          ? old.map(item =>
              item.id === appointmentId && !!item.appointmentType
                ? {
                    ...item,
                    notes: [...item.notes, result],
                  }
                : item
            )
          : old
      )
    },
  })

  return { createNote, ...others }
}

export const useUpdateNote = (patientId, appointmentId, noteId) => {
  const queryClient = useQueryClient()

  if (!noteId) return {}

  const { mutateAsync: updateNote, ...others } = requestMiddleware({
    key: ['UpdateNote', appointmentId, noteId],
    request: async ({ doctorId, annotation }) =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/appointments/${appointmentId}/notes/${noteId}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({ doctor_id: doctorId, annotation }),
        }
      ),
    onSuccess: result => {
      queryClient.invalidateQueries({
        queryKey: ['Notes', `${appointmentId}`],
      })
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        old
          ? old.map(item =>
              item.id === appointmentId && !!item.appointmentType
                ? {
                    ...item,
                    notes: item.notes.map(note =>
                      note.id === noteId ? { ...note, ...result } : note
                    ),
                  }
                : item
            )
          : old
      )
    },
  })

  return { updateNote, ...others }
}

export const useDeleteNote = (patientId, appointmentId, noteId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: deleteNote, ...others } = requestMiddleware({
    key: ['DeleteNote', appointmentId, noteId],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/treatments/appointments/${appointmentId}/notes/${noteId}`,
        {
          method: 'DELETE',
          headers,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Notes', `${appointmentId}`],
      })
      queryClient.setQueryData(['Treatments', `${patientId}`], old =>
        old
          ? old.map(item =>
              item.id === appointmentId && !!item.appointmentType
                ? {
                    ...item,
                    notes: item.notes.filter(note => note.id !== noteId),
                  }
                : item
            )
          : old
      )
    },
  })

  return { deleteNote, ...others }
}
