import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import SelectField from '@/v2/components/common/SelectField'
import SelectCalendar from '@/v2/components/common/SelectCalendar'
import { useCheckAppointmentWarnings } from '@/v2/hooks/useCheckAppointmentWarnings'
import { useAvailableTimeSlots } from '@/v2/hooks/useAvailableTimeSlots'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import {
  convertMinutesToHours,
  convertHoursToMinutes,
} from '@/v2/utils/convert'

const TwoColumns = styled.div`
  display: inline-flex;
  column-gap: 10px;

  & > div {
    width: 50%;
  }
`

const formatOptions = array =>
  array.map(item => ({
    value: item.id,
    label: `${item.title} (${item.duration} min)`,
    ...item,
  }))

const formatWarning = warning => {
  if (!warning) return warning
  const str = warning.join(' and ').toLocaleLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const AppointmentForm = props => {
  const {
    onlyRead = false,
    values = {
      doctorId: '',
      typeId: '',
      roomId: '',
      startsAtDate: '',
      startsAtTime: '',
      duration: '',
    },
    error = {},
    doctorsOptions = [],
    typesOptions: allTypes = [],
    roomsOptions = [],
    onChange,
  } = props
  const { warnings } = useCheckAppointmentWarnings(values)
  const [typesOptions, setTypesOptions] = useState(formatOptions(allTypes))
  const selectedDate = format(values.startsAtDate, DATE_PARAM_FORMAT)
  const { timeSlots } = useAvailableTimeSlots(selectedDate)
  const timeSlotsOptions = timeSlots.map(time => ({
    value: time,
    label: convertMinutesToHours(time),
  }))

  const onsiteOptions = typesOptions.filter(({ isRemote }) => !isRemote)
  const virtualOptions = typesOptions.filter(({ isRemote }) => isRemote)

  const typeSelectOptions = [
    { label: 'On site', options: onsiteOptions },
    { label: 'Virtual', options: virtualOptions },
  ]

  useEffect(() => {
    if (values.doctorId !== '') {
      const doctorTypes = doctorsOptions.find(
        ({ id }) => id === values.doctorId
      ).appointmentTypes

      const types = allTypes.filter(({ id }) =>
        doctorTypes.find(doctorType => doctorType.id === id)
      )

      setTypesOptions(formatOptions(types))
    }
  }, [values, allTypes, setTypesOptions, doctorsOptions])

  const handleChangeDoctor = doctorId => {
    if (doctorId === '') {
      setTypesOptions(formatOptions(allTypes))
      onChange({ doctorId: '' })
    } else {
      const doctorTypes = doctorsOptions.find(({ id }) => id === doctorId)
        .appointmentTypes
      onChange({ doctorId: doctorId })

      if (doctorTypes.length) {
        const wholeTypes = allTypes.filter(({ id }) =>
          doctorTypes.find(type => type.id === id)
        )
        setTypesOptions(formatOptions(wholeTypes))
        const doctorHasSelectedType = doctorTypes.find(
          ({ id }) => id === values.typeId
        )
        if (!doctorHasSelectedType) {
          onChange({ typeId: '', doctorId: doctorId, roomId: '' })
        }
      }
    }
  }

  const handleChangeType = typeId => {
    if (!typeId)
      return onChange({
        typeId: '',
        duration: 15,
        roomId: '',
        virtual: false,
      })
    const type = allTypes.find(({ id }) => id === typeId)
    onChange({
      typeId: type.id,
      duration: type.duration || 15,
      roomId: type.isRemote ? '' : type.room.id,
      virtual: type.isRemote,
    })
  }

  return (
    <>
      <SelectField
        fullWidth
        name="doctor"
        label="Doctor"
        placeholder="Choose a doctor"
        options={doctorsOptions}
        value={values.doctorId}
        error={error.doctorId}
        warning={onlyRead ? undefined : formatWarning(warnings?.doctor)}
        onChange={handleChangeDoctor}
        disabled={onlyRead}
      />
      <TwoColumns>
        <SelectField
          group
          name="type"
          label="Appointment type"
          placeholder="Choose a type"
          options={typeSelectOptions}
          value={values.typeId}
          error={error.typeId}
          onChange={handleChangeType}
          disabled={onlyRead}
        />
        <SelectField
          name="roomId"
          label="Room"
          placeholder="Choose a room"
          options={roomsOptions}
          value={values.roomId}
          error={error.roomId}
          onChange={value => onChange({ roomId: value })}
          disabled={values.virtual || onlyRead}
        />
      </TwoColumns>
      {values.virtual && (
        <TwoColumns>
          <SelectField
            name="contactMethod"
            label="Contact Method"
            placeholder="Choose a contact method"
            options={[
              { label: 'FaceTime', value: 'FaceTime' },
              { label: 'Zoom', value: 'Zoom' },
            ]}
            value={values.contactMethod || ''}
            error={error.contactMethod}
            onChange={value => onChange({ contactMethod: value })}
            disabled={onlyRead}
          />
        </TwoColumns>
      )}
      <TwoColumns>
        <SelectCalendar
          absoluteHeight
          label="Date"
          value={values.startsAtDate}
          onChange={value => onChange({ startsAtDate: value })}
          disabled={onlyRead}
        />

        <SelectField
          name="time"
          label="Time"
          placeholder="Choose a time"
          options={timeSlotsOptions}
          value={convertHoursToMinutes(
            format(new Date(`01/01/01 ${values.startsAtTime}`), 'HH:mm')
          )}
          error={error.startsAtTime}
          warning={onlyRead ? undefined : formatWarning(warnings?.startsAt)}
          onChange={value =>
            onChange({ startsAtTime: convertMinutesToHours(value) })
          }
          disabled={onlyRead}
        />
      </TwoColumns>
    </>
  )
}

export default AppointmentForm
