import { useQuery } from '@tanstack/react-query'
import { baseURL } from '@/v2/constants/requests'

const useFolders = ({ patientId }) => {
  const query = useQuery({
    queryKey: ['folders', patientId, baseURL],
    queryFn: () =>
      fetch(
        `${baseURL}/v2/rest/patients/${patientId}/documents/folders`
      ).then(res => res.json()),
    enabled: !!patientId,
  })

  return {
    ...query,
  }
}

export default useFolders
