import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import {
  useCreateProgression,
  useDeleteProgression,
  useRenameProgression,
} from '@/v2/hooks/useProgressions'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import NoFolders from './NoFolders'
import ConfirmDelete from './ConfirmDelete'
import MobileFolders from './mobile/Folders'
import Folders from './Folders'
import StandardImages from './StandardImages'
import Gallery from './Gallery'

const GalleryWrapper = styled.div`
  ${({ $isMobile }) =>
    $isMobile
      ? ''
      : `
    display: flex;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    margin-right: 45px;
    margin-top: 45px;
  `}
`
const parse = value => parseInt(value, 10)

const Imaging = () => {
  const { isMobile } = useDetectDevice()
  const { push } = useHistory()
  const { id, progression, series } = useParams()
  const { createProgression } = useCreateProgression()
  const { deleteProgression } = useDeleteProgression()
  const { renameProgression } = useRenameProgression()
  const {
    patient,
    progressions,
    onAddProgression,
    onRenameProgression,
    onDeleteProgression,
  } = usePatientContext()
  const selectedProgression = progressions.find(
    item => parse(item.id) === parse(progression)
  )
  const selectedSeries = (selectedProgression?.series || []).find(
    item => parse(item.id) === parse(series)
  )
  const [folderId, setFolderId] = useState(
    progression ? progression : progressions[0]?.id
  )
  const [folderDate, setFolderDate] = useState(
    progression ? selectedProgression?.startDate : progressions[0]?.startDate
  )
  const [isCreationMode, setIsCreationMode] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  useEffect(() => {
    if (!selectedProgression && !!progression) {
      push(`/v2/patient/${id}/imaging`)
    }
  }, [selectedProgression, progression, push, id])

  const handleUpdateFolders = newFolder => {
    onAddProgression(newFolder)
    setFolderId(newFolder.id)
    setFolderDate(newFolder.startDate)
  }

  const handleCreate = async value => {
    try {
      await createProgression(patient.id, value || 'Progression', newFolder => {
        handleUpdateFolders(newFolder)
        setIsCreationMode(false)
        push(`/v2/patient/${id}/imaging/progression/${newFolder.id}`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = async () => {
    try {
      const progression = { id: folderId }
      await deleteProgression(patient.id, progression, () => {
        const selectedFolder =
          progressions[0].id === progression.id && progressions.length > 1
            ? progressions[1]
            : progressions[0]
        setFolderId(selectedFolder.id)
        setFolderDate(selectedFolder.startDate)
        onDeleteProgression(progression)
        setOpenDeleteConfirmation(false)
        push(`/v2/patient/${id}/imaging/progression/${selectedFolder.id}`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleEdit = async (value, editId) => {
    try {
      const progression = { id: editId ? editId : folderId, name: value }
      await renameProgression(patient.id, progression, () => {
        onRenameProgression(progression)
        setIsEditMode(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  if (progressions.length === 0)
    return <NoFolders patient={patient} onCreate={handleUpdateFolders} />

  return (
    <>
      {isMobile ? (
        <MobileFolders
          folderId={folderId}
          folderDate={folderDate}
          isCreationMode={isCreationMode}
          isEditMode={isEditMode}
          onCreate={handleCreate}
          onEdit={handleEdit}
          onSetFolderId={setFolderId}
          onSetFolderDate={setFolderDate}
          enableDelete={selectedSeries?.images?.length <= 0}
          onOpenDeleteConfirmation={() => setOpenDeleteConfirmation(true)}
          onSetIsCreationMode={() => setIsCreationMode(true)}
          onSetIsEditMode={() => setIsEditMode(true)}
        />
      ) : (
        <Folders
          isCreationMode={isCreationMode}
          isEditMode={isEditMode}
          onCreate={handleCreate}
          onEdit={handleEdit}
          enableDelete={selectedSeries?.images?.length <= 0}
          onOpenDeleteConfirmation={folder => {
            setFolderId(folder)
            setOpenDeleteConfirmation(true)
          }}
          onSetIsCreationMode={() => setIsCreationMode(true)}
          onSetIsEditMode={() => setIsEditMode(true)}
        />
      )}
      {progression && series && selectedSeries && (
        <GalleryWrapper $isMobile={isMobile ? 1 : 0}>
          {selectedSeries.name.indexOf('Standard') >= 0 ? (
            <StandardImages series={selectedSeries} folderDate={folderDate} />
          ) : (
            <Gallery series={selectedSeries} />
          )}
        </GalleryWrapper>
      )}

      <ConfirmDelete
        isOpen={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}

export default Imaging
