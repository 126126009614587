import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@/v2/components/common/Typography'

const Header = styled.div`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  min-height: 32px;
  padding: 21px 24px;
`

const CloseButton = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 19px;
  right: 24px;

  &:hover {
    opacity: 0.6;
  }
`

const DefaultHeader = ({ title, header, onClose }) => {
  const theme = useTheme()

  return (
    <DialogTitle>
      <Header theme={theme.palette}>
        {header ? (
          header
        ) : (
          <Typography id="modal-header-title" variant="h3" fontWeight="500">
            {title}
          </Typography>
        )}
        <CloseButton fontSize="small" onClick={onClose} />
      </Header>
    </DialogTitle>
  )
}
export default DefaultHeader
