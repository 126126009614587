import React from 'react'
import { useParams } from 'react-router-dom'
import Modal from '@/v2/components/common/Modal'
import NewPatientNote from '@/v2/containers/patient-notes/Create'

const CreatePatientNote = ({ type, isOpen, onClose, onSave }) => {
  const { id: patientId } = useParams()
  return (
    <Modal
      isOpen={isOpen}
      title={!type ? 'Create note' : `Create ${type} note`}
      footer={<></>}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <NewPatientNote
        patientId={patientId}
        type={type}
        onSave={note => {
          onSave && onSave(note)
          onClose()
        }}
        onClose={onClose}
      />
    </Modal>
  )
}

export default CreatePatientNote
