import React from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import ArrowIcon from '@mui/icons-material/ArrowRight'
import Typography from '@/v2/components/common/Typography'
import Accordion from '@/v2/components/common/Accordion'
import SubFolders from './SubFolders'
import FolderTitle from './FolderTitle'
import CreateFolder from './CreateFolder'

const Progressions = styled.div`
  margin-right: 30px;
  margin-top: 45px;
  padding-left: 3%;
  min-width: 200px;
  max-width: 300px;
  width: 20%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding-left: 40px;
  margin-top: 2px;
`

const parse = value => parseInt(value, 10)

const Folders = props => {
  const theme = useTheme()
  const { progression } = useParams()
  const {
    isCreationMode,
    isEditMode,
    onCreate,
    onEdit,
    onOpenDeleteConfirmation,
    onSetIsCreationMode,
    onSetIsEditMode,
    enableDelete,
  } = props
  const { progressions } = usePatientContext()

  return (
    <Progressions>
      <Typography variant="h1" fontSize="26px" sx={{ mb: '14px', ml: '39px' }}>
        Imaging
      </Typography>
      {progressions.map(({ id, name, startDate }, key) => (
        <Accordion
          noBackground
          key={id}
          defaultExpanded={
            progression ? parse(progression) === parse(id) : key === 0
          }
          title={
            <FolderTitle
              progression={{ id, name, startDate }}
              isEditMode={isEditMode}
              onSetIsEditMode={onSetIsEditMode}
              onEdit={onEdit}
            />
          }
          content={
            <Content>
              <SubFolders key={id} folder={id} isFirst={key === 0} />
              {enableDelete ? (
                <Typography
                  variant="h6"
                  fontSize="12px"
                  color={theme.palette.gray.dark}
                  cursor={'pointer'}
                  sx={{ mt: '10px' }}
                  onClick={() => onOpenDeleteConfirmation(id)}
                >
                  Remove Series
                </Typography>
              ) : null}
            </Content>
          }
          icon={<ArrowIcon color="black" />}
          iconPosition="left"
        />
      ))}
      <CreateFolder
        isCreationMode={isCreationMode}
        onCreate={onCreate}
        onSetIsCreationMode={onSetIsCreationMode}
      />
    </Progressions>
  )
}

export default Folders
