import React, { useState } from 'react'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import SelectField from '@/v2/components/common/SelectField'
import Form from './Form'
import { useProfileNotes } from '@/v2/hooks/useProfileNotes'
import { useCreateTreatmentNote } from '@/v2/hooks/treatments/useTreatmentNotes'

const CreatePatientNote = ({ patientId, type, onSave, onClose }) => {
  const { createTreatmentNote } = useCreateTreatmentNote(patientId)
  const { onOpenSnackbar } = useSnackbarContext()
  const [color, setColor] = useState(type || 'yellow')
  const { createProfileNote } = useProfileNotes()

  const handleSave = async value => {
    try {
      if (value.length === 0) {
        return onOpenSnackbar('You need to fill the note field.', 'error')
      }
      if (!color) {
        return onOpenSnackbar('You need to fill the note type.', 'error')
      }

      if (type !== 'yellow') {
        const note = await createProfileNote({
          note: {
            patientId,
            annotation: value,
            alerts: false,
            classification: color,
          },
        })

        onSave(note)
      } else {
        const { error, ...data } = await createTreatmentNote(value)

        if (error) {
          throw new Error(error)
        }
        onSave && onSave(data)
      }

      onOpenSnackbar(`Created ${type} note.`)
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  return (
    <>
      {!type && (
        <SelectField
          value={color}
          label="Type"
          options={[
            { value: 'yellow', label: 'Yellow Note' },
            { value: 'green', label: 'Green Note' },
          ]}
          onChange={value => setColor(value)}
        />
      )}
      <Form onSave={value => handleSave(value)} onClose={onClose} />
    </>
  )
}

export default CreatePatientNote
