import { baseURL, headers } from '@/v2/constants/requests'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const STALE_TIME = 30000 // 30 seconds
const CACHE_TIME = 300000 // 5 minutes

const useProfileNotes = (props = {}) => {
  const { patientId } = props

  const queryClient = useQueryClient()
  const { onOpenSnackbar } = useSnackbarContext()

  const {
    data: profileNotes,
    isLoading: isProfileNotesLoading,
    isError: isProfileNotesError,
  } = useQuery({
    queryKey: ['profileNotes', baseURL, headers, patientId],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/notes`
      )

      if (!res.ok) {
        const message = 'We could not find the patient you are looking for'
        onOpenSnackbar(message, 'error')
        throw new Error(message)
      }

      const data = await res.json()
      return data
    },
    enabled: !!patientId,
    staleTime: STALE_TIME,
    cacheTime: CACHE_TIME,
  })

  const {
    mutateAsync: createProfileNote,
    isLoading: isCreatingProfileNote,
    isError: isCreatingProfileNoteError,
  } = useMutation({
    mutationKey: ['createProfileNote', baseURL, headers],
    mutationFn: async variables => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${variables.note.patientId}/notes`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(variables),
        }
      )
      if (!res.ok) {
        throw new Error('We could not create the note')
      }
      const data = await res.json()

      return data
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        ['profileNotes', baseURL, headers, String(variables.note.patientId)],
        old => (old ? [...old, data] : [data])
      )
      queryClient.setQueryData(
        ['OfficeNotes', `${variables.note.patientId}`],
        old => (old ? [data, ...old] : [data])
      )
      onOpenSnackbar('Note has been created', 'success')
    },
    onError: () => {
      onOpenSnackbar('We could not create the note', 'error')
    },
  })

  const {
    mutateAsync: updateProfileNote,
    isLoading: isUpdatingProfileNote,
    isError: isUpdatingProfileNoteError,
  } = useMutation({
    mutationKey: ['updateProfileNote', baseURL, headers],
    mutationFn: async variables => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${variables.note.patientId}/notes/${variables.note.id}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(variables),
        }
      )
      if (!res.ok) {
        throw new Error('We could not update the note')
      }
      const data = await res.json()

      return data
    },
    onMutate: async variables => {
      const queryKey = [
        'profileNotes',
        baseURL,
        headers,
        String(variables.note.patientId),
      ]

      await queryClient.cancelQueries({
        queryKey,
      })

      const previousProfileNotes = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, old =>
        old
          ? old.map(note =>
              note.id === variables.note.id
                ? { ...note, ...variables.note }
                : note
            )
          : old
      )

      queryClient.setQueryData(
        ['OfficeNotes', `${variables.note.patientId}`],
        old =>
          old.map(note =>
            note.id === variables.note.id
              ? { ...note, ...variables.note }
              : note
          )
      )

      return { previousProfileNotes }
    },
    onSuccess: () => {
      onOpenSnackbar('Note has been updated', 'success')
    },
    onError: (_error, variables, context) => {
      const queryKey = [
        'profileNotes',
        baseURL,
        headers,
        String(variables.note.patientId),
      ]
      onOpenSnackbar('We could not update the note', 'error')
      queryClient.setQueryData(queryKey, context?.previousProfileNotes)
    },
    onSettled: (_data, error, variables) => {
      const queryKey = [
        'profileNotes',
        baseURL,
        headers,
        String(variables.note.patientId),
      ]
      if (error) {
        queryClient.invalidateQueries({
          queryKey,
        })
      }
    },
  })

  const {
    mutateAsync: deleteProfileNote,
    isLoading: isDeletingProfileNote,
    isError: isDeletingProfileNoteError,
  } = useMutation({
    mutationKey: ['deleteProfileNote', baseURL, headers],
    mutationFn: async variables => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${variables.note.patientId}/notes/${variables.note.id}`,
        {
          method: 'DELETE',
          headers,
        }
      )
      if (!res.ok) {
        throw new Error('We could not delete the note')
      }

      return res
    },
    onMutate: async variables => {
      const queryKey = [
        'profileNotes',
        baseURL,
        headers,
        String(variables.note.patientId),
      ]

      await queryClient.cancelQueries(queryKey)

      const previousProfileNotes = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, old =>
        old ? old.filter(note => note.id !== variables.note.id) : old
      )

      queryClient.setQueryData(
        ['OfficeNotes', `${variables.note.patientId}`],
        old => old.filter(note => note.id !== variables.note.id)
      )

      return { previousProfileNotes }
    },
    onError: (_error, variables, context) => {
      const queryKey = [
        'profileNotes',
        baseURL,
        headers,
        String(variables.note.patientId),
      ]

      onOpenSnackbar('We could not delete the note', 'error')
      queryClient.setQueryData(queryKey, context?.previousProfileNotes)
    },
  })

  return {
    profileNotes,
    isProfileNotesLoading,
    isProfileNotesError,
    createProfileNote,
    isCreatingProfileNote,
    isCreatingProfileNoteError,
    updateProfileNote,
    isUpdatingProfileNote,
    isUpdatingProfileNoteError,
    deleteProfileNote,
    isDeletingProfileNote,
    isDeletingProfileNoteError,
  }
}

export default useProfileNotes
