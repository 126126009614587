import React from 'react'
import styled from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import SelectMui from '@mui/material/Select'

const SelectField = styled(SelectMui)`
  & .MuiSelect-select {
    align-items: center;
    display: flex;
    min-width: 76px;

    ${({ big }) => (big ? 'padding: 12px;' : '')}
  }

  ${({ variant }) =>
    variant === 'standard'
      ? `
    &&&:before, &&&:after, &&&:hover, &&&:hover:not(.Mui-disabled):before, & > .MuiSelect-standard:focus {
      border-bottom: 0;
      background-color: transparent;
    }
  `
      : ''}
`

const Select = ({ big, options, placeholder, onChange, ...others }) => {
  return (
    <SelectField
      displayEmpty
      big={big ? 1 : 0}
      onChange={({ target: { value } }) => onChange(value)}
      {...others}
    >
      {placeholder && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </SelectField>
  )
}

export default Select
