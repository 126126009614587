import React, { useCallback, useState } from 'react'
import Typography from '@/v2/components/common/Typography'
import Modal from '@/v2/components/common/Modal'
import useModals from '@/v2/hooks/useModals'
import SavedCard from './SavedCard'
import CardPaymentForm from './CardPaymentForm'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const ManageCards = ({ isOpen, onClose, patient, params }) => {
  const { goTo } = useModals()
  const [action, setAction] = useState(null)

  const { creditCards, isLoading, isError } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const handleClose = useCallback(() => {
    resetStates()
    if (params) {
      goTo(`/v2/patient/${patient.id}/finance/payment-plan/new`, {
        initialValues: {
          patient: patient,
          existingParams: params,
          paymentType: params.installments > 1 ? 'installments' : 'single',
        },
      })
    } else {
      onClose()
    }
  }, [params, goTo, patient, onClose, resetStates])

  const renderModalTitle = () => {
    const titles = {
      delete: 'Card Deleted Successfully',
      create: 'Manage Credit/Debit Cards',
      default: 'Manage Credit/Debit Cards',
    }

    return titles[action] || titles['default']
  }

  const resetStates = useCallback(() => {
    setAction(null)
    onClose()
  }, [onClose])

  if (isError) return 'Unable to load saved payment methods'

  return (
    <Modal
      loading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={handleClose}
      title={renderModalTitle()}
      footer={<></>}
      maxWidth={'xs'}
      fullWidth
    >
      <Box display={'flex'} flexDirection={'column'} gap={6}>
        {action === 'delete' ? (
          <>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => resetStates()}
            >
              Close
            </Button>
          </>
        ) : null}
        {action === 'create' || action === null ? (
          <>
            {creditCards && creditCards.length > 0 ? (
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                {creditCards.map(card => (
                  <SavedCard
                    key={`card-${card.id}`}
                    card={card}
                    patient={patient}
                    setAction={setAction}
                  />
                ))}
              </Box>
            ) : null}

            {action === 'create' ? (
              <>
                <Typography
                  alignSelf="center"
                  textAlign="center"
                  mb={2}
                  variant="h3"
                >
                  Card has been successfully added.
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => resetStates()}
                >
                  Close
                </Button>
              </>
            ) : (
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Typography variant="h4" bold>
                  Add New Card
                </Typography>
                <CardPaymentForm
                  patient={patient}
                  params={params}
                  handleClose={handleClose}
                  setAction={setAction}
                />
              </Box>
            )}
          </>
        ) : null}
      </Box>
    </Modal>
  )
}

export default ManageCards
