import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

const SavedCard = ({ card, isSelected }) => {
  const theme = useTheme()
  return (
    <FormControlLabel
      className={isSelected ? 'selected' : ''}
      key={card.id}
      value={card.id}
      control={<Radio className="hidden" />}
      label={
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <AccountBalanceIcon
              sx={{
                height: '29px',
                width: '70px',
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <Typography variant="h3">**** **** {card.last4}</Typography>
            <Typography fontSize={'12px'} color={theme.palette.grey[500]}>
              {card.brand}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  )
}

export default SavedCard
