import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const Body = styled.div`
  display: flex;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const EmergencyContact = () => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { patient, onSetPatient } = usePatientContext()

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Header>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Emergency Contact
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/edit/emergency-contact`, {
              patient,
              onSuccess: onSetPatient,
            })
          }
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Edit</Typography>
        </Button>
      </Header>
      <Body>
        <Column>
          <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
            NAME
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
            {patient.emergencyName || 'Not informed'}
          </Typography>
          <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
            RELATIONSHIP
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
            {patient.emergencyRelationship || 'Not informed'}
          </Typography>
        </Column>
        <Column>
          <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
            MOBILE
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
            {patient.emergencyPhoneNumber || 'Not informed'}
          </Typography>
          <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
            EMAIL
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '16px', mt: '2px' }}>
            {patient.emergencyEmail || 'Not informed'}
          </Typography>
        </Column>
      </Body>
    </Paper>
  )
}

export default EmergencyContact
