import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'

const Wrapper = styled.div`
  max-width: 90%;
  width: fit-content;
  border-radius: 2px;
  padding: 19px 23px;
  background-color: ${({ color }) => color};
  position: relative;
  margin-bottom: 10px;
  ${({ direction }) => `margin-${direction}: 10px;`}

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    ${({ direction }) => direction}: -10px;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${({ color }) => color};
  }
`

const MessageBaloon = ({ children, color = 'silver', direction = 'right' }) => {
  const theme = useTheme()
  return (
    <Wrapper color={theme.palette[color].main} direction={direction}>
      {children}
    </Wrapper>
  )
}

export default MessageBaloon
