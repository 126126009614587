import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import { NoteWrapper } from './styles'

const NoItems = () => {
  const theme = useTheme()

  return (
    <NoteWrapper className="no-notes" theme={theme.palette}>
      <Typography fontSize="14px" fontWeight="300" sx={{ mr: '10px' }}>
        No notes added.
      </Typography>
    </NoteWrapper>
  )
}

export default NoItems
