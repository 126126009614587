import { useQuery } from '@apollo/client'
import NextAppointmentsQuery from '@/v2/graphql/queries/NextAppointments.gql'

const useNextAppointments = ({ date }) => {
  const { data, loading, error } = useQuery(NextAppointmentsQuery, {
    variables: { date: date },
  })

  const appointments = data?.daySchedule?.appointments || null

  return { appointments, loading, error }
}

export default useNextAppointments
