import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  min-height: 122px;
  margin-left: 40px;
  margin-top: 16px;
  padding: 25px 26px 30px 28px;
  border: 1px solid ${({ theme }) => theme.silver.dark};
  border-radius: 3px;
  position: relative;
`
export const DateTime = styled.div`
  margin-right: 56px;
`
export const Image = styled.img`
  position: absolute;
  top: 0px;
  left: -30px;
`

export const Content = styled.div`
  flex: 1;
`
