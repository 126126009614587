import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import getDoctors from '@/v2/graphql/queries/Doctors.gql'

export const useGetDoctors = () => {
  const { data, loading, error } = useQuery(getDoctors)

  const firstDoctor = useMemo(() => data?.doctors?.[0], [data])

  const res = useMemo(() => ({ loading, error, data: data?.doctors || [] }), [
    loading,
    error,
    data,
  ])

  return { ...res, firstDoctor }
}
