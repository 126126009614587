import { useQuery } from '@tanstack/react-query'
import { baseURL } from '@/v2/constants/requests'

const usePayments = ({ invoiceId }) => {
  const {
    data: payments,
    isLoading: isPaymentsLoading,
    error: isPaymentsError,
  } = useQuery({
    queryKey: ['payments', invoiceId, baseURL],
    queryFn: () =>
      fetch(
        `${baseURL}/v2/rest/billing/invoices/${invoiceId}/payments`
      ).then(res => res.json()),
    enabled: !!invoiceId,
  })

  return {
    payments: payments || [],
    isPaymentsLoading,
    isPaymentsError,
  }
}

export default usePayments
