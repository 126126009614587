import React, { createContext, useState, useContext, useEffect } from 'react'

const AuthContext = createContext([])

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = sessionStorage.getItem('isLoggedIn')
    const currentPath = window.location.pathname
    const authRoutes = ['/new-password', '/reset-password', 'login']

    if (authRoutes.includes(currentPath)) {
      return false
    }

    return storedIsLoggedIn !== null ? storedIsLoggedIn === 'true' : true
  })
  const [user, setUser] = useState(null)

  useEffect(() => {
    sessionStorage.setItem('isLoggedIn', isLoggedIn)
  }, [isLoggedIn])

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const { isLoggedIn, setIsLoggedIn, user, setUser } = useContext(AuthContext)

  return {
    isLoggedIn,
    setIsLoggedIn,
    user,
    setUser,
  }
}
