import React from 'react'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline'
import { format, differenceInMinutes } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import LabelList from '@/v2/components/common/LabelList'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import { TIME_IN_US_FORMAT } from '@/v2/constants'
import VirtualIcon from '#/assets/images/v2/virtual-icon.svg?inline'
import WaitingTime from './WaitingTime'
import useInterval from '@/v2/hooks/useInterval'

const Wrapper = styled.div`
  background-color: ${({ color }) => color || 'white'};
  cursor: pointer;
  padding: 10px 16px 10px 19px;
`
const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Text = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// TODO: make this a dynamic room field
const roomAliases = {
  'Exam Room One': 'R1',
  'Exam Room Two': 'R2',
}

const AppointmentCard = ({ appointment }) => {
  const theme = useTheme()
  const [_time, setTime] = React.useState(Date.now())
  const {
    patient,
    startsAt,
    appointmentType: type,
    room,
    checkedIn,
    checkedInTime,
    seated,
    labels,
    virtual,
    reschedule,
    noShow,
  } = appointment
  const hour = format(getDateTimeWithoutTimezone(startsAt), TIME_IN_US_FORMAT)
  const isWaiting = checkedIn && !seated
  const roomAlias = roomAliases[room?.title]
  const waitingTime = checkedInTime
    ? differenceInMinutes(
        nowOnClinicTimezone(),
        getDateTimeWithoutTimezone(checkedInTime)
      )
    : null

  const extraLabels = isWaiting
    ? [
        {
          id: 'waiting',
          name: 'Waiting',
          color: waitingTime >= 15 ? 'red' : 'yellow',
        },
      ]
    : []

  if (noShow) {
    extraLabels.push({
      id: 'no-show',
      name: 'No Show',
      color: 'black',
    })
  }

  if (reschedule) {
    extraLabels.push({
      id: 'reschedule',
      name: 'Reschedule',
      color: 'link',
    })
  }

  useInterval(() => {
    if (isWaiting) {
      setTime(Date.now())
    }
  }, 60 * 1000) // every minute

  return (
    <Wrapper color={theme.palette[type.color].type}>
      <Line>
        <Text bold variant="h5" title={patient.fullName}>
          {patient.fullName}
        </Text>
        <Typography
          variant="h5"
          sx={{
            width: isWaiting || seated ? '84px' : '66px',
            textAlign: 'end',
          }}
        >
          {hour}
          {(isWaiting || seated) && ` - ${roomAlias || 'V'}`}{' '}
          {/* Today only virtual appointments doesnt have a room */}
        </Typography>
      </Line>
      <Line>
        <Text
          variant="h5"
          title={type.title}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {virtual && (
            <SVGInline
              className="virtual-icon"
              svg={VirtualIcon}
              style={{ width: '14px', height: '14px', marginRight: '8px' }}
            />
          )}
          {type.title}
        </Text>
        <LabelList labels={[...labels, ...extraLabels]} />
      </Line>
      {isWaiting && (
        <WaitingTime appointmentTime={startsAt} checkedIn={checkedInTime} />
      )}
    </Wrapper>
  )
}

export default AppointmentCard
