import Button from '@/v2/components/common/Button'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NotesForm, NoteFormActions } from './styles'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import useNotes from '@/v2/hooks/useNotes'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useGetDoctors } from '@/v2/hooks/useDoctors'
import { TextField } from '@mui/material'

const schema = z.object({
  annotation: z.string().min(1),
})

const NoteForm = ({ appointmentId, setShowNoteForm, note, doctorId }) => {
  const { mutationCreate, mutationUpdate } = useNotes()

  const { onOpenSnackbar } = useSnackbarContext()

  const { mutate: createNote } = mutationCreate

  const { mutate: updateNote } = mutationUpdate

  const { firstDoctor } = useGetDoctors()

  const initValues = {
    annotation: note?.annotation || '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: initValues,
  })

  const onSubmit = async data => {
    if (!note) {
      await createNote({
        appointmentId,
        note: data.annotation,
        doctor: doctorId || firstDoctor?.id,
      })
      setShowNoteForm(false)
      return onOpenSnackbar('Created note.')
    }

    await updateNote({
      id: note.id,
      note: data.annotation,
      appointmentId,
      doctor: doctorId || firstDoctor?.id,
    })
    setShowNoteForm(false)
    return onOpenSnackbar('Updated note.')
  }

  return (
    <NotesForm>
      <Controller
        name="annotation"
        control={control}
        render={({ field }) => (
          <TextField
            multiline
            minRows={4}
            placeholder="Write a note..."
            error={!!errors.annotation}
            helperText={errors.annotation?.message}
            {...field}
          />
        )}
      />
      <NoteFormActions>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: note ? '50%' : 8,
            fontWeight: 300,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          {note ? 'Save' : 'Add Note'}
        </Button>
        {note && (
          <Button
            variant="contrast"
            color="primary"
            onClick={() => setShowNoteForm(false)}
          >
            Cancel
          </Button>
        )}
      </NoteFormActions>
    </NotesForm>
  )
}

export default NoteForm
