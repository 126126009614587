import { useContext, useCallback, useMemo } from 'react'
import { ModalHistoryContext } from '@/v2/contexts/RoutesContext'

const useModals = () => {
  const history = useContext(ModalHistoryContext)

  // According to react-router documentation, the history object is mutable. Also,
  // since we're just using its methods, we don't need to add it to the depts
  // array for the callbacks below.
  /* eslint-disable react-hooks/exhaustive-deps */
  const goTo = useCallback(
    (pathname, state) => history.push(pathname, state),
    []
  )
  const goBack = useCallback(() => history.goBack(), [])
  const close = useCallback(() => history.go(-history.index), [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return useMemo(
    () => ({
      goTo,
      goBack,
      close,
    }),
    [goTo, goBack, close]
  )
}

export default useModals
