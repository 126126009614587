import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Modal from '@/v2/components/common/Modal'
import SelectField from '@/v2/components/common/SelectField'
import Typografy from '@/v2/components/common/Typography'
import { useTabContext } from '@/v2/contexts/TabsContext'
import useAppointment from '@/v2/hooks/useAppointment'
import { useLoadData } from './hooks/useLoadData'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

const SeatPatient = ({ isOpen, appointmentId, onClose }) => {
  const history = useHistory()
  const { onAddTab } = useTabContext()
  const [values, setValues] = useState({ doctor: '', room: '' })
  const [errorMessage, setErrorMessage] = useState(null)
  const { seatPatient } = useAppointment({})
  const { loading, error, rooms, doctors, appointment } = useLoadData(
    appointmentId
  )

  useEffect(() => {
    if (!loading && !error) {
      setValues({
        doctor: appointment.doctor?.id || '',
        room: appointment.room?.id || '',
      })
    }
  }, [loading, appointment, error])

  const roomsOptions = rooms.map(({ id, title }) => ({
    value: id,
    label: title,
  }))
  const doctorsOptions = doctors.map(({ id, fullName }) => ({
    value: id,
    label: fullName,
  }))

  const handleChange = newValue => {
    setValues({ ...values, ...newValue })
  }

  const handleConfirm = async () => {
    try {
      if (!values.doctor && !values.room) {
        throw Error('Doctor or Room are required')
      }

      await seatPatient({
        id: appointmentId,
        doctorId: values.doctor,
        roomId: values.room,
        seated: true,
      })

      await onAddTab({
        id: appointment.patient.id,
        name: appointment.patient.fullName,
        status: 'SEATED',
      })

      history.push({
        pathname: `/v2/patient/${appointment.patient.id}/treatment/${appointmentId}`,
        state: { seatAppointmentId: appointmentId },
      })

      onClose()
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      title="Seat Patient"
      confirmLabel="Start"
      onClose={onClose}
      onConfirm={handleConfirm}
      maxWidth="xs"
      fullWidth
    >
      {errorMessage && (
        <Alert severity="error">
          <AlertTitle color="black.label">Error</AlertTitle>
          <Typografy fontSize="12px" color="black.label">
            {errorMessage}
          </Typografy>
        </Alert>
      )}
      <SelectField
        value={values.doctor}
        name="doctor"
        label="Doctor"
        options={[{ value: '', label: 'No Doctor' }, ...doctorsOptions]}
        onChange={value => handleChange({ doctor: value })}
      />
      <SelectField
        value={values.room}
        name="room"
        label="Room"
        options={[{ value: '', label: 'No Room' }, ...roomsOptions]}
        onChange={value => handleChange({ room: value })}
      />
    </Modal>
  )
}

export default SeatPatient
