import validate from '@/v2/validations/validate'
import { required } from '@/v2/validations/validators/general'

export const validators = {
  firstName: required({ message: 'Must have a first name' }),
  lastName: required({ message: 'Must have a last name' }),
}

const validator = values => validate(values, validators)

export default validator
