import React, { useMemo } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useClinic } from '@/v2/hooks/useClinic'

const StripeElements = ({ children }) => {
  const { clinic } = useClinic()

  if (clinic) {
    const stripePromise = useMemo(
      () =>
        loadStripe(process.env.STRIPE_PUBLISHABLE_KEY, {
          stripeAccount: clinic.stripeLedgerAccountId,
        }),
      [clinic]
    )

    return <Elements stripe={stripePromise}>{children}</Elements>
  }

  return null
}

export default StripeElements
