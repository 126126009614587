import React from 'react'
import { useParams } from 'react-router-dom'
import { useContracts } from '@/v2/hooks/documents/useContracts'
import { usePatientFinanceInfo } from '@/v2/hooks/usePatientFinanceInfo'
import { useClinic } from '@/v2/hooks/useClinic'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import DisabledFinance from './components/DisabledFinance'
import FinanceInfo from './components/FinanceInfo'

const Finance = () => {
  const { id } = useParams()
  const { patient } = usePatientContext()
  const { clinic, loading: loadingClinic, refetch: refetchClinic } = useClinic()
  // TODO: only call contracts when click on new Payment plan
  const { contracts, isLoading: isLoadingContracts } = useContracts(id)
  const { patientFinanceInfo, isLoading, isError } = usePatientFinanceInfo({
    patientId: id,
  })

  const {
    creditCards,
    bankAccounts,
    paymentMethods,
  } = usePatientFinancePaymentMethods({
    patientId: id,
  })

  if (isLoading || isLoadingContracts) return null
  if (isError) return 'Unable to load patient balance'

  if (!loadingClinic && clinic.billingOnboardingStatus !== 'completed') {
    return (
      <DisabledFinance
        onboardingStatus={clinic.billingOnboardingStatus}
        refetch={refetchClinic}
      />
    )
  }

  return (
    <FinanceInfo
      patient={{ ...patient, contracts }}
      financeInfo={patientFinanceInfo}
      creditCards={creditCards}
      bankAccounts={bankAccounts}
      paymentMethods={paymentMethods}
    />
  )
}

export default Finance
