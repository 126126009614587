import React from 'react'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import useModals from '@/v2/hooks/useModals'
import styled from 'styled-components'
import Label from '@/v2/components/common/Label'
import Typography from '@/v2/components/common/Typography'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 29px;
  margin-bottom: 3px;
  background-color: ${({ color }) => color || 'white'};
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
    box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
  }
`

const Content = styled.div`
  width: 100%;
`

const Labels = styled.div`
  margin-left: 8px;
  width: 48px;
`

const Text = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 144px;
`

const AppointmentCard = ({ appointment }) => {
  const theme = useTheme()
  const { goTo } = useModals()
  const {
    id,
    room,
    startsAt,
    appointmentType: type,
    labels,
    reschedule,
    noShow,
  } = appointment
  const hour = getDateTimeWithoutTimezone(startsAt)

  const extraLabels = []

  if (reschedule) {
    extraLabels.push({
      id: 'reschedule',
      name: 'Rescheduled',
      color: 'link',
    })
  }

  if (noShow) {
    extraLabels.push({
      id: 'no-show',
      name: 'No Show',
      color: 'black',
    })
  }

  const eventLabels = [...labels, ...extraLabels]

  return (
    <Wrapper
      color={theme.palette[type.color].type}
      onClick={() =>
        goTo(`/v2/appointment/${id}`, {
          initialValues: { appointment: { ...appointment } },
        })
      }
    >
      <Content>
        <Text bold variant="h5" title={type.title}>
          {type.title}
        </Text>
        <Typography variant="h5">
          {format(new Date(hour), 'dd MMMM, yyyy - hh:mm aa')}
        </Typography>
        <Typography variant="h5">{room?.title}</Typography>
      </Content>
      {eventLabels.length > 0 && (
        <Labels>
          {(eventLabels || []).map(({ id, name, color }) => (
            <Label key={id} title={name} color={theme.palette[color].label} />
          ))}
        </Labels>
      )}
    </Wrapper>
  )
}

export default AppointmentCard
