import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@/v2/components/common/TextField'
import { sanitizeConversationSearch } from '@/v2/utils/searches'
import OpenClosedConversations from './OpenClosedConversations'
import SearchResults from './SearchResults'
import { Wrapper } from './styles'

const ConversationsList = () => {
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')

  let debounce
  const handleSearch = value => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      setSearchTerm(sanitizeConversationSearch(value))
    }, 1000)
  }

  return (
    <Wrapper theme={theme.palette}>
      <TextField
        name="search"
        placeholder="Find or start a conversation"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{ margin: '15px', width: '250px' }}
        onChange={value => handleSearch(value)}
      />
      {!searchTerm && <OpenClosedConversations />}
      {!!searchTerm && <SearchResults searchTerm={searchTerm} />}
    </Wrapper>
  )
}

export default ConversationsList
