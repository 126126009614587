import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@/v2/components/common/TextField'
import { useShowClaim } from '@/v2/hooks/insurance/useClaims'
import Subscriber from './subforms/Subscriber'
import Providers from './subforms/Providers'
import Procedures from './subforms/Procedures'
import Button from '@/v2/components/common/Button'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useClaimOptions from '@/v2/containers/patient-insurance/claims-tab/useClaimOptions'
import Typography from '@/v2/components/common/Typography'
import { EditOutlined, CancelOutlined } from '@mui/icons-material'
import IconButton from '@/v2/components/common/IconButton'

const Edit = ({
  claim,
  patientId,
  insuranceId,
  error = {},
  onChange,
  onTriggerEdit,
}) => {
  const { claimInfo, isLoading } = useShowClaim(insuranceId, claim.id)
  const theme = useTheme()
  const [allowSubscriberEdit, setAllowSubscriberEdit] = useState(false)
  const [activities, setActivities] = useState([])
  const [values, setValues] = useState({ ...claim, insuranceId })

  console.log(activities)

  useEffect(() => {
    if (claimInfo) {
      setValues({ ...claimInfo, insuranceId })
      setActivities(claimInfo.activities)
    }
  }, [claimInfo])

  const handleChangeSubscriber = value => {
    onTriggerEdit(true)
    const claimObject = {
      ...values,
      subscriber: { ...values.subscriber, ...value },
    }

    onChange(claimObject)
    setValues({ ...claimObject, insuranceId })
  }

  useEffect(() => {
    if (!allowSubscriberEdit) {
      onTriggerEdit(false)

      setValues({ ...claimInfo, insuranceId })
    }
  }, [allowSubscriberEdit])

  const { statusColors, outlinedStatus, getLabel } = useClaimOptions(
    claim.status,
    claim.id,
    patientId,
    claim.insuranceId,
    claim
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Grid container spacing={2}>
      {!isLoading && (
        <>
          <Grid item xs={6}>
            <Button
              variant={
                outlinedStatus.includes(claim.status) ? 'outlined' : 'contained'
              }
              sx={{
                background: outlinedStatus.includes(claim.status)
                  ? 'transparent'
                  : statusColors[claim.status],
                color: outlinedStatus.includes(claim.status)
                  ? statusColors[claim.status]
                  : theme.palette.background.default,
                borderColor: outlinedStatus.includes(claim.status)
                  ? statusColors[claim.status]
                  : 'transparent',
                width: 'auto',
                minWidth: '40px',
                height: '20px',
                cursor: 'not-allowed',
                pointerEvents: 'none',
              }}
            >
              {getLabel(claim.status)}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              noMargin
              label="Insurance"
              name="insurance"
              value={
                values?.insurance?.payer?.name ||
                values?.payer?.name ||
                'No Insurance Found'
              }
              disabled
            />
          </Grid>

          <Subscriber
            isEdit={true}
            values={values}
            insuranceId={insuranceId}
            error={error}
            editAllowed={allowSubscriberEdit}
            onChange={handleChangeSubscriber}
          />

          <Grid item xs={12}>
            <Box display={'flex'} flexDirection={'row-reverse'}>
              <IconButton
                title={allowSubscriberEdit ? 'Cancel' : 'Edit Subscriber'}
                aria-label={allowSubscriberEdit ? 'Cancel' : 'Edit Subscriber'}
                bordercolor="silver"
                sx={{
                  background: theme.palette.silver.main,
                  marginBottom: '4px',
                }}
                onClick={() => setAllowSubscriberEdit(!allowSubscriberEdit)}
              >
                {allowSubscriberEdit ? (
                  <CancelOutlined color="error" />
                ) : (
                  <EditOutlined color="action" />
                )}
              </IconButton>
            </Box>
          </Grid>
          <Providers
            values={values}
            error={error}
            insuranceId={insuranceId}
            claimId={claim.id}
          />
          <Procedures
            values={values}
            error={error}
            insuranceId={insuranceId}
            claimId={claim.id}
          />
          <Box
            sx={{
              marginTop: '36px',
              marginBottom: '12px',
              marginLeft: '14px',
              background: theme.palette.grey[100],
              padding: '16px',
              borderRadius: '4px',
              width: '100%',
            }}
          >
            <Box sx={{ marginTop: '16px' }}>
              <Typography variant="h3" bold>
                History
              </Typography>
              {activities?.length > 0 ? (
                activities?.map((activity, key) => (
                  <Box key={key} mt={2}>
                    <Typography variant="span">{activity?.message}</Typography>
                  </Box>
                ))
              ) : (
                <Box sx={{ marginTop: '8px' }}>
                  <Typography variant="span">No history found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Grid>
  )
}

export default Edit
