import React, { useEffect } from 'react'
import Typography from '@/v2/components/common/Typography'
import SelectInputField from '@/v2/components/common/SelectInputField'
import { range, geThisDayOnNextMonth } from '@/v2/utils/helpers'
import { toUSNumberFormat } from '@/v2/utils/format'
import { useWatch } from 'react-hook-form'
import Box from '@mui/material/Box'
import Row from '@/v2/components/common/Row'

const InstallmentFields = ({
  register,
  errors,
  theme,
  control,
  contract,
  disabled,
  state,
  setValue,
}) => {
  const watch = useWatch({
    control,
    name: [
      'classification',
      'amountWithDiscount',
      'discountRate',
      'installments',
      'installmentAmount',
      'interestRate',
      'totalDue',
      'amount',
    ],
  })
  const watchClassification = watch[0]
  const watchAmoutWithDiscount = watch[1]
  const watchDiscountRate = watch[2]
  const watchInstallments = watch[3]
  const watchInstallmentAmount = watch[4]
  const watchInterestRate = watch[5]
  const watchTotalDue = watch[6]
  const watchAmount = watch[7]

  const downPayment = contract?.downPayment || 0

  useEffect(() => {
    if (watchClassification === 'installments' && watchInstallments === 1) {
      setValue('installments', 2)
    }
  }, [watchClassification, watchInstallments, setValue])

  const installments = state.updatedInstallments
    ? state.updatedInstallments
    : watchInstallments

  return watchClassification === 'installments' ? (
    <>
      <Row>
        <SelectInputField
          noMargin
          label="Installments"
          name="installments"
          id="payment-plan-installments"
          options={range(2, 12, 1).map(installments => ({
            label: installments,
            value: installments,
          }))}
          color="secondary"
          error={errors?.installments?.message}
          {...register('installments', {
            valueAsNumber: true,
          })}
          disabled={disabled}
        />

        <SelectInputField
          noMargin
          label="Interest rate"
          name="interest-rate"
          id="payment-plan-interest"
          options={range(0, 5, 0.5).map(interest => ({
            label: `${interest}%`,
            value: interest,
          }))}
          color="secondary"
          error={errors?.interestRate?.message}
          {...register('interestRate', {
            valueAsNumber: true,
          })}
          disabled={disabled}
        />
      </Row>

      {watchAmount > 0 ? (
        <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
          <Box display={'flex'} flexDirection={'column'}>
            {contract?.downPayment ? (
              <Box display={'flex'} gap={1}>
                <Typography variant={'body2'}>Total due today:</Typography>
                <Typography variant={'body2'} bold>
                  {toUSNumberFormat(downPayment, true, true)}
                </Typography>
              </Box>
            ) : null}

            {watchAmoutWithDiscount > 0 && watchDiscountRate > 0 ? (
              <Box display={'flex'} gap={1}>
                <Typography variant={'body2'}>
                  Total with {watchDiscountRate}% discount:
                </Typography>
                <Typography variant={'body2'} bold>
                  {toUSNumberFormat(watchAmoutWithDiscount, true, true)}
                </Typography>
              </Box>
            ) : null}

            {watchTotalDue && watchInterestRate > 0 ? (
              <Box display={'flex'} gap={1}>
                <Typography variant={'body2'}>
                  Total with {watchInterestRate}% interest:
                </Typography>
                <Typography variant={'body2'} bold>
                  {toUSNumberFormat(watchTotalDue, true, true)}
                </Typography>
              </Box>
            ) : null}

            {watchInstallmentAmount > 0 ? (
              <>
                <Box display={'flex'} gap={1}>
                  <Typography variant={'body2'}>
                    {installments === 1 ? 'Installment' : 'Installments'}:
                  </Typography>
                  <Typography variant={'body2'} bold>
                    {`${toUSNumberFormat(
                      watchInstallmentAmount,
                      true,
                      true
                    )} x ${installments} ${
                      installments === 1 ? 'Payment' : 'Payments'
                    }`}
                  </Typography>
                </Box>

                <Box display={'flex'} gap={1} mt={1}>
                  <Typography
                    fontWeight="light"
                    variant="body3"
                    color={theme.palette.fieldLabel.main}
                  >
                    First one to be charged today. Next payment{' '}
                    {geThisDayOnNextMonth()}
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  ) : (
    <Box
      bgcolor={'gray.light'}
      display={
        watchAmoutWithDiscount > 0 && watchDiscountRate > 0 ? 'block' : 'none'
      }
      borderRadius={2}
      px={2}
      py={2}
    >
      <Box display={'flex'} flexDirection={'column'} gap={0.5}>
        {watchAmoutWithDiscount > 0 && watchDiscountRate > 0 ? (
          <Box display={'flex'} gap={1}>
            <Typography variant={'body2'}>
              Total with {watchDiscountRate}% discount:
            </Typography>
            <Typography variant={'body2'} bold>
              {toUSNumberFormat(watchAmoutWithDiscount, true, true)}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default InstallmentFields
