import React from 'react'
import SVGInline from 'react-svg-inline/src'
import VisaIcon from '#/assets/images/v2/icons/visa.svg?inline'
import MasterIcon from '#/assets/images/v2/icons/master.svg?inline'
import AmexIcon from '#/assets/images/v2/icons/amex.svg?inline'
import DinersIcon from '#/assets/images/v2/icons/diners.svg?inline'
import JcbIcon from '#/assets/images/v2/icons/jcb.svg?inline'
import GenericIcon from '#/assets/images/v2/icons/generic.svg?inline'

export const getCreditCardIcon = brand => {
  switch (brand) {
    case 'mastercard':
      return <SVGInline svg={MasterIcon} width="70px" height="29px" />
    case 'visa':
      return <SVGInline svg={VisaIcon} width="70px" height="29px" />
    case 'amex' || 'american-express':
      return <SVGInline svg={AmexIcon} width="70px" height="29px" />
    case 'diners':
      return <SVGInline svg={DinersIcon} width="70px" height="29px" />
    case 'jcb':
      return <SVGInline svg={JcbIcon} width="70px" height="29px" />
    default:
      return <SVGInline svg={GenericIcon} width="70px" height="29px" />
  }
}
