import React from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import compose from 'lodash/flowRight'
import { graphql } from '@apollo/client/react/hoc'
import {
  add,
  sub,
  format,
  isToday,
  parseISO,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
} from 'date-fns'
import Button from '@/v2/components/common/Button'
import LeftIcon from '@mui/icons-material/ChevronLeft'
import RightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@/v2/components/common/IconButton'
import Select from '@/v2/components/common/Select'
import MultipleSelect from '@/v2/components/common/MultipleSelect'
import { DATE_PARAM_FORMAT } from '@/v2/constants'
import { nowOnClinicTimezone } from '@/v2/utils/convert'
import getTypes from '@/v2/graphql/queries/AppointmentTypes.gql'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import {
  Wrapper,
  NavigationWrapper,
  DateWrapper,
  FilterWrapper,
  Filters,
  ButtonsWrapper,
} from './styles'

const today = format(nowOnClinicTimezone(), 'yyyy-MM-dd')

const Filterbar = props => {
  const {
    onChangeSubject,
    onChangeType,
    types: { loading, error, clinic },
  } = props
  const types = clinic?.appointmentTypes || {}
  const appointmentTypes =
    loading || error
      ? []
      : types
          .map(({ id, title, color }) => ({ value: id, label: title, color }))
          .sort((a, b) => (a.label > b.label ? 1 : -1))

  const theme = useTheme()
  const { isMobile } = useDetectDevice()
  const { view = 'day', date = today } = useParams()
  const currentDate = parseISO(date)
  const dateFormat = {
    day: 'MMMM dd, yyyy',
    weekStart: 'MMMM dd',
    weekEnd: 'dd, yyyy',
    month: 'MMMM yyyy',
  }
  const firstDay = {
    day: currentDate,
    week: startOfWeek(currentDate),
    month: startOfMonth(currentDate),
  }

  return (
    <Wrapper theme={theme.palette}>
      <NavigationWrapper>
        <IconButton
          title="Previous"
          hovercolor="black"
          aria-label="Previous"
          href={`/v2/calendar/${view}/${format(
            sub(firstDay[view], { [`${view}s`]: 1 }),
            DATE_PARAM_FORMAT
          )}`}
        >
          <LeftIcon />
        </IconButton>
        <IconButton
          title="Next"
          hovercolor="black"
          aria-label="Next day/week/month"
          href={`/v2/calendar/${view}/${format(
            add(firstDay[view], { [`${view}s`]: 1 }),
            DATE_PARAM_FORMAT
          )}`}
        >
          <RightIcon />
        </IconButton>
        <DateWrapper bold variant="h1">
          {view === 'week'
            ? `${format(firstDay.week, dateFormat.weekStart)}-${format(
                endOfWeek(firstDay.week),
                dateFormat.weekEnd
              )}`
            : format(currentDate, dateFormat[view])}
        </DateWrapper>
      </NavigationWrapper>
      {!isMobile && (
        <FilterWrapper>
          {view !== 'month' ? (
            <Filters>
              <Select
                key="view"
                defaultValue="room"
                options={[
                  { value: 'room', label: 'Rooms' },
                  { value: 'doctor', label: 'Doctors' },
                ]}
                onChange={onChangeSubject}
              />
              <MultipleSelect
                chipOptions
                key="filter-type"
                placeholder="Filter by appointment"
                options={appointmentTypes}
                onChange={types => onChangeType(types)}
              />
            </Filters>
          ) : null}
          <ButtonsWrapper>
            <Button
              key="today"
              className="today-button"
              variant={
                view === 'day' && isToday(currentDate)
                  ? 'contained'
                  : 'outlined'
              }
              href={`/v2/calendar/day/${format(
                startOfDay(Date.now()),
                DATE_PARAM_FORMAT
              )}`}
            >
              Today
            </Button>
            <Button
              key="week"
              className="week-button"
              variant={view === 'week' ? 'contained' : 'outlined'}
              href={`/v2/calendar/week/${format(
                firstDay.week,
                DATE_PARAM_FORMAT
              )}`}
            >
              Week
            </Button>
            <Button
              key="month"
              className="month-button"
              variant={view === 'month' ? 'contained' : 'outlined'}
              href={`/v2/calendar/month/${format(
                firstDay.month,
                DATE_PARAM_FORMAT
              )}`}
            >
              Month
            </Button>
          </ButtonsWrapper>
        </FilterWrapper>
      )}
    </Wrapper>
  )
}

export default compose(graphql(getTypes, { name: 'types' }))(Filterbar)
