import styled from 'styled-components'
import { getDimensions } from '../Image'

export const EmptyImage = styled.div`
  ${({ $isMobile, $position }) => getDimensions($isMobile, $position)}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.gray.dark};
  background: ${({ theme, $isMobile }) =>
    $isMobile ? theme.silver.main : theme.white.main};
  text-transform: capitalize;
  transition: 0.2s;

  ${({ $isMobile, theme }) =>
    $isMobile
      ? ''
      : `
    border: 1px solid ${theme.silver.dark};

    &:hover {
      background: ${theme.secondary.main};
      color: ${theme.white.main};
    }

    &:hover path {
      stroke: ${theme.white.main};
    }
  `}
`

export const Icon = styled.div`
  background: ${({ $isMobile, theme }) =>
    $isMobile ? theme.white.main : theme.transparent.main};
  border-radius: 50%;
  height: 24px;
  margin-bottom: 10px;
`

export const Loading = styled.div`
  background: ${({ theme }) => theme.black.main}38;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;

  ${({ $isMobile }) =>
    $isMobile
      ? `
        top: 101px;
        height: calc(100vh - 150px);
      `
      : `
        top: 0;
        left: 0;
        height: 100%;
  `}
`
