import React from 'react'
import SVGInline from 'react-svg-inline'
import Typography from '@/v2/components/common/Typography'
import { formatPhone } from '@/v2/utils/format'
import AppointmentPatientQuery from '@/v2/graphql/queries/AppointmentPatient.gql'
import Button from '@/v2/components/common/Button'
import {
  PatientProfileHeader,
  PatientProfileSection,
  PatientProfile,
  PatientWrapper,
  PatientActions,
  PreviousAppointments,
  PreviousAppointmentsContainer,
} from './styles'
import { useQuery } from '@apollo/client'
import AppointmentsList from '@/v2/containers/patient-profile/Appointments/AppointmentsList'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import PersonIcon from '#/assets/images/icons/v2/profile.svg?inline'
import MessageIcon from '#/assets/images/icons/v2/write-messages.svg?inline'
import { useTabContext } from '@/v2/contexts/TabsContext'
import Labels from './Labels'
import { Skeleton } from '@mui/material'

const Patient = ({ appointment }) => {
  const { onAddTab } = useTabContext()
  const { data, loading } = useQuery(AppointmentPatientQuery, {
    variables: { id: appointment?.patient.id, limit: 3 },
  })

  if (loading) return <Skeleton variant="rectangular" height={300} />

  const patient = data.patient

  return (
    <PatientWrapper>
      <PatientProfileSection>
        <PatientProfileHeader>
          <PatientAvatar patient={patient} />
          <PatientProfile>
            <Typography fontSize="16px" marginBottom="8px" variant="h3">
              {patient.fullName}
            </Typography>
            <Typography variant="h5" fontSize="14px">
              {patient.email}
            </Typography>
            <Typography variant="h5" fontSize="14px">
              {formatPhone(patient.phoneNumber)}
            </Typography>
          </PatientProfile>
        </PatientProfileHeader>

        <PatientActions>
          <Button
            variant="profile"
            startIcon={
              <SVGInline
                className="message-icon"
                svg={PersonIcon}
                height="12px"
                width="12px"
              />
            }
            href={`/v2/patient/${patient.id}/profile`}
          >
            See Profile
          </Button>

          <Button
            variant="profile"
            startIcon={
              <SVGInline svg={MessageIcon} height="12px" width="12px" />
            }
            href={`/v2/messages/start-conversation/${patient.id}`}
            onClick={e => {
              if (patient.phoneNumber === null) {
                e.preventDefault()
                e.stopPropagation()
                alert(
                  "This patient doesn't have a phone number!\nPlease, update the patient contact info."
                )
              }
            }}
          >
            Send Message
          </Button>
        </PatientActions>
      </PatientProfileSection>

      <Labels appointment={appointment} />
      <PreviousAppointmentsContainer>
        <PreviousAppointments>
          {patient.previousAppointments && (
            <AppointmentsList
              defaultExpanded
              title="Previous Appointments"
              appointments={patient.previousAppointments}
            />
          )}
        </PreviousAppointments>
        <Button
          href={`/v2/patient/${patient.id}/treatment`}
          fullWidth
          variant="outlined"
        >
          View all appointments
        </Button>
      </PreviousAppointmentsContainer>
    </PatientWrapper>
  )
}

export default Patient
