import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { isToday, parseISO } from 'date-fns'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@/v2/components/common/Typography'
import OffBackground from '#/assets/images/v2/off-hours-2.png'
import {
  Content,
  DayNumber,
  FulfillmentRateBar,
  AppointmentCounts,
} from './styles'

const DayCard = ({
  dateAsString,
  appointmentsCount,
  confirmedAppointmentsCount,
  fulfillmentRate,
  closed,
  outOfBound,
}) => {
  const theme = useTheme()
  const isCurrentDay = isToday(parseISO(dateAsString))
  const history = useHistory()
  return (
    <Content
      theme={theme.palette}
      $isToday={isCurrentDay ? 1 : 0}
      $outOfBound={outOfBound ? 1 : 0}
      onClick={() => history.push(`/v2/calendar/day/${dateAsString}`)}
    >
      <DayNumber $isToday={isCurrentDay ? 1 : 0} theme={theme.palette}>
        <Typography variant="h2">{parseISO(dateAsString).getDate()}</Typography>
      </DayNumber>
      {!outOfBound && (
        <Tooltip title={`${fulfillmentRate}%`}>
          <FulfillmentRateBar
            $fulfillmentRate={fulfillmentRate}
            $color={`${
              closed
                ? ''
                : fulfillmentRate >= 75
                ? theme.palette.calendar.red
                : fulfillmentRate > 30 && fulfillmentRate < 75
                ? theme.palette.calendar.yellow
                : theme.palette.calendar.green
            }`}
            $bgImage={`${closed ? OffBackground : ''}`}
          />
        </Tooltip>
      )}
      <AppointmentCounts $closed={closed}>
        <Typography fontSize={closed ? '12px' : 'small'}>
          {closed
            ? 'CLOSED'
            : `${appointmentsCount} (${confirmedAppointmentsCount})`}
        </Typography>
      </AppointmentCounts>
    </Content>
  )
}

export default DayCard
