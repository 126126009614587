import React from 'react'
import { format } from 'date-fns'
import useModals from '@/v2/hooks/useModals'
import GalleryItem from '@/v2/components/common/GalleryItem'
import Typography from '@/v2/components/common/Typography'
import Document from '#/assets/images/v2/document.jpg'
import { DATE_FORMAT } from '@/v2/constants'
import { getContractStatus } from '@/v2/utils/helpers'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import {
  useArchiveContract,
  useSendContract,
} from '@/v2/hooks/documents/useContracts'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const Contract = ({ contract, patientId }) => {
  const { goTo } = useModals()
  const { archiveContract } = useArchiveContract(patientId, contract.id)
  const { sendContract } = useSendContract(contract.id)
  const { onOpenSnackbar } = useSnackbarContext()

  if (!contract) return null

  const handleResendEmail = async () => {
    try {
      const { error } = await sendContract()

      if (!error) {
        onOpenSnackbar('Email sent successfully', 'success')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  const handleArchive = async () => {
    try {
      const { error } = await archiveContract()

      if (!error) {
        onOpenSnackbar('Contract archived successfully', 'success')
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  const contractStatus = getContractStatus(contract)

  const contractOptions = !contract.active
    ? [
        {
          label: 'View',
          onClick: () =>
            goTo(`/v2/contract-details/${contract.id}`, {
              initialValues: { contract },
            }),
        },
      ]
    : [
        {
          label: 'View',
          onClick: () =>
            goTo(`/v2/contract-details/${contract.id}`, {
              initialValues: { contract },
            }),
        },
        contract?.signed
          ? {
              label: 'Download PDF',
              href: `/contracts/${contract.id}/pdf`,
              target: '_blank',
            }
          : {
              label: contract?.emailSent ? 'Resend email' : 'Send email',
              onClick: handleResendEmail,
            },
        {
          label: 'Archive',
          onClick: handleArchive,
        },
      ]

  const createdAt = getDateTimeWithoutTimezone(contract.createdAt)

  return (
    <GalleryItem
      preview={Document}
      contractStatus={contractStatus}
      options={contractOptions}
    >
      <Typography fontWeight="500" fontSize="16px">
        Treatment Contract {`#${contract.identifierToken}`}
      </Typography>
      <Typography fontSize="14px">{format(createdAt, DATE_FORMAT)}</Typography>
    </GalleryItem>
  )
}

export default Contract
