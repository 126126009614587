import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import SVGInline from 'react-svg-inline/src'
import TrashIcon from '#/assets/images/v2/icons/trash.svg?inline'
import { Cell, Row } from '../../styles'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'

import usePaymentMethods from '@/v2/hooks/usePaymentMethods'

const BankInfo = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.gray.dark};
`

const AccountCard = ({ card, patient, onClose, count }) => {
  const theme = useTheme()
  const { removeInvoicesPaymentMethod } = usePaymentMethods()
  const { refetch } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const handleClick = async e => {
    e.preventDefault()

    if (
      confirm(
        'All invoices using this bank account will be effected.\n\nAre you sure you want to delete this account?'
      ) === true
    ) {
      await removeInvoicesPaymentMethod({
        patientId: patient.id,
        paymentMethodId: card.id,
      })

      await refetch()

      onClose()
    }
  }

  return (
    <Row>
      <Cell>
        <AccountBalanceIcon
          sx={{
            height: '29px',
            width: '70px',
          }}
        />
      </Cell>

      <Cell>
        <p>**** **** {card.last4}</p>
        <BankInfo theme={theme.palette}>{card.brand}</BankInfo>
      </Cell>

      <Cell className="flex-center">
        {/* TODO: use modal hook for redirect */}
        {count > 1 ? (
          <a
            href="app/javascript/v2/containers/patient-finance/ManageCards/AccountCard/index#"
            onClick={handleClick}
          >
            <SVGInline svg={TrashIcon} width="20px" height="20px" />
          </a>
        ) : null}
      </Cell>
    </Row>
  )
}

export default AccountCard
