import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 16px;
  padding-bottom: 20px;
`
// TODO: review bottom margin on small modals, probably reset.scss is the reason
const DefaultFooter = ({
  cancelLabel,
  confirmLabel,
  showConfirm,
  showClose,
  onClose,
  onCancel,
  onConfirm,
  submitDisabled,
}) => {
  const theme = useTheme()

  return (
    <Wrapper theme={theme.palette}>
      {showClose && (
        <Button variant="text" onClick={onCancel ? onCancel : onClose}>
          <Typography fontWeight={400} fontSize="14px">
            {cancelLabel}
          </Typography>
        </Button>
      )}
      {showConfirm && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onConfirm}
          disabled={submitDisabled}
        >
          {confirmLabel}
        </Button>
      )}
    </Wrapper>
  )
}
export default DefaultFooter
