export const stringIsNumber = value => !isNaN(value.replace(/\s/g, ''))

export const sanitizeConversationSearch = value => {
  const _value = value.replace(/\(|\)|-/g, '')

  if (stringIsNumber(_value)) {
    return _value.replace(/\s/g, '')
  }

  return _value
}
