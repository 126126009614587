import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Typography from '@/v2/components/common/Typography'
import { useMessagesContext } from '@/v2/contexts/MessagesContext'

const SubmitMessage = styled.div`
  background: ${({ theme }) => theme.background.default};
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  padding-bottom: 16px;
`

const SendMessage = ({ onSend }) => {
  const theme = useTheme()
  const { message, patient, onSetMessage } = useMessagesContext()

  const handleSubmit = () => {
    if (message.trim().length === 0) return
    if (!patient?.intakeForm?.uuid && message.indexOf('{HH form link}') >= 0)
      return alert("This patient doesn't have an intake form link to send.")

    onSend(
      message.replace(
        /{HH form link}/g,
        patient?.intakeForm?.status === 'completed'
          ? `https://smilebar.clearworks.com/intake_forms/${patient?.intakeForm?.id}`
          : `https://smilebar.clearworks.com/intake_forms/${patient?.intakeForm?.uuid}/intake_responses/new`
      )
    )

    onSetMessage('')
  }

  const handleKeyDown = event => {
    const shift = event.shiftKey
    const enter = event.key === 'Enter'

    if (shift && enter) {
      event.preventDefault()
      handleSubmit()
    }
  }

  return (
    <SubmitMessage theme={theme.palette}>
      <OutlinedInput
        fullWidth
        multiline
        autoFocus
        maxRows={6}
        value={message}
        placeholder="Type a message and press shift+enter to send"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={handleSubmit}
              onMouseDown={e => e.preventDefault()}
              sx={{
                background: theme.palette.primary.main,
                color: `${theme.palette.primary.contrastText} !important`,
                borderRadius: '0',
                marginLeft: '-40px',
                height: '120px',
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </InputAdornment>
        }
        onChange={e => onSetMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ overflow: 'hidden', mb: '10px', padding: '2px 0 4px 0' }}
      />
      <Typography fontSize="12px" color="#7D7D7D">
        <b>Enter</b> adds a new line, <b>Shift + Enter</b> sends,{' '}
        <b>{'{HH form link}'}</b> inserts the patient Health History form link.
      </Typography>
    </SubmitMessage>
  )
}

export default SendMessage
