import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@/v2/components/common/Divider'
import { Wrapper, Item } from '../styles'
import { formatLabel } from './utils'

const Providers = ({ providers }) => {
  if (!providers)
    return (
      <Typography variant="span" fontWeight={200} textAlign="center">
        No Providers data found
      </Typography>
    )

  return (
    <Wrapper>
      {providers.map((item, index) => (
        <Item key={index}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              Kind:{' '}
              <Typography variant="span" fontWeight={200}>
                {item?.kind}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              NPI:{' '}
              <Typography variant="span" fontWeight={200}>
                {item?.npi}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              Organization:{' '}
              <Typography variant="span" fontWeight={200}>
                {formatLabel(item?.organization)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              Specialty:{' '}
              <Typography variant="span" fontWeight={200}>
                {item?.specialty}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              Tax Id:{' '}
              <Typography variant="span" fontWeight={200}>
                {item?.taxId}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4" fontWeight={500}>
              Taxonomy:{' '}
              <Typography variant="span" fontWeight={200}>
                {item?.taxonomy}
              </Typography>
            </Typography>
          </Grid>
          <Divider margin="20px 0px" />
        </Item>
      ))}
    </Wrapper>
  )
}

export default Providers
