import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURLGraphql, headers, baseURL } from '@/v2/constants/requests'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import request from 'graphql-request'
import UpdatePatient from './graphql/mutations/UpdatePatient.gql'
import CreatePatient from './graphql/mutations/CreatePatient.gql'
import GetPatient from './graphql/queries/GetPatient.gql'

const usePatient = ({ patientId, rest = true }) => {
  const { onSetPatient } = usePatientContext()
  const queryClient = useQueryClient()

  const {
    data,
    isLoading: isPatientLoading,
    isError: isPatientError,
  } = useQuery({
    queryKey: ['GetPatient', patientId, baseURL],
    queryFn: async () => {
      const res = await fetch(`${baseURL}/v2/rest/patients/${patientId}`)
      if (!res.ok) {
        throw new Error('We could not find the patient you are looking for')
      }
      const data = await res.json()

      if (data) {
        onSetPatient(data)
      }

      return data
    },
    enabled: !!patientId && rest,
  })

  const {
    data: patientData,
    isLoading: isPatientDataLoading,
    isError: isPatientDataError,
  } = useQuery({
    queryKey: ['GetPatientGql', patientId, baseURLGraphql, GetPatient, headers],
    queryFn: async () =>
      request(baseURLGraphql, GetPatient, { id: patientId }, headers),
    enabled: !!patientId && !rest,
  })

  const {
    mutateAsync: createPatient,
    isLoading: isCreatingPatient,
    isError: isCreatingPatientError,
  } = useMutation({
    mutationKey: ['CreatePatient', baseURLGraphql, headers],
    mutationFn: async variables =>
      request(baseURLGraphql, CreatePatient, variables, headers),
  })

  const {
    mutateAsync: updatePatient,
    isLoading: isUpdatingPatient,
    isError: isUpdatingPatientError,
  } = useMutation({
    mutationKey: ['UpdatePatient', baseURLGraphql, headers],
    mutationFn: async variables =>
      request(baseURLGraphql, UpdatePatient, variables, headers),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'GetPatientGql',
          patientId,
          baseURLGraphql,
          GetPatient,
          headers,
        ],
      })
    },
  })

  return {
    patient: data?.patient || null,
    patientData: patientData?.patient || null,
    isPatientDataLoading,
    isPatientDataError,
    isPatientLoading,
    isPatientError,
    updatePatient,
    isUpdatingPatient,
    isUpdatingPatientError,
    createPatient,
    isCreatingPatient,
    isCreatingPatientError,
  }
}

export default usePatient
