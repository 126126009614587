import React, { useState } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useUpdatePatient } from '@/v2/hooks/patient/usePatient'
import { formatPhone } from '@/v2/utils/format'
import Form from './Form'
import validator from './validator'
import { toSnakeCase, toCamelCase } from '@/v2/utils/format'

const EditPatient = ({ patient, isOpen, onClose, onSuccess }) => {
  const [values, setValues] = useState({
    phoneNumber: formatPhone(patient.phoneNumber || ''),
    email: patient.email || '',
    address1: patient.address1 || '',
    address2: patient.address2 || '',
    city: patient.city || '',
    state: patient.state || '',
    country: patient.country || '',
    zipcode: patient.zipcode || '',
  })
  const [error, setError] = useState({})
  const { updatePatient } = useUpdatePatient(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()

  const handleEditPatient = async () => {
    try {
      // TODO: ask to migrate from snake case to camel case
      const { error, ...data } = await updatePatient({
        patient: toSnakeCase({
          id: patient.id,
          ...values,
        }),
      })

      if (error) {
        setError(toCamelCase(error))
      } else {
        onOpenSnackbar('Patient has been updated.', 'success')
        onSuccess && onSuccess(data)
        onClose()
      }
    } catch (error) {
      console.log(error)
      onOpenSnackbar('Something went wrong.', 'error')
    }
  }

  const handleSubmit = () => {
    const validErrors = validator(values)
    setError(validErrors || {})

    if (!validErrors) {
      handleEditPatient()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Edit Patient"
      confirmLabel="Save"
      cancelLabel="Cancel"
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Form
        values={values}
        error={error}
        onChange={value => setValues({ ...values, ...value })}
      />
    </Modal>
  )
}

export default EditPatient
