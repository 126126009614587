import React, { useEffect } from 'react'
import SVGInline from 'react-svg-inline'
import Tab from '@/v2/components/common/Tab'
import CalendarIcon from '#/assets/images/v2/icons/calendar.svg?inline'
import MessagesIcon from '#/assets/images/v2/icons/messages.svg?inline'
import { useNotification } from '@/v2/hooks/messages/useNotification'
import RTNotifications from '@/v2/hooks/subscriptions/messages/RTNotifications'
import PatientTabs from './PatientTabs'
import { TabWrapper, Icon, Badge } from './styles'

const Tabs = () => {
  const { notification = {} } = useNotification()
  const { messagesUnreadCount = 0, conversationsUnreadCount = 0 } = notification

  useEffect(() => {
    // Update page title with unread messages count
    const pageTitle = document.title.replace(/^\(\d+\)\s/, '')
    document.title =
      messagesUnreadCount > 0
        ? `(${messagesUnreadCount}) ${pageTitle}`
        : pageTitle
  }, [messagesUnreadCount])

  const handleRequestPermission = () => {
    const isSupported = () =>
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    if (isSupported()) {
      const hasPermission = Notification.permission === 'granted'
      hasPermission && Notification.requestPermission()
    }
  }

  return (
    <>
      <RTNotifications />
      <TabWrapper>
        <Tab title="Calendar" href="/v2/calendar/day" justify="center">
          <Icon>
            <SVGInline svg={CalendarIcon} />
          </Icon>
        </Tab>
        <Tab
          id="messages-button"
          title="Messages"
          href="/v2/messages"
          justify="center"
          onClick={handleRequestPermission}
        >
          {messagesUnreadCount > 0 || conversationsUnreadCount > 0 ? (
            <Badge
              badgeContent={messagesUnreadCount || ''}
              color="secondary"
              variant={messagesUnreadCount === 0 ? 'dot' : 'standard'}
            >
              <Icon>
                <SVGInline svg={MessagesIcon} />
              </Icon>
            </Badge>
          ) : (
            <Icon>
              <SVGInline svg={MessagesIcon} />
            </Icon>
          )}
        </Tab>
        <PatientTabs />
      </TabWrapper>
    </>
  )
}

export default Tabs
