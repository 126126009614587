import React from 'react'
import ClockIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'

const MessageStatus = ({ type, status }) => {
  if (type === 'Outgoing' || type === 'Reminder') {
    if (status === 'sending' || status === 'failed')
      return (
        <ClockIcon sx={{ fontSize: '14px', marginBottom: '-2px', mx: '4px' }} />
      )
    else if (status === 'sent')
      return (
        <CheckIcon sx={{ fontSize: '14px', marginBottom: '-2px', mx: '2px' }} />
      )
  }
  return null
}

export default MessageStatus
