import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useTheme } from '@mui/material/styles'
import { format } from 'date-fns'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import { TIME_IN_US_FORMAT, DAY_IN_US_FORMAT } from '@/v2/constants'
import { NoteWrapper, InfoWrapper } from './styles'
import NoteForm from './NoteForm'
import { differenceInDays } from 'date-fns'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import { parseHtmlToString } from '@/v2/utils/helpers'

const getTime = daytime =>
  format(
    getDateTimeWithoutTimezone(daytime),
    `${TIME_IN_US_FORMAT} - ${DAY_IN_US_FORMAT}`
  )

const Notes = ({ notes, appointmentId, onRemove, onChange }) => {
  const theme = useTheme()
  const [edit, setEdit] = useState()

  return notes.map(note => {
    const { id, annotation, createdAt, updatedAt, user } = note

    const currentDate = nowOnClinicTimezone()
    const noteCreatedAt = getDateTimeWithoutTimezone(createdAt)
    const daysOld = differenceInDays(currentDate, noteCreatedAt)

    const isEditable = daysOld < 1

    return (
      <NoteWrapper key={id} theme={theme.palette} className="note-item">
        <Typography
          fontSize="15px"
          wordBreak
          fontWeight="300"
          sx={{ mb: '22px' }}
        >
          {parseHtmlToString(annotation)}
        </Typography>
        <Typography fontSize="14px" fontWeight={500}>
          {`Dr. ${user?.fullName || 'Not Assigned'}`}
        </Typography>
        <InfoWrapper>
          <Typography
            fontSize="14px"
            fontWeight="300"
            sx={{ mr: '10px' }}
            title={`Created at ${getTime(createdAt)} by ${
              user.fullName
            }, updated at ${getTime(updatedAt)}`}
          >
            {getTime(updatedAt)}
          </Typography>
          {isEditable && (
            <IconMenu
              icon={<MoreVertIcon fontSize="small" />}
              aria-label="More"
              title="More"
              style={{ marginLeft: 'auto', marginRight: '0' }}
              options={[
                { label: 'Edit', onClick: () => setEdit(note) },
                { label: 'Remove', onClick: () => onRemove(note) },
              ]}
            />
          )}
        </InfoWrapper>
        {!!edit && edit.id === id && (
          <NoteForm
            note={edit}
            appointmentId={appointmentId}
            onChange={onChange}
          />
        )}
      </NoteWrapper>
    )
  })
}

export default Notes
