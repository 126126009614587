import styled from 'styled-components'
import { INFOBAR_HEIGHT } from '@/v2/constants'

export const Wrapper = styled.div`
  height: calc(100% - ${INFOBAR_HEIGHT});
  overflow-y: auto;
  padding: 38px 64px 60px 48px;
  background: ${({ theme }) => theme.silver.main};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const Summary = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 38px;
`

export const WhiteBox = styled.div`
  height: 105px;
  width: 100%;
  border-radius: 3px;
  background: ${({ theme }) => theme.white.main};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  gap: 26px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
