import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import Provider from './Provider'
import * as Yup from 'yup'
import {
  useAddClaimProvider,
  useGetTaxonomyCodes,
  useRemoveClaimProvider,
  useUpdateClaimProvider,
} from '@/v2/hooks/insurance/claim-subforms/useClaimProviders'

const DEFAULT_PROVIDER_DATA = {
  provider: '',
  taxId: '',
  npi: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  kind: '',
  type: '',
  specialty: '',
  taxonomy: '',
  npiType: '',
}

// Define Yup validation schema
const providerSchema = Yup.object().shape({
  type: Yup.string().required('Provider type is required'),
  specialty: Yup.string().required('Specialty is required'),
  taxonomy: Yup.string().required('Taxonomy is required'),
  taxId: Yup.string().required('Tax ID is required'),
  npi: Yup.string().required('NPI is required'),
  npiType: Yup.string().required('NPI type is required'),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zipcode is required'),
  kind: Yup.string().required('Kind of address is required'),
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

const formatTaxonomyOptions = (taxonomies = []) =>
  taxonomies.map(({ code, specialty, category }) => ({
    value: code,
    label: `${code} - ${specialty || category || ''}`,
  }))

const Providers = ({ values = {}, onChange, claimId }) => {
  const [providers, setProviders] = useState(values.providers || [])
  const [editingProviderId, setEditingProviderId] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [initialProviderData, setInitialProviderData] = useState(
    DEFAULT_PROVIDER_DATA
  )
  const [validationErrors, setValidationErrors] = useState({})
  const { addClaimProvider } = useAddClaimProvider(claimId)
  const { removeClaimProvider } = useRemoveClaimProvider(claimId)
  const { taxonomyCodes } = useGetTaxonomyCodes()
  const { updateClaimProvider } = useUpdateClaimProvider(
    claimId,
    editingProviderId
  )

  useEffect(() => {
    setProviders(values.providers || [])
  }, [values.providers])

  const updateProvider = (providerId, updatedValue) => {
    const updatedProviders = providers.map(provider =>
      provider.id === providerId ? { ...provider, ...updatedValue } : provider
    )
    setProviders(updatedProviders)
  }

  const handleAddProvider = () => {
    setIsAdding(true)
    setEditingProviderId(null)
    setInitialProviderData(DEFAULT_PROVIDER_DATA)
    setValidationErrors({})
  }

  const handleSaveProvider = async () => {
    try {
      await providerSchema.validate(initialProviderData, { abortEarly: false })
      setValidationErrors({})

      const providerWithId = initialProviderData.id
        ? initialProviderData
        : { ...initialProviderData, id: Date.now() }

      const body = {
        provider: {
          kind: providerWithId.type,
          specialty: providerWithId.specialty,
          taxonomy: providerWithId.taxonomy,
          taxId: providerWithId.taxId,
          npi: providerWithId.npi,
          npiType: providerWithId.npiType,
          addresses_attributes: [
            {
              address1: providerWithId.address1,
              address2: providerWithId.address2,
              city: providerWithId.city,
              state: providerWithId.state,
              zipcode: providerWithId.zipcode,
              kind: providerWithId.kind,
            },
          ],
        },
      }

      if (isAdding) {
        const res = await addClaimProvider(body)
        if (!res.error) {
          const newProviders = [...providers, res]
          setProviders(newProviders)
        }
      } else if (editingProviderId) {
        const res = await updateClaimProvider(body)
        if (!res.error) {
          const updatedProviders = providers.map(provider =>
            provider.id === editingProviderId ? providerWithId : provider
          )
          setProviders(updatedProviders)
        }
      }

      setIsAdding(false)
      setEditingProviderId(null)
      setInitialProviderData({})
    } catch (error) {
      const errors = {}
      error?.inner?.forEach(err => {
        errors[err.path] = err.message
      })
      setValidationErrors(errors) // Only set errors for the active provider
    }
  }

  const handleEditProvider = providerId => {
    setEditingProviderId(providerId)
    setIsAdding(false)
    const providerToEdit =
      providers.find(provider => provider.id === providerId) ||
      DEFAULT_PROVIDER_DATA
    setInitialProviderData(providerToEdit)
    setValidationErrors({})
  }

  const handleDeleteProvider = async providerId => {
    const res = await removeClaimProvider(providerId)
    if (!res.error) {
      const updatedProviders = providers.filter(
        provider => provider.id !== providerId
      )
      setProviders(updatedProviders)
    }
  }

  const handleCancelEdit = () => {
    setEditingProviderId(null)
    setIsAdding(false)
    setInitialProviderData({})
    setValidationErrors({})
  }

  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        <Typography variant="h5" bold>
          Providers
        </Typography>
      </Grid>
      {providers.map((provider, index) => (
        <Grid item key={provider.id} xs={12}>
          <Wrapper>
            <Provider
              isEdit={editingProviderId === provider.id}
              values={
                editingProviderId === provider.id
                  ? initialProviderData
                  : provider
              }
              errorAffixe={
                editingProviderId === provider.id
                  ? editingProviderId
                  : `provider[${index}]`
              }
              provider={provider}
              // Pass errors only for the active provider
              error={
                editingProviderId === provider.id ||
                (isAdding && index === providers.length)
                  ? validationErrors
                  : {}
              }
              onChange={value => setInitialProviderData(value)}
              onSave={handleSaveProvider}
              onEdit={() => handleEditProvider(provider.id)}
              onDelete={() => handleDeleteProvider(provider.id)}
              onCancel={handleCancelEdit}
              taxonomyCodes={formatTaxonomyOptions(taxonomyCodes)}
            />
          </Wrapper>
        </Grid>
      ))}

      <Grid item xs={12} sx={{ marginTop: '12px' }}>
        {!isAdding && editingProviderId === null ? (
          <Button fullWidth variant="outlined" onClick={handleAddProvider}>
            <Typography bold variant="label">
              + Add a Provider
            </Typography>
          </Button>
        ) : null}
      </Grid>
      {isAdding && (
        <Grid item xs={12}>
          <Wrapper>
            <Provider
              values={initialProviderData}
              errorAffixe="newProvider"
              error={validationErrors} // Pass errors only for the new provider
              isEdit={true}
              isAdding={true}
              onChange={newProvider => setInitialProviderData(newProvider)}
              onSave={handleSaveProvider}
              onCancel={handleCancelEdit}
              taxonomyCodes={formatTaxonomyOptions(taxonomyCodes)}
            />
          </Wrapper>
        </Grid>
      )}
    </>
  )
}

export default Providers
