import React, { useState } from 'react'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import Filterbar from '../Filterbar'
import Day from './Day'
import { Canvas } from '../styles'
import { usePeriodOfTime } from './hooks/usePeriodOfTime'
import { useLoadData } from './hooks/useLoadData'
import { nowOnClinicTimezone } from '@/v2/utils/convert'

const today = format(nowOnClinicTimezone(), 'yyyy-MM-dd')

const Daily = () => {
  const { date = today } = useParams()
  const [subjectView, setSubjectView] = useState('room')
  const [typeFilter, setTypeFilter] = useState([])
  const {
    startHour: defaultStartHour,
    endHour: defaultEndHour,
    changeStartHour,
    changeEndHour,
  } = usePeriodOfTime()

  const {
    loading,
    error,
    appointments,
    timeOffs,
    clinicHours,
    startHour,
    endHour,
  } = useLoadData(date)

  if (startHour) changeStartHour(startHour)
  if (endHour) changeEndHour(endHour)

  return (
    <Canvas>
      <Filterbar
        onChangeSubject={setSubjectView}
        onChangeType={setTypeFilter}
      />
      <Day
        date={date}
        subjectView={subjectView}
        typeFilter={typeFilter}
        loading={loading}
        error={error}
        appointments={appointments}
        timeOffs={timeOffs}
        clinicHours={clinicHours}
        defaultStartHour={defaultStartHour}
        defaultEndHour={defaultEndHour}
      />
    </Canvas>
  )
}

export default Daily
