import getRandomId from '@/v2/utils/random-id'

export const generateEmptyProvider = () => ({
  id: getRandomId(),
  provider: '',
  type: '',
  specialty: '',
  taxonomy: '',
  taxId: '',
  npi: '',
  npiType: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  kind: '',
})

export const generateEmptyProcedure = () => ({
  id: getRandomId(),
  procedureCode: '',
  fee: null,
  quantity: null,
})

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' }
]

export const sequenceCodeOptions = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Tertiary', value: 'tertiary' },
]

export const modalTitle = {
  edit: 'Edit Claim',
  view: 'View Claim',
  create: 'Create Claim',
}

export const ctaButtonTitle = {
  edit: 'Save',
  view: 'Close',
  create: 'Create',
}

export const modalHeihgt = {
  edit: 'auto',
  view: '45vh',
  create: 'auto',
}
