import React from 'react'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import getRandomId from '@/v2/utils/random-id'
import Label from '@/v2/components/common/FieldLabel'
import Typography from '@/v2/components/common/Typography'
import styled from 'styled-components'
import InputAdornment from '@mui/material/InputAdornment'

const Wrapper = styled(FormControl)`
  &.prefixed input {
    padding-left: 0;
  }
`

const TextField = ({
  fullWidth,
  label,
  labelAlignment = 'left',
  noMargin = true,
  prefix,
  error,
  warning,
  onChange,
  ...others
}) => {
  const id = getRandomId()

  return (
    <Wrapper
      variant="standard"
      sx={{ mb: noMargin ? '0px' : '23px' }}
      className={`${prefix ? 'prefixed' : ''}`}
      fullWidth={fullWidth}
    >
      {label && (
        <Label htmlFor={id} align={labelAlignment}>
          {label}
        </Label>
      )}
      <OutlinedInput
        id={id}
        startAdornment={
          prefix ? (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ) : null
        }
        onChange={e => onChange && onChange(e.target.value)}
        {...others}
      />
      {error && <Typography variant="error">{error}</Typography>}
      {warning && <Typography variant="warning">{warning}</Typography>}
    </Wrapper>
  )
}

export default TextField
