import { styled } from '@mui/material/styles'
import MUIRadioGroup from '@mui/material/RadioGroup'

export const RadioGroup = styled(MUIRadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  width: '100%',
  '& label': {
    padding: '24px 16px',
    margin: 0,
    '& span, & input[type="radio"]': {
      pointerEvents: 'none',
    },
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
  '& label:is(:hover, .selected)': {
    border: `1px solid ${theme.palette.link.main}`,
    borderRadius: '4px',
    padding: '23px 15px',
  },
  '& .hidden': {
    display: 'none',
  },
}))
